/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SvgIcon from "Controls/SvgIcon";
import { spacingIndex, spacing } from "../shared/spacing";
import { colors, shadow, transition } from "../shared/theme";

const InputWrapper = styled.div`
	${spacing}
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`;

const getBorderColor = ({ success, error }) => {
	if (success) {
		return colors.jungle;
	}
	if (error) {
		return colors.red;
	}
	return colors.metal;
};

const StyledTextArea = styled.textarea`
	width: 100%;
	max-height: ${({ maxHeight }) => maxHeight}px;
	font-size: ${spacingIndex * 2}px;
	background-color: ${colors.white};
	line-height: ${spacingIndex * 2.5}px;
	border: 1px solid ${(props) => getBorderColor(props)};
	resize: ${({ resize }) => (!resize ? "none" : "vertical")};
	transition: ${transition.main};
	${({ height }) => height && `height: ${height}px !important;`}
	border-radius: 6px;
	overflow: ${({ hideScrollbar }) => (hideScrollbar ? "hidden" : "auto")};
	padding: ${spacingIndex * 2}px
		${({ success, error }) => (success || error ? spacingIndex * 5 : spacingIndex * 2)}px
		${spacingIndex * 2}px ${spacingIndex * 2}px;

	&::placeholder {
		color: ${colors.metal};
	}

	&:hover {
		box-shadow: ${shadow.grayOutline};
	}

	&:focus {
		outline: none;
		border: 1px solid ${({ error }) => (error ? colors.red : colors.jungle)};
		box-shadow: ${({ error }) => (error ? shadow.redOutline : shadow.greenOutline)};
	}

	&:disabled {
		box-shadow: none;
		border: 1px solid ${colors.smoke};
		background-color: ${colors.smoke};
		cursor: not-allowed;
	}

	&::-webkit-scrollbar {
		width: ${spacingIndex}px;
		border-radius: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: ${colors.smoke};
		border-radius: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${colors.metal};
		border-radius: 6px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: ${colors.nickel};
		border-radius: 6px;
	}
`;

const minHeight = spacingIndex * 9;

const TextArea = ({
	success,
	error,
	value,
	disabled,
	hideScrollbar,
	placeholder,
	handleEvent,
	resize,
	maxHeight,
	blurEvent,
	onKeyDown,
	dataTest,
	height,
	...spacing
}) => {
	const [textAreaRef, setTextAreaRef] = useState(React.createRef());

	const updateHeight = () => {
		const updatedTextArea = { ...textAreaRef };
		updatedTextArea.current.style.height = "";
		if (updatedTextArea.current.scrollHeight > minHeight) {
			updatedTextArea.current.style.height = `${Math.min(
				updatedTextArea.current.scrollHeight,
				maxHeight
			)}px`;
			setTextAreaRef(updatedTextArea);
		}
	};
	const updateTextArea = (event) => {
		handleEvent(event);
		updateHeight();
	};

	useEffect(() => updateHeight(), [value]);

	return (
		<InputWrapper {...spacing}>
			<StyledTextArea
				data-test={dataTest}
				ref={textAreaRef}
				success={success}
				error={error}
				value={value}
				disabled={disabled}
				placeholder={placeholder}
				resize={resize}
				maxHeight={maxHeight}
				height={height}
				hideScrollbar={hideScrollbar}
				onChange={handleEvent && updateTextArea}
				onKeyDown={onKeyDown && onKeyDown}
				onBlur={blurEvent && blurEvent}
			/>
			{(success || error) && (
				<SvgIcon
					style={{
						zIndex: "9000",
						marginLeft: `-${spacingIndex * 5}px`,
						marginTop: `${spacingIndex * 3}px`,
						paddingRight: `${spacingIndex * 3}px`,
					}}
					icon={success ? "check-16" : "alert"}
					size={`${spacingIndex * 2}px`}
					alignSelf="start"
					fill={success ? colors.jungle : colors.red}
				/>
			)}
		</InputWrapper>
	);
};

TextArea.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	success: PropTypes.bool,
	resize: PropTypes.bool,
	hideScrollbar: PropTypes.bool,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	maxHeight: PropTypes.string,
	height: PropTypes.string,
	handleEvent: PropTypes.func,
	blurEvent: PropTypes.func,
	onKeyDown: PropTypes.func,
	dataTest: PropTypes.string,
};

TextArea.defaultProps = {
	value: "",
	resize: true,
	maxHeight: "9001",
};

export default TextArea;
