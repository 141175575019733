const getCulture = (state) => {
    var culture = localStorage.getItem('culture') || null;
    return culture ? culture : state.general.culture;
}

const setCulture = (culture) => {
    localStorage.setItem('culture', culture);
};

export
{
    getCulture, setCulture
}