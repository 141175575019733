import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import LineIcons from "LineIcons";
import Resources from "Resources";
import { TemperatureTemplate } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import Button from "Controls/Button";
import LineIconComponent from "Controls/LineIconComponent";
import { SmileyFactory } from "Controls/Smiley";

class QuestionTemperatureBackButton extends PureComponent {
	onGradeClick = () => {
		if (this.props.isReadOnlyAfterSubmit) {
			return null;
		}

		this.props.onGradeClick();
	};

	render() {
		return (
			<ContentWrapper
				display="flex"
				alignItems="center"
				position="absolute"
				top={this.props.top}
				bottom={this.props.bottom}
				left={this.props.left}>
				{!this.props.isReadOnlyAfterSubmit && (
					<Button
						aria-label={Resources[this.props.culture].back}
						backgroundColor="transparent"
						margin={this.props.questionType !== TemperatureTemplate.default ? "0" : "25px 0 0"}
						onClick={this.onGradeClick}>
						<LineIconComponent color="#5A5F66" content={LineIcons.arrowleft} fontSize="12px" />
					</Button>
				)}
				<SmileyFactory
					isReadOnly={this.props.isReadOnlyAfterSubmit}
					isTemperature={this.props.questionType === TemperatureTemplate.default}
					iconSize={this.props.questionType !== TemperatureTemplate.default ? "50px" : null}
					isWithShake={this.props.isWithShake}
					gradeType={this.props.questionType}
					grade={this.props.grade}
					color={this.props.color}
					onClick={this.onGradeClick}
				/>
			</ContentWrapper>
		);
	}
}

QuestionTemperatureBackButton.propTypes = {
	isWithShake: PropTypes.bool,
	questionType: PropTypes.number,
	grade: PropTypes.number,
	top: PropTypes.string,
	bottom: PropTypes.string,
	left: PropTypes.string,
	color: PropTypes.string,
	onGradeClick: PropTypes.func,
};

export default QuestionTemperatureBackButton;
