// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import Text from "Controls/Text";
import TextAreaComponent from "Controls/TextAreaComponent";
import SimpleDropdownSelect from "Controls/SimpleDropdownSelect";
import QuestionTalkModalFooterWrapper from "./QuestionTalkModalFooterWrapper";
import QuestionTalkInputLabel from "./QuestionTalkInputLabel";

class QuestionSelectContactScreen extends PureComponent {
	onCommentChange = (event) => {
		this.props.onCommentChange(event.target.value);
	};

	render() {
		return (
			<ContentWrapper
				backgroundColor={Colors.questionWindowBackground}
				backgroundColorXS={Colors.questionWindowBackgroundXS}
				paddingBottom="0"
				minHeightXS="99vh"
				paddingTopXS="60px">
				<ContentWrapper position="relative" padding="10px 10px 0">
					<ContentWrapper
						backgroundColor={Colors.backgroundLightGray}
						borderRadius="4px"
						padding="20px 15px 30px">
						<Text
							color={Colors.textGray}
							display="inline-block"
							fontWeight="500"
							fontSize="14px"
							lineHeight="18px"
							dangerouslySetInnerHTML={{ __html: this.props.text }}
						/>
						<QuestionTalkInputLabel text={Resources[this.props.culture].contact} />
						<SimpleDropdownSelect
							selected={this.props.selectedContact}
							options={this.props.contacts}
							labelPropName="Name"
							onChange={this.props.onContactChange}
							border={`1px solid ${Colors.borderLightGray}`}
						/>
						{
							!this.props.isExternalContact &&
							<>
								<QuestionTalkInputLabel
									text={Resources[this.props.culture].pleaseLeaveAVoluntaryComment}
								/>
								<TextAreaComponent
									value={this.props.comment}
									borderRadius="3px"
									height="140px"
									padding="10px 15px"
									fontSize="14px"
									fontWeight="500"
									color={Colors.darkGrayText}
									maxWidth="100%"
									width="100%"
									boxShadow={`0 2px 2px ${Colors.borderLightGray}`}
									onChange={this.onCommentChange}
								/>
							</>
						}
					</ContentWrapper>
				</ContentWrapper>
				<QuestionTalkModalFooterWrapper
					nextButtonText={Resources[this.props.culture].send}
					backButtonText={Resources[this.props.culture].undo}
					nextButtonAttribute={
						this.props.nextButtonAttribute || "follow-up-strategy-questions-step-2-send"
					}
					backButtonAttribute={
						this.props.backButtonAttribute || "follow-up-strategy-questions-step-2-undo"
					}
					onBackClick={this.props.onUndoClick}
					onNextClick={this.props.onSendClick}
				/>
			</ContentWrapper>
		);
	}
}

QuestionSelectContactScreen.propTypes = {
	isExternalContact: PropTypes.bool,
	culture: PropTypes.string,
	nextButtonAttribute: PropTypes.string,
	backButtonAttribute: PropTypes.string,
	comment: PropTypes.string,
	text: PropTypes.string,
	selectedContact: PropTypes.object,
	contacts: PropTypes.array,
	onUndoClick: PropTypes.func,
	onSendClick: PropTypes.func,
	onCommentChange: PropTypes.func,
	onContactChange: PropTypes.func,
};

export default QuestionSelectContactScreen;
