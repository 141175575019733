// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import Text from "Controls/Text";
import QuestionTalkModalFooterWrapper from "./QuestionTalkModalFooterWrapper";
import Question from "../Question";

class QuestionDoYouWantToTalkScreen extends PureComponent {
	render() {
		return(
			<ContentWrapper
				backgroundColor={Colors.questionWindowBackground}
				backgroundColorXS={Colors.questionWindowBackgroundXS}
				paddingBottom="0"
				minHeightXS="99vh"
				paddingTopXS="60px">
				<ContentWrapper
					position="relative"
					padding="10px 10px 0">
					<ContentWrapper
						backgroundColor={Colors.backgroundLightGray}
						borderRadius="4px"
						padding="20px 15px 30px">
						<Text
							color={Colors.textGray}
							display="inline-block"
							fontWeight="500"
							marginBottom={this.props.question ? "0" : "30px"}
							fontSize="14px"
							lineHeight="18px">
							{this.props.doYouWantToTalkDescription ? this.props.doYouWantToTalkDescription : Resources[this.props.culture].giveMoreInformationAboutYourAnswer }
						</Text>
						{ this.props.question &&
							<Question
								questionTextPadding="20px"
								wrapperMargin="15px 0 20px"
								questionPadding="0"
								question={this.props.question}
								isCommentDisabled={true}
								isReadOnlyAfterSubmit={true}
								culture={this.props.culture}
								questionColor="#4c4c4c"
								addCommentColor="#7b828c"
								questionFontSize="18px"
								questionFontSizeXS="16px"/>
						}
						<Text
							color={Colors.darkGrayText}
							display="inline-block"
							fontWeight="600"
							fontSize="16px">
							{this.props.doYouWantToTalkQuestion ? this.props.doYouWantToTalkQuestion : Resources[this.props.culture].doYouWantToTalkToSomeoneInYourOrganization }
						</Text>
					</ContentWrapper>
				</ContentWrapper>
				<QuestionTalkModalFooterWrapper
					nextButtonText={Resources[this.props.culture].yes}
					backButtonText={Resources[this.props.culture].noThanks}
					nextButtonAttribute={this.props.nextButtonAttribute || "follow-up-strategy-questions-step-1-yes"}
					backButtonAttribute={this.props.backButtonAttribute || "follow-up-strategy-questions-step-1-no"}
					onBackClick={this.props.onNoClick}
					onNextClick={this.props.onYesClick}/>
			</ContentWrapper>
		);
	}
}

QuestionDoYouWantToTalkScreen.propTypes = {
	culture: PropTypes.string,
	doYouWantToTalkDescription: PropTypes.string,
	doYouWantToTalkQuestion: PropTypes.string,
	nextButtonAttribute: PropTypes.string,
	backButtonAttribute: PropTypes.string,
	question: PropTypes.object,
	onNoClick: PropTypes.func,
	onYesClick: PropTypes.func,
}

export default QuestionDoYouWantToTalkScreen;