import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import { logoutUser } from "../actions/authentication";
import { acceptAgreement } from "../actions/general";
import { navigateToLogin } from "../actions/router";
import { getAuth } from "../actions/authentication.localStorage";
import { getCulture } from "../actions/culture";
import { withBackgroundHeightAdjust } from "../components/WithBackgroundHeightAdjust";
import SurveyGdpr from "../components/SurveyGdpr"

class GdprContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOnboarding: true,
            showAgreement: false
        }
    }

    acceptAgreement = () => {
        this.props.acceptAgreement();
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };

        if (!this.props.showGdprAgreement) {
            return <Redirect to={from} />;
        }

        return(
            <SurveyGdpr
                culture={this.props.culture}
                username={this.props.username}
                onNext={this.onNext}
                onAcceptAgreement={this.props.onAcceptAgreement}
                showOnboarding={this.state.showOnboarding}
                showAgreement={this.state.showAgreement}
                onLogout={this.props.onLogout}
            />
        );
    }

    onNext = () => {
        this.setState({showOnboarding: false, showAgreement: true});
    }
}

GdprContainer.propTypes = {
    culture: PropTypes.string,
    username: PropTypes.string,
    onLogout: PropTypes.func,
    onNext: PropTypes.func,
    onAcceptAgreement: PropTypes.func
}

const mapStateToProps = (state, { match }) => { 
    const authentication = getAuth();

    return {
        culture: getCulture(state),
        username: authentication ? authentication.name : null,
        showGdprAgreement: state.general.showGdprAgreement
    };
};

const mapDispatchToProps = (dispatch, {history}) => {
    return {
        onLogout: () => dispatch(logoutUser()).then(navigateToLogin(history)),        
        onAcceptAgreement: () => dispatch(acceptAgreement())
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withBackgroundHeightAdjust(GdprContainer)));