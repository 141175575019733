import { QuestionAdditionalStrategy } from "Enums";

export default (answers) => {
	const negativeBullying = answers.find((answer) => {
		const isNegativeBullying =
			answer.additionalInformationStrategy === QuestionAdditionalStrategy.negativeBullying;
		const isNegativeAnswer = answer.grade < 2;
		return !answer.skipReason && isNegativeBullying && isNegativeAnswer;
	});

	const negativeDiscrimination = answers.find((answer) => {
		const isNegativeDiscrimination =
			answer.additionalInformationStrategy === QuestionAdditionalStrategy.negativeDiscrimination;
		const isNegativeAnswer = answer.grade < 2;
		return !answer.skipReason && isNegativeDiscrimination && isNegativeAnswer;
	});
	return [negativeBullying, negativeDiscrimination].filter((x) => x !== undefined);
};
