import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { spacingIndex } from "ComponentsLibrary/shared/spacing";
import { shadow, colors } from "ComponentsLibrary/shared/theme";

const DropdownComponentWrapper = styled.div`
	position: ${(props) => props.position || "absolute"};
	${(props) => (props.top ? `top: ${props.top};` : null)}
	overflow: hidden;
	padding-top: ${(props) => (props.isWithTabs ? "0" : props.paddingTop || `${spacingIndex}px`)};
	padding-bottom: ${spacingIndex}px;
	display: block;
	width: max-content;
	min-width: ${(props) => props.minWidth || "180px"};
	max-width: ${(props) => props.maxWidth || "500px"};
	background-color: ${colors.white};
	border-radius: ${(props) =>
		props.borderRadius || (props.bottom === "auto" ? "0 0 6px 6px" : "6px 6px 0 0")};
	border: ${(props) => props.border || "none"};
	z-index: 1000;
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
	box-shadow: ${shadow.fly};
	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
		border: none;
		position: fixed;
		padding-top: 0;
		left: 0;
		width: 100%;
		height: ${(props) => (props.isFullHeightXS ? "100%" : "auto")};
		min-height: ${(props) => (props.maxHeight === 0 ? "0" : props.dropdownHeight || "auto")};
		max-height: ${(props) => props.maxHeight};
		transition: all 0.25s linear 0.1s;
	}
`;

DropdownComponentWrapper.propTypes = {
	isWithTabs: PropTypes.bool,
	isFullHeightXS: PropTypes.bool,
	minWidth: PropTypes.string,
	bottom: PropTypes.string,
	right: PropTypes.string,
	maxHeight: PropTypes.string,
	dropdownHeight: PropTypes.string,
	border: PropTypes.string,
	maxWidth: PropTypes.string,
	borderRadius: PropTypes.string,
	paddingTop: PropTypes.string,
};

export default DropdownComponentWrapper;
