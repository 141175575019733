// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const AgreementWrapper = styled.div`
	flex: 1;
	width: ${(props) => props.width || "100%"};
	padding: ${(props) => props.padding || "20px 20px 10px"};
	margin: ${(props) => props.margin || "0"};
	overflow-y: ${(props) => props.overflowY || "auto"};
	border-radius: 6px;
	background-color: ${(props) => props.backgroundColor || "#764a8f"};
	${(props) => props.border && `border: ${props.border};`}
	${(props) => props.maxHeight && `max-height: ${props.maxHeight};`}
	& * {
		color: ${(props) => props.color || "#d9d9d9"};
		font-size: 14px;
		text-align: left;
		line-height: 18px;
		font-weight: ${(props) => props.fontWeight || "300"};
	}
	& h2 {
		margin: 0 0 10px;
		font-weight: 700;
		font-size: 15px;
		text-align: center;
		text-transform: uppercase;
	}
	& h3 {
		margin: 10px 0 10px;
		font-weight: 700;
		text-transform: uppercase;
	}
	& p {
		margin: 10px 0 10px;
	}
	& ul {
		margin: 10px 0 10px;
	}
	@media (max-width: 768px) {
		padding: ${(props) => props.paddingXS || "10px 10px 0"};
	}
`;

AgreementWrapper.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	fontWeight: PropTypes.string,
	margin: PropTypes.string,
	padding: PropTypes.string,
	backgroundColor: PropTypes.string,
	overflowY: PropTypes.string,
	border: PropTypes.string,
	maxHeight: PropTypes.string,
};

export default AgreementWrapper;
