// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import { TemperatureTemplate } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionTemperatureBackButton from "./QuestionTemperatureBackButton";
import QuestionText from "./QuestionText";

class QuestionMinified extends PureComponent {
	onGradeClick = () => {
		this.props.onGradeClick(null);
	};

	render() {
		const isAnswered = this.props.grade >= 0;

		return (
			<ContentWrapper
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				justifyContent="center"
				backgroundColor={Colors.backgroundLightGray}
				padding={this.props.questionPadding}
				paddingXS={this.props.questionPaddingXS}
				paddingLeft="60px"
				paddingLeftXS="60px"
				paddingTop={this.props.isWithQuestionCounter ? "0" : null}
				position="relative"
				minHeight={this.props.questionType !== TemperatureTemplate.default ? "60px" : "90px"}>
				<QuestionText
					isWithQuestionCounter={this.props.isWithQuestionCounter}
					color={this.props.questionColor}
					fontSize={this.props.questionFontSize}
					fontSizeXS={this.props.questionFontSizeXS}
					fontWeight={this.props.questionFontWeight}
					padding={this.props.questionTextPadding}
					paddingTop={(this.props.isWithQuestionCounter && !isAnswered) ? "0" : null}
					questionTextAlign={this.props.questionTextAlign}>
					{this.props.questionText}
				</QuestionText>
				<QuestionTemperatureBackButton
					isWithShake={this.props.isWithShake}
					culture={this.props.culture}
					isReadOnlyAfterSubmit={this.props.isReadOnlyAfterSubmit}
					questionType={this.props.questionType}
					grade={this.props.grade}
					bottom={this.props.questionType !== TemperatureTemplate.default ? "calc(50% - 25px)" : "calc(50% - 39px)"}
					left={this.props.isReadOnlyAfterSubmit ? "15px" : "7px"}
					color={this.props.gradeColor}
					onGradeClick={this.onGradeClick}
				/>
			</ContentWrapper>
		);
	}
}

QuestionMinified.propTypes = {
	isWithQuestionCounter: PropTypes.bool,
	isWithComment: PropTypes.bool,
	isWithShake: PropTypes.bool,
	isReadOnlyAfterSubmit: PropTypes.bool,
	questionType: PropTypes.number,
	grade: PropTypes.number,
	gradeColor: PropTypes.string,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	questionText: PropTypes.string,
	questionColor: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionFontWeight: PropTypes.string,
	questionTextPadding: PropTypes.string,
	questionTextAlign: PropTypes.string,
	culture: PropTypes.string,
	onGradeClick: PropTypes.func,
	onExpandQuestionClick: PropTypes.func,
};

export default QuestionMinified;
