// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";

const QuestionsFixedWrapper = styled.div`
	width: 100%;
	background-color: ${(props) => props.backgroundColor || Colors.questionWindowBackground};
	@media (max-width: 768px) {
		position: fixed;
		z-index: 1;
		left: 0;
		${(props) => (props.top ? `top: ${props.top};` : null)}
		${(props) => (props.bottom ? `bottom: ${props.bottom};` : null)}
        ${(props) => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom};` : null)}
        ${(props) => (props.backgroundColorXS ? `background-color: ${props.backgroundColorXS};` : null)}
	}
`;

QuestionsFixedWrapper.propTypes = {
	top: PropTypes.string,
	bottom: PropTypes.string,
	paddingBottom: PropTypes.string,
	backgroundColor: PropTypes.string,
	backgroundColorXS: PropTypes.string,
};

export default QuestionsFixedWrapper;
