// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";

class QuestionProgressBar extends PureComponent {
    render() {
        return(
            <ContentWrapper
                width="100%"
                backgroundColor={Colors.questionWindowProgressBackground}>
                <ContentWrapper 
                    width={`${this.props.progressWidth < 101 && this.props.progressWidth > -1 ? this.props.progressWidth : 0}%`}
                    height="7px"
                    backgroundColor={Colors.questionWindowProgress}>
                </ContentWrapper>
            </ContentWrapper>
        );
    }
}

QuestionProgressBar.propTypes = {
    progressWidth: PropTypes.number
}

export default QuestionProgressBar;