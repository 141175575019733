export default function (str, ...vals) {
	// eslint-disable-next-line
	var regex = /\[([^\]^\|]*\|[^\]^\|]*)\]/g;
	var matches = str.matchAll(regex);
	var iterationIndex = 0;
	var args = vals[0];

	for (const match of matches) {
		var res = match[1].split('|');
		var replace = args[iterationIndex++] === 1 ? res[0] : res[1];
		str = str.replace(match[0], replace);
	}

	return str;
}