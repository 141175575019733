import React from "react";
import PropTypes from "prop-types";
import DropdownSelectComponent from "Controls/DropdownSelect/DropdownSelect";
import DropdownSelectHeader from "./DropdownSelectHeader";
import DescriptiveDropdownSelectHeader from "./DescriptiveDropdownSelectHeader";

const renderDescriptiveDropdownHeader = (title, onMainViewClick, isOpened, selectedItems, disabled) => (
	<DescriptiveDropdownSelectHeader
		fontType="normalBold"
		label={title}
		onClick={onMainViewClick}
		mr={1}
		disabled={disabled}
	/>
);

const DropdownSelect = (props) => {
	const renderDropdownHeader = (title, onMainViewClick, isOpened, selectedItems, disabled) => (
		<DropdownSelectHeader
			dataTest={"dropdow-header-" + props.dataTest}
			error={props.error}
			width={props.width}
			active={isOpened}
			label={title}
			onClick={onMainViewClick}
			disabled={disabled}
			mr={1}
		/>
	);

	return (
		<DropdownSelectComponent
			{...props}
			dataTestItem={props.dataTest}
			renderCustomHeader={
				props.renderCustomHeader ||
				(props.isDiscriptive ? renderDescriptiveDropdownHeader : renderDropdownHeader)
			}
		/>
	);
};

DropdownSelect.propTypes = {
	error: PropTypes.bool,
	isDiscriptive: PropTypes.bool,
	dataTest: PropTypes.string,
	width: PropTypes.string,
	culture: PropTypes.string,
	noSelectedLabel: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	renderCustomHeader: PropTypes.func,
};

export default DropdownSelect;
