// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const DURATION = "0.5s";

class SmileyStronglyDisagree extends PureComponent {
    render() {
        if (!this.props.isWithAnimation) {
            return (
                <g fill="#41464D" fillRule="nonzero" transform={this.props.isTemperature ? "translate(12.52 43.28)" : "translate(12.52 17.28)"}>
                    <ellipse cx="5" cy="6.74" rx="2.3" ry="1.7" transform="rotate(20, 5, 6.74)"></ellipse>
                    <path stroke="#41464D" strokeWidth="1.5" fill="none" d="M 8.61 14.6 q 5 -5 10 0"></path>
                    <ellipse cx="21.4" cy="6.74" rx="2.3" ry="1.7" transform="rotate(-20, 21.4, 6.74)"></ellipse>
                </g>
            );
        }

        return (
            <g fill="#41464D" fillRule="nonzero" transform={this.props.isTemperature ? "translate(12.52 43.28)" : "translate(12.52 17.28)"}>
                <ellipse cx="2.77" cy="2.74" rx="2.3" ry="2.3" transform="rotate(20, 5, 6.74)">
                    <animate attributeName="cx" dur={DURATION} from="2.77" to="5" fill="freeze" />
                    <animate attributeName="cy" dur={DURATION} from="2.74" to="6.74" fill="freeze" />
                    <animate attributeName="ry" dur={DURATION} from="2.3" to="1.7" fill="freeze" />
                </ellipse>
                <path stroke="#41464D" strokeWidth="1.5" fill="none">
                    <animate attributeName="d" dur={DURATION} from="M 6.25 10.72 q 5 0 10 0" to="M 8.61 14.6 q 5 -5 10 0" fill="freeze" />
                </path>
                <ellipse cx="19.4" cy="2.74" rx="2.3" ry="2.3" transform="rotate(-20, 21.4, 6.74)">
                    <animate attributeName="cx" dur={DURATION} from="19.4" to="21.4" fill="freeze" />
                    <animate attributeName="cy" dur={DURATION} from="2.74" to="6.74" fill="freeze" />
                    <animate attributeName="ry" dur={DURATION} from="2.3" to="1.7" fill="freeze" />
                </ellipse>
            </g>
        );
    }
}

SmileyStronglyDisagree.propTypes = {
    isWithAnimation: PropTypes.bool,
    isTemperature: PropTypes.bool
}

export default SmileyStronglyDisagree;