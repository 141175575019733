// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class SmileyHmeAgree extends PureComponent {
    render() {
        return(
            <g fill="none" fillRule="nonzero" transform={this.props.isTemperature ? "translate(0 26)" : ""}>
                <path fill="#38401F" d="M29.05 26.39h-1.3v2.22h-2.2v-2.22h-4.57V24.8l4.69-6.91h2.09v6.73h1.29v1.76zm-3.5-1.76V22.8a33.65 33.65 0 0 1 .1-2.32h-.07c-.18.4-.4.8-.65 1.17l-1.96 2.97h2.58z" opacity=".8"/>
            </g>
        );
    }
}

SmileyHmeAgree.propTypes = {
    isTemperature: PropTypes.bool
}

export default SmileyHmeAgree;