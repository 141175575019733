import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import HeaderWithProfile from "./HeaderWithProfile";
import { QuestionSubmit, SurveyContent, QuestionProgressBar } from "./Question";

const Wrapper = styled.div`
	background: ${Colors.questionWindowBackground};
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 30px;
	@media (max-width: 1024px) {
		min-height: 99vh;
		padding-top: 30px;
		padding-top: calc(30px + env(safe-area-inset-bottom));
		padding-bottom: ${(props) => props.paddingBottom || "60px"};
		padding-bottom: calc(${(props) => props.paddingBottom || "60px"} + env(safe-area-inset-bottom));
	}
	@media (max-width: 425px) {
		padding-top: calc(30px + env(safe-area-inset-bottom));
		padding-bottom: ${(props) =>
			props.isAllAnswered
				? `calc(${props.showChristmasModeToggle ? 158 : 126}px + env(safe-area-inset-bottom))`
				: `calc(${props.showChristmasModeToggle ? 102 : 70}px + env(safe-area-inset-bottom))`};
	}
`;

class Survey extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeQuestionId: null,
		};
	}

	onSubmit = (completed) => {
		if (!completed) return;
		this.props.onSubmit(this.props.batch);
	};

	hasAnsweredQuestion = (question) => {
		if (question.skipReason) return true;

		return question.grade || question.grade === 0;
	};

	getInfoText = () => {
		return this.props.batch.isTest
			? this.props.t("ThisIsATestMailingAndYourAnswersWillNotBeSaved")
			: this.props.t("YourAnswersAreAnonymous");
	};

	getTitle = () => {
		return this.props.batch.isTest
			? `${this.props.t("Test")}: ${this.props.batch.description}`
			: this.props.batch.description;
	};

	onChange = (question) => {
		this.setState({ activeQuestionId: question.questionId }, () =>
			this.props.onChange(this.props.batch, question)
		);
	};

	onActiveQuestionChange = (questionId) => {
		this.setState({ activeQuestionId: questionId });
	};

	render() {
		if (!this.props.batch) return null;

		const notGeneralQuestions = this.props.batch.questions.filter((q) => !q.isGeneralComment);
		const answers = notGeneralQuestions.filter((x) => this.hasAnsweredQuestion(x)).length;
		const isAllAnswered = answers === notGeneralQuestions.length;
		const progressWidth = isAllAnswered ? 100 : Math.round((100 / notGeneralQuestions.length) * answers);

		return (
			<SurveyContainerWrapper className="with-auto-height-adjust">
				<ContentWrapper display="block" position="fixed" top="0" width="100%" zIndex="1">
					<HeaderWithProfile
						padding="20px 30px"
						paddingMedia="20px"
						username={this.props.username}
						culture={this.props.culture}
						onLogout={this.props.onLogout}
					/>
					<QuestionProgressBar progressWidth={progressWidth} />
				</ContentWrapper>
				<Wrapper
					showChristmasModeToggle={this.props.batch.customization.showChristmasModeToggle}
					isAllAnswered={isAllAnswered}>
					<SurveyContent
						showPatchInfoText={this.props.batch.showPatchInfoText}
						infoText={this.getInfoText()}
						culture={this.props.culture}
						title={this.getTitle()}
						questions={notGeneralQuestions}
						questionSettings={this.props.batch.questionSettings}
						activeQuestionId={this.state.activeQuestionId}
						onBackClick={this.props.onBack}
						onChange={this.onChange}
						onActiveQuestionChange={this.onActiveQuestionChange}
						isChristmasModeEnabled={this.props.batch.customization.isChristmasModeEnabled}
					/>

					<QuestionSubmit
						submitTrackId="checkin-questions-submit-btn"
						isGeneralCommentEnabled={this.props.isGeneralCommentEnabled}
						isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
						generalQuestion={this.props.batch.questions.find((q) => q.isGeneralComment)}
						onGeneralQuestionChange={this.onChange}
						isAllAnswered={isAllAnswered}
						isEnabled={!this.props.submitting}
						culture={this.props.culture}
						onSubmit={this.onSubmit}
						isCommentDisabled={this.props.batch.questionSettings.isCommentDisabled}
						isChristmasModeEnabled={this.props.batch.customization.isChristmasModeEnabled}
						showChristmasModeToggle={this.props.batch.customization.showChristmasModeToggle}
						onToggleChristmasMode={this.props.onToggleChristmasMode}
					/>
				</Wrapper>
			</SurveyContainerWrapper>
		);
	}
}

Survey.propTypes = {
	isGeneralCommentEnabled: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	submitting: PropTypes.bool,
	culture: PropTypes.string,
	username: PropTypes.string,
	batch: PropTypes.object,
	onBack: PropTypes.func,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default withTranslation()(Survey);
