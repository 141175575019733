import {
    CHANGE_CULTURE,
    ACCEPT_AGREEMENT_REQUEST,
    ACCEPT_AGREEMENT_SUCCESS,
    ACCEPT_AGREEMENT_ERROR,
    CHECK_AGREEMENT_REQUEST,
    CHECK_AGREEMENT_SUCCESS,
    CHECK_AGREEMENT_ERROR
} from "../actions/general";

const general = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_CULTURE:
            return {
                ...state,
                culture: action.culture
            };
        case ACCEPT_AGREEMENT_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case ACCEPT_AGREEMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                showGdprAgreement: false
            };
        case ACCEPT_AGREEMENT_ERROR:
            return {
                ...state,
                fetching: false
            };
        case CHECK_AGREEMENT_REQUEST:
            return {
                ...state,
                fetching: true
            };
        case CHECK_AGREEMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                showGdprAgreement: action.showGdprAgreement
            };
        case CHECK_AGREEMENT_ERROR:
            return {
                ...state,
                fetching: false
            };
        default:
            return state;
    }
}

export default general;