import { get, post, put } from "./api";
import {
	ESCALATE_TEMPERATURE_ID,
	ESCALATE_TALK_ID,
	ESCALATE_TEMPERATURE_URLS,
	ESCALATE_TALK_URLS,
	ESCALATE_TALK_EXTENDED_ID,
} from "../constants";
import { mapObject } from "../util/helpers";
import { heapTrack } from "Helpers";

const BATCHES_REQUEST = "BATCHES_REQUEST";
const BATCHES_REQUEST_SUCCESS = "BATCHES_REQUEST_SUCCESS";
const BATCHES_REQUEST_ERROR = "BATCHES_REQUEST_ERROR";
const BATCHES_CLEAR = "BATCHES_CLEAR";
const SUBMIT_BATCH_REQUEST = "SUBMIT_BATCH_REQUEST";
const SUBMIT_BATCH_SUCCESS = "SUBMIT_BATCH_SUCCESS";
const UPDATE_QUESTION = "UPDATE_QUESTION";
const UPDATE_ESCALATE = "UPDATE_ESCALATE";
const COMPLETE_BATCH = "COMPLETE_BATCH";
const GET_INFORMATION_STRATEGY_REQUEST = "GET_INFORMATION_STRATEGY_REQUEST";
const UPDATE_CHRISTMAS_THEME = "UPDATE CHRISTMAS THEME";

const mapEscalations = (questions, escalations, escalationOptions) => {
	return escalations.map((e) => ({
		...{ ...questions.find((x) => x.questionId === e.questionId) },
		...e,
		detailsOptions: escalationOptions
			.find((x) => x.questionId === e.questionId)
			.detailsOptions.map((o) => ({
				Id: o.id,
				Name: o.name,
				ParentId: o.parentId,
			})),
		contextOptions: escalationOptions
			.find((x) => x.questionId === e.questionId)
			.contextOptions.map((o) => ({
				Id: o.id,
				Name: o.name,
				ParentId: o.parentId,
			})),
		contacts: escalationOptions
			.find((x) => x.questionId === e.questionId)
			.contacts.map((c) => ({
				Key: c.key,
				Name: c.name,
			})),
	}));
};

const saveTemperatureComments = (data, batch, url) => {
	return (dispatch, getState) => {
		const state = getState();
		if (state.questions && state.questions.submitting) {
			return {};
		}

		dispatch(submitBatchRequest(true));
		return post(url, data, getState()).then((response) => {
			dispatch(submitBatchRequest(false));
			if (response.status >= 400) {
				return {
					success: false,
					status: response.status,
				};
			}
			dispatch(completeBatch(batch));
			return {
				success: true,
				status: response.status,
			};
		});
	};
};

const saveQuestionTalk = (data, batch, url) => {
	return (dispatch, getState) => {
		const state = getState();
		if (state.questions && state.questions.submitting) {
			return {};
		}

		dispatch(submitBatchRequest(true));
		return post(url, data, getState()).then((response) => {
			dispatch(submitBatchRequest(false));
			if (response.status >= 400) {
				return { success: false, status: response.status };
			}
			dispatch(completeBatch(batch));
			return {
				success: true,
				status: response.status,
			};
		});
	};
};

const requestBatches = () => {
	return (dispatch, getState) => {
		dispatch({
			type: BATCHES_REQUEST,
		});

		return get("questions", getState())
			.then((response) => {
				if (response.status >= 400) {
					return {
						success: false,
						status: response.status,
						batches: [],
						questionSettings: {},
					};
				}

				return {
					success: true,
					status: response.status,
					batches: response.data.batches,
					questionSettings: response.data.questionSettings,
				};
			})
			.then((result) => {
				if (result.success) dispatch(batchesRequestSuccess(result.batches, result.questionSettings));
				return result;
			});
	};
};

const getOptionsForInformationStrategies = (batch, questions, escalations) => {
	return (dispatch, getState) => {
		dispatch(getInformationStrategy(true));
		const requestData = questions.map((q) => ({
			QuestionId: q.questionId,
			Strategy: q.additionalInformationStrategy,
		}));
		return post(`informationStrategies`, requestData, getState()).then((response) => {
			if (response.status >= 400) {
				return { success: false, status: response.status };
			}

			dispatch(getInformationStrategy(false));

			if (!response.data || response.data.some((x) => x.Contacts?.length === 0)) {
				return dispatch(submitBatch(batch));
			}
			const mappedEscalations = mapEscalations(questions, escalations, response.data);

			const escalated = {
				questions,
				answers: batch.questions,
				batchExecutionUserId: batch.batchExecutionUserId,
				escalateTalkExtendedData: {
					answers: batch.questions,
					escalations: mappedEscalations,
					batchExecutionUserId: batch.batchExecutionUserId,
				},
				typeId: ESCALATE_TALK_EXTENDED_ID,
			};

			dispatch(updateEscalate(batch, escalated));
			heapTrack("Discrimination/Bullying follow-up");

			return {
				success: true,
				result: response.result,
				status: response.status,
				escalated: escalated,
			};
		});
	};
};

const getOptionsForInformationStrategy = (batch, question) => {
	return (dispatch, getState) => {
		dispatch(getInformationStrategy(true));
		return get(
			`informationStrategyOptions?additionalInformationStrategy=${question.additionalInformationStrategy}`,
			getState()
		).then((response) => {
			if (response.status >= 400) {
				return { success: false, status: response.status };
			}

			dispatch(getInformationStrategy(false));

			if (!response.data.contacts || !response.data.contacts.length) {
				return dispatch(submitBatch(batch));
			}

			const escalated = {
				question,
				answers: batch.questions,
				batchExecutionUserId: batch.batchExecutionUserId,
				detailsOptions: response.data.detailsOptions.map((o) => ({
					Id: o.id,
					Name: o.name,
					ParentId: o.parentId,
				})),
				contextOptions: response.data.contextOptions.map((o) => ({
					Id: o.id,
					Name: o.name,
					ParentId: o.parentId,
				})),
				contacts: response.data.contacts.map((c) => ({
					Key: c.key,
					Name: c.name,
				})),
				typeId: ESCALATE_TALK_EXTENDED_ID,
			};

			dispatch(updateEscalate(batch, escalated));
			heapTrack("Discrimination/Bullying follow-up");

			return {
				success: true,
				result: response.result,
				status: response.status,
				escalated: escalated,
			};
		});
	};
};

const onQuestionTalkExtendedSend = (batch, answers) => {
	const talkModels = answers.filter((t) => t.talkModel).map((x) => x.talkModel);

	const mappedAnswers = answers.map((question) => {
		const mappedQuestion = {
			questionId: question.questionId,
			grade: question.grade,
			comment: question.comment,
			visibleForImmediateManager: question.visibleForImmediateManager,
			allowAiAssistedReplies: question.allowAiAssistedReplies,
			isCustom: question.isCustom,
			adapter: question.adapter,
			type: question.type,
			skipReason: question.skipReason,
		};
		if (question.additionalInformation)
			mappedQuestion.additionalInformation = question.additionalInformation;
		return mappedQuestion;
	});

	const dataForSave = {
		type: batch.type,
		batchExecutionUserId: batch.id,
		answers: mappedAnswers,
	};

	return (dispatch, getState) => {
		const state = getState();
		if (state.questions && state.questions.submitting) {
			return {};
		}

		dispatch(submitBatchRequest(true));

		return post("questions", dataForSave, getState()).then((response) => {
			if (response.status >= 400) {
				return { success: false, status: response.status };
			}

			if (talkModels && talkModels.length > 0) {
				const url = ESCALATE_TALK_URLS.submitDefaults;
				post(url, talkModels, getState());
			}
			dispatch(submitBatchRequest(false));
			dispatch(completeBatch(batch));
			dispatch(submitBatchSuccess(batch));
			return {
				success: true,
				result: response.data.value.Result,
				status: response.status,
			};
		});
	};
};

const submitBatch = (batch) => {
	const answers = batch.questions.map((question) => {
		return {
			questionId: question.questionId,
			grade: question.grade,
			comment: question.comment,
			visibleForImmediateManager: question.visibleForImmediateManager,
			allowAiAssistedReplies: question.allowAiAssistedReplies,
			isCustom: question.isCustom,
			adapter: question.adapter,
			type: question.type,
			skipReason: question.skipReason,
		};
	});

	const dataForSave = {
		type: batch.type,
		batchExecutionUserId: batch.id,
		answers,
	};

	return (dispatch, getState) => {
		const state = getState();
		if (state.questions && state.questions.submitting) {
			return {};
		}

		dispatch(submitBatchRequest(true));
		return post("questions", dataForSave, getState())
			.then((response) => {
				if (response.status >= 400) {
					return { success: false, status: response.status };
				}

				let escalated = null;

				if (
					response.data.value &&
					response.data.value.result &&
					response.data.value.result.type === ESCALATE_TALK_ID
				) {
					escalated = {
						urls: ESCALATE_TALK_URLS,
						userEscalateId: response.data.value.result.userEscalateId,
						contacts: response.data.value.result.contacts.map((c) => ({
							Name: c.name,
							Key: c.key,
						})),
						typeId: response.data.value.result.type,
					};
				}

				if (
					response.data.value &&
					response.data.value.result &&
					response.data.value.result.type === ESCALATE_TEMPERATURE_ID
				) {
					escalated = {
						urls: ESCALATE_TEMPERATURE_URLS,
						userEscalateId: response.data.value.result.userEscalateId,
						temperature: {
							grade: response.data.value.result.grade,
							question: mapObject(response.data.value.result.question),
						},
						typeId: response.data.value.result.type,
					};
				}

				escalated && dispatch(updateEscalate(batch, escalated));

				return {
					success: true,
					result: response.data.value && response.data.value.result,
					status: response.status,
					escalated: escalated,
				};
			})
			.then((result) => {
				dispatch(submitBatchRequest(false));

				if (result.success) dispatch(submitBatchSuccess(batch));

				if (!result.escalated) dispatch(completeBatch(batch));

				return result;
			});
	};
};

const saveQuestionTalkExtendedComment = (batch, commentData) => {
	const dataForSave = {
		type: batch.type,
		batchExecutionUserId: batch.id,
		answers: batch.questions,
	};

	return (dispatch, getState) => {
		const state = getState();
		if (state.questions && state.questions.submitting) {
			return {};
		}

		dispatch(submitBatchRequest(true));

		return post("questions", dataForSave, getState())
			.then((response) => {
				if (response.status >= 400) {
					return { success: false, status: response.status };
				}

				return {
					success: true,
					result: response.data.Result,
					status: response.status,
				};
			})
			.then((result) => {
				if (result.status >= 400) {
					return { success: false, status: result.status };
				}

				const url =
					commentData.contactKey === "External" || commentData.SupportUserId === "External"
						? ESCALATE_TALK_URLS.submitExternal
						: ESCALATE_TALK_URLS.submitDefault;

				const data = {
					Type: result.Type,
					UserEscalateId: result.UserEscalateId,
					SupportUserId: commentData.contactKey || commentData.SupportUserId,
					Comment: commentData.comment || commentData.Comment,
				};

				return post(url, data, getState());
			})
			.then((response) => {
				dispatch(submitBatchRequest(false));
				if (response.status >= 400) {
					return { success: false, status: response.status };
				}
				dispatch(completeBatch(batch));
				return {
					success: true,
					status: response.status,
				};
			});
	};
};

const saveQuestionTalkExtendedExperience = (batch, answer) => {
	const dataForSave = {
		type: batch.type,
		batchExecutionUserId: batch.id,
		answers: batch.questions.map((q) => (q.questionId === answer.questionId ? answer : q)),
	};

	return (dispatch, getState) => {
		const state = getState();
		if (state.questions && state.questions.submitting) {
			return {};
		}

		dispatch(submitBatchRequest(true));

		return post("questions", dataForSave, getState())
			.then((response) => {
				if (response.status >= 400) {
					return { success: false, status: response.status };
				}

				return {
					success: true,
					result: response.result,
					status: response.status,
				};
			})
			.then((result) => {
				dispatch(submitBatchRequest(false));

				if (result.success) dispatch(submitBatchSuccess(batch));

				if (!result.escalated) dispatch(completeBatch(batch));

				return result;
			});
	};
};

const toggleChristmasMode = (batch) => {
	return (dispatch, getState) => {
		return put(
			"user/SaveChristmasMode",
			{ enabled: !batch.customization.isChristmasModeEnabled },
			getState()
		).then((response) => {
			if (response.status >= 400) {
				return { success: false, status: response.status };
			}

			dispatch(updateChristmasTheme(batch));
			return {
				success: true,
				status: response.status,
			};
		});
	};
};

const batchesRequestSuccess = (batches, questionSettings) => {
	return {
		type: BATCHES_REQUEST_SUCCESS,
		batches: batches,
		questionSettings: questionSettings,
	};
};

const submitBatchSuccess = (batch) => {
	return {
		type: SUBMIT_BATCH_SUCCESS,
		batch: batch,
	};
};

const submitBatchRequest = (submitting) => {
	return {
		type: SUBMIT_BATCH_REQUEST,
		submitting: submitting,
	};
};

const getInformationStrategy = (submitting) => {
	return {
		type: GET_INFORMATION_STRATEGY_REQUEST,
		submitting: submitting,
	};
};

const completeBatch = (batch) => {
	return {
		type: COMPLETE_BATCH,
		batch: batch,
	};
};

const clearBatches = () => {
	return {
		type: BATCHES_CLEAR,
	};
};

const updateEscalate = (batch, result) => {
	return {
		type: UPDATE_ESCALATE,
		escalated: {
			batch: batch,
			result: result,
		},
	};
};

const updateQuestion = (batch, question) => {
	return {
		type: UPDATE_QUESTION,
		batch: batch,
		question: question,
	};
};

const updateChristmasTheme = (batch) => {
	return {
		type: UPDATE_CHRISTMAS_THEME,
		batch: batch,
	};
};

export {
	COMPLETE_BATCH,
	BATCHES_REQUEST,
	BATCHES_REQUEST_SUCCESS,
	BATCHES_REQUEST_ERROR,
	BATCHES_CLEAR,
	UPDATE_QUESTION,
	SUBMIT_BATCH_REQUEST,
	SUBMIT_BATCH_SUCCESS,
	UPDATE_ESCALATE,
	GET_INFORMATION_STRATEGY_REQUEST,
	UPDATE_CHRISTMAS_THEME,
	requestBatches,
	clearBatches,
	submitBatch,
	updateQuestion,
	submitBatchSuccess,
	updateEscalate,
	saveTemperatureComments,
	saveQuestionTalk,
	getOptionsForInformationStrategy,
	getOptionsForInformationStrategies,
	saveQuestionTalkExtendedComment,
	saveQuestionTalkExtendedExperience,
	toggleChristmasMode,
	onQuestionTalkExtendedSend,
};
