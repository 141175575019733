// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import Button from "Controls/Button";
import ContentWrapper from "Controls/ContentWrapper";

class QuestionChristmasThemeToggle extends PureComponent {
	render() {
		return (
			<ContentWrapper
				display="flex"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				maxWidth="100%"
				marginTop="14px">
				<Button
					color={Colors.white}
					backgroundColor="transparent"
					fontSize="13px"
					hoverOpacity="0.6"
					onClick={this.props.onToggleChristmasMode}>
					{this.props.isChristmasModeEnabled ? Resources[this.props.culture].hideChristmasTheme : Resources[this.props.culture].showChristmasTheme}
				</Button>
			</ContentWrapper>
		);
	}
}

QuestionChristmasThemeToggle.propTypes = {
	culture: PropTypes.string
}

export default QuestionChristmasThemeToggle;