// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";
import { guid } from "Helpers";

const MAX_EMPTY_SPACE_RECT_HEIGHT = 26;

const EmptySpaceComponent = styled.rect`
    transition: height 0.5s linear;
`;

class TemperatureWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.emptySpaceRectHeight = this.getEmptySpaceRectHeight();

        this.state = {
            emptySpaceRectHeight: this.props.isAnimated ? MAX_EMPTY_SPACE_RECT_HEIGHT : this.getEmptySpaceRectHeight()
        }
    }

    getEmptySpaceRectHeight = () => {
        const gradeHeight = Math.round(MAX_EMPTY_SPACE_RECT_HEIGHT / (this.props.gradeCount - 1)) * this.props.gradeIndex;

        return gradeHeight <= MAX_EMPTY_SPACE_RECT_HEIGHT ? gradeHeight : MAX_EMPTY_SPACE_RECT_HEIGHT;
    }

    render() {
        const filterId = `gradeTemperatureFilter-${guid.newValue()}`;
        const styles = {
            width: this.props.width || "49px",
            height: this.props.height || "74px",
            margin: this.props.margin
        }

        return(
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={styles} viewBox="0 0 49 74">
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(4 2)">
                        <g fillRule="nonzero">
                            <filter id={filterId} width="134.3%" height="121.2%" x="-17.1%" y="-7.6%" filterUnits="objectBoundingBox">
                                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                            </filter>
                            <g fill="#000" filter={`url(#${filterId})`}>
                                <path d="M11.182 27.662V9.106C11.182 4.077 15.27 0 20.314 0c5.043 0 9.131 4.077 9.131 9.106V27.54c6.998 3.49 11.849 10.83 11.397 18.917-.638 11.416-8.895 19.65-20.406 19.65S.667 57.872.029 46.456c-.446-7.993 4.287-15.257 11.153-18.794z"/>
                            </g>
                            <g fill={this.props.isActive ? Colors.mainBlue : Colors.white}>
                                <path d="M11.182 27.662V9.106C11.182 4.077 15.27 0 20.314 0c5.043 0 9.131 4.077 9.131 9.106V27.54c6.998 3.49 11.849 10.83 11.397 18.917-.638 11.416-8.895 19.65-20.406 19.65S.667 57.872.029 46.456c-.446-7.993 4.287-15.257 11.153-18.794z"/>
                            </g>
                        </g>
                        <mask fill="#fff">
                            <path d="M14.288 29.66V9.124a6.026 6.026 0 1 1 12.051 0v20.445c6.803 2.531 11.82 9.23 11.402 16.715-.533 9.53-7.216 16.726-17.305 16.726-10.09 0-16.773-7.195-17.306-16.726-.413-7.395 4.48-14.023 11.158-16.622z"/>
                        </mask>
                        <g fill={this.props.color} fillRule="nonzero">
                            <path d="M14.288 29.66V9.124a6.026 6.026 0 1 1 12.051 0v20.445c6.803 2.531 11.82 9.23 11.402 16.715-.533 9.53-7.216 16.726-17.305 16.726-10.09 0-16.773-7.195-17.306-16.726-.413-7.395 4.48-14.023 11.158-16.622z"/>
                        </g>
                        <g className="smiley-background" fill={Colors.white} opacity="0">
                            <path d="M14.288 29.66V9.124a6.026 6.026 0 1 1 12.051 0v20.445c6.803 2.531 11.82 9.23 11.402 16.715-.533 9.53-7.216 16.726-17.305 16.726-10.09 0-16.773-7.195-17.306-16.726-.413-7.395 4.48-14.023 11.158-16.622z"/>
                        </g>
                        <path fill="#FFF" fillOpacity=".7" fillRule="nonzero" d="M12.663 30.232c.355.141-2.77 4.568-2.76 10.471.008 5.903 6.237 13.993 5.885 14.152-.352.16-7.61-1.005-9.932-10.108-2.323-9.102 6.451-14.657 6.807-14.515z" mask="url(#d)" transform="rotate(20 10.634 42.549)"/>
                    </g>
                    <EmptySpaceComponent x="18.05" y="5" width="12.3" height={this.props.isAnimated ? MAX_EMPTY_SPACE_RECT_HEIGHT : this.emptySpaceRectHeight } fill="#E6E6E6" fillRule="nonzero">
                        {this.props.isAnimated && <animate attributeName="height" dur="0.5s" from={MAX_EMPTY_SPACE_RECT_HEIGHT} to={this.emptySpaceRectHeight} fill="freeze" />}
                    </EmptySpaceComponent>
                    <path d="M16.7 12 A 5 5 0 0 1 32 12" stroke={this.props.isActive ? Colors.mainBlue : Colors.white} fill="none" strokeWidth="3" fillOpacity="0.5"/>
                    <g className="smiley-background" opacity="0">
                        <path d="M16.7 12 A 5 5 0 0 1 32 12" stroke={Colors.white} fill="none" strokeWidth="3"/>
                    </g>
                    <g fill="#41464D" fillRule="nonzero">
                        <path d="M27.239 31.57c-.5 0-.904-.388-.904-.867 0-.478.405-.866.904-.866h3.106v1.733h-3.106zm0-5.778c-.5 0-.904-.388-.904-.866 0-.479.405-.867.904-.867h3.106v1.733h-3.106zm0-5.777c-.5 0-.904-.388-.904-.867 0-.478.405-.866.904-.866h3.106v1.733h-3.106zm0-5.777c-.5 0-.904-.388-.904-.867s.405-.867.904-.867h3.106v1.734h-3.106z"/>
                    </g>
                    {this.props.children}
                </g>
            </svg>
        );
    }
}

TemperatureWrapper.propTypes = {
    isActive: PropTypes.bool,
    isAnimated: PropTypes.bool,
    gradeIndex: PropTypes.number,
    gradeCount: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
    margin: PropTypes.string
}

export default TemperatureWrapper;