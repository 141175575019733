import { clearBatches, requestBatches } from './batches';
import { login } from './api';
import { getAuth, setAuth, removeAuth } from './authentication.localStorage';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

const requestLogin = () => {
	return {
		type: LOGIN_REQUEST
	};
};

const requestLogout = () => {
	return {
		type: LOGOUT_REQUEST
	};
}

const receiveLogin = () => {
	return {
		type: LOGIN_SUCCESS
	};
};

const loginError = (message) => {
	return {
		type: LOGIN_FAILURE,
		message
	};
};

const logoutUser = () => {
	return dispatch => {
		dispatch(clearBatches());
		dispatch(requestLogout());
		removeAuth();
		return Promise.resolve();
	};
}


const loginUser = (code, customerId) => {
	//add customerId to Datadog logs
	if (window.DD_LOGS) {
		setTimeout(() => window.DD_LOGS.addLoggerGlobalContext('customerId', customerId), 0);
	}

	return dispatch => {
		dispatch(requestLogin());

		return login('token', code, customerId)
			.then(response => {
				if (response.status >= 400) {
					dispatch(loginError());

					return {
						success: false
					}
				}

				var data = response.data;
				setAuth(data.access_token);
				dispatch(receiveLogin(data.access_token));

				return {
					success: true
				};
			});
	};
};

export {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGOUT_REQUEST,
	requestLogin,
	receiveLogin,
	loginError,
	loginUser,
	logoutUser,
	requestLogout
}
