// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

//"display" adds display prop to the element, use "displayStyle" instead of "display" if you need css display
const ContentWrapper = styled.div`
	display: ${(props) => props.display || "block"};
	${(props) => (props.displayStyle ? `display: ${props.displayStyle}` : null)};
	align-items: ${(props) => props.alignItems || "unset"};
	justify-content: ${(props) => props.justifyContent || "unset"};
	flex: ${(props) => props.flex || "unset"};
	flex-direction: ${(props) => props.flexDirection || "row"};
	width: ${(props) => props.width || "auto"};
	height: ${(props) => props.height || "auto"};
	border: ${(props) => props.border || "none"};
	border-radius: ${(props) => props.borderRadius || "0"};
	${(props) => (props.borderWidth ? `border-width: ${props.borderWidth}` : null)};
	padding: ${(props) => props.padding || "0"};
	margin: ${(props) => props.margin || "0"};
	background-color: ${(props) => props.backgroundColor || "transparent"};
	cursor: ${(props) => props.cursor || "auto"};
	${(props) => (props.gridArea ? `grid-area: ${props.gridArea};` : null)}
	${(props) => (props.background ? `background: ${props.background};` : null)}
	${(props) => (props.verticalAlign ? `vertical-align: ${props.verticalAlign}` : null)};
	${(props) => (props.position ? `position: ${props.position};` : null)}
	${(props) => (props.overflowX ? `overflow-x: ${props.overflowX};` : null)}
	${(props) => (props.overflowY ? `overflow-y: ${props.overflowY};` : null)}
	${(props) => (props.overflow ? `overflow: ${props.overflow};` : null)}
	${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow};` : null)}
	${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : null)}
	${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : null)}
	${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : null)}
	${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : null)}
	${(props) => (props.userSelect ? `user-select: ${props.userSelect};` : null)}
	${(props) => (props.flexWrap ? `flex-wrap: ${props.flexWrap};` : null)}
	${(props) => (props.borderTop ? `border-top: ${props.borderTop};` : null)}
	${(props) => (props.borderRight ? `border-right: ${props.borderRight};` : null)}
	${(props) => (props.borderBottom ? `border-bottom: ${props.borderBottom};` : null)}
	${(props) => (props.borderLeft ? `border-left: ${props.borderLeft};` : null)}
	${(props) => (props.flexGrow ? `flex-grow: ${props.flexGrow};` : null)}
	${(props) => (props.flexShrink ? `flex-shrink: ${props.flexShrink};` : null)}
	${(props) => (props.flexBasis ? `flex-basis: ${props.flexBasis};` : null)}
	${(props) => (props.top ? `top: ${props.top};` : null)}
	${(props) => (props.bottom ? `bottom: ${props.bottom};` : null)}
	${(props) => (props.left ? `left: ${props.left};` : null)}
	${(props) => (props.right ? `right: ${props.right};` : null)}
	${(props) => (props.backgroundSize ? `background-size: ${props.backgroundSize};` : null)}
	${(props) => (props.textOverflow ? `text-overflow: ${props.textOverflow};` : null)}
	${(props) => (props.float ? `float: ${props.float};` : null)}
	${(props) => (props.clear ? `clear: ${props.clear};` : null)}
	${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : null)}
	${(props) => (props.transition ? `transition: ${props.transition};` : null)}
	${(props) => (props.alignSelf ? `align-self: ${props.alignSelf};` : null)}
	${(props) => (props.pageBreakInside ? `page-break-inside: ${props.pageBreakInside};` : null)}
	${(props) => (props.fontSize ? `font-size: ${props.fontSize};` : null)}
	${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : null)}
	${(props) => (props.lineHeight ? `line-height: ${props.lineHeight};` : null)}
	${(props) => (props.textTransform ? `text-transform: ${props.textTransform};` : null)}
	${(props) => (props.color ? `color: ${props.color};` : null)}
	${(props) => (props.textShadow ? `text-shadow: ${props.textShadow};` : null)}
	${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : null)}
	${(props) => (props.marginRight ? `margin-right: ${props.marginRight};` : null)}
	${(props) => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : null)}
	${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft};` : null)}
	${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : null)}
	${(props) => (props.whiteSpace ? `white-space: ${props.whiteSpace};` : null)}
	${(props) => (props.backgroundImage ? `background-image: ${props.backgroundImage};` : null)}
	${(props) => (props.backgroundRepeat ? `background-repeat: ${props.backgroundRepeat};` : null)}
	${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop};` : null)}
	${(props) => (props.paddingLeft ? `padding-left: ${props.paddingLeft};` : null)}
	${(props) => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom};` : null)}
	${(props) => (props.pointerEvents ? `pointer-events: ${props.pointerEvents};` : null)}
	${(props) => (props.opacity ? `opacity: ${props.opacity};` : null)}
	${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : null)}
	${(props) => (props.hyphens ? `hyphens: ${props.hyphens};` : null)};
	${(props) => (props.transform ? `transform: ${props.transform};` : null)}
	${(props) => (props.wordWrap ? `word-wrap: ${props.wordWrap};` : null)};
	${(props) => (props.fontFamily ? `font-family: ${props.fontFamily};` : null)};
	${(props) => (props.boxSizing ? `box-sizing: ${props.boxSizing};` : null)};
	& * {
		${(props) => (props.fontFamily ? `font-family: ${props.fontFamily};` : null)};
	}
	@media (max-width: 768px) {
		${(props) => (props.boxShadowXS ? `box-shadow: ${props.boxShadowXS};` : null)}
		${(props) => (props.widthXS ? `width: ${props.widthXS};` : null)}
		${(props) => (props.heightXS ? `height: ${props.heightXS};` : null)}
		${(props) => (props.paddingXS ? `padding: ${props.paddingXS};` : null)}
		${(props) => (props.marginXS ? `margin: ${props.marginXS};` : null)}
		${(props) => (props.borderXS ? `border: ${props.borderXS};` : null)}
		${(props) => (props.borderWidthXS ? `border-width: ${props.borderWidthXS};` : null)}
		${(props) => (props.maxWidthXS ? `max-width: ${props.maxWidthXS};` : null)}
		${(props) => (props.minWidthXS ? `min-width: ${props.minWidthXS};` : null)}
		${(props) => (props.backgroundColorXS ? `background-color: ${props.backgroundColorXS};` : null)}
		${(props) => (props.displayXS ? `display: ${props.displayXS};` : null)}
		${(props) => (props.positionXS ? `position: ${props.positionXS};` : null)}
		${(props) => (props.topXS ? `top: ${props.topXS};` : null)}
		${(props) => (props.rightXS ? `right: ${props.rightXS};` : null)}
		${(props) => (props.bottomXS ? `bottom: ${props.bottomXS};` : null)}
		${(props) => (props.leftXS ? `left: ${props.leftXS}` : null)};
		${(props) => (props.zIndexXS ? `z-index: ${props.zIndexXS};` : null)}
		${(props) => (props.justifyContentXS ? `justify-content: ${props.justifyContentXS};` : null)}
		${(props) => (props.floatXS ? `float: ${props.floatXS};` : null)}
		${(props) => (props.clearXS ? `clear: ${props.clearXS};` : null)}
		${(props) => (props.maxHeightXS ? `max-height: ${props.maxHeightXS};` : null)}
		${(props) => (props.flexWrapXS ? `flex-wrap: ${props.flexWrapXS};` : null)}
		${(props) => (props.alignSelfXS ? `align-self: ${props.alignSelfXS};` : null)}
		${(props) => (props.flexGrowXS ? `flex-grow: ${props.flexGrowXS};` : null)}
		${(props) => (props.textShadowXS ? `text-shadow: ${props.textShadowXS};` : null)}
		${(props) => (props.minHeightXS ? `min-height: ${props.minHeightXS};` : null)}
		${(props) => (props.backgroundImageXS ? `background-image: ${props.backgroundImageXS};` : null)}
		${(props) => (props.paddingTopXS ? `padding-top: ${props.paddingTopXS};` : null)}
		${(props) => (props.paddingLeftXS ? `padding-left: ${props.paddingLeftXS};` : null)}
		${(props) => (props.paddingBottomXS ? `padding-bottom: ${props.paddingBottomXS};` : null)}
        ${(props) => (props.opacityXS ? `opacity: ${props.opacityXS};` : null)}
		${(props) => (props.flexDirectionXS ? `flex-direction: ${props.flexDirectionXS};` : null)}
		${(props) => (props.alignItemsXS ? `align-items: ${props.alignItemsXS};` : null)}
		${(props) => (props.borderRadiusXS ? `border-radius: ${props.borderRadiusXS};` : null)}
		${(props) => (props.textAlignXS ? `text-align: ${props.textAlignXS};` : null)}
	}
	@media (max-width: 1280px) {
		${(props) => (props.marginS ? `margin: ${props.marginS};` : null)}
	}
	&:hover {
		${(props) => (props.backgroundColorHover ? `background-color: ${props.backgroundColorHover};` : null)}
		${(props) => (props.hoverOpacity ? `opacity: ${props.hoverOpacity};` : null)}
		${(props) => (props.hoverOverflow ? `overflow: ${props.hoverOverflow};` : null)}
		${(props) => (props.hoverTextShadow ? `text-shadow: ${props.hoverTextShadow};` : null)}
		${(props) => (props.hoverBoxShadow ? `box-shadow: ${props.hoverBoxShadow};` : null)}
		${(props) => (props.hoverColor ? `color: ${props.hoverColor};` : null)}
		${(props) => (props.hoverBorder ? `border: ${props.hoverBorder};` : null)}
		${(props) => (props.hoverBorderWidth ? `border-width: ${props.hoverBorderWidth};` : null)}
	}
	&:focus {
		${(props) => (props.focusOutline ? `outline: ${props.focusOutline};` : null)}
		${(props) => (props.focusBorderBottom ? `border-bottom: ${props.focusBorderBottom};` : null)}
	}
`;

ContentWrapper.propTypes = {
	display: PropTypes.string,
	alignItems: PropTypes.string,
	justifyContent: PropTypes.string,
	flex: PropTypes.string,
	flexDirection: PropTypes.string,
	flexDirectionXS: PropTypes.string,
	flexWrap: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	border: PropTypes.string,
	borderRadius: PropTypes.string,
	borderWidth: PropTypes.string,
	padding: PropTypes.string,
	margin: PropTypes.string,
	backgroundColor: PropTypes.string,
	cursor: PropTypes.string,
	widthXS: PropTypes.string,
	heightXS: PropTypes.string,
	paddingXS: PropTypes.string,
	marginXS: PropTypes.string,
	marginS: PropTypes.string,
	borderXS: PropTypes.string,
	borderWidthXS: PropTypes.string,
	backgroundColorXS: PropTypes.string,
	displayXS: PropTypes.string,
	backgroundColorHover: PropTypes.string,
	position: PropTypes.string,
	overflowX: PropTypes.string,
	overflowY: PropTypes.string,
	overflow: PropTypes.string,
	minHeight: PropTypes.string,
	userSelect: PropTypes.string,
	textAlign: PropTypes.string,
	boxShadow: PropTypes.string,
	boxShadowXS: PropTypes.string,
	background: PropTypes.string,
	borderTop: PropTypes.string,
	borderRight: PropTypes.string,
	borderBottom: PropTypes.string,
	borderLeft: PropTypes.string,
	flexGrow: PropTypes.string,
	flexShrink: PropTypes.string,
	flexBasis: PropTypes.string,
	top: PropTypes.string,
	bottom: PropTypes.string,
	left: PropTypes.string,
	right: PropTypes.string,
	positionXS: PropTypes.string,
	topXS: PropTypes.string,
	rightXS: PropTypes.string,
	bottomXS: PropTypes.string,
	leftXS: PropTypes.string,
	zIndex: PropTypes.string,
	zIndexXS: PropTypes.string,
	justifyContentXS: PropTypes.string,
	displayStyle: PropTypes.string,
	backgroundSize: PropTypes.string,
	textOverflow: PropTypes.string,
	maxWidth: PropTypes.string,
	maxWidthXS: PropTypes.string,
	minWidth: PropTypes.string,
	minWidthXS: PropTypes.string,
	float: PropTypes.string,
	floatXS: PropTypes.string,
	clear: PropTypes.string,
	clearXS: PropTypes.string,
	maxHeight: PropTypes.string,
	maxHeightXS: PropTypes.string,
	transition: PropTypes.string,
	flexWrapXS: PropTypes.string,
	alignSelf: PropTypes.string,
	alignSelfXS: PropTypes.string,
	pageBreakInside: PropTypes.string,
	opacity: PropTypes.string,
	opacityXS: PropTypes.string,
	hoverOpacity: PropTypes.string,
	hoverOverflow: PropTypes.string,
	flexGrowXS: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lineHeight: PropTypes.string,
	textTransform: PropTypes.string,
	color: PropTypes.string,
	textShadow: PropTypes.string,
	textShadowXS: PropTypes.string,
	hoverTextShadow: PropTypes.string,
	hoverBoxShadow: PropTypes.string,
	hoverColor: PropTypes.string,
	marginTop: PropTypes.string,
	marginRight: PropTypes.string,
	marginBottom: PropTypes.string,
	marginLeft: PropTypes.string,
	verticalAlign: PropTypes.string,
	whiteSpace: PropTypes.string,
	backgroundImage: PropTypes.string,
	backgroundImageXS: PropTypes.string,
	paddingTop: PropTypes.string,
	paddingTopXS: PropTypes.string,
	paddingLeft: PropTypes.string,
	paddingLeftXS: PropTypes.string,
	paddingBottom: PropTypes.string,
	paddingBottomXS: PropTypes.string,
	pointerEvents: PropTypes.string,
	hyphens: PropTypes.string,
	wordWrap: PropTypes.string,
	hoverBorder: PropTypes.string,
	transform: PropTypes.string,
	alignItemsXS: PropTypes.string,
	hoverBorderWidth: PropTypes.string,
	borderRadiusXS: PropTypes.string,
	focusOutline: PropTypes.string,
	focusBorderBottom: PropTypes.string,
	fontFamily: PropTypes.string,
	backgroundRepeat: PropTypes.string,
};

export default ContentWrapper;
