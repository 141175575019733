import {
	BATCHES_REQUEST,
	BATCHES_REQUEST_SUCCESS,
	BATCHES_CLEAR,
	SUBMIT_BATCH_REQUEST,
	SUBMIT_BATCH_SUCCESS,
	UPDATE_QUESTION,
	UPDATE_ESCALATE,
	COMPLETE_BATCH,
	GET_INFORMATION_STRATEGY_REQUEST,
	UPDATE_CHRISTMAS_THEME,
} from "../actions/batches";

const questions = (state = { fetching: true }, action) => {
	switch (action.type) {
		case BATCHES_REQUEST:
			return { ...state, fetching: true };
		case BATCHES_REQUEST_SUCCESS:
			return {
				...state,
				fetching: false,
				requested: true,
				batches: action.batches.map((batch) => {
					const mappedBatch = {
						submitted: false,
						questions: batch.questions.map((question) =>
							Object.assign(question, {
								grade: null,
								comment: "",
								skipReason: null,
							})
						),
					};

					if (action.questionSettings.isGeneralCommentEnabled) {
						mappedBatch.questions.push({
							comment: "",
							isGeneralComment: true,
							questionId: "",
							visibleForImmediateManager: true,
							allowAiAssistedReplies: true,
						});
					}

					return Object.assign(batch, mappedBatch);
				}),
				questionSettings: action.questionSettings,
			};
		case BATCHES_CLEAR:
			return { ...state, batches: [], questionSettings: {}, requested: false };
		case COMPLETE_BATCH:
			return {
				...state,
				batches: state.batches.map((batch) => {
					if (batch.id === action.batch.id) batch.completed = true;
					return batch;
				}),
				escalated:
					state.escalated && state.escalated.batch.id === action.batch.id ? null : state.escalated,
			};
		case SUBMIT_BATCH_SUCCESS:
			return {
				...state,
				batches: state.batches.map((batch) => {
					if (batch.id === action.batch.id) batch.submitted = true;
					return batch;
				}),
			};
		case SUBMIT_BATCH_REQUEST:
			return { ...state, submitting: action.submitting };
		case GET_INFORMATION_STRATEGY_REQUEST:
			return { ...state, submitting: action.submitting };
		case UPDATE_ESCALATE:
			return { ...state, escalated: action.escalated };
		case UPDATE_QUESTION:
			return {
				...state,
				batches: state.batches.map((batch) => {
					if (batch.id === action.batch.id) {
						batch.questions = batch.questions.map((question) => {
							if (question.questionId === action.question.questionId) {
								return {
									...question,
									comment: action.question.comment,
									grade: action.question.grade,
									visibleForImmediateManager: action.question.visibleForImmediateManager,
									allowAiAssistedReplies: action.question.allowAiAssistedReplies,
									skipReason: action.question.skipReason,
								};
							}
							return question;
						});
					}
					return batch;
				}),
			};
		case UPDATE_CHRISTMAS_THEME:
			return {
				...state,
				batches: state.batches.map((batch) => {
					if (batch.id === action.batch.id) {
						batch.customization = {
							...batch.customization,
							isChristmasModeEnabled: !batch.customization.isChristmasModeEnabled,
						};
					}
					return batch;
				}),
			};
		default:
			return state;
	}
};

export default questions;
