// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const QuestionWrapper = styled.div`
	background-color: #fff;
	border-radius: 4px;
	margin: ${(props) => props.margin || "0 0 10px"};
	padding: ${(props) => props.padding || "0"};
	${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
	${(props) => props.border && `border: ${props.border};`}
`;

QuestionWrapper.propTypes = {
	margin: PropTypes.string,
	padding: PropTypes.string,
	border: PropTypes.string,
	maxWidth: PropTypes.string,
};

export default QuestionWrapper;
