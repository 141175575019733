/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Text from "../Text";
import { spacingIndex, spacing } from "../shared/spacing";
import { colors } from "../shared/theme";

const Wrapper = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	box-sizing: border-box;
	text-transform: initial;
	height: ${spacingIndex * 2.5}px;
	border-radius: ${spacingIndex * 2}px;
	padding: 0 ${spacingIndex * 1.25}px;
	background-color: ${(props) => (props.isSelected ? colors.white : colors.purple)};
	${spacing};
`;

const NewPill = (props) => {
	const { t } = useTranslation();

	return (
		<Wrapper {...props}>
			<Text fontType="smallRegular" color={props.isSelected ? colors.nickel : colors.white}>
				{props.text || `${t("New")}!`}
			</Text>
		</Wrapper>
	);
};

NewPill.propTypes = {
	isSelected: PropTypes.bool,
	text: PropTypes.string,
};

export default NewPill;
