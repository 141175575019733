// this file origin from Winningtemp.Shared

import React, {Component} from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import styled from "styled-components";
import Text from "Controls/Text";

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 10px;
    border-radius: 0;
    border: none;
    outline: none;
    text-shadow: none;
    user-select: none;
    background-color: ${props => props.backgroundColor};
    transition: background 120ms ease-in;
    @media(max-width: 425px) {
        padding: 15px 10px;
    }
    &:hover {
        background-color: ${props => props.hoverBackgroundColor};
    }
`;

class QuestionDispatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIncomplete: false
        }
    }

    onClick = () => {
        if (!this.props.enabled)
            return;

        var completed = this.isCompleted();

        this.setState({
            showIncomplete: !completed
        });

        this.props.onClick(completed);
    }

    getButtonClass = () => {
        if (this.props.buttonText || this.isCompleted()) return "#59b0c7";
        if (this.state.showIncomplete) return "#ff6f6f";
        return "#dddddd";
    }

    getHoverButtonClass = () => {
        if (this.props.buttonText || this.isCompleted()) return "#4a93a5";
        if (this.state.showIncomplete) return "#ff6f6f";
        return "#dddddd";
    }

    getDescription = () => {
        if (this.props.buttonText) {
            return this.props.buttonText;
        }

        const suffix = `(${this.props.answers}/${this.props.questions} ${Resources[this.props.culture].answered})`;

        if (this.state.showIncomplete)
            return `${Resources[this.props.culture].answersAreMissing} ${suffix}`;

        return `${Resources[this.props.culture].send} ${suffix}`;
    }

    isCompleted = () => {
        return this.props.answers === this.props.questions;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showIncomplete) {
            this.setState({
                showIncomplete: false
            });
        }
    }

    render() {
        return (
            <Button
                type="button"
                data-pendo-id="submit-questions"
				data-track-id="submit-questions"
                backgroundColor={this.getButtonClass()}
                hoverBackgroundColor={this.getHoverButtonClass()}
                onClick={this.onClick}
                disabled={!this.props.enabled}>
                <Text
                    fontSize="14px"
                    fontWeight="700"
                    textTransform="uppercase"
                    margin="0 auto"
                    color="#fff">
                    {this.getDescription()}
                </Text>
            </Button>
        );
    }
}

QuestionDispatch.propTypes = {
    enabled: PropTypes.bool,
    answers: PropTypes.number,
    questions: PropTypes.number,
    buttonText: PropTypes.string,
    culture: PropTypes.string,
    onClick: PropTypes.func
}

export default QuestionDispatch;