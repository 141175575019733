// this file origin from Winningtemp.Shared

import React, { Component } from "react";
import PropTypes from "prop-types";
import { QuestionSurveyType } from "Enums";
import Question from "./Question";
import QuestionEnps from "./QuestionEnps";
import QuestionHme from "./QuestionHme";

class QuestionFactory extends Component {
	render() {
		switch (this.props.question.type) {
			case QuestionSurveyType.enps:
				return (
					<QuestionEnps
						feedbackQuestionRef={this.props.feedbackQuestionRef}
						key={this.props.question.Id}
						activeQuestionId={this.props.activeQuestionId}
						questionIndex={this.props.questionIndex}
						questionsCount={this.props.questions && this.props.questions.length}
						isReadOnly={this.props.isReadOnly}
						questionColor={this.props.questionColor}
						questionFontSize={this.props.questionFontSize}
						questionFontSizeXS={this.props.questionFontSizeXS}
						questionFontWeight={this.props.questionFontWeight}
						questionPadding={this.props.questionPadding}
						questionPaddingXS={this.props.questionPaddingXS}
						questionTextPadding={this.props.questionTextPadding}
						wrapperMargin={this.props.wrapperMargin}
						wrapperBorder={this.props.wrapperBorder}
						isOneLineItem={this.props.isOneLineItem}
						question={this.props.question}
						culture={this.props.culture}
						onChange={this.props.onChange}
						onActiveQuestionChange={this.props.onActiveQuestionChange}
						isCommentDisabled={this.props.isCommentDisabled}
						isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
						isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
						highlight={this.props.highlight}
					/>
				);
			case QuestionSurveyType.hme:
				return (
					<QuestionHme
						feedbackQuestionRef={this.props.feedbackQuestionRef}
						key={this.props.question.Id}
						questionIndex={this.props.questionIndex}
						questionsCount={this.props.questions && this.props.questions.length}
						activeQuestionId={this.props.activeQuestionId}
						questionColor={this.props.questionColor}
						addCommentColor={this.props.addCommentColor}
						questionFontSize={this.props.questionFontSize}
						questionFontSizeXS={this.props.questionFontSizeXS}
						questionFontWeight={this.props.questionFontWeight}
						smileySize={this.props.smileySize}
						smileyFontSize={this.props.smileyFontSize}
						smileyFontSizeXS={this.props.smileyFontSizeXS}
						isReadOnly={this.props.isReadOnly}
						questionPadding={this.props.questionPadding}
						questionPaddingXS={this.props.questionPaddingXS}
						questionTextPadding={this.props.questionTextPadding}
						isOneLineItem={this.props.isOneLineItem}
						questionTextAlign="left"
						wrapperMargin={this.props.wrapperMargin}
						wrapperBorder={this.props.wrapperBorder}
						question={this.props.question}
						culture={this.props.culture}
						onChange={this.props.onChange}
						onActiveQuestionChange={this.props.onActiveQuestionChange}
						isCommentDisabled={this.props.isCommentDisabled}
						isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
						isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
						highlight={this.props.highlight}
					/>
				);
			default:
				return (
					<Question
						feedbackQuestionRef={this.props.feedbackQuestionRef}
						canSkipQuestion={this.props.canSkipQuestion}
						key={this.props.question.Id}
						questionIndex={this.props.questionIndex}
						questionsCount={this.props.questions && this.props.questions.length}
						activeQuestionId={this.props.activeQuestionId}
						questionColor={this.props.questionColor}
						addCommentColor={this.props.addCommentColor}
						questionFontSize={this.props.questionFontSize}
						questionFontSizeXS={this.props.questionFontSizeXS}
						questionFontWeight={this.props.questionFontWeight}
						smileySize={this.props.smileySize}
						smileyFontSize={this.props.smileyFontSize}
						smileyFontSizeXS={this.props.smileyFontSizeXS}
						isReadOnly={this.props.isReadOnly}
						questionPadding={this.props.questionPadding}
						questionPaddingXS={this.props.questionPaddingXS}
						questionTextPadding={this.props.questionTextPadding}
						isOneLineItem={this.props.isOneLineItem}
						questionTextAlign="left"
						wrapperMargin={this.props.wrapperMargin}
						wrapperBorder={this.props.wrapperBorder}
						question={this.props.question}
						culture={this.props.culture}
						activationAlign={this.props.activationAlign}
						onChange={this.props.onChange}
						onActiveQuestionChange={this.props.onActiveQuestionChange}
						isCommentDisabled={this.props.isCommentDisabled}
						isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
						isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
						highlight={this.props.highlight}
						isChristmasModeEnabled={this.props.isChristmasModeEnabled}
					/>
				);
		}
	}
}

QuestionFactory.propTypes = {
	canSkipQuestion: PropTypes.bool,
	highlight: PropTypes.bool,
	isOneLineItem: PropTypes.bool,
	isCommentDisabled: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	isWithoutTopBorder: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	questionIndex: PropTypes.number,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	questionColor: PropTypes.string,
	addCommentColor: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionFontWeight: PropTypes.string,
	smileySize: PropTypes.string,
	smileyFontSize: PropTypes.string,
	smileyFontSizeXS: PropTypes.string,
	questionLabel: PropTypes.string,
	activationAlign: PropTypes.string,
	activeQuestionId: PropTypes.string,
	wrapperMargin: PropTypes.string,
	wrapperBorder: PropTypes.string,
	question: PropTypes.object,
	questions: PropTypes.array,
	onChange: PropTypes.func,
	onActiveQuestionChange: PropTypes.func,
};

export default QuestionFactory;
