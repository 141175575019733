import React from "react";
import { css } from "styled-components";

const spacingIndex = 8;

const getSpacingValue = (values, index) => {
	if (!Array.isArray(values)) {
		return null;
	}

	switch (values.length) {
		case 1: {
			const value = `${values[0] * index}px`;
			return `${value} ${value} ${value} ${value}`;
		}
		case 2: {
			const vertical = `${values[0] * index}px`;
			const horizontal = `${values[1] * index}px`;
			return `${vertical} ${horizontal} ${vertical} ${horizontal}`;
		}
		case 3: {
			const top = `${values[0] * index}px`;
			const horizontal = `${values[1] * index}px`;
			const bottom = `${values[2] * index}px`;
			return `${top} ${horizontal} ${bottom} ${horizontal}`;
		}
		case 4: {
			const top = `${values[0] * index}px`;
			const right = `${values[1] * index}px`;
			const bottom = `${values[2] * index}px`;
			const left = `${values[3] * index}px`;
			return `${top} ${right} ${bottom} ${left}`;
		}
		default:
			return null;
	}
};

const spacing = () => css`
	${(props) => !!props.mt && `margin-top: ${props.mt * spacingIndex}px;`}
	${(props) => !!props.mr && `margin-right: ${props.mr * spacingIndex}px;`}
	${(props) => !!props.mb && `margin-bottom: ${props.mb * spacingIndex}px;`}
	${(props) => !!props.ml && `margin-left: ${props.ml * spacingIndex}px;`}
	${(props) => !!props.m && `margin: ${getSpacingValue(props.m, spacingIndex)};`}
	${(props) => !!props.pt && `padding-top: ${props.pt * spacingIndex}px;`}
	${(props) => !!props.pr && `padding-right: ${props.pr * spacingIndex}px;`}
	${(props) => !!props.pb && `padding-bottom: ${props.pb * spacingIndex}px;`}
	${(props) => !!props.pl && `padding-left: ${props.pl * spacingIndex}px;`}
	${(props) => !!props.p && `padding: ${getSpacingValue(props.p, spacingIndex)};`}
`;

export { spacingIndex, spacing };
