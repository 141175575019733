// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Logo from "./Logo";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${(props) => props.backgroundColor || "#945bb3"};
	color: ${(props) => props.color || "#fff"};
	width: ${(props) => props.width || "100%"};
	height: ${(props) => props.height || "100px"};
	padding: ${(props) => props.padding || "20px"};
	@media (max-width: 768px) {
		${(props) => (props.paddingMedia ? `padding: ${props.paddingMedia};` : "")}
	}
	@media (max-width: 425px) {
		padding: 10px 20px;
		height: 80px;
	}
`;

class Header extends PureComponent {
	render() {
		return (
			<Wrapper
				height={this.props.height}
				width={this.props.width}
				padding={this.props.padding}
				paddingMedia={this.props.paddingMedia}
				backgroundColor={this.props.backgroundColor}
				color={this.props.backgroundColor}>
				<Logo usePurpleLogo={this.props.usePurpleLogo} />
				{this.props.children}
			</Wrapper>
		);
	}
}

Header.propTypes = {
	usePurpleLogo: PropTypes.bool,
	backgroundColor: PropTypes.string,
	color: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	padding: PropTypes.string,
	paddingMedia: PropTypes.string,
};

export default Header;
