// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import Text from "Controls/Text";
import QuestionAnonymousIcon from "./QuestionAnonymousIcon";

class QuestionAnonymousLabel extends PureComponent {
	render() {
		const colorXS = this.props.colorXS || Colors.questionAnonymousLabelXS;
		return (
			<ContentWrapper
				display="flex"
				alignItems="flex-start"
				justifyContent="center"
				textAlign="center"
				maxWidth="100%"
				padding={this.props.padding}>
				<QuestionAnonymousIcon size="16px" color={colorXS} colorXS={colorXS} />
				<Text
					display="inline-block"
					margin="0 0 0 8px"
					color={colorXS}
					colorXS={colorXS}
					fontSize="12px"
					lineHeight="14px"
					fontWeight="600">
					{this.props.isCommentDisabled
						? Resources[this.props.culture].youAreAlwaysAnonymous
						: Resources[this.props.culture].youAreAlwaysAnonymousWithOrWithoutComment}
				</Text>
			</ContentWrapper>
		);
	}
}

QuestionAnonymousLabel.propTypes = {
	isCommentDisabled: PropTypes.bool,
	culture: PropTypes.string,
	padding: PropTypes.string,
	color: PropTypes.string,
	colorXS: PropTypes.string,
};

export default QuestionAnonymousLabel;
