import axios from 'axios';
import { getAuth } from './authentication.localStorage';
import { getCulture } from './culture';

const BASE_URL = '/api/';

const get = (endpoint, state) => {
    return axios.get(BASE_URL + endpoint, { 
        headers: getHeaders(state),
        validateStatus: function (status) {
            return status >= 200;
        } 
    });
};

const post = (endpoint, data, state) => {
    return axios({
        method: 'POST',
        url: BASE_URL + endpoint,
        data: data,
        validateStatus: function (status) {
            return status >= 200;
        },
        headers: Object.assign(getHeaders(state), {
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    });
};

const put = (endpoint, data, state) => {
    return axios({
        method: 'PUT',
        url: BASE_URL + endpoint,
        data: data,
        validateStatus: function (status) {
            return status >= 200;
        },
        headers: Object.assign(getHeaders(state), {
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    });
};

const login = (endpoint, code, customerId) => {
    return axios({
        method: 'POST',
        url: BASE_URL + endpoint,
        data: `code=${code}&customerId=${customerId}`,
        validateStatus: function (status) {
            return status >= 200;
        },
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    });
}

const getHeaders = (state) => {
    let authentication = getAuth();
    
    return {
        'Authorization': `Bearer ${authentication ? authentication.token : null}`,
        'accept-language': getCulture(state)
    };
}

export {
    get, post, put, login
}