// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import Resources from "Resources";
import { TemperatureTemplate } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionCommentInfo from "./QuestionCommentInfo";
import QuestionCommentManagerToggle from "./QuestionCommentManagerToggle";
import QuestionCommentMobile from "./QuestionCommentMobile";
import QuestionCommentDescriptionMobile from "./QuestionCommentDescriptionMobile";
import QuestionTemperatureBackButton from "./QuestionTemperatureBackButton";
import AIAssistedRepliesToggle from "./AIAssistedRepliesToggle";

const opacity = keyframes`  
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const TextArea = styled.textarea`
	resize: none;
	box-sizing: border-box;
	overflow: auto;
	width: auto;
	min-height: 48px;
	max-height: 160px;
	border: 1px solid #ededed;
	box-shadow: 0 2px 0 0 #ced3d9;
	border-radius: 2px;
	background-color: #fff;
	padding: 15px;
	font-size: 14px;
	font-weight: 300;
	line-height: 18px;
	animation: ${opacity} 0.5s linear;
	@media (max-width: 425px) {
		min-height: 60px;
	}
`;

const TEXT_AREA_MIN_ROWS = 1;
const TEXT_AREA_MAX_ROWS = 3;

class QuestionComment extends PureComponent {
	constructor(props) {
		super(props);

		this.textArea = React.createRef();

		this.state = {
			isEditing: false,
			isCommentDescriptionActive: false,
			isAiAssistedRepliesDescriptionActive: false,
			textAreaRows: TEXT_AREA_MIN_ROWS,
		};
	}

	updateTextAreaRows = () => {
		if (!this.textArea.current) {
			return null;
		}

		let rows = Math.round((this.textArea.current.scrollHeight - 30) / 18);

		if (rows < TEXT_AREA_MIN_ROWS) {
			rows = TEXT_AREA_MIN_ROWS;
		}

		if (rows > TEXT_AREA_MAX_ROWS) {
			rows = TEXT_AREA_MAX_ROWS;
		}

		if (rows === this.state.textAreaRows) {
			return null;
		}

		return this.setState({ textAreaRows: rows });
	};

	onChange = (event) => {
		const value = event.target.value;

		this.updateTextAreaRows();
		this.props.onChange(value);
	};

	setFocusToTextArea = () => {
		const isMobile = window.innerWidth <= 768;
		if (!this.textArea.current || isMobile) {
			return null;
		}

		setTimeout(() => {
			this.textArea.current && this.textArea.current.focus();
		}); //we need setTimeout(). without setTimeout() the modal container will be focused
	};

	onGradeClick = () => {
		this.props.onGradeClick(null);
	};

	onFocus = () => {
		this.setState({ isEditing: true });
	};

	onBlur = () => {
		if (window.innerWidth <= 768) {
			return null;
		}

		this.setState({ isEditing: false });
	};

	onCommentCancel = () => {
		this.textArea.current.blur();
		this.setState({ isEditing: false });
	};

	commentDescriptionToggle = () => {
		this.setState({
			isCommentDescriptionActive: !this.state.isCommentDescriptionActive,
		});
	};

	onAiAssistedRepliesDescriptionToggle = () => {
		this.setState({
			isAiAssistedRepliesDescriptionActive: !this.state.isAiAssistedRepliesDescriptionActive,
		});
	};

	componentDidMount() {
		this.updateTextAreaRows();
		this.setFocusToTextArea();
	}

	render() {
		const isWithComment = this.props.comment && this.props.comment.length;

		return (
			<ContentWrapper
				data-test="question-comment"
				display="flex"
				flexDirection="column"
				position="relative"
				height="auto"
				width="100%"
				boxSizing="border-box"
				padding="0 0 10px 60px">
				{this.state.isEditing && (
					<QuestionCommentMobile
						isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
						allowAiAssistedReplies={this.props.allowAiAssistedReplies}
						onAllowAiAssistedRepliesChange={this.props.onAllowAiAssistedRepliesChange}
						isCommentDisabled={this.props.isCommentDisabled}
						isVisibleForImmediateManager={this.props.isVisibleForImmediateManager}
						isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
						questionType={this.props.questionType}
						grade={this.props.grade}
						color={this.props.color}
						culture={this.props.culture}
						questionText={this.props.questionText}
						comment={this.props.comment}
						onCommentCancel={this.onCommentCancel}
						onNextClick={this.onCommentCancel}
						onCommentChange={this.onChange}
						onDescriptionOpen={this.commentDescriptionToggle}
						onAiAssistedRepliesDescriptionToggle={this.onAiAssistedRepliesDescriptionToggle}
						onVisibleForImmediateManagerChange={this.props.onVisibleForImmediateManagerChange}
					/>
				)}

				{this.state.isCommentDescriptionActive && (
					<QuestionCommentDescriptionMobile
						culture={this.props.culture}
						onClose={this.commentDescriptionToggle}
					/>
				)}
				{this.state.isAiAssistedRepliesDescriptionActive && (
					<QuestionCommentDescriptionMobile
						culture={this.props.culture}
						description={Resources[this.props.culture].smartAssistantUserDescription}
						onClose={this.onAiAssistedRepliesDescriptionToggle}
					/>
				)}

				<QuestionTemperatureBackButton
					isWithShake={true}
					culture={this.props.culture}
					isReadOnlyAfterSubmit={this.props.isReadOnlyAfterSubmit}
					questionType={this.props.questionType}
					grade={this.props.grade}
					top={this.props.questionType !== TemperatureTemplate.default ? "0" : "-20px"}
					left="-3px"
					color={this.props.color}
					onGradeClick={this.onGradeClick}
				/>

				<TextArea
					ref={this.textArea}
					rows={this.state.textAreaRows}
					value={this.props.comment}
					placeholder={Resources[this.props.culture].leaveAVoluntaryComment}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				/>

				{isWithComment && (
					<React.Fragment>
						<QuestionCommentInfo
							culture={this.props.culture}
							isVisibleForImmediateManager={this.props.isVisibleForImmediateManager}
						/>
						{this.props.isHideCommentFromManagerVisible &&
							!!this.props.comment &&
							!!this.props.comment.length && (
								<ContentWrapper display="flex" displayXS="none" padding="10px 0 0">
									<QuestionCommentManagerToggle
										isVisible={true}
										isVisibleForImmediateManager={this.props.isVisibleForImmediateManager}
										culture={this.props.culture}
										onVisibleForImmediateManagerChange={
											this.props.onVisibleForImmediateManagerChange
										}
									/>
								</ContentWrapper>
							)}
						{this.props.isAllowAiAssistedRepliesVisible &&
							!!this.props.comment &&
							!!this.props.comment.length && (
								<ContentWrapper display="flex" displayXS="none" padding="10px 0 0">
									<AIAssistedRepliesToggle
										isVisible
										checked={this.props.allowAiAssistedReplies}
										culture={this.props.culture}
										onAllowAiAssistedRepliesChange={
											this.props.onAllowAiAssistedRepliesChange
										}
									/>
								</ContentWrapper>
							)}
					</React.Fragment>
				)}
			</ContentWrapper>
		);
	}
}

QuestionComment.propTypes = {
	allowAiAssistedReplies: PropTypes.bool,
	isCommentDisabled: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	isVisibleForImmediateManager: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	isReadOnlyAfterSubmit: PropTypes.bool,
	grade: PropTypes.number,
	questionType: PropTypes.number,
	culture: PropTypes.string,
	comment: PropTypes.string,
	color: PropTypes.string,
	questionText: PropTypes.string,
	onChange: PropTypes.func,
	onVisibleForImmediateManagerChange: PropTypes.func,
	onAllowAiAssistedRepliesChange: PropTypes.func,
	onGradeClick: PropTypes.func,
};

export default QuestionComment;
