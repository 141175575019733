// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Resources from "Resources";
import AlertComponent from "Controls/AlertComponent";
import SurveyTitlebar from "./SurveyTitlebar";
import QuestionFactory from "./QuestionFactory";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const QuestionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 10px 0;
	box-sizing: border-box;
`;

class SurveyContent extends PureComponent {
	render() {
		return (
			<Wrapper>
				{this.props.title && (
					<SurveyTitlebar
						culture={this.props.culture}
						title={this.props.title}
						onBack={this.props.onBackClick}
					/>
				)}
				<QuestionsWrapper>
					{this.props.showPatchInfoText && (
						<AlertComponent
							warning
							margin="0 0 10px"
							text={Resources[this.props.culture].questionsPatchInfo}
						/>
					)}
					{this.props.questions.map((question, index) => (
						<QuestionFactory
							key={question.questionId}
							questionIndex={index}
							questions={this.props.questions}
							questionTextPadding="20px"
							questionPadding="0"
							question={question}
							activeQuestionId={this.props.activeQuestionId}
							culture={this.props.culture}
							onChange={this.props.onChange}
							onActiveQuestionChange={this.props.onActiveQuestionChange}
							canSkipQuestion={this.props.questionSettings.canSkipQuestion}
							isCommentDisabled={this.props.questionSettings.isCommentDisabled}
							isHideCommentFromManagerVisible={
								this.props.questionSettings.allowEmployeeHideCommentFromManager
							}
							isAllowAiAssistedRepliesVisible={
								this.props.questionSettings.allowAiAssistedRepliesToggle
							}
							questionColor="#4c4c4c"
							addCommentColor="#7b828c"
							questionFontSize="18px"
							questionFontSizeXS="16px"
							isChristmasModeEnabled={this.props.isChristmasModeEnabled}
						/>
					))}
				</QuestionsWrapper>
			</Wrapper>
		);
	}
}

SurveyContent.propTypes = {
	showPatchInfoText: PropTypes.bool,
	culture: PropTypes.string,
	title: PropTypes.string,
	activeQuestionId: PropTypes.string,
	questionSettings: PropTypes.object,
	questions: PropTypes.array,
	onBackClick: PropTypes.func,
	onChange: PropTypes.func,
	onActiveQuestionChange: PropTypes.func,
};

export default SurveyContent;
