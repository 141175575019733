import React, { Component } from "react";
import { deepFind, findGroupById } from "Helpers";

const IS_WITH_SEARCH_LIMIT = 10;

export default (ComposedComponent) =>
	class extends Component {
		constructor(props) {
			super(props);

			this.searchTimer = null;
			const stateOptions = this.getDefaultStateOptions(this.props);
			const isWithTabs = this.props.tabsConfig && this.props.optionsConfig && !this.props.isMultiSelect;

			this.closeTimeout = null; //need for tests, need to delete it in componentWillUnmount

			this.state = {
				isChanged: false,
				isOpened: false,
				dropdownHeight: null,
				isWithTabs: isWithTabs,
				isWithSearch:
					this.props.isWithSearchImportant ||
					(this.props.isWithSearch &&
						this.getNumberOfOptions(stateOptions) >= IS_WITH_SEARCH_LIMIT),
				selectedTab: this.props.selectedTab || (this.props.tabsConfig && this.props.tabsConfig[0]),
				stateOptions: stateOptions,
				selectedItems: this.getDefaultSelectedItems(
					this.props.defaultSelectedIds,
					this.props.selectedImportant
				),
				expandedIds: this.getDefaultExpandedIds(
					stateOptions,
					this.props.defaultExpandedIds,
					this.props.defaultSelectedIds,
					this.props.isDefaultSelectedExpanded
				),
				filterText: "",
			};
		}

		getDefaultStateOptionsBothTabs = (props) => {
			if (!props.tabsConfig || props.isMultiSelect) {
				return props.options || [];
			}
			const groupTab = props.tabsConfig[0];
			const segmentTab = props.tabsConfig[1];
			return ((groupTab && groupTab.Options) || []).concat((segmentTab && segmentTab.Options) || []);
		};

		getDefaultStateOptions = (props) => {
			if (!props.tabsConfig || props.isMultiSelect) {
				return props.options || [];
			}
			const selectedTab = props.tabsConfig[0];
			return (selectedTab && selectedTab.Options) || [];
		};

		getFullStateOptions = (props, selectedTab) => {
			if (!props.tabsConfig || props.isMultiSelect) {
				return props.options || [];
			}

			return (selectedTab && selectedTab.Options) || [];
		};

		onTabClick = (tab, dropdownHeight) => {
			if (!tab) {
				return null;
			}

			return this.setState(
				{
					dropdownHeight: dropdownHeight,
					selectedTab: tab,
					stateOptions: tab.Options || [],
					isWithSearch:
						this.props.isWithSearch &&
						this.getNumberOfOptions(tab.Options) >= IS_WITH_SEARCH_LIMIT,
					expandedIds: [],
					filterText: "",
				},
				() => this.props.onTabClick && this.props.onTabClick(tab)
			);
		};

		getDefaultSelectedItems = (defaultSelectedIds, selectedImportant) => {
			if (defaultSelectedIds && !selectedImportant) {
				return this.findItemsByIds(defaultSelectedIds);
			}
			if (selectedImportant) {
				return selectedImportant;
			}
			return [];
		};

		getDefaultExpandedIds = (
			options,
			defaultExpandedIds,
			defaultSelectedIds,
			isDefaultSelectedExpanded
		) => {
			const isWithDefaultExpandedIds = !!defaultExpandedIds;
			const isWithDefaultSelectedIds = defaultSelectedIds && defaultSelectedIds.length;

			if (isWithDefaultExpandedIds) {
				return defaultExpandedIds;
			}
			if (!isWithDefaultExpandedIds && isWithDefaultSelectedIds) {
				return isDefaultSelectedExpanded
					? this.getItemsParentsIds(this.findItemsByIds(defaultSelectedIds)).concat(
							defaultSelectedIds
					)
					: this.getItemsParentsIds(this.findItemsByIds(defaultSelectedIds));
			}
			if (!isWithDefaultExpandedIds && !isWithDefaultSelectedIds && options.length <= 5) {
				const expandedIds = [];
				options.forEach((option) => {
					if (option.Children && option.Children.length) {
						expandedIds.push(option.Id);
					}
				});
				return expandedIds;
			}
			return [];
		};

		handleClickOutside = () => {
			if (this.state.isOpened) {
				return this.props.isMultiSelect ? this.closeDropdown() : this.setState({ isOpened: false });
			}
		};

		onMainViewClick = () => {
			if (this.state.isOpened) {
				return this.props.isMultiSelect
					? this.closeDropdown()
					: this.setState(
							{ isOpened: false },
							() => this.props.onMainViewClick && this.props.onMainViewClick()
					);
			}
			this.setState(
				{ isOpened: true },
				() => this.props.onMainViewClick && this.props.onMainViewClick()
			);
		};

		onItemClick = (item, isParentSelected) => {
			if (!this.props.isMultiSelect) {
				return this.selectSingleItem(item);
			}

			const isSelected = !!this.state.selectedItems.find((selectedItem) => selectedItem.Id === item.Id);

			if (isParentSelected) {
				const parents = this.getAllItemParents(item);
				const selectedParent = parents.find((parent) =>
					this.state.selectedItems.find((selected) => selected.Id === parent.Id)
				);
				const index = parents.indexOf(selectedParent);
				const selectedParents = parents.slice(index);
				const exceptionItems = [...selectedParents, item];
				const options = !this.state
					? this.getDefaultStateOptionsBothTabs(this.props)
					: this.getFullStateOptions(this.props, this.state.selectedTab);
				const groups = selectedParents.map((parent) => findGroupById(parent.Id, options));
				const selectedData = [];

				groups.forEach((group) => {
					if (!group.Children) {
						selectedData.push(group);
					}
					group.Children.forEach((child) => {
						if (exceptionItems.find((exception) => exception.Id === child.Id)) {
							return null;
						}
						if (!child.Children) {
							return selectedData.push(child);
						}
						selectedData.push(child);
					});
				});

				const selectedWithoutParent = this.state.selectedItems.filter(
					(selected) => selected.Id !== selectedParent.Id
				);

				return this.setState({
					isChanged: true,
					selectedItems: [...selectedWithoutParent, ...selectedData],
				});
			}

			if (isSelected) {
				const newSelected = this.getAllSelectedWithoutCurrent(item);
				return this.setState({
					isChanged: true,
					selectedItems: newSelected,
				});
			}

			return this.selectWithParents(item);
		};

		onSelectGroupClick = (group, isParentSelected, event) => {
			event && event.stopPropagation && event.stopPropagation();
			event && event.preventDefault && event.preventDefault();

			if (!this.props.isMultiSelect) {
				return this.selectSingleItem(group);
			}

			const groupItems = this.getAllItemsFromGroup(group);
			const isGroupSelected = this.state.selectedItems.find(
				(selectedItem) => selectedItem.Id === group.Id
			);

			if (isParentSelected) {
				const newSelected = this.getAllSelectedWithoutCurrent(group);
				return this.setState(
					{
						isChanged: true,
						selectedItems: newSelected.filter(
							(selectedItem) => !groupItems.find((item) => item.Id === selectedItem.Id)
						),
					},
					() => this.selectAllChildren(group.ParentId, group.Id)
				);
			}

			if (isGroupSelected) {
				const newSelected = this.getAllSelectedWithoutCurrent(group);
				return this.setState({
					isChanged: true,
					selectedItems: newSelected.filter(
						(selectedItem) => !groupItems.find((item) => item.Id === selectedItem.Id)
					),
				});
			}

			return this.selectWithParents(group);
		};

		onGroupClick = (item) => {
			const isExpanded = this.state.expandedIds.indexOf(item.Id) !== -1;
			const ids = isExpanded
				? this.state.expandedIds.filter((id) => id !== item.Id)
				: [...this.state.expandedIds, item.Id];

			this.setState(
				{ expandedIds: ids },
				() => this.props.onExpandedToggle && this.props.onExpandedToggle(item, isExpanded)
			);
		};

		onFilterTextChange = (event) => {
			const filterValue = event.target.value;

			if (filterValue === this.state.filterText) {
				return null;
			}

			this.setState({ filterText: filterValue }, () => {
				clearTimeout(this.searchTimer);
				this.searchTimer = setTimeout(
					() =>
						this.props.filterByText
							? this.props.filterByText(filterValue)
							: this.filterByText(filterValue),
					300
				);
			});
		};

		filterByText = (filterValue) => {
			const options = this.getFullStateOptions(this.props, this.state.selectedTab);

			if (filterValue === "") {
				return this.setState({ filterText: filterValue, stateOptions: options });
			}

			const filteredChildren = this.filterChildrenByText(filterValue.toLowerCase(), options);

			return this.setState({
				stateOptions: filteredChildren,
			});
		};

		onSearchButtonClick = () => {
			return this.state.isChanged && this.returnResult();
		};

		getAllItemParents = (item, options) => {
			let parents = [];

			if (!item.ParentId) {
				return parents;
			}

			let isFinished = false;

			const findParents = (itemId, parentsArr, groupsArr) => {
				return groupsArr.forEach((group) => {
					if (isFinished) {
						return null;
					}
					if (itemId === group.Id) {
						parents = parentsArr;
						isFinished = true;
						return parentsArr;
					}
					if (group.Children && group.Children.length) {
						return findParents(itemId, [...parentsArr, group], group.Children);
					}
					return null;
				});
			};

			const groups =
				options ||
				(!this.state
					? this.getDefaultStateOptions(this.props)
					: this.getFullStateOptions(this.props, this.state.selectedTab));

			findParents(item.Id, [], groups);
			return parents;
		};

		getItemsParentsIds = (items, options) => {
			if (!items) {
				return [];
			}

			let parentsIds = [];

			items.forEach((item) => {
				const parents = this.getAllItemParents(item, options);
				parentsIds = parentsIds.concat(parents.map((parent) => parent.Id));
			});

			return parentsIds;
		};

		getNumberOfOptions = (options) => {
			let numberOfItems = 0;
			const countItems = (items) => {
				if (!items || !items.length) {
					return null;
				}
				numberOfItems += items.length;
				return items.forEach((item) => countItems(item.Children));
			};
			countItems(options);
			return numberOfItems;
		};

		getFlattenArray = (options) => {
			const result = [];
			const findItems = (items) => {
				if (!items || !items.length) {
					return null;
				}
				return items.forEach((item) => {
					if (result.findIndex((x) => x.Id === item.Id) === -1) {
						result.push({ ...item, Children: null });
					}
					return findItems(item.Children);
				});
			};
			findItems(options);
			return result;
		};

		findItemsByIds = (ids) => {
			const items = [];
			ids.forEach((id) => {
				const options = !this.state
					? this.getDefaultStateOptionsBothTabs(this.props)
					: this.getFullStateOptions(this.props, this.state.selectedTab);
				const item = findGroupById(id, options);
				if (item) {
					items.push(item);
				}
			});
			return items;
		};

		findTheTopSelectedParent = (group) => {
			if (!group.ParentId) {
				return group;
			}
			const isParentSelected = this.checkIsAllSelected(group.ParentId, group.Id);
			if (isParentSelected) {
				const options = !this.state
					? this.getDefaultStateOptionsBothTabs(this.props)
					: this.getFullStateOptions(this.props, this.state.selectedTab);
				const parent = findGroupById(group.ParentId, options);
				return this.findTheTopSelectedParent(parent);
			}
			return group;
		};

		checkIsAllSelected = (parentId, exceptionItemId) => {
			const options = !this.state
				? this.getDefaultStateOptionsBothTabs(this.props)
				: this.getFullStateOptions(this.props, this.state.selectedTab);
			const parent = findGroupById(parentId, options);
			const unselected = parent
				? parent.Children.find(
						(child) =>
							child.Id !== exceptionItemId &&
							!this.state.selectedItems.find((selected) =>
								exceptionItemId
									? selected.Id === child.Id || selected.Id === exceptionItemId
									: selected.Id === child.Id
							)
				)
				: true;
			return !unselected;
		};

		getAllItemsFromGroup = (group, items) => {
			if (!items) {
				items = [];
			}
			if (group.Children && group.Children.length) {
				group.Children.forEach((child) => this.getAllItemsFromGroup(child, items));
			}

			items.push(group);

			return items;
		};

		getAllSelectedWithoutCurrent = (item) => {
			const parents = this.getAllItemParents(item);
			const newSelected = this.state.selectedItems.filter((selectedItem) => {
				const isCurrentItem = selectedItem.Id === item.Id;
				const isParent = parents.find((parent) => selectedItem.Id === parent.Id);
				return !isCurrentItem && !isParent;
			});

			return newSelected;
		};

		selectAllChildren = (groupId, exceptionItemId) => {
			const options = !this.state
				? this.getDefaultStateOptionsBothTabs(this.props)
				: this.getFullStateOptions(this.props, this.state.selectedTab);
			const group = findGroupById(groupId, options);
			if (!group || !group.Children || !group.Children.length) {
				return null;
			}

			if (!exceptionItemId) {
				return this.setState({
					selectedItems: [...this.state.selectedItems, group],
				});
			}

			const children = group.Children.filter((child) => child.Id !== exceptionItemId);
			const selectedWithoutCurrentChildren = this.state.selectedItems.filter(
				(selected) => !children.find((child) => child.Id === selected.Id)
			);

			this.setState({
				selectedItems: [...selectedWithoutCurrentChildren, ...children],
			});
		};

		filterChildrenByText = (text, children) => {
			if (!children || !children.length) {
				return null;
			}

			const filteredByName = children.map((child) => {
				if (!child) {
					return null;
				}

				const isWithChildren = child.Children && child.Children.length;
				const extraFilterItem = this.props.extraFilterPath
					? deepFind(child, this.props.extraFilterPath)
					: null;
				const isHasMatch = extraFilterItem
					? child.Name.toLowerCase().indexOf(text) !== -1 ||
					extraFilterItem.toLowerCase().indexOf(text) !== -1
					: child.Name.toLowerCase().indexOf(text) !== -1;

				if (!isWithChildren && isHasMatch) {
					return child;
				}

				if (isWithChildren) {
					const filtered = this.filterChildrenByText(text, child.Children);

					if (!filtered && child.Name.toLowerCase().indexOf(text) !== -1) {
						return { ...child, Children: null };
					}

					return filtered && { ...child, Children: filtered };
				}
			});

			const filteredChildren = filteredByName.filter((child) => !!child);

			return filteredChildren.length ? filteredChildren : null;
		};

		getMultiselectTitle = () => {
			const isAllSelected = !this.props.options.find(
				(option) => !this.state.selectedItems.find((selected) => selected.Id === option.Id)
			);

			if (isAllSelected && this.props.selectedAllLabel) {
				return this.props.selectedAllLabel;
			}

			if (this.props.selectedXLabel) {
				return this.props.selectedXLabel.replace("{0}", this.state.selectedItems.length);
			}

			const names = this.state.selectedItems.map((item) => item.Name || item.Title);
			return names.length > 0 ? names.join(", ") : this.props.noSelectedLabel;
		};

		getTitle = () => {
			if (this.props.unchangeableTitle) {
				return this.props.unchangeableTitle;
			}

			if (this.props.isMultiSelect) {
				return this.getMultiselectTitle();
			}

			if (!this.state.selectedItems.length) {
				return this.props.noSelectedLabel || "";
			}

			return this.state.selectedItems[0].Name || this.state.selectedItems[0].Title;
		};

		closeDropdown = () => {
			const isChanged = this.state.isChanged;
			let stateOptions = this.props.options || [];

			if (this.props.tabsConfig && !this.props.isMultiSelect) {
				const selectedTab = this.state.selectedTab;
				stateOptions = (selectedTab && selectedTab.Options) || [];
			}

			this.setState(
				{
					isOpened: false,
					filterText: "",
					stateOptions: stateOptions,
					isChanged: false,
					dropdownHeight: null,
				},
				() => isChanged && this.returnResult()
			);
		};

		selectSingleItem = (item) => {
			return this.state.selectedItems.length && this.state.selectedItems[0].Id === item.Id
				? null
				: this.setState(
						{ isChanged: true, selectedItems: [item] },
						() => (this.closeTimeout = setTimeout(this.closeDropdown))
				);
		};

		selectWithParents = (item) => {
			const topSelectedParent = this.findTheTopSelectedParent(item);
			const parentChildren = this.getAllItemsFromGroup(topSelectedParent);
			const newSelected = this.state.selectedItems.filter((selectedItem) => {
				const isCurrentParent = selectedItem.Id === topSelectedParent.Id;
				const isChild = parentChildren.find((child) => selectedItem.Id === child.Id);
				return !isCurrentParent && !isChild;
			});

			return this.setState({
				isChanged: true,
				selectedItems: [...newSelected, topSelectedParent],
			});
		};

		getNewExpandedIds = (options) => {
			return this.getItemsParentsIds(this.state.selectedItems, options);
		};

		returnResult = () => {
			const result = this.props.isFlattenResult
				? this.getFlattenArray(this.state.selectedItems)
				: this.state.selectedItems;
			this.props.onChange(
				this.props.isMultiSelect ? result : this.state.selectedItems[0],
				this.state.selectedTab && this.state.selectedTab.GroupingType
			);

			this.props.filterByText && setTimeout(() => this.props.filterByText(""));
		};

		UNSAFE_componentWillReceiveProps(nextProps) {
			let state = { ...this.state };

			if (nextProps.selectedImportant) {
				const ids = nextProps.selectedImportant.map((item) => item.Id);
				state.selectedItems = nextProps.selectedImportant;
				state.expandedIds = nextProps.isSelectedImportantExpanded
					? this.getItemsParentsIds(nextProps.selectedImportant).concat(ids)
					: this.state.expandedIds;
			}

			if (this.props.options !== nextProps.options) {
				state.stateOptions = nextProps.options;
				state.isWithSearch =
					nextProps.isWithSearchImportant ||
					(nextProps.isWithSearch &&
						this.getNumberOfOptions(nextProps.options) >= IS_WITH_SEARCH_LIMIT);
			}

			if (this.props.selectedTab !== nextProps.selectedTab) {
				state.selectedTab = nextProps.selectedTab;
			}

			this.setState(state);
		}

		componentWillUnmount() {
			this.closeTimeout && clearTimeout(this.closeTimeout);
		}

		render() {
			return (
				<ComposedComponent
					{...this.props}
					{...this.state}
					title={this.getTitle()}
					onMainViewClick={this.onMainViewClick}
					onGroupClick={this.onGroupClick}
					onSelectGroupClick={this.onSelectGroupClick}
					onSelectItemClick={this.onItemClick}
					onSearchButtonClick={this.onSearchButtonClick}
					onTabClick={this.onTabClick}
					onCloseDropdownClick={this.closeDropdown}
					onFilterSearchTextChange={this.onFilterTextChange}
				/>
			);
		}
	};
