import React, { PureComponent } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authentication";
import { navigateToLogin } from "../actions/router";
import { checkAgreement, getHeapData } from "../actions/general";
import { getCulture } from "../actions/culture";
import { idleAutoLogout, clearIdleInterval } from "../util/idleAutoLogout";
import { getStringHashCode } from "../util/helpers";
import wtI18n from "../util/wt-i18n";
import StartContainer from "./StartContainer";
import LoginContainer from "./LoginContainer";
import BatchesContainer from "./BatchesContainer";
import EscalateContainer from "./EscalateContainer";
import WidgetContainer from "./WidgetContainer";
import QuestionContainer from "./QuestionContainer";
import GdprContainer from "./GdprContainer";

const GdprWithLoginRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!rest.authenticated) {
					return <Redirect to={{ pathname: `/${window.initialState.customer}` }} />;
				}
				if (rest.showGdprAgreement) {
					return (
						<Redirect
							to={{
								pathname: `/${window.initialState.customer}/gdpr`,
								state: { from: props.location },
							}}
						/>
					);
				}
				return <Component {...props} />;
			}}
		/>
	);
};

const LoginRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!rest.authenticated) {
					return <Redirect to={{ pathname: `/${window.initialState.customer}` }} />;
				}
				return <Component {...props} />;
			}}
		/>
	);
};

class App extends PureComponent {
	componentDidMount() {
		wtI18n.getResources(this.props.culture);

		if (this.props.authenticated) {
			this.props.checkAgreementValue();
			this.props.initHeap();
			idleAutoLogout(this.props.logout);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.authenticated && !this.props.authenticated) {
			this.props.checkAgreementValue();
			this.props.initHeap();
			idleAutoLogout(this.props.logout);
		}

		if (!nextProps.authenticated && this.props.authenticated) {
			clearIdleInterval();
		}

		if (nextProps.culture !== this.props.culture) {
			wtI18n.getResources(nextProps.culture);
		}
	}

	render() {
		return (
			<div>
				<BrowserRouter>
					<Switch>
						<Route exact path={"/"} component={StartContainer} />
						<Route exact path={"/" + window.initialState.customer} component={LoginContainer} />
						<LoginRoute
							exact
							path={"/" + window.initialState.customer + "/gdpr"}
							component={GdprContainer}
							authenticated={this.props.authenticated}
						/>
						<GdprWithLoginRoute
							exact
							path={"/" + window.initialState.customer + "/batches"}
							component={BatchesContainer}
							authenticated={this.props.authenticated}
							showGdprAgreement={this.props.showGdprAgreement}
						/>
						<GdprWithLoginRoute
							exact
							path={"/" + window.initialState.customer + "/escalate"}
							component={EscalateContainer}
							authenticated={this.props.authenticated}
							showGdprAgreement={this.props.showGdprAgreement}
						/>
						<GdprWithLoginRoute
							exact
							path={"/" + window.initialState.customer + "/temperature"}
							component={WidgetContainer}
							authenticated={this.props.authenticated}
							showGdprAgreement={this.props.showGdprAgreement}
						/>
						<GdprWithLoginRoute
							path={"/" + window.initialState.customer + "/batches/:id"}
							component={QuestionContainer}
							authenticated={this.props.authenticated}
							showGdprAgreement={this.props.showGdprAgreement}
						/>
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.authentication.authenticated,
		showGdprAgreement: state.general.showGdprAgreement,
		culture: getCulture(state),
	};
};

const mapDispatchToProps = (dispatch, { history }) => {
	return {
		checkAgreementValue: () => {
			dispatch(checkAgreement()).then((result) => {
				if (result.status === 401) {
					dispatch(logoutUser()).then(navigateToLogin(history));
				}
			});
		},
		initHeap: () => {
			if (!window.heap) return null;
			dispatch(getHeapData()).then((result) => {
				if (result.success) {
					window.heap.identify(getStringHashCode(result.visitor.id));
					window.heap.addUserProperties({
						RoleName: result.visitor.role,
						CustomerId: result.account.id,
						Language: result.visitor.language_override,
						CustomerPackage: result.account.planLevel,
					});
				}
			});
		},
		logout: () => dispatch(logoutUser()).then(navigateToLogin()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
