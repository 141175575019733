import React, { Component } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import HeaderWithProfile from "./HeaderWithProfile";
import { QuestionEscalate, SurveyContainerWrapper } from "./Question";

class Escalate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			temperature: {
				description: this.props.temperature.question.description,
				grade: this.props.temperature.grade,
				visibleForImmediateManager: true,
				allowAiAssistedReplies: true,
				comment: null,
			},
		};
	}

	onChange = (comment) => {
		this.setState({
			temperature: {
				...this.state.temperature,
				comment: comment.comment,
				visibleForImmediateManager: comment.visibleForImmediateManager,
				allowAiAssistedReplies: comment.allowAiAssistedReplies,
			},
		});
	};

	onSubmit = () => {
		//TODO: change this. inject the userEscalateId into temperature
		const comment = Object.assign(this.state.temperature, { userEscalateId: this.props.userEscalateId });

		this.props.onSubmit(comment, this.props.batch, this.props.urls.save);
	};

	onCancel = () => {
		this.props.onCancel(
			{ userEscalateId: this.props.userEscalateId, declined: true },
			this.props.batch,
			this.props.urls.save
		);
	};

	render() {
		return (
			<SurveyContainerWrapper>
				<ContentWrapper display="block" position="fixed" top="0" width="100%" zIndex="1">
					<HeaderWithProfile
						padding="20px 30px"
						paddingMedia="20px"
						username={this.props.username}
						culture={this.props.culture}
						onLogout={this.props.onLogout}
					/>
				</ContentWrapper>
				<div className="surveyQuestionsBox">
					{this.state.temperature && (
						<QuestionEscalate
							questionSettings={this.props.batch.questionSettings}
							escalationDate={this.props.userEscalateDate}
							backgroundColor={Colors.backgroundLightGray}
							culture={this.props.culture}
							temperature={this.state.temperature}
							onChange={this.onChange}
							onSubmit={this.onSubmit}
							onCancel={this.onCancel}
						/>
					)}
				</div>
			</SurveyContainerWrapper>
		);
	}
}

Escalate.propTypes = {
	username: PropTypes.string,
	culture: PropTypes.string,
	batch: PropTypes.object,
	userEscalateId: PropTypes.string,
	userEscalateDate: PropTypes.string,
	temperature: PropTypes.object,
	onLogout: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
};

export default Escalate;
