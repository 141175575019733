// this file origin from Winningtemp.Shared

import React, { Component } from "react";
import $ from "jquery";

function withBackgroundHeightAdjust(WrappedComponent) {
	var height; 
	
	return class extends Component {
		checkForHeightChanges = () => {
			var newHeight = $(document).height();
			
			if (newHeight !== height) {
				$(".with-auto-height-adjust").css("height", newHeight);             
			}

			height = newHeight;
		}

		componentDidMount() {
			var self = this;

			$(document).ready(() => {
				var containerHasImage = $(".with-auto-height-adjust").css("background-image") !== "none";
				if (containerHasImage) {
					setInterval(self.checkForHeightChanges, 500); 
				}               
			});
		}

		render() {
			return (
				<WrappedComponent {...this.props} />
			);
		}
	}
}

export {
	withBackgroundHeightAdjust
}