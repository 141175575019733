// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionCommentHeaderMobile from "./QuestionCommentHeaderMobile";

class QuestionCommentDescriptionMobile extends PureComponent {
	render() {
		return (
			<ContentWrapper
				display="none"
				displayXS="flex"
				flexDirection="column"
				position="fixed"
				zIndex="3"
				width="100%"
				height="100%"
				backgroundColor={Colors.backgroundLightGray}
				top="0"
				left="0">
				<QuestionCommentHeaderMobile
					title={Resources[this.props.culture].whatDoesThisMean}
					buttonText={Resources[this.props.culture].cancel}
					onButtonClick={this.props.onClose}
				/>
				<ContentWrapper
					display="block"
					padding="15px"
					fontSize="16px"
					color="#333333"
					lineHeight="23px">
					{this.props.description ||
						Resources[this.props.culture].questionCommentWhatDoesItMeanDescription}
				</ContentWrapper>
			</ContentWrapper>
		);
	}
}

QuestionCommentDescriptionMobile.propTypes = {
	culture: PropTypes.string,
	description: PropTypes.string,
	onClose: PropTypes.func,
};

export default QuestionCommentDescriptionMobile;
