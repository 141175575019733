// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SmileyWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.isReadOnly ? "" : "pointer"};
    border-radius: 2px;
    width: ${props => props.size || "46px"};
    min-width: ${props => props.size || "46px"};
    height: ${props => props.size || "46px"};
    background-color: ${props => props.isActive ? "#f2f9fa" : "#ffffff"};
    border: ${props => props.isActive ? props.activeBorder : "1px solid #dbdbdb"};
    margin: ${props => props.margin || "0"};
    ${props => props.isReadOnly ? "" : 
        `&:hover {
            border-color: ${props.isActive ? props.hoverBorder || "#59b0c7" : "#b3b3b3"};
            background-color: ${props.isActive ? "#f2f9fa" : "#f7f7f7"};
        }`
    }
    @media(max-width: 768px) {
        ${props => props.sizeXS ? `width: ${props.sizeXS}; min-width: ${props.sizeXS}; height: ${props.sizeXS};` : ""}
    }
    @media(max-width: 360px) {
        width: 38px;
        min-width: 38px;
        height: 38px;
    }
`;

SmileyWrapper.propTypes = {
    isActive: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    size: PropTypes.string,
    sizeXS: PropTypes.string,
    margin: PropTypes.string,
    activeBorder: PropTypes.string,
    hoverBorder: PropTypes.string,
}

export default SmileyWrapper;