// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.button`
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${props => props.border || "1px solid #adadad"};
    border-radius: ${props => props.borderRadius || "4px"};
    background-color: ${props => props.backgroundColor || (props.isActive ? "#e6e6e6" : "#ffffff")};
    padding: ${props => props.padding || "10px 20px"};
    width: 100%;
    min-height: 40px;
    height: ${props => props.isWithLongItems ? "auto" : "40px"};
    &:hover {
        ${props => props.hoverBorder ? `border: ${props.hoverBorder};` : null}
        ${props => props.hoverBackgroundColor ? `background-color: ${props.hoverBackgroundColor};` : null}
    }
    &:disabled {
        cursor: not-allowed;
        background-color: ${props => props.disabledBackgroundColor || "#f5f5f5"};
    }
`;

const Text = styled.span`
    font-size: 14px;
    font-weight: 400;
    white-space: ${props => props.isWithLongItems ? "unset" : "nowrap"};
    overflow: hidden;
    user-select: none;
    color: ${props => props.color || "#595959"};
    margin-right: 10px;
    text-align: left;
`;

const Icon = styled.span`
    width: 0;
    height: 0;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
`;

class MainView extends PureComponent {
	render() {
		return (
			<Wrapper
				type="button"
				isWithLongItems={this.props.isWithLongItems}
				isActive={this.props.isActive}
				disabledBackgroundColor={this.props.disabledBackgroundColor}
				onClick={this.props.onClick}
				disabled={this.props.isDisabled}
				border={this.props.border}
				borderRadius={this.props.borderRadius}
				hoverBorder={this.props.hoverBorder}
				backgroundColor={this.props.backgroundColor}
				hoverBackgroundColor={this.props.hoverBackgroundColor}
				padding={this.props.mainViewPadding}>
				<Text
					color={this.props.mainViewColor}
					isWithLongItems={this.props.isWithLongItems}>
					{this.props.selected ? this.props.selected[this.props.labelPropName] : this.props.noSelectedLabel}
				</Text>
				<Icon/>
			</Wrapper>
		);
	}
}

MainView.propTypes = {
	isDisabled: PropTypes.bool,
	isWithLongItems: PropTypes.bool,
	isActive: PropTypes.bool,
	labelPropName: PropTypes.string,
	noSelectedLabel: PropTypes.string,
	disabledBackgroundColor: PropTypes.string,
	border: PropTypes.string,
	hoverBorder: PropTypes.string,
	mainViewColor: PropTypes.string,
	borderRadius: PropTypes.string,
	mainViewPadding: PropTypes.string,
	backgroundColor: PropTypes.string,
	hoverBackgroundColor: PropTypes.string,
	selected: PropTypes.object,
	onClick: PropTypes.func
}

export default MainView;