// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { GradeEnps } from "Enums";
import styled, { css, keyframes } from "styled-components";
import Button from "Controls/Button";
import CircleWrapper from "./CircleWrapper";
import SmileyGrade from "./SmileyGrade";
import SmileyStronglyDisagree from "./SmileyStronglyDisagree";
import SmileyAgree from "./SmileyAgree";
import SmileyStronglyAgree from "./SmileyStronglyAgree";

const shake = keyframes`
	0% { -webkit-transform: rotate(0deg); }
	25% { -webkit-transform: rotate(-3deg); }
	50% { -webkit-transform: rotate(3deg); }
	75% { -webkit-transform: rotate(-3deg); }
	100% { -webkit-transform: rotate(0deg); }
`;

const animation = props => css`animation: ${shake} 0.5s linear;`;

const Wrapper = styled(Button)`
	transform-origin: 50% 100%;
	${props => props.isWithShake ? animation : null}
	&:hover {
		.smiley-background {
			${props => !props.isReadOnly && `opacity: 0.2;`}
		}
	}
`;

class SmileyEnps extends PureComponent {
	onClick = () => {
		if (!this.props.onClick) {
			return null;
		}

		this.props.onClick(this.props.grade);
	}

	renderSmile = () => {
		switch (this.props.grade) {
			case GradeEnps.detractors:
				return (<SmileyStronglyDisagree isTemperature={this.props.isTemperature} />);
			case GradeEnps.passives:
				return (<SmileyAgree isTemperature={this.props.isTemperature} />);
			default:
				return (<SmileyStronglyAgree isTemperature={this.props.isTemperature} />);
		}
	}

	render() {
		return (
			<Wrapper
				type="button"
				backgroundColor="transparent"
				margin={this.props.margin}
				disabled={this.props.isReadOnly}
				isReadOnly={this.props.isReadOnly}
				isWithShake={this.props.isWithShake}
				position={this.props.position}
				left={this.props.left}
				top={this.props.top}
				leftXS={this.props.leftXS}
				topXS={this.props.topXS}
				height={this.props.smileySize}
				width={this.props.smileySize}
				onClick={this.onClick}>
				<CircleWrapper
					size={this.props.smileySize}
					sizeXS={this.props.smileySizeXS}
					color={this.props.color}
					isActive={this.props.isActive}>
					{
						this.props.isWithSmiley && this.renderSmile()
					}
					{
						!this.props.isWithSmiley &&
						<SmileyGrade
							grade={this.props.grade}>
						</SmileyGrade>
					}
				</CircleWrapper>
			</Wrapper>
		);
	}
}

SmileyEnps.propTypes = {
	isWithSmiley: PropTypes.bool,
	isActive: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isWithShake: PropTypes.bool,
	grade: PropTypes.number,
	margin: PropTypes.string,
	color: PropTypes.string,
	smileySize: PropTypes.string,
	smileySizeXS: PropTypes.string,
	position: PropTypes.string,
	left: PropTypes.string,
	top: PropTypes.string,
	topXS: PropTypes.string,
	leftXS: PropTypes.string,
	onClick: PropTypes.func
}

export default SmileyEnps;