// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

import MainView from "./MainView";
import DropdownComponent from "./DropdownComponent";

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    min-width: ${props => props.minWidth || "200px"};
    ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : null}
`;

class SimpleDropdownSelect extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isOpened: false,
			labelPropName: this.props.labelPropName || "name",
			selected: this.props.selected
		}
	}

	handleClickOutside = () => {
		if (this.state.isOpened) {
			return this.closeDropdown();
		}
	}

	closeDropdown = () => {
		this.setState({ isOpened: false });
	}

	onMainViewClick = () => {
		if (this.props.isDisabled) {
			return null;
		}

		this.setState({ isOpened: !this.state.isOpened });
	}

	onItemClick = (item) => {
		if (item === this.state.selected) {
			return this.closeDropdown();
		}

		this.setState({ selected: item, isOpened: false });
		this.props.onChange(item);
	}

	componentDidUpdate(prevProps) {
		if (this.props.selected !== prevProps.selected) {
			this.setState({ selected: this.props.selected });
		}
	}

	render() {
		return (
			<Wrapper minWidth={this.props.minWidth} maxWidth={this.props.maxWidth}>
				<MainView
					isWithLongItems={this.props.isWithLongItems}
					isActive={this.state.isOpened}
					selected={this.state.selected}
					labelPropName={this.state.labelPropName}
					isDisabled={this.props.isDisabled}
					disabledBackgroundColor={this.props.disabledBackgroundColor}
					noSelectedLabel={this.props.noSelectedLabel}
					border={this.props.border}
					hoverBorder={this.props.hoverBorder}
					mainViewColor={this.props.mainViewColor}
					mainViewPadding={this.props.mainViewPadding}
					borderRadius={this.props.mainViewBorderRadius}
					backgroundColor={this.props.mainViewBackgroundColor}
					hoverBackgroundColor={this.props.mainViewHoverBackgroundColor}
					onClick={this.onMainViewClick}/>
				{
					this.state.isOpened &&
						<DropdownComponent
							labelPropName={this.state.labelPropName}
							selected={this.state.selected}
							itemColor={this.props.dropdownItemColor}
							isWithLongItems={this.props.isWithLongItems}
							maxHeight={this.props.dropdownMaxHeight}
							options={this.props.options}
							onItemClick={this.onItemClick}/>
				}
			</Wrapper>
		);
	}
}

SimpleDropdownSelect.propTypes = {
	isDisabled: PropTypes.bool,
	isWithLongItems: PropTypes.bool,
	dropdownMaxHeight: PropTypes.string,
	noSelectedLabel: PropTypes.string,
	labelPropName: PropTypes.string,
	disabledBackgroundColor: PropTypes.string,
	border: PropTypes.string,
	hoverBorder: PropTypes.string,
	mainViewBackgroundColor: PropTypes.string,
	mainViewHoverBackgroundColor: PropTypes.string,
	mainViewColor: PropTypes.string,
	mainViewBorderRadius: PropTypes.string,
	mainViewPadding: PropTypes.string,
	minWidth: PropTypes.string,
	maxWidth: PropTypes.string,
	dropdownItemColor: PropTypes.string,
	selected: PropTypes.object,
	options: PropTypes.array,
	onChange: PropTypes.func
}

export default onClickOutside(SimpleDropdownSelect);