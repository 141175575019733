// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import styled from "styled-components";

const Wrapper = styled.svg`
	height: ${(props) => props.size || "16px"};
	width: ${(props) => props.size || "16px"};
	min-width: ${(props) => props.size || "16px"};
	fill: ${(props) => props.color || Colors.questionAnonymousLabel};
	@media (max-width: 768px) {
		fill: ${(props) => props.colorXS || Colors.questionAnonymousLabelXS};
	}
`;

class QuestionAnonymousIcon extends PureComponent {
	render() {
		return (
			<Wrapper {...this.props} viewBox="0 0 18 16">
				<path d="M9.13 10.96c-.52 0-1.02-.06-1.5-.15L9.1 9.35a2.27 2.27 0 0 0 2.8-2.8l1.27-1.27c1.29.8 2.19 1.78 2.64 2.33-.83.98-3.2 3.35-6.67 3.35zM17.4 7.2a11.65 11.65 0 0 0-3.12-3.03l3.02-3.02L16.21.07 1.38 14.91l1.08 1.07 3.92-3.92a9.2 9.2 0 0 0 2.75.42c5.19 0 8.13-4.25 8.25-4.43l.29-.42-.28-.43zM3.5 10.62l1.11-1.1A10.51 10.51 0 0 1 2.47 7.6c.82-1.02 3.17-3.5 6.66-3.5.3 0 .57.02.84.05l1.3-1.3a8.77 8.77 0 0 0-2.14-.28C3.94 2.58 1 7.02.88 7.2l-.28.43.28.42c.07.1.98 1.4 2.63 2.57" />
			</Wrapper>
		);
	}
}

QuestionAnonymousIcon.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
	colorXS: PropTypes.string,
};

export default QuestionAnonymousIcon;
