import React, { Component } from "react";
import PropTypes from "prop-types";

class LanguageSelectorItem extends Component {
    onClick = () => {
        this.props.onClick(this.props.culture);
    }

    render() {
        return (
            <li className="dropdown-menu-culture">
                <a href="#" onClick={this.onClick}>
                    <img src={this.props.imageUrl} />{this.props.name}
                </a>
            </li>
        );
    }
}

LanguageSelectorItem.propTypes = {
    name: PropTypes.string,
    culture: PropTypes.string,
    imageUrl: PropTypes.string,
    onClick: PropTypes.func
}

export default LanguageSelectorItem;