import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import DropdownSelectHOC from "./DropdownSelectHOC";
import DropdownWrapper from "./DropdownWrapper";
import DropdownMainView from "./DropdownMainView";
import DropdownComponent from "./DropdownComponent";

class DropdownSelect extends PureComponent {
	constructor(props) {
		super(props);

		this.isOpenedTimeout = null;

		this.state = {
			isOpened: this.props.isOpened,
			isShouldBeClosed: !this.props.isOpened,
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isOpened && this.props.isOpened) {
			return this.setState({ isOpened: this.props.isOpened, isShouldBeClosed: false });
		}

		if (prevProps.isOpened && !this.props.isOpened) {
			const bodyWidth = document.body.clientWidth;

			return this.setState({ isShouldBeClosed: true }, () => {
				this.isOpenedTimeout = setTimeout(
					() => this.setState({ isOpened: this.props.isOpened }),
					bodyWidth <= 767 ? 500 : 0
				);
			});
		}

		this.props.onSelectedItemsChange && this.props.onSelectedItemsChange(this.props.selectedItems);
	}

	componentWillUnmount() {
		clearTimeout(this.isOpenedTimeout);
	}

	render() {
		return (
			<DropdownWrapper
				data-test={this.props.dataTest}
				width={this.props.width}
				maxWidth={this.props.maxWidth}
				flexGrow={this.props.flexGrow}
				flexGrowXS={this.props.flexGrowXS}
				display={this.props.display}
				mediaDisplay={this.props.mediaDisplay}
				mediaWidth={this.props.mediaWidth}
				margin={this.props.margin}
				mediaMargin={this.props.mediaMargin}>
				{this.props.renderCustomHeader ? (
					this.props.renderCustomHeader(
						this.props.title,
						this.props.onMainViewClick,
						this.state.isOpened,
						this.props.selectedItems,
						this.props.disabled
					)
				) : (
					<DropdownMainView
						height={this.props.mainViewHeight}
						selectorLabel={this.props.selectorLabel}
						selectedLabel={this.props.title}
						bottomBorder={this.props.mainViewBottomBorder}
						onClick={this.props.onMainViewClick}
					/>
				)}
				{this.state.isOpened && (
					<DropdownComponent
						dataTestItem={this.props.dataTestItem}
						expandUnder={this.props.expandUnder}
						searchPlaceholder={this.props.searchPlaceholder}
						selectorLabel={this.props.selectorLabel}
						curvedCheckmark={this.props.curvedCheckmark}
						culture={this.props.culture}
						highlightSelectedItemColor={this.props.highlightSelectedItemColor}
						liFontWeight={this.props.liFontWeight}
						liFontSize={this.props.liFontSize}
						isShouldBeClosed={this.state.isShouldBeClosed}
						isMultiSelect={this.props.isMultiSelect}
						isWithSearch={this.props.isWithSearch}
						isWithButton={this.props.isWithButton}
						dropdownHeight={this.props.dropdownHeight}
						dropdownMaxHeight={this.props.dropdownMaxHeight}
						dropdownMinWidth={this.props.dropdownMinWidth}
						hoverItemBackgroundColor={this.props.hoverItemBackgroundColor}
						checkmarkColor={this.props.checkmarkColor}
						checkmarkHiddenForNotCheckedItems={this.props.checkmarkHiddenForNotCheckedItems}
						notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
						dropdownLabel={this.props.dropdownLabel}
						emptySearchLabel={this.props.emptySearchLabel}
						renderCustomItem={this.props.renderCustomItem}
						renderCustomItemParent={this.props.renderCustomItemParent}
						options={this.props.stateOptions}
						searchText={this.props.filterText}
						buttonLabel={this.props.buttonLabel}
						selectedArray={this.props.selectedItems}
						selectedTab={this.props.selectedTab}
						tabs={this.props.tabsConfig}
						expandedIds={this.props.expandedIds}
						disabledIds={this.props.disabledIds}
						wrapperRightValueImportant={this.props.wrapperRightValueImportant}
						wrapperPaddingTop={this.props.dropdownPaddingTop}
						dropdownListMargin={this.props.dropdownListMargin}
						onGroupClick={this.props.onGroupClick}
						onSelectGroupClick={this.props.onSelectGroupClick}
						onSelectItemClick={this.props.onSelectItemClick}
						onSearchButtonClick={this.props.onSearchButtonClick}
						onTabClick={this.props.onTabClick}
						onCloseClick={this.props.onCloseDropdownClick}
						onFilterSearchTextChange={this.props.onFilterSearchTextChange}
						isSelectedWithoutChildren={this.props.isSelectedWithoutChildren}
						isWithHighlightBar={this.props.isWithHighlightBar}
						paddingVertical={this.props.paddingVertical}
						maxWidth={this.props.dropdownMaxWidth}
					/>
				)}
			</DropdownWrapper>
		);
	}
}

DropdownSelect.propTypes = {
	isOpened: PropTypes.bool,
	isMultiSelect: PropTypes.bool,
	isWithSearch: PropTypes.bool,
	isWithButton: PropTypes.bool,
	isDefaultSelectedExpanded: PropTypes.bool,
	checkmarkHiddenForNotCheckedItems: PropTypes.bool,
	notHighlightSelectedItemsText: PropTypes.bool,
	curvedCheckmark: PropTypes.bool,
	expandUnder: PropTypes.bool,
	isFlattenResult: PropTypes.bool,
	width: PropTypes.string,
	dropdownHeight: PropTypes.number,
	dropdownMaxHeight: PropTypes.string,
	dropdownMinWidth: PropTypes.string,
	margin: PropTypes.string,
	mediaDisplay: PropTypes.string,
	mediaWidth: PropTypes.string,
	selectorLabel: PropTypes.string,
	selectedAllLabel: PropTypes.string,
	selectedXLabel: PropTypes.string,
	noSelectedLabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	culture: PropTypes.string,
	mediaMargin: PropTypes.string,
	mainViewHeight: PropTypes.string,
	mainViewMinHeight: PropTypes.string,
	mainViewPadding: PropTypes.string,
	mainViewBottomBorder: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	highlightSelectedItemColor: PropTypes.string,
	liFontWeight: PropTypes.string,
	emptySearchLabel: PropTypes.string,
	display: PropTypes.string,
	flexGrow: PropTypes.string,
	flexGrowXS: PropTypes.string,
	wrapperRightValueImportant: PropTypes.string,
	dropdownPaddingTop: PropTypes.string,
	dropdownListMargin: PropTypes.string,
	hoverItemBackgroundColor: PropTypes.string,
	checkmarkColor: PropTypes.string,
	liFontSize: PropTypes.string,
	dataTestItem: PropTypes.string,
	dropdownMaxWidth: PropTypes.string,
	selectedTab: PropTypes.object,
	tabsConfig: PropTypes.array,
	defaultSelectedIds: PropTypes.array,
	selectedImportant: PropTypes.array,
	defaultExpandedIds: PropTypes.array,
	disabledIds: PropTypes.array,
	options: PropTypes.array,
	onChange: PropTypes.func,
	onTabClick: PropTypes.func,
	renderCustomHeader: PropTypes.func,
	onCloseDropdownClick: PropTypes.func,
	renderCustomItem: PropTypes.func,
	renderCustomParentItem: PropTypes.func,
	onSelectedItemsChange: PropTypes.func,
	dataTest: PropTypes.string,
	isWithHighlightBar: PropTypes.bool,
	paddingVertical: PropTypes.number,
};

export default onClickOutside(DropdownSelectHOC(DropdownSelect));
