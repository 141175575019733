// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionsFixedWrapper from "../QuestionsFixedWrapper";
import QuestionProgressBar from "../QuestionProgressBar";

const HeaderText = styled.h4`
    color: #ffffff;
    line-height: 1.4;
    font-size: ${props => props.fontSize || "20px"};
    font-weight: 700;
    margin: 0;
    @media(max-width: 768px) {
        font-size: 15px;
		font-weight: 600;
    }
`;

class QuestionTalkModalHeader extends PureComponent {
	render() {
		return (
			<QuestionsFixedWrapper top="0" backgroundColor={Colors.mainPurple} backgroundColorXS={Colors
				.questionWindowBackgroundXS}>
				<ContentWrapper
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
					padding="15px 20px"
					paddingXS="10px 20px"
					minHeight="16.43px">
					<HeaderText>
						{this.props.titleText}
					</HeaderText>
				</ContentWrapper>
				{this.props.progressWidth >= 0 && 
					<QuestionProgressBar progressWidth={this.props.progressWidth}/>
				}
			</QuestionsFixedWrapper>
		);
	}
}

QuestionTalkModalHeader.propTypes = {
	progressWidth: PropTypes.number,
	titleText: PropTypes.string
}

export default QuestionTalkModalHeader;