import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { get } from "../actions/api";

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			"sv-SE": {
				translations: {},
			},
		},
		ns: ["translations"],
		defaultNS: "translations",
		fallbackLng: "sv-SE",
		lng: "sv-SE", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

const setEventHandlers = (handlers) => {
	Object.keys(handlers).map((key) => {
		i18n.on(key, handlers[key]);
	});
};

const getResources = (culture) => {
	get(`translations${culture ? "?culture=" + culture : ""}`, { general: {culture} }).then((response) => {
		i18n.addResourceBundle(response.data.culture, "translations", response.data.resources, true, true);
		i18n.changeLanguage(response.data.culture);
	});
};

const wtI18n = {
	getResources,
	setEventHandlers,
	i18n,
};

export default wtI18n;
