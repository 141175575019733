import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ContentWrapper from "Controls/ContentWrapper";
import DropdownListComponent from "./DropdownListComponent";
import DropdownButtonComponent from "./DropdownButtonComponent";

const MobileButtonWrapper = styled(ContentWrapper)`
	@media (max-width: 767px) {
		display: block;
		min-height: 40px;
	}
`;

class DropdownComponentContent extends PureComponent {
	render() {
		return (
			<React.Fragment>
				<DropdownListComponent
					dataTestItem={this.props.dataTestItem}
					maxHeightXS={this.props.isWithSearch ? "calc(60vh - 187px)" : "calc(60vh - 130px)"}
					isFullHeightXS={this.props.isDropdownFullHeightXS}
					isFilterLoading={this.props.isFilterLoading}
					curvedCheckmark={this.props.curvedCheckmark}
					isPreventExpandedForFilter={this.props.isPreventExpandedForFilter}
					dropdownMaxHeight={this.props.dropdownMaxHeight}
					listMargin={this.props.dropdownListMargin}
					isMultiSelect={this.props.isMultiSelect}
					isWithSearch={this.props.isWithSearch}
					highlightSelectedItemColor={this.props.highlightSelectedItemColor}
					hoverBackgroundColor={this.props.hoverItemBackgroundColor}
					checkmarkColor={this.props.checkmarkColor}
					checkmarkHiddenForNotCheckedItems={this.props.checkmarkHiddenForNotCheckedItems}
					notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
					liFontWeight={this.props.liFontWeight}
					itemFontSize={this.props.liFontSize}
					isWithButton={this.props.isWithButton}
					isWithAdittionalFlags={this.props.isWithAdittionalFlags}
					filterText={this.props.searchText}
					selected={this.props.selectedArray}
					expandedIds={this.props.expandedIds}
					disabledIds={this.props.disabledIds}
					dataLoadingId={this.props.dataLoadingId}
					onGroupClick={this.props.onGroupClick}
					onSelectGroupClick={this.props.onSelectGroupClick}
					onSelectItemClick={this.props.onSelectItemClick}
					renderCustomItem={this.props.renderCustomItem}
					renderCustomItemParent={this.props.renderCustomItemParent}
					culture={this.props.culture}
					emptySearchLabel={this.props.emptySearchLabel}
					options={this.props.options}
					isSelectedWithoutChildren={this.props.isSelectedWithoutChildren}
					isWithHighlightBar={this.props.isWithHighlightBar}
					paddingVertical={this.props.paddingVertical}
				/>

				{this.props.isWithButton && (
					<DropdownButtonComponent
						label={this.props.buttonLabel}
						onButtonClick={this.props.onSearchButtonClick}
					/>
				)}

				<MobileButtonWrapper display="none">
					<DropdownButtonComponent
						label="Close"
						onButtonClick={() => this.props.onCloseClick && this.props.onCloseClick()}
					/>
				</MobileButtonWrapper>
			</React.Fragment>
		);
	}
}

DropdownComponentContent.propTypes = {
	isLoading: PropTypes.bool,
	isFilterLoading: PropTypes.bool,
	isPreventExpandedForFilter: PropTypes.bool,
	isDropdownFullHeightXS: PropTypes.bool,
	isMultiSelect: PropTypes.bool,
	isWithSearch: PropTypes.bool,
	isWithButton: PropTypes.bool,
	isWithAdittionalFlags: PropTypes.bool,
	checkmarkHiddenForNotCheckedItems: PropTypes.bool,
	notHighlightSelectedItemsText: PropTypes.bool,
	curvedCheckmark: PropTypes.bool,
	dropdownMaxHeight: PropTypes.string,
	highlightSelectedItemColor: PropTypes.string,
	liFontWeight: PropTypes.string,
	liFontSize: PropTypes.string,
	searchText: PropTypes.string,
	dataLoadingId: PropTypes.string,
	culture: PropTypes.string,
	buttonLabel: PropTypes.string,
	dropdownListMargin: PropTypes.string,
	emptySearchLabel: PropTypes.string,
	hoverItemBackgroundColor: PropTypes.string,
	checkmarkColor: PropTypes.string,
	dataTestItem: PropTypes.string,
	selectedArray: PropTypes.array,
	expandedIds: PropTypes.array,
	disabledIds: PropTypes.array,
	options: PropTypes.array,
	onGroupClick: PropTypes.func,
	onSelectGroupClick: PropTypes.func,
	onSelectItemClick: PropTypes.func,
	renderCustomItem: PropTypes.func,
	renderCustomItemParent: PropTypes.func,
	onSearchButtonClick: PropTypes.func,
	onCloseClick: PropTypes.func,
	isWithHighlightBar: PropTypes.bool,
	paddingVertical: PropTypes.number,
};

export default DropdownComponentContent;
