// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionTalkModalHeader from "./QuestionTalkModalHeader";
import QuestionDoYouWantToTalkScreen from "./QuestionDoYouWantToTalkScreen";
import QuestionCommentDescriptionMobile from "../QuestionCommentDescriptionMobile";
import QuestionSelectContactScreen from "./QuestionSelectContactScreen";
import QuestionSelectContextScreen from "./QuestionSelectContextScreen";
import QuestionDescribeDetailsScreen from "./QuestionDescribeDetailsScreen";

const SCREENS = {
	doYouWantToTalk: 0,
	selectContact: 1,
	selectContext: 2,
	describeDetails: 3,
};

const mapEscalations = (escalations) => {
	return escalations.map((es) => ({
		...es,
		selectedDetailOption: es.detailsOptions[0],
		selectedContextOption: es.contextOptions[0],
		escalateTalkComment: "",
		selectedContact: es.contacts[0],
	}));
};

class QuestionTalkExtended extends PureComponent {
	constructor(props) {
		super(props);

		this.config = this.getConfig();

		this.state = {
			isCommentDescriptionActive: false,
			activeScreen: SCREENS.doYouWantToTalk,
			progress: 0,
			escalations: mapEscalations(this.props.escalations),
			selectedEscalation: 0,
		};
	}

	nextEscalation = () => {
		this.setState({
			selectedEscalation: this.state.selectedEscalation + 1,
			activeScreen: SCREENS.doYouWantToTalk,
		});
	};

	onExperienceVisibleForImmediateManagerChange = () => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			visibleForImmediateManager: !tempArr[this.state.selectedEscalation].visibleForImmediateManager,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onAllowAiAssistedRepliesChange = () => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			allowAiAssistedReplies: !tempArr[this.state.selectedEscalation].allowAiAssistedReplies,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onExperienceCommentChange = (comment) => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			comment,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onExperienceDetailOptionChange = (item) => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			selectedDetailOption: item,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onExperienceContextOptionChange = (item) => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			selectedContextOption: item,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onEscalateTalkCommentChange = (comment) => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			escalateTalkComment: comment,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onEscalateTalkContactChange = (contact) => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			selectedContact: contact,
		};
		this.setState({
			escalations: tempArr,
		});
	};

	onQuestionTalkExtendedSend = () => {
		const escalationMap = new Map(this.state.escalations.map((e) => [e.questionId, e]));
		const answers = this.props.answers.map((a) => {
			if (escalationMap.has(a.questionId)) {
				const es = escalationMap.get(a.questionId);
				const mappedAnswer = {
					...a,
					comment: es.comment,
					allowAiAssistedReplies: es.allowAiAssistedReplies,
					visibleForImmediateManager: es.visibleForImmediateManager,
				};
				if (es.isWithTalkModel) {
					mappedAnswer.talkModel = {
						SupportUserId: es.selectedContact.Key,
						Comment: es.escalateTalkComment,
					};
				} else {
					mappedAnswer.additionalInformation = {
						additionalInformationStrategy: es.additionalInformationStrategy,
						context: es.selectedContextOption?.Id,
						details: es.selectedDetailOption?.Id,
					};
				}
				return mappedAnswer;
			} else {
				return a;
			}
		});

		this.props.onQuestionTalkExtendedSend(answers);
	};

	getConfig = () => {
		return {
			[SCREENS.doYouWantToTalk]: {
				render: this.renderDoYouWantToTalkScreen,
			},
			[SCREENS.selectContact]: {
				render: this.renderSelectContactScreen,
			},
			[SCREENS.selectContext]: {
				render: this.renderSelectContextScreen,
			},
			[SCREENS.describeDetails]: {
				render: this.renderDescribeDetailsScreen,
			},
		};
	};

	openDoYouWantToTalkScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(0);
		this.setState({
			activeScreen: SCREENS.doYouWantToTalk,
			progress: 0,
		});
	};

	openSelectContactScreen = () => {
		const tempArr = [...this.state.escalations];
		tempArr[this.state.selectedEscalation] = {
			...tempArr[this.state.selectedEscalation],
			isWithTalkModel: true,
		};
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(50);
		this.setState({
			activeScreen: SCREENS.selectContact,
			progress: 50,
			escalations: tempArr,
		});
	};

	openSelectContextScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(33);
		this.setState({
			activeScreen: SCREENS.selectContext,
			progress: 33,
		});
	};

	openDescribeDetailsScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(66);
		this.setState({
			activeScreen: SCREENS.describeDetails,
			progress: 66,
		});
	};

	commentDescriptionToggle = () => {
		this.setState({ isCommentDescriptionActive: !this.state.isCommentDescriptionActive });
	};

	renderDoYouWantToTalkScreen = () => {
		return (
			<QuestionDoYouWantToTalkScreen
				culture={this.props.culture}
				onNoClick={this.openSelectContextScreen}
				onYesClick={this.openSelectContactScreen}
				question={this.state.escalations[this.state.selectedEscalation]}
			/>
		);
	};

	renderSelectContactScreen = () => {
		return (
			<QuestionSelectContactScreen
				isExternalContact={
					this.state.escalations[this.state.selectedEscalation].selectedContact?.Key === "External"
				}
				culture={this.props.culture}
				contacts={this.state.escalations[this.state.selectedEscalation].contacts}
				text={Resources[this.props.culture].contactDesiredPerson}
				comment={this.state.escalations[this.state.selectedEscalation].escalateTalkComment}
				onCommentChange={this.onEscalateTalkCommentChange}
				selectedContact={
					this.state.escalations[this.state.selectedEscalation].selectedContact ||
					this.state.escalations[this.state.selectedEscalation].contacts[0]
				}
				onContactChange={this.onEscalateTalkContactChange}
				onSendClick={
					this.state.selectedEscalation + 1 === this.state.escalations.length
						? this.onQuestionTalkExtendedSend
						: this.nextEscalation
				}
				onUndoClick={this.openDoYouWantToTalkScreen}
			/>
		);
	};

	renderSelectContextScreen = () => {
		return (
			<QuestionSelectContextScreen
				culture={this.props.culture}
				questionSettings={this.props.questionSettings}
				onContextOptionChange={this.onExperienceContextOptionChange}
				onDetailOptionChange={this.onExperienceDetailOptionChange}
				detailsOptions={this.state.escalations[this.state.selectedEscalation].detailsOptions}
				contextOptions={this.state.escalations[this.state.selectedEscalation].contextOptions}
				selectedDetailOption={
					this.state.escalations[this.state.selectedEscalation].selectedDetailOption ||
					this.state.escalations[this.state.selectedEscalation].detailsOptions[0]
				}
				selectedContextOption={
					this.state.escalations[this.state.selectedEscalation].selectedContextOption ||
					this.state.escalations[this.state.selectedEscalation].contextOptions[0]
				}
				onNextClick={this.openDescribeDetailsScreen}
				onSendClick={this.onQuestionTalkExtendedSend}
				question={this.state.escalations[this.state.selectedEscalation]}
			/>
		);
	};

	renderDescribeDetailsScreen = () => {
		return (
			<QuestionDescribeDetailsScreen
				culture={this.props.culture}
				question={this.state.escalations[this.state.selectedEscalation]}
				questionSettings={this.props.questionSettings}
				onCommentChange={this.onExperienceCommentChange}
				allowAiAssistedReplies={
					this.state.escalations[this.state.selectedEscalation].allowAiAssistedReplies
				}
				onAllowAiAssistedRepliesChange={this.onAllowAiAssistedRepliesChange}
				isVisibleForImmediateManager={
					this.state.escalations[this.state.selectedEscalation].visibleForImmediateManager
				}
				onVisibleForImmediateManagerChange={this.onExperienceVisibleForImmediateManagerChange}
				comment={this.state.escalations[this.state.selectedEscalation].comment}
				onSendClick={
					this.state.selectedEscalation + 1 === this.state.escalations.length
						? this.onQuestionTalkExtendedSend
						: this.nextEscalation
				}
				commentDescriptionToggle={this.commentDescriptionToggle}
				onBackClick={this.openSelectContextScreen}
			/>
		);
	};

	render() {
		if (!this.config[this.state.activeScreen]) {
			return null;
		}

		return (
			<ContentWrapper>
				{this.state.isCommentDescriptionActive && (
					<QuestionCommentDescriptionMobile
						culture={this.props.culture}
						onClose={this.commentDescriptionToggle}
					/>
				)}
				{this.props.withHeader && (
					<QuestionTalkModalHeader
						titleText={Resources[this.props.culture].pleaseElaborateOnYourResponse}
						progressWidth={this.state.progress}
					/>
				)}
				{this.config[this.state.activeScreen].render()}
			</ContentWrapper>
		);
	}
}

QuestionTalkExtended.propTypes = {
	withHeader: PropTypes.bool,
	culture: PropTypes.string,
	escalations: PropTypes.array,
	questionSettings: PropTypes.object,
	getOutterProgressBarWidth: PropTypes.func,
	onQuestionTalkExtendedSend: PropTypes.func,
};

export default QuestionTalkExtended;
