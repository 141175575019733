import React, { useState } from "react";
import Resources from "Resources";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors, transition } from "ComponentsLibrary/shared/theme";
import Text from "ComponentsLibrary/Text";
import IconButton from "ComponentsLibrary/Button/IconButton";
import TextArea from "ComponentsLibrary/TextFields/TextArea";
import Checkbox from "ComponentsLibrary/SelectionControls/Checkbox";
import Tooltip from "ComponentsLibrary/Tooltip/Tooltip";
import NewPill from "ComponentsLibrary/Pill/NewPill";

const Wrapper = styled.div`
	display: flex;
	align-content: center;
	flex-direction: column;
	align-items: center;
	padding: 14px 24px;
	border-radius: 8px;
	margin-bottom: 12px;
	background-color: ${colors.onyx};
	min-height: ${({ open }) => (open ? "220px" : "auto")};
	transition: ${transition.all};
	&:hover {
		cursor: ${({ open }) => (open ? "default" : "pointer")};
		background-color: ${({ open }) => (open ? colors.onyx : "rgba(0, 0, 0, 0.5)")};
	}
`;

const WrapperCeckBoxText = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 16px;
	& > label {
		position: relative;
		top: 2px;
	}
`;

const WrapperOpenContent = styled.div`
	display: flex;
	align-content: center;
	flex-direction: column;
	width: 100%;
	opacity: ${({ open }) => (open ? "1" : "0")};
	transition: ${transition.all};
`;

const WrapperHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
	width: 100%;
`;

const TextWrapper = styled.div`
	margin-left: 8px;
`;

const CustomSubject = ({
	culture,
	isAllowAiAssistedRepliesVisible,
	isHideCommentFromManagerVisible,
	generalQuestion,
	onGeneralQuestionChange,
}) => {
	const [open, setOpen] = useState(false);

	const handleOnclick = () => {
		if (!open) setOpen(!open);
	};

	return (
		<Wrapper {...{ open, onClick: handleOnclick }}>
			<WrapperHeader>
				<Text
					{...{
						color: colors.white,
						fontType: "normalMedium",
						mb: open ? 3 : 0,
						cursor: open ? "default" : "pointer",
					}}>
					{Resources[culture].anotherSubjectToCommentOn}
				</Text>
				<IconButton
					{...{
						useTransparentBackground: false,
						appearance: "inverse",
						onClick: () => setOpen(!open),
						position: "absolute",
						right: "-15px",
						top: "-2px",
						icon: "chevron-down-16",
						iconRotate: open ? "180deg" : "0",
					}}
				/>
			</WrapperHeader>
			{open && (
				<WrapperOpenContent {...{ open }}>
					<TextArea
						{...{
							height: "120",
							resize: false,
							placeholder: Resources[culture].leaveAVoluntaryComment,
							value: generalQuestion.comment,
							handleEvent: (e) =>
								onGeneralQuestionChange({
									...generalQuestion,
									comment: e.target.value,
								}),
						}}
					/>
					{isHideCommentFromManagerVisible && (
						<WrapperCeckBoxText>
							<Checkbox
								{...{
									checked: generalQuestion.visibleForImmediateManager,
									toggle: () =>
										onGeneralQuestionChange({
											...generalQuestion,
											visibleForImmediateManager:
												!generalQuestion.visibleForImmediateManager,
										}),
								}}
							/>
							<TextWrapper>
								<Text {...{ color: colors.metal, fontType: "normalMedium" }}>
									{Resources[culture].okThatYourImmediateManagerSeesTheComment}
								</Text>
								<Tooltip
									{...{
										followCursor: true,
										text: Resources[culture]
											.questionCommentWhatDoesItMeanDescriptionAndHR,
										maxWidth: "500px",
									}}>
									<Text
										{...{
											ml: 1,
											fontType: "normalMedium",
											color: colors.metal,
											textDecoration: "underline",
											cursor: "pointer",
										}}>
										{Resources[culture].whatDoesThisMean}
									</Text>
								</Tooltip>
							</TextWrapper>
						</WrapperCeckBoxText>
					)}
					{isAllowAiAssistedRepliesVisible && (
						<WrapperCeckBoxText>
							<Checkbox
								{...{
									checked: generalQuestion.allowAiAssistedReplies,
									toggle: () =>
										onGeneralQuestionChange({
											...generalQuestion,
											allowAiAssistedReplies: !generalQuestion.allowAiAssistedReplies,
										}),
								}}
							/>
							<TextWrapper>
								<Text {...{ color: colors.metal, fontType: "normalMedium" }}>
									{Resources[culture].okToUseAIAssistedReplies}
								</Text>
								<Tooltip
									{...{
										followCursor: true,
										text: Resources[culture].smartAssistantUserDescription,
										maxWidth: "500px",
									}}>
									<Text
										{...{
											ml: 1,
											fontType: "normalMedium",
											color: colors.metal,
											textDecoration: "underline",
											cursor: "pointer",
										}}>
										{Resources[culture].whatDoesThisMean}
									</Text>
								</Tooltip>
								<NewPill ml={1} text="Beta" />
							</TextWrapper>
						</WrapperCeckBoxText>
					)}
				</WrapperOpenContent>
			)}
		</Wrapper>
	);
};

CustomSubject.propTypes = {
	isHideCommentFromManagerVisible: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	culture: PropTypes.string,
	generalQuestion: PropTypes.object,
	onGeneralQuestionChange: PropTypes.func,
};

export default CustomSubject;
