export default (object, path) => {
    if (!path || !path.length) {
        return null;
    }

    return path.split(".")
        .reduce(
            (obj, key) => obj && obj[key] ? obj[key] : null,
            object
        );
}