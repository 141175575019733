import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ModalHeader from "../../Question/ModalHeader.jsx";

const MobileHeaderWrapper = styled.div`
	display: none;
	margin-bottom: ${(props) => (props.isWithMargin ? "10px" : "0")};
	@media (max-width: 767px) {
		display: block;
		min-height: 49px;
		flex-grow: 0;
		flex-shrink: 0;
	}
`;

const DropdownComponentMobileHeader = (props) => {
	return (
		<MobileHeaderWrapper isWithMargin={!props.tabs && !props.isWithSearch}>
			<ModalHeader
				fontSize="15px"
				fontSizeXS="15px"
				padding="10px 15px"
				paddingXS="10px 15px"
				title={props.selectorLabel ? props.selectorLabel : ""}
				backgroundColor="#59b0c7"
				onCloseClick={() => props.onCloseClick && props.onCloseClick()}
			/>
		</MobileHeaderWrapper>
	);
};

DropdownComponentMobileHeader.propTypes = {
	isWithSearch: PropTypes.bool,
	selectoorLabel: PropTypes.string,
	tabs: PropTypes.array,
	onCloseClick: PropTypes.func,
};

export default DropdownComponentMobileHeader;
