import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DropdownIcon = styled.span`
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
	${props => props.margin ? `margin: ${props.margin};` : null}
`;

DropdownIcon.propTypes = {
	margin: PropTypes.string
}

export default DropdownIcon;