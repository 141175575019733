/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SvgIcon from "Controls/SvgIcon";
import { spacing } from "../shared/spacing";
import { colors, transition, border, typography, shadow } from "../shared/theme";
import Text from "../Text";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
	display: flex;
	width: 16px;
	height: 16px;
	border-radius: 2px;
	transition: ${transition.main};
	box-sizing: border-box;
	border: ${({ checked }) => (checked ? `2px solid ${colors.jungle}` : `2px solid ${colors.metal}`)};
	background: ${({ checked }) => (checked ? colors.jungle : colors.white)};
`;

const CheckboxContainer = styled.div`
	display: flex;
`;

const Wrapper = styled.label`
	${spacing}
	display: flex;
	flex-direction: ${({ labelType }) => (labelType === "fullWidth" ? "row-reverse" : "row")};
	justify-content: ${({ labelType }) => (labelType === "fullWidth" ? "row-reverse" : "row")};
	align-items: center;
	width: fit-content;
	cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};

	&:hover ${StyledCheckbox} {
		box-shadow: ${({ checked, disabled }) =>
			!disabled
				? checked
					? shadow.selectionControlFocusOutline
					: shadow.selectionControlHoverOutline
				: ""};
	}
`;

const Checkbox = (props) => {
	return (
		<Wrapper data-test={props.dataTest} checked={props.checked} {...props}>
			<CheckboxContainer>
				<HiddenCheckbox
					readOnly
					checked={props.checked}
					disabled={props.disabled}
					onClick={props.toggle && props.toggle}
				/>
				<StyledCheckbox checked={props.checked} disabled={props.disabled}>
					{props.checked && (
						<SvgIcon
							icon={props.indeterminate ? "minus-12" : "check-12"}
							size="12px"
							fill={colors.white}
						/>
					)}
				</StyledCheckbox>
			</CheckboxContainer>
			{props.label && (
				<Text ml={props.labelType === "default" ? 1 : 0} mr={props.labelType === "fullWidth" ? 1 : 0}>
					{props.label}
				</Text>
			)}
		</Wrapper>
	);
};

Checkbox.propTypes = {
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
	indeterminate: PropTypes.bool,
	label: PropTypes.string,
	dataTest: PropTypes.string,
	labelType: PropTypes.oneOf(["default", "fullWidth"]),
	toggle: PropTypes.func,
};

Checkbox.defaultProps = {
	labelType: "default",
};

export default Checkbox;
