import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logoutUser } from '../actions/authentication';
import { navigateToBatches, navigateToLogin } from '../actions/router';
import { getAuth } from '../actions/authentication.localStorage';
import { getCulture } from '../actions/culture';
import { withBackgroundHeightAdjust } from '../components/WithBackgroundHeightAdjust';
import Widget from '../components/Widget';

const mapStateToProps = (state, { match }) => {   
    var authentication = getAuth();

    return {
        culture: getCulture(state),
        username: authentication ? authentication.name : null
    };
};

const mapDispatchToProps = (dispatch, {history}) => {
    return {
        onLogout: function() {
            dispatch(logoutUser()).then(navigateToLogin(history));
        },
        onBack: function() {
            navigateToBatches(history);
        }
    };
};

const WidgetContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withBackgroundHeightAdjust(Widget)));

export default WidgetContainer;