// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors"
import ButtonComponent from "Controls/ButtonComponent";
import ContentWrapper from "Controls/ContentWrapper";

const FooterWrapper = styled(ContentWrapper)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${Colors.questionWindowBackground};
	padding: 15px 10px 15px;
`;

class QuestionTalkModalFooterWrapper extends PureComponent {
	render() {
		return(
			<FooterWrapper>
				{
					this.props.onBackClick &&
					<ButtonComponent
						gray
						display="flex"
						alignItems="center"
						height="40px"
						padding="0 15px"
						margin="0 20px 0 0"
						flexGrow="1"
						data-pendo-id={this.props.backButtonAttribute}
						data-track-id={this.props.backButtonAttribute}
						dataTest={this.props.backButtonAttribute}
						onClick={this.props.onBackClick}>
							{this.props.backButtonText}
					</ButtonComponent>
				}
				{
					this.props.onNextClick &&
					<ButtonComponent
						purple
						display="flex"
						alignItems="center"
						height="40px"
						padding="0 15px"
						flexGrow="1"
						data-pendo-id={this.props.nextButtonAttribute}
						data-track-id={this.props.nextButtonAttribute}
						dataTest={this.props.nextButtonAttribute}
						onClick={this.props.onNextClick}>
							{this.props.nextButtonText}
					</ButtonComponent>
				}
			</FooterWrapper>
		);
	}
}

QuestionTalkModalFooterWrapper.propTypes = {
	nextButtonText: PropTypes.string,
	backButtonText: PropTypes.string,
	nextButtonAttribute: PropTypes.string,
	backButtonAttribute: PropTypes.string,
	onBackClick: PropTypes.func,
	onNextClick: PropTypes.func,
}

export default QuestionTalkModalFooterWrapper;