// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Image = styled.img`
	display: ${props => props.display || "inline"};
	align-items: ${props => props.alignItems || "unset"};
	justify-content: ${props => props.justifyContent || "unset"};
	background-color: ${props => props.backgroundColor || "transparent"};
	margin: ${props => props.margin || "0"};
	padding: ${props => props.padding || "0"};
	height: ${props => props.height || "auto"};
	width: ${props => props.width || "auto"};
	border: ${props => props.border || "none"}; 
	${props => props.position ? `position: ${props.position};` : null}
	${props => props.top ? `top: ${props.top};` : null}
	${props => props.bottom ? `bottom: ${props.bottom};` : null}
	${props => props.left ? `left: ${props.left};` : null}
	${props => props.right ? `right: ${props.right};` : null}
	${props => props.boxShadow ? `box-shadow: ${props.boxShadow};` : null}
	${props => props.borderRadius ? `border-radius: ${props.borderRadius};` : null}
	${props => props.minWidth ? `min-width: ${props.minWidth};` : null}
	${props => props.maxWidth ? `max-width: ${props.maxWidth};` : null}
	${props => props.maxHeight ? `max-height: ${props.maxHeight};` : null}
`;

Image.propTypes = {
	display: PropTypes.string,
	alignItems: PropTypes.string,
	justifyContent: PropTypes.string,
	backgroundColor: PropTypes.string,
	margin: PropTypes.string,
	padding: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	minWidth: PropTypes.string,
	border: PropTypes.string,
	position: PropTypes.string,
	top: PropTypes.string,
	bottom: PropTypes.string,
	left: PropTypes.string,
	right: PropTypes.string,
	boxShadow: PropTypes.string,
	borderRadius: PropTypes.string,
	maxWidth: PropTypes.string,
	maxHeight: PropTypes.string,
}

export default Image;