// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Text = styled.span`
	display: ${props => props.display || "inline"};
	align-items: ${props => props.alignItems || "unset"};
	justify-content: ${props => props.justifyContent || "unset"};
	color: ${props => props.color || "#ffffff"};
	background-color: ${props => props.backgroundColor || "transparent"};
	font-size: ${props => props.fontSize || "12px"};
	font-weight: ${props => props.fontWeight || "normal"};
	line-height: ${props => props.lineHeight || "1"};
	text-decoration: ${props => props.textDecoration || "none"};
	margin: ${props => props.margin || "0"};
	padding: ${props => props.padding || "0"};
	height: ${props => props.height || "auto"};
	width: ${props => props.width || "auto"};
	text-overflow: ${props => props.textOverflow || "unset"};
	border: ${props => props.border || "none"}; 
	white-space: ${props => props.whiteSpace || "normal"}; 
	overflow: ${props => props.overflow || "visible"}; 
	text-align: ${props => props.textAlign || "left"};
	${props => props.verticalAlign ? `vertical-align: ${props.verticalAlign}` : null};
	${props => props.borderRadius ? `border-radius: ${props.borderRadius}` : null};
	${props => props.letterSpacing ? `letter-spacing: ${props.letterSpacing}` : null};
	${props => props.textTransform ? `text-transform: ${props.textTransform}` : null};
	${props => props.alignSelf ? `align-self: ${props.alignSelf}` : null};
	${props => props.userSelect ? `user-select: ${props.userSelect}` : null };
	${props => props.cursor ? `cursor: ${props.cursor}` : null };
	${props => props.marginTop ? `margin-top: ${props.marginTop}` : null };
	${props => props.marginRight ? `margin-right: ${props.marginRight}` : null };
	${props => props.marginBottom ? `margin-bottom: ${props.marginBottom}` : null };
	${props => props.marginLeft ? `margin-left: ${props.marginLeft}` : null };
	${props => props.maxHeight ? `max-height: ${props.maxHeight}` : null };
	${props => props.boxShadow ? `box-shadow: ${props.boxShadow}` : null };
	${props => props.fontStyle ? `font-style: ${props.fontStyle}` : null };
	${props => props.opacity ? `opacity: ${props.opacity}` : null };
	${props => props.maxWidth ? `max-width: ${props.maxWidth}` : null };
	${props => props.transition ? `transition: ${props.transition}` : null };
	${props => props.hyphens ? `hyphens: ${props.hyphens};` : null };
	${props => props.wordWrap ? `word-wrap: ${props.wordWrap};` : null };
	${props => props.fontFamily ? `font-family: ${props.fontFamily};` : null };
	${props => props.flexGrow ? `flex-grow: ${props.flexGrow};` : null };
	@media(max-width: 768px) {
		${props => props.displayXS ? `display: ${props.displayXS}` : null };
		${props => props.colorXS ? `color: ${props.colorXS}` : null };
		${props => props.fontSizeXS ? `font-size: ${props.fontSizeXS}` : null };
		${props => props.maxHeightXS ? `max-height: ${props.maxHeightXS}` : null };
		${props => props.boxShadowXS ? `box-shadow: ${props.boxShadowXS}` : null };
		${props => props.marginXS ? `margin: ${props.marginXS}` : null };
        ${props => props.textAlignXS ? `text-align: ${props.textAlignXS}` : null };
	}
	&:hover {
		${props => props.hoverOverflow ? `overflow: ${props.hoverOverflow};` : null}
		${props => props.hoverWhiteSpace ? `white-space: ${props.hoverWhiteSpace};` : null}
		${props => props.hoverColor ? `color: ${props.hoverColor};` : null}
		${props => props.hoverBorder ? `border: ${props.hoverBorder};` : null}
		${props => props.hoverBackgroundColor ? `background-color: ${props.hoverBackgroundColor};` : null}
		${props => props.hoverWidth ? `width: ${props.hoverWidth};` : null}
		${props => props.hoverTextDecoration ? `text-decoration: ${props.hoverTextDecoration};` : null}
	}
`;

Text.propTypes = {
	display: PropTypes.string,
	alignItems: PropTypes.string,
	justifyContent: PropTypes.string,
	color: PropTypes.string,
	colorXS: PropTypes.string,
	backgroundColor: PropTypes.string,
	fontSize: PropTypes.string,
	fontSizeXS: PropTypes.string,
	fontWeight: PropTypes.string,
	lineHeight: PropTypes.string,
	textDecoration: PropTypes.string,
	margin: PropTypes.string,
	padding: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	textOverflow: PropTypes.string,
	border: PropTypes.string,
	overflow: PropTypes.string,
	textAlign: PropTypes.string,
	textAlignXS: PropTypes.string,
	whiteSpace: PropTypes.string,
	displayXS: PropTypes.string,
	textTransform: PropTypes.string,
	letterSpacing: PropTypes.string,
	userSelect: PropTypes.string,
	cursor: PropTypes.string,
	alignSelf: PropTypes.string,
	marginTop: PropTypes.string,
	marginRight: PropTypes.string,
	marginBottom: PropTypes.string,
	marginLeft: PropTypes.string,
	maxHeight: PropTypes.string,
	maxHeightXS: PropTypes.string,
	boxShadow: PropTypes.string,
	boxShadowXS: PropTypes.string,
	hoverOverflow: PropTypes.string,
	hoverWhiteSpace: PropTypes.string,
	hoverColor: PropTypes.string,
	hoverBorder: PropTypes.string,
	hoverBackgroundColor: PropTypes.string,
	hoverWidth: PropTypes.string,
	fontStyle: PropTypes.string,
	opacity: PropTypes.string,
	maxWidth: PropTypes.string,
	transition: PropTypes.string,
	hoverTextDecoration: PropTypes.string,
	marginXS: PropTypes.string,
	hyphens: PropTypes.string,
	wordWrap: PropTypes.string,
	fontFamily: PropTypes.string,
	flexGrow: PropTypes.string,
}

export default Text;