import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Resources from "Resources";
import Button from "Controls/Button";
import ContentWrapper from "Controls/ContentWrapper";
import { spacingIndex } from "ComponentsLibrary/shared/spacing";
import Text from "ComponentsLibrary/Text";
import { colors, typography, shadow } from "ComponentsLibrary/shared/theme";
import SvgIcon from "Controls/SvgIcon";
import DropdownListItem from "./DropdownListItem";

const Wrapper = styled.div`
	width: 100%;
	flex: 1;
	position: relative;
	max-height: ${(props) => (props.maxHeight ? props.maxHeight : "300px")};
	overflow: ${(props) => (props.isFilterLoading ? "hidden" : props.withoutOverflow ? "unset" : "auto")};
	${(props) => props.isFilterLoading && `min-height: ${props.minHeight};`}
	${(props) => props.isWithSearch && `padding-top: 8px;`}
	@media(max-width: 767px) {
		max-height: ${(props) => (props.isFullHeightXS ? "100vh" : props.maxHeightXS)};
		flex-basis: auto;
	}
`;

const List = styled.ul`
	list-style-type: none;
	padding-left: 0px;
	user-select: none;
	margin: ${(props) => (props.margin ? `${props.margin};` : `0`)};
`;

const ListTitleWrapper = styled.div`
	width: 100%;
	display: flex;
	padding-right: ${spacingIndex}px;
	padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft + "px" : "10px")};
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "inherit")};
	&:hover {
		background-color: ${(props) => props.hoverBackgroundColor || colors.lightTurquoise};
	}
	&:hover .expand-chevron {
		fill: ${colors.onyx};
	}
	@media (max-width: 767px) {
		display: none;
	}
`;

const ListTitleWrapperMobile = styled.div`
	width: 100%;
	display: none;
	padding-right: ${spacingIndex}px;
	padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft + "px" : "10px")};
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "inherit")};
	&:hover {
		background-color: ${colors.lightTurquoise};
	}
	&:hover .expand-chevron {
		fill: ${colors.onyx};
	}
	@media (max-width: 767px) {
		display: flex;
	}
`;

const ListTitle = styled(Button)`
	display: flex;
	flex: 1;
	justify-content: space-between;
	background-color: transparent;
	min-height: ${spacingIndex * 4}px;
	font-size: ${(props) => props.itemFontSize || "14px"};
	@media (max-width: 767px) {
		justify-content: flex-start;
	}
`;

const ListItem = styled.li`
	cursor: pointer;
	line-height: ${spacingIndex * 4}px;
	display: block;
	${(props) =>
		props.isSelected
			? `
		background-color: ${colors.white};
	`
			: null}
	${(props) =>
		props.highlightSelectedItemColor && props.isSelected
			? `
		box-shadow: ${shadow.fly};
		border-left: 4px solid ${props.highlightSelectedItemColor};
	`
			: null}
`;

const ListItemWrapper = styled.div`
	display: flex;
	cursor: pointer;
	line-height: ${spacingIndex * 4}px;
	justify-content: space-between;
	padding-left: ${(props) =>
		props.paddingLeft ? (props.isWithBorder ? props.paddingLeft - 4 : props.paddingLeft) + "px" : "10px"};
	background-color: ${(props) =>
		props.isItemDisabled ? colors.white : props.backgroundColor || "inherit"};
	&:hover {
		background-color: ${(props) =>
			props.isItemDisabled ? colors.white : props.hoverBackgroundColor || colors.lightTurquoise};
		cursor: ${(props) => (props.isItemDisabled ? "default" : "pointer")};
	}
	@media (max-width: 767px) {
		display: none;
	}
`;

const ListItemWrapperMobile = styled.div`
	display: none;
	cursor: pointer;
	line-height: ${spacingIndex * 4}px;
	padding-left: ${(props) =>
		props.paddingLeft ? (props.isWithBorder ? props.paddingLeft - 4 : props.paddingLeft) + "px" : "10px"};
	background-color: ${(props) =>
		props.isItemDisabled ? colors.white : props.backgroundColor || "inherit"};
	&:hover {
		background-color: ${(props) => (props.isItemDisabled ? colors.white : colors.lightTurquoise)};
		cursor: ${(props) => (props.isItemDisabled ? "default" : "pointer")};
	}
	@media (max-width: 767px) {
		display: flex;
	}
`;

const ListItemLabel = styled.span`
	user-select: none;
	${typography.normalRegular};
	color: ${(props) => (props.isItemDisabled ? colors.metal : colors.metal)};
`;

const PADDING_INDEX = spacingIndex * 2;

class DropdownListComponent extends PureComponent {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
	}

	onGroupClick(e, group) {
		e.preventDefault();
		this.props.onGroupClick(group);
	}

	checkIsParentItem = (item) => {
		return item.ChildrenCount > 0 || (item.Children && item.Children.length);
	};

	checkIsWithoutOverflow = (options, overflowLimit) => {
		let numberOfItems = 0;

		const countItems = (items) => {
			if (!items || !items.length) {
				return null;
			}
			numberOfItems += items.length;
			return numberOfItems < overflowLimit && items.forEach((item) => countItems(item.Children));
		};
		countItems(options);

		return numberOfItems < overflowLimit;
	};

	renderItem = (
		item,
		isSelected,
		isParentSelected,
		recursionLevel,
		itemPaddingIndex,
		itemPaddingIndexMobile,
		isItemDisabled,
		itemIndex,
		itemsLength,
		dataTest
	) => {
		const paddingLeft = recursionLevel * PADDING_INDEX + itemPaddingIndex;
		const paddingLeftMobile = recursionLevel * PADDING_INDEX + itemPaddingIndexMobile;

		if (this.props.renderCustomItem) {
			return this.props.renderCustomItem(
				item,
				isSelected,
				isParentSelected,
				paddingLeft,
				paddingLeftMobile,
				itemIndex,
				itemsLength
			);
		}

		const backgroundColor =
			!this.props.isMultiSelect &&
			!this.props.highlightSelectedItemColor &&
			isSelected &&
			colors.lightTurquoise;

		return (
			<div>
				<ListItemWrapper
					isItemDisabled={isItemDisabled}
					isWithBorder={isSelected && this.props.highlightSelectedItemColor}
					hoverBackgroundColor={this.props.hoverBackgroundColor}
					backgroundColor={backgroundColor}
					paddingLeft={this.props.highlightSelectedItemColor ? 45 : paddingLeft}>
					<ListItemLabel
						isItemDisabled={isItemDisabled}
						notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
						liFontWeight={this.props.liFontWeight}
						itemFontSize={this.props.itemFontSize}
						highlightSelectedItemColor={this.props.highlightSelectedItemColor}
						selected={isSelected}>
						{item.Name}
					</ListItemLabel>
					{this.props.isMultiSelect && (
						<SvgIcon
							fill={isSelected ? colors.jungle : "transparent"}
							size="16px"
							icon="check-16"
						/>
					)}
				</ListItemWrapper>
				<ListItemWrapperMobile
					isItemDisabled={isItemDisabled}
					isWithBorder={isSelected && this.props.highlightSelectedItemColor}
					backgroundColor={backgroundColor}
					paddingLeft={this.props.highlightSelectedItemColor ? 45 : paddingLeftMobile}>
					{this.props.isMultiSelect && (
						<SvgIcon
							fill={isSelected ? colors.jungle : "transparent"}
							size="16px"
							icon="check-16"
						/>
					)}
					<ListItemLabel
						isItemDisabled={isItemDisabled}
						notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
						liFontWeight={this.props.liFontWeight}
						highlightSelectedItemColor={this.props.highlightSelectedItemColor}
						selected={isSelected}>
						{item.Name}
					</ListItemLabel>
				</ListItemWrapperMobile>
			</div>
		);
	};

	renderParentItem = (
		item,
		isSelected,
		isParentSelected,
		isExpanded,
		recursionLevel,
		itemPaddingIndex,
		itemPaddingIndexMobile,
		dataTest
	) => {
		const paddingLeft = recursionLevel * PADDING_INDEX;
		const paddingLeftMobile = recursionLevel * PADDING_INDEX + itemPaddingIndexMobile;
		const onSelect = (e) => !item.IsLabel && this.props.onSelectGroupClick(item, isParentSelected, e);

		if (this.props.renderCustomItemParent) {
			return this.props.renderCustomItemParent(
				item,
				isSelected,
				isParentSelected,
				isExpanded,
				paddingLeft,
				paddingLeftMobile,
				this.props.dataLoadingId,
				onSelect
			);
		}

		return (
			<React.Fragment>
				<ListTitleWrapper
					backgroundColor={!this.props.isMultiSelect && isSelected && colors.lightTurquoise}
					hoverBackgroundColor={this.props.hoverBackgroundColor}
					paddingLeft={paddingLeft}>
					<Button
						type="button"
						backgroundColor="transparent"
						marginRight={`${spacingIndex}px`}
						borderRadius="0"
						onClick={(e) => this.onGroupClick(e, item)}>
						<SvgIcon
							className="expand-chevron"
							fill={isSelected ? colors.onyx : colors.metal}
							size="16px"
							icon="chevron-down-16"
							rotate={isExpanded ? "0" : "270deg"}
						/>
					</Button>
					<ListTitle itemFontSize={this.props.itemFontSize} onClick={onSelect}>
						<Text fontType="normalRegular" color={colors.onyx}>
							{item.Name}
						</Text>
						{this.props.isMultiSelect && !item.IsLabel && (
							<SvgIcon
								fill={isSelected ? colors.jungle : "transparent"}
								size="16px"
								icon="check-16"
							/>
						)}
					</ListTitle>
				</ListTitleWrapper>
				<ListTitleWrapperMobile
					backgroundColor={!this.props.isMultiSelect && isSelected && colors.lightTurquoise}
					paddingLeft={paddingLeftMobile}>
					<ListTitle itemFontSize={this.props.itemFontSize} onClick={onSelect}>
						{this.props.isMultiSelect && !item.IsLabel && (
							<SvgIcon
								className="expand-chevron"
								fill={isSelected ? colors.jungle : "transparent"}
								size="16px"
								icon="check-16"
								margin={`0 ${spacingIndex}px 0 0`}
							/>
						)}
						<Text fontType="normalRegular" color={colors.onyx}>
							{item.Name}
						</Text>
					</ListTitle>
					<Button
						type="button"
						backgroundColor="transparent"
						margin={`0 0 0 ${spacingIndex}px`}
						width={`${spacingIndex * 4}px`}
						borderRadius="0"
						onClick={(e) => this.onGroupClick(e, item)}>
						<SvgIcon
							fill={isSelected ? colors.onyx : colors.metal}
							size="16px"
							icon="chevron-down-16"
							rotate={isExpanded ? "180deg" : "0"}
						/>
					</Button>
				</ListTitleWrapperMobile>
			</React.Fragment>
		);
	};

	renderList = (items, isParentSelected, recursionLevel) => {
		if (!items || !items.length) {
			return null;
		}

		if (!recursionLevel) {
			recursionLevel = 1;
		}

		const isWithChildren = !!items.find((item) => this.checkIsParentItem(item));

		const itemPaddingIndex = recursionLevel === 1 && !isWithChildren ? 0 : spacingIndex;
		const itemPaddingIndexMobile = this.props.isMultiSelect ? (recursionLevel - 1) * 10 : 0;

		return (
			<List margin={recursionLevel === 1 && this.props.listMargin}>
				{items.map((item, index) => {
					const isSelected =
						(isParentSelected && !this.props.isSelectedWithoutChildren) ||
						!!this.props.selected.find((selectedItem) => selectedItem.Id === item.Id);

					const isParentItem = this.checkIsParentItem(item);

					if (isParentItem) {
						const isExpanded =
							this.props.expandedIds.indexOf(item.Id) !== -1 ||
							(this.props.filterText.length && !this.props.isPreventExpandedForFilter);

						return (
							<React.Fragment key={item.Id ? item.Id : index}>
								<ListItem data-test={"parent-list-item-" + this.props.dataTestItem}>
									{this.renderParentItem(
										item,
										isSelected,
										isParentSelected,
										isExpanded,
										recursionLevel,
										itemPaddingIndex,
										itemPaddingIndexMobile
									)}
									{!!isExpanded &&
										this.renderList(item.Children, isSelected, recursionLevel + 1)}
								</ListItem>
							</React.Fragment>
						);
					}

					const isItemDisabled =
						this.props.disabledIds && this.props.disabledIds.indexOf(item.Id) !== -1;
					return (
						<ListItem
							key={item.Id ? item.Id : index}
							isSelected={isSelected}
							highlightSelectedItemColor={this.props.highlightSelectedItemColor}
							onClick={() =>
								!item.IsLabel &&
								!isItemDisabled &&
								this.props.onSelectItemClick(item, isParentSelected)
							}>
							<DropdownListItem
								dataTestItem={this.props.dataTestItem}
								isMultiSelect={this.props.isMultiSelect}
								curvedCheckmark={this.props.curvedCheckmark}
								checkmarkHiddenForNotCheckedItems={
									this.props.checkmarkHiddenForNotCheckedItems
								}
								isItemDisabled={isItemDisabled}
								isSelected={isSelected}
								isParentSelected={isParentSelected}
								recursionLevel={recursionLevel}
								itemPaddingIndex={itemPaddingIndex}
								itemPaddingIndexMobile={itemPaddingIndexMobile}
								itemIndex={index}
								itemsLength={items.length}
								paddingIndex={PADDING_INDEX}
								highlightSelectedItemColor={this.props.highlightSelectedItemColor}
								hoverBackgroundColor={this.props.hoverBackgroundColor}
								notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
								liFontWeight={this.props.liFontWeight}
								itemFontSize={this.props.itemFontSize}
								checkmarkColor={this.props.checkmarkColor}
								item={item}
								onSelectItemClick={this.props.onSelectItemClick}
								renderCustomItem={this.props.renderCustomItem}
								isWithHighlightBar={this.props.isWithHighlightBar}
								paddingVertical={this.props.paddingVertical}
							/>
						</ListItem>
					);
				})}
			</List>
		);
	};

	/*

								<ListItem
									key={item.Id}
									isSelected={isSelected}
									highlightSelectedItemColor={this.props.highlightSelectedItemColor}
									onClick={() => !item.IsLabel && !isItemDisabled &&
										this.props.onSelectItemClick(item, isParentSelected)}>
									{this.renderItem(item,
										isSelected,
										isParentSelected,
										recursionLevel,
										itemPaddingIndex,
										itemPaddingIndexMobile,
										isItemDisabled,
										index,
										items.length)}
									</ListItem>
	 */

	render() {
		const isWithOptions = this.props.options && this.props.options.length > 0;
		const withoutOverflow = this.checkIsWithoutOverflow(this.props.options, 4);

		return (
			<Wrapper
				isWithSearch={this.props.isWithSearch}
				maxHeight={this.props.dropdownMaxHeight}
				maxHeightXS={this.props.maxHeightXS}
				isFullHeightXS={this.props.isFullHeightXS}
				isFilterLoading={this.props.isFilterLoading}
				withoutOverflow={withoutOverflow}
				minHeight={isWithOptions ? "40px" : "30px"}
				ref={this.wrapper}>
				{this.props.descriptionBeforeItems && (
					<Text
						isEllipsis
						display="block"
						mb={0.25}
						ml={2}
						fontStyle="smallRegular"
						color={colors.nickel}>
						{this.props.descriptionBeforeItems}
					</Text>
				)}
				{!isWithOptions && (
					<Text
						p={this.props.isWithAdittionalFlags ? [1, 2] : [0.5, 2, 1]}
						fontStyle="smallRegular"
						color={colors.onyx}>
						{this.props.emptySearchLabel || Resources[this.props.culture].noItemsAvailable}
					</Text>
				)}
				{isWithOptions && this.renderList(this.props.options)}
			</Wrapper>
		);
	}
}

DropdownListComponent.propTypes = {
	isWithSearch: PropTypes.bool,
	isFullHeightXS: PropTypes.bool,
	isMultiSelect: PropTypes.bool,
	isFilterLoading: PropTypes.bool,
	isPreventExpandedForFilter: PropTypes.bool,
	isWithAdittionalFlags: PropTypes.bool,
	checkmarkHiddenForNotCheckedItems: PropTypes.bool,
	notHighlightSelectedItemsText: PropTypes.bool,
	curvedCheckmark: PropTypes.bool,
	filterText: PropTypes.string,
	dropdownMaxHeight: PropTypes.string,
	maxHeightXS: PropTypes.string,
	culture: PropTypes.string,
	dataLoadingId: PropTypes.string,
	highlightSelectedItemColor: PropTypes.string,
	liFontWeight: PropTypes.string,
	itemFontSize: PropTypes.string,
	emptySearchLabel: PropTypes.string,
	listMargin: PropTypes.string,
	hoverBackgroundColor: PropTypes.string,
	checkmarkColor: PropTypes.string,
	dataTestItem: PropTypes.string,
	options: PropTypes.array,
	selected: PropTypes.array,
	expandedIds: PropTypes.array,
	disabledIds: PropTypes.array,
	onGroupClick: PropTypes.func,
	onSelectGroupClick: PropTypes.func,
	onSelectItemClick: PropTypes.func,
	renderCustomItem: PropTypes.func,
	isWithHighlightBar: PropTypes.bool,
	paddingVertical: PropTypes.number,
};

export default DropdownListComponent;
