// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class SmileyHmeStronglyDisagree extends PureComponent {
    render() {
        return(
            <g fill="none" fillRule="nonzero" transform={this.props.isTemperature ? "translate(0 26)" : ""}>
                <path fill="#3D1A1A" d="M26.92 28.6h-2.27v-6.19l.02-1.02.04-1.11-.78.74-1.23.99-1.1-1.37 3.45-2.74h1.87v10.7z" opacity=".8"/>
            </g>
        );
    }
}

SmileyHmeStronglyDisagree.propTypes = {
    isTemperature: PropTypes.bool
}

export default SmileyHmeStronglyDisagree;