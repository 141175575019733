// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class SmileyHmeNeutral extends PureComponent {
    render() {
        return(
            <g fill="none" fillRule="nonzero" transform={this.props.isTemperature ? "translate(0 26)" : ""}>
                <path fill="#403D22" d="M28.39 20.3c0 .66-.2 1.23-.61 1.7-.4.47-.97.8-1.7.97V23c.86.1 1.51.37 1.96.79.44.42.66.98.66 1.69 0 1.03-.37 1.83-1.12 2.4-.75.58-1.81.86-3.2.86-1.16 0-2.2-.19-3.09-.58v-1.92a6.99 6.99 0 0 0 2.85.7c.75 0 1.3-.12 1.65-.37.36-.26.54-.66.54-1.23 0-.5-.2-.86-.62-1.07a4.55 4.55 0 0 0-1.96-.31h-.81v-1.74h.83c.83 0 1.43-.1 1.82-.32.38-.22.57-.6.57-1.12 0-.8-.5-1.21-1.52-1.21-.35 0-.71.05-1.08.17-.36.12-.76.32-1.2.6L21.3 18.8a5.82 5.82 0 0 1 3.5-1.06c1.1 0 1.98.23 2.62.68.65.45.97 1.07.97 1.87z" opacity=".8"/>
            </g>
        );
    }
}

SmileyHmeNeutral.propTypes = {
    isTemperature: PropTypes.bool
}

export default SmileyHmeNeutral;