import { combineReducers } from "redux";
import general from "./general";
import authentication from "./authentication";
import questions from "./questions";

const checkinApp = combineReducers({
    general,
    authentication,
    questions
});

export default checkinApp;