import Resources from "Resources";
import { UserRole } from "Enums";

export default (culture) => {
	return {
		[UserRole.user]: Resources[culture].user,
		[UserRole.support]: Resources[culture].support,
		[UserRole.systemAdministrator]: Resources[culture].systemAdministrator,
		[UserRole.groupAdministrator]: Resources[culture].groupAdministrator,
		[UserRole.winningtempAdministrator]: Resources[culture].winningtempAdministrator,
	};
}