import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LineIcons from "LineIcons";
import LineIconComponent from "Controls/LineIconComponent";
import Header from "./Header";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Username = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`;

const Logout = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    opacity: 0.6;
    text-decoration: none;
    &:hover {
        opacity: 1;
        color: #fff;
    }
`;

const HeaderWithProfile = (props) => {
	const { t } = useTranslation();

    return (
        <Header padding={props.padding} paddingMedia={props.paddingMedia} height="auto">
            <Wrapper>
                <Username>{props.username}</Username>
                <Logout onClick={props.onLogout}>
					{t("LogOut")}
                    <LineIconComponent content={LineIcons.signOut} margin="0 0 0 5px" />
                </Logout>
            </Wrapper>
        </Header>            
    );
}

HeaderWithProfile.propTypes = {
    padding: PropTypes.string,
    paddingMedia: PropTypes.string,
    culture: PropTypes.string,
    username: PropTypes.string,
    onLogout: PropTypes.func
}

export default HeaderWithProfile;