// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { GradeHme } from "Enums";
import Resources from "Resources";
import Button from "Controls/Button";
import CircleWrapper from "./CircleWrapper";
import TemperatureWrapper from "./TemperatureWrapper";
import SmileyHmeStronglyDisagree from "./SmileyHmeStronglyDisagree";
import SmileyHmeDisagree from "./SmileyHmeDisagree";
import SmileyHmeNeutral from "./SmileyHmeNeutral";
import SmileyHmeAgree from "./SmileyHmeAgree";
import SmileyHmeStronglyAgree from "./SmileyHmeStronglyAgree";
import SmileyGrade from "./SmileyGrade";

const shake = keyframes`
	0% { -webkit-transform: rotate(0deg); }
	25% { -webkit-transform: rotate(-3deg); }
	50% { -webkit-transform: rotate(3deg); }
	75% { -webkit-transform: rotate(-3deg); }
	100% { -webkit-transform: rotate(0deg); }
`;

const animation = (props) =>
	css`
		animation: ${shake} 0.5s linear;
	`;

const Wrapper = styled(Button)`
	transform-origin: 50% 100%;
	${(props) => (props.isWithShake ? animation : null)}
	&:hover {
		.smiley-background {
			${(props) => !props.isReadOnly && `opacity: 0.2;`}
		}
	}
`;

class SmileyHme extends PureComponent {
	constructor(props) {
		super(props);

		const isIE = !!document.documentMode; // An IE only property, first available in IE8.
		const isEdge = /Edge/.test(navigator.userAgent);
		this.isWithAnimation = !isIE && !isEdge && this.props.isWithShake;
	}

	onClick = () => {
		if (!this.props.onClick) {
			return null;
		}

		this.props.onClick(this.props.grade);
	};

	renderSmile = () => {
		switch (this.props.grade) {
			case GradeHme.stronglyDisagree:
				return <SmileyHmeStronglyDisagree isTemperature={this.props.isTemperature} />;
			case GradeHme.disagree:
				return <SmileyHmeDisagree isTemperature={this.props.isTemperature} />;
			case GradeHme.neutral:
				return <SmileyHmeNeutral isTemperature={this.props.isTemperature} />;
			case GradeHme.agree:
				return <SmileyHmeAgree isTemperature={this.props.isTemperature} />;
			default:
				return <SmileyHmeStronglyAgree isTemperature={this.props.isTemperature} />;
		}
	};

	getAriaLabel = () => {
		if (!this.props.culture) {
			return null;
		}

		switch (this.props.grade) {
			case GradeHme.stronglyDisagree:
				return Resources[this.props.culture].stronglyDisagree;
			case GradeHme.disagree:
				return Resources[this.props.culture].disagree;
			case GradeHme.neutral:
				return Resources[this.props.culture].neutral;
			case GradeHme.agree:
				return Resources[this.props.culture].agree2;
			default:
				return Resources[this.props.culture].stronglyAgree;
		}
	};

	render() {
		const ariaLabel = this.getAriaLabel();

		if (this.props.isTemperature) {
			return (
				<Wrapper
					data-test="smiley-hme"
					type="button"
					backgroundColor="transparent"
					margin={this.props.margin}
					disabled={this.props.isReadOnly}
					isWithShake={this.props.isWithShake}
					position={this.props.position}
					left={this.props.left}
					top={this.props.top}
					leftXS={this.props.leftXS}
					topXS={this.props.topXS}
					onClick={this.onClick}>
					<TemperatureWrapper
						color={this.props.color}
						isAnimated={this.isWithAnimation}
						gradeIndex={5 - this.props.grade}
						gradeCount={5}
						isActive={this.props.isActive}>
						{this.renderSmile()}
					</TemperatureWrapper>
				</Wrapper>
			);
		}

		return (
			<Wrapper
				data-test="smiley-hme"
				aria-hidden={this.props.culture ? "false" : "true"}
				aria-label={ariaLabel}
				type="button"
				backgroundColor="transparent"
				margin={this.props.margin}
				disabled={this.props.isReadOnly}
				isWithShake={this.props.isWithShake}
				position={this.props.position}
				left={this.props.left}
				top={this.props.top}
				leftXS={this.props.leftXS}
				topXS={this.props.topXS}
				minHeight={this.props.smileySize}
				minWidth={this.props.smileySize}
				height={this.props.smileySize}
				width={this.props.smileySize}
				onClick={this.onClick}>
				<CircleWrapper
					size={this.props.smileySize}
					sizeXS={this.props.smileySizeXS}
					color={this.props.color}
					isActive={this.props.isActive}>
					<SmileyGrade
						grade={this.props.grade}
						fontSize={this.props.gradeFontSize}
						opacity={this.props.gradeOpacity}></SmileyGrade>
				</CircleWrapper>
			</Wrapper>
		);
	}
}

SmileyHme.propTypes = {
	isActive: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isTemperature: PropTypes.bool,
	isWithShake: PropTypes.bool,
	grade: PropTypes.number,
	margin: PropTypes.string,
	color: PropTypes.string,
	smileySize: PropTypes.string,
	smileySizeXS: PropTypes.string,
	position: PropTypes.string,
	left: PropTypes.string,
	top: PropTypes.string,
	topXS: PropTypes.string,
	leftXS: PropTypes.string,
	gradeFontSize: PropTypes.string,
	gradeOpacity: PropTypes.string,
	culture: PropTypes.string,
	onClick: PropTypes.func,
};

export default SmileyHme;
