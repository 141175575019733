import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import LanguageSelectorItem from "./LanguageSelectorItem";

class LanguageSelector extends Component {
    onCultureChange = (culture) => {
        $(this.refs.dropdown).toggle();
        
        if (culture === this.props.culture)
            return;

        this.props.onCultureChange(culture);
    }

    componentDidMount() {
        var self = this;

        $(document).ready(function() {
            $(self.refs.active).on("click", function() {
                $(self.refs.dropdown).toggle();
            });
        });
    }

    render() {
        const active = (culture) => {
            var language = this.props.languages.filter(x => x.culture === culture);
            language = language.length > 0 ? language[0] : this.props.languages[0];

            return (
                <a href="#" ref="active" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                    <img src={language.imageUrl} />
                    <span>{language.name}</span><span className="caret"></span>
                </a>
            );
        };

        return (
            <ul>
                <li className="dropdown dropdown-lang">
                    {active(this.props.culture)}
                    <ul ref="dropdown" className="dropdown-menu" role="menu">
                        {this.props.languages.map((language, index) =>
                            <LanguageSelectorItem 
                                name={language.name}
                                culture={language.culture}
                                imageUrl={language.imageUrl}
                                onClick={this.onCultureChange}
                                key={index} />
                        )}
                    </ul>
                </li>
            </ul>
        );
    }
}

LanguageSelector.propTypes = {
    culture: PropTypes.string,
    languages: PropTypes.array,
    onCultureChange: PropTypes.func
}

export default LanguageSelector;