import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import HeaderWithProfile from "./HeaderWithProfile";
import { QuestionProgressBar } from "./Question";
import {
	QuestionTalkHOC,
	QuestionDoYouWantToTalkScreen,
	QuestionSelectContactScreen,
} from "./Question/QuestionTalk";

const Wrapper = styled.div`
	background: ${Colors.questionWindowBackground};
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 30px;
	@media (max-width: 1024px) {
		min-height: 99vh;
		padding-top: 30px;
		padding-top: calc(30px + env(safe-area-inset-bottom));
		padding-bottom: 60px;
		padding-bottom: calc(60px + env(safe-area-inset-bottom));
	}
	@media (max-width: 425px) {
		padding-top: calc(30px + env(safe-area-inset-bottom));
		padding-bottom: calc(118px + env(safe-area-inset-bottom));
	}
`;

class EscalateTalk extends PureComponent {
	constructor(props) {
		super(props);

		this.config = this.getConfig();
	}

	getConfig = () => {
		return {
			[this.props.screens.doYouWantToTalk]: {
				render: this.renderDoYouWantToTalkScreen,
				progress: 0,
			},
			[this.props.screens.selectContact]: {
				render: this.renderSelectContactScreen,
				progress: 50,
			},
		};
	};

	getHasExternalPartnerEnabled = () => {
		return this.props.contacts?.some(x => x.Key.startsWith("External"));
	}
	
	renderDoYouWantToTalkScreen = () => {
		// switch and return a modified screen if customer has a external partner enabled
		// as the default text is very much focused on talking to someone and this is not always
		// compatible with the partner offerings
		if(this.getHasExternalPartnerEnabled()) {
			return (
				<QuestionDoYouWantToTalkScreen
					culture={this.props.culture}
					doYouWantToTalkDescription={
						this.props.t("DoYouNeedSupportAndWantToTalkToSomeoneExternalPartner")
					}
					doYouWantToTalkQuestion={
						this.props.t("DoYouWantToContinueAndSeeWhatPossibilitiesWinningtempOffersAsSupport")
					}
					nextButtonAttribute="escalation-strategy-health-step-1-yes"
					backButtonAttribute="escalation-strategy-health-step-1-no"
					onNoClick={this.props.onQuestionTalkNoSubmit}
					onYesClick={this.props.openSelectContactScreen}
				/>
			);
		}
		
		return (
			<QuestionDoYouWantToTalkScreen
				culture={this.props.culture}
				doYouWantToTalkDescription={
					this.props.t("DoYouNeedSupportAndWantToTalkToSomeone")
				}
				doYouWantToTalkQuestion={
					this.props.t("DoYouWantToTalkToSomeoneAboutYourWorkSituation")
				}
				nextButtonAttribute="escalation-strategy-health-step-1-yes"
				backButtonAttribute="escalation-strategy-health-step-1-no"
				onNoClick={this.props.onQuestionTalkNoSubmit}
				onYesClick={this.props.openSelectContactScreen}
			/>
		);
	};

	getIsExternalContact = () => {
		return this.props.selectedContact?.Key.startsWith("External");
	};

	getContactDescription = () => {
		if (this.props.selectedContact?.Key === "External") {
			return `${this.props.t("QuestionTalkExternalDescription")}<br/><br/>${
				this.props.t("OrIfYouPreferYouCanTalkToSomeoneInYourOrganization")
			}`;
		}

		if (this.props.selectedContact?.Key === "ExternalBraive") {
			let partnerUrl = "https://app.braive.com/users/sign_up?companyId=" + this.props.customerId;
			return `${this.props.t("QuestionTalkExternalBraiveDescription").replace(
				"{{partner_url}}",
				partnerUrl
			)}<br/><br/>${this.props.t("OrIfYouPreferYouCanTalkToSomeoneInYourOrganization")}`;
		}

		return `${this.props.t("QuestionTalkDefaultDescription")}<br/><br/>${
			this.props.t("ContactDesiredPerson")
		}`;
	};

	renderSelectContactScreen = () => {
		let text = this.getContactDescription();
		let isExternalContact = this.getIsExternalContact();

		return (
			<QuestionSelectContactScreen
				isExternalContact={isExternalContact}
				culture={this.props.culture}
				nextButtonAttribute="escalation-strategy-health-step-2-submit"
				backButtonAttribute="escalation-strategy-health-step-2-back"
				contacts={this.props.contacts}
				text={text}
				comment={this.props.questionTalkData.comment}
				onCommentChange={this.props.onCommentChange}
				selectedContact={this.props.selectedContact}
				onContactChange={this.props.onContactChange}
				onSendClick={this.props.onSubmit}
				onUndoClick={this.props.openDoYouWantToTalkScreen}
			/>
		);
	};

	render() {
		if (!this.props.batch || !this.config[this.props.activeScreen]) {
			return null;
		}

		return (
			<SurveyContainerWrapper className="with-auto-height-adjust">
				<ContentWrapper display="block" position="fixed" top="0" width="100%" zIndex="1">
					<HeaderWithProfile
						padding="20px 30px"
						paddingMedia="20px"
						username={this.props.username}
						culture={this.props.culture}
						onLogout={this.props.onLogout}
					/>
					<QuestionProgressBar progressWidth={this.config[this.props.activeScreen].progress} />
				</ContentWrapper>
				<Wrapper>{this.config[this.props.activeScreen].render()}</Wrapper>
			</SurveyContainerWrapper>
		);
	}
}

EscalateTalk.propTypes = {
	culture: PropTypes.string,
	batch: PropTypes.object,
	username: PropTypes.string,
	onBack: PropTypes.func,
	onChange: PropTypes.func,
	submitting: PropTypes.bool,
};

export default QuestionTalkHOC(withTranslation()(EscalateTalk));
