import React, { PureComponent } from "react";

const DEFAULT_MAIN_VIEW_HEIGHT = 54;

export default (ComposedComponent) =>
	class extends PureComponent {
		constructor(props) {
			super(props);

			this.wrapperElement = React.createRef();

			this.state = {
				isShouldBeClosed: this.props.isShouldBeClosed,
				wrapperMaxHeight: "0",
				wrapperRightValue: "auto",
				wrapperBottomValue: "auto",
			};
		}

		onTabClick = (tab) => {
			const wrapperSize = this.wrapperElement.current.getBoundingClientRect();
			this.props.onTabClick(tab, wrapperSize.height);
		};

		setWrapperProps = () => {
			const wrapperSize = this.wrapperElement.current.getBoundingClientRect();
			const bodyWidth = document.body.clientWidth;
			const bodyHeight = document.body.clientHeight;
			const mainViewHeight = this.wrapperElement.current.previousElementSibling
				? this.wrapperElement.current.previousElementSibling.getBoundingClientRect().height
				: DEFAULT_MAIN_VIEW_HEIGHT;

			let wrapperRightValue = "auto";
			let wrapperBottomValue = "auto";
			let wrapperMaxHeight = this.props.isDropdownFullHeightXS ? "100vh" : "60vh";

			if (wrapperSize.right > bodyWidth || this.state.wrapperRightValue === "0") {
				wrapperRightValue = "0";
			}

			if (wrapperSize.bottom > bodyHeight && wrapperSize.top - mainViewHeight > wrapperSize.height) {
				wrapperBottomValue = "100%";
			}

			if (bodyWidth <= 767) {
				wrapperBottomValue = "0";
			}

			this.setState({
				wrapperRightValue,
				wrapperBottomValue,
				wrapperMaxHeight,
			});
		};

		static getDerivedStateFromProps(props, state) {
			if (props.isShouldBeClosed && !state.isShouldBeClosed) {
				return { wrapperMaxHeight: "0" };
			}
			return null;
		}

		componentDidMount() {
			this.setWrapperProps();
		}

		componentDidUpdate(prevProps) {
			if (
				prevProps.expandedIds !== this.props.expandedIds ||
				prevProps.isDataLoaded !== this.props.isDataLoaded
			) {
				this.setWrapperProps();
			}
		}

		render() {
			return (
				<ComposedComponent
					{...this.props}
					{...this.state}
					onTabClickHandler={this.onTabClick}
					setWrapperElement={this.wrapperElement}
				/>
			);
		}
	};
