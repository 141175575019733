import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";

const Button = styled.button`
	width: ${props => props.width || "100%"};
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.backgroundColor || Colors.buttonPurple};
	border: none;
	&:hover {
		background-color: ${props => props.hoverBackgroundColor || Colors.buttonPurpleHighlighted};
	}
	&:focus {
		border: none;
		outline: none;
	}
`;

const Text = styled.span`
	display: inline-block;
	width: 100%;
	text-align: center;
	font-weight: 600;
	color: #ffffff;
	text-transform: uppercase;
	margin: 0 auto;
`;

const DropdownButtonComponent = (props) => {
	return (
		<Button
			type="button"
			width={props.width}
			backgroundColor={props.backgroundColor}
			hoverBackgroundColor={props.hoverBackgroundColor}
			onClick={props.onButtonClick && props.onButtonClick}>
			<Text>{props.label || "Search"}</Text>
		</Button>
	);
};

DropdownButtonComponent.propTypes = {
	label: PropTypes.string,
	width: PropTypes.string,
	backgroundColor: PropTypes.string,
	hoverBackgroundColor: PropTypes.string,
	onButtonClick: PropTypes.func
};

export default DropdownButtonComponent;