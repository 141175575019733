import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { logoutUser } from "../actions/authentication";
import { requestBatches } from "../actions/batches";
import { navigateToBatch, navigateToLogin, navigateToWidget } from "../actions/router";
import { getAuth } from "../actions/authentication.localStorage";
import { getCulture } from "../actions/culture";
import { withBackgroundHeightAdjust } from "../components/WithBackgroundHeightAdjust";
import Batches from "../components/Batches";

class BatchesContainer extends Component {
	componentDidMount() {
		this.props.onMount(this.props.shouldRequest);
		this.addUserIdToDatadogLogs();
	}

	addUserIdToDatadogLogs = () => {
		const authentication = getAuth();

		//add userId to Datadog logs
		if (authentication && authentication.id) {
			window.DD_LOGS && window.DD_LOGS.addLoggerGlobalContext("userId", authentication.id);
		}
	};

	render() {
		return <Batches {...this.props} fetching={this.props.fetching} />;
	}
}

BatchesContainer.propTypes = {
	shouldRequest: PropTypes.bool,
	fetching: PropTypes.bool,
	onMount: PropTypes.func,
};

const mapStateToProps = (state) => {
	var authentication = getAuth();

	return {
		culture: getCulture(state),
		fetching: state.questions.fetching,
		username: authentication ? authentication.name : null,
		shouldRequest: !state.questions.requested,
		widgetEnabled: state.general.widget.enabled,
		batches: state.questions.batches ? state.questions.batches.filter((batch) => !batch.submitted) : [],
		questionSettings: state.questions.questionSettings,
	};
};

const mapDispatchToProps = (dispatch, { history }) => {
	return {
		onLogout: function () {
			dispatch(logoutUser()).then(navigateToLogin(history));
		},
		onBatchClick: function (batch) {
			navigateToBatch(batch.id, history);
		},
		onWidgetClick: function () {
			navigateToWidget(history);
		},
		onMount: function (shouldRequest) {
			if (!shouldRequest) return;
			dispatch(requestBatches()).then((result) => {
				if (result.success) {
					if (result.batches.length === 1) navigateToBatch(result.batches[0].id, history);
				} else {
					if (result.status === 401) {
						dispatch(logoutUser()).then(navigateToLogin(history));
					}
				}
			});
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withBackgroundHeightAdjust(BatchesContainer))
);
