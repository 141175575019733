// this file origin from Winningtemp.Shared

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Resources from "Resources";
import {OptionalAnswerActivationType} from "Enums";
import QuestionText from "./QuestionText";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${props => props.padding || "0"};
    flex-direction: column;
	${props => props.paddingTop && `padding-top: ${props.paddingTop};`}
    @media (max-width: 768px) {
        flex-direction: column;
        padding: ${props => props.paddingXS || "0"};
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent || "center"};
    margin-bottom: 0;
    padding: 10px;
    width: ${props => props.width};
`;

const Button = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 60px;
    height: 40px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    user-select: none;
    background-color: ${props => props.isSelected ? "#f2f9fa" : "#ffffff"};
    color: ${props => props.isSelected ? "#59b0c7" : "#737373"};
    margin: ${props => props.margin};
    border: 1px solid ${props => props.isSelected ? "#59b0c7" : "#e0e0e0"};
    &:hover {
        border-color: #ccc;
        color: #595959;
    }
`;

class QuestionActivator extends Component {
	onActivation = (e, activated) => {
		this.props.onActivation(activated);
	}

	render() {
		return (
			<Wrapper
				padding={this.props.questionPadding}
				paddingXS={this.props.questionPaddingXS}
				paddingTop={this.props.isWithQuestionCounter ? "0" : null}>
				<QuestionText
					color={this.props.questionColor}
					fontSize={this.props.questionFontSize}
					fontSizeXS={this.props.questionFontSizeXS}
					fontWeight={this.props.questionFontWeight}
					padding={this.props.questionTextPadding}
					textAlign={this.props.questionTextAlign}>
					{this.props.question.preDescription || this.props.question.description}
				</QuestionText>
				<ButtonsWrapper
					justifyContent={this.props.activationAlign}
					padding={this.props.activationAlign ? "10px 0" : null}
					width={this.props.activationAlign ? "100%" : "auto"}>
					<Button
						type="button"
						margin={this.props.activationAlign ? "0 15px 0 0" : "0 15px 0 5px"}
						isSelected={this.props.question.activationType === OptionalAnswerActivationType.approved}
						onClick={(e) => this.onActivation(e, true)}>
						{Resources[this.props.culture].yes}
					</Button>
					<Button
						type="button"
						isSelected={this.props.question.activationType === OptionalAnswerActivationType.declined}
						onClick={(e) => this.onActivation(e, false)}>
						{Resources[this.props.culture].no}
					</Button>
				</ButtonsWrapper>
			</Wrapper>
		);
	}
}

QuestionActivator.propTypes = {
	isWithQuestionCounter: PropTypes.bool,
	activationAlign: PropTypes.string,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionColor: PropTypes.string,
	questionFontWeight: PropTypes.string,
	questionTextPadding: PropTypes.string,
	questionTextAlign: PropTypes.string,
	question: PropTypes.object,
	onChange: PropTypes.func,
	onActivation: PropTypes.func
}

export default QuestionActivator;