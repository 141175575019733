// this file origin from Winningtemp.Shared

import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import Resources from "Resources";
import Colors from "Colors";
import { format } from "Helpers";
import ContentWrapper from "Controls/ContentWrapper";
import TextAreaComponent from "Controls/TextAreaComponent";
import Text from "Controls/Text";
import Question from "./Question";
import QuestionTalkModalFooterWrapper from "./QuestionTalk/QuestionTalkModalFooterWrapper";
import QuestionCommentManagerToggle from "./QuestionCommentManagerToggle";
import AIAssistedRepliesToggle from "./AIAssistedRepliesToggle";

class QuestionEscalate extends Component {
	onTextChange = (e) => {
		this.props.onChange({ ...this.props.temperature, comment: e.target.value });
	};

	onVisibleForImmediateManagerChange = () => {
		this.props.onChange({
			...this.props.temperature,
			visibleForImmediateManager: !this.props.temperature.visibleForImmediateManager,
		});
	};

	onAllowAiAssistedRepliesChange = () => {
		this.props.onChange({
			...this.props.temperature,
			allowAiAssistedReplies: !this.props.temperature.allowAiAssistedReplies,
		});
	};

	render() {
		return (
			<ContentWrapper
				data-test="question-escalate"
				backgroundColor={Colors.questionWindowBackground}
				backgroundColorXS={Colors.questionWindowBackgroundXS}
				paddingBottom="0"
				minHeightXS="99vh"
				paddingTopXS="60px">
				<ContentWrapper position="relative" padding="10px 10px 0">
					<ContentWrapper
						backgroundColor={Colors.backgroundLightGray}
						borderRadius="4px"
						padding="20px 15px 30px">
						<Text
							color={Colors.textGray}
							display="inline-block"
							fontWeight="500"
							fontSize="14px"
							lineHeight="18px">
							{format(
								Resources[this.props.culture]
									.doYouWantToAnonymouslyWriteAMotivationForYourAnswerOn,
								Moment(this.props.escalationDate).format("YYYY-MM-DD")
							)}
							:
						</Text>
						<Question
							questionTextPadding="20px"
							questionPadding="0"
							question={this.props.temperature}
							isCommentDisabled={true}
							isReadOnlyAfterSubmit={true}
							culture={this.props.culture}
							questionColor="#4c4c4c"
							addCommentColor="#7b828c"
							questionFontSize="18px"
							questionFontSizeXS="16px"
						/>
						<TextAreaComponent
							value={this.props.temperature.comment || ""}
							borderRadius="3px"
							height="140px"
							padding="10px 15px"
							fontSize="14px"
							fontWeight="500"
							color={Colors.darkGrayText}
							maxWidth="100%"
							width="100%"
							boxShadow={`0 2px 2px ${Colors.borderLightGray}`}
							onChange={this.onTextChange}
							placeholder={Resources[this.props.culture].writeAnAnonymousComment + "..."}
						/>
						<ContentWrapper display="flex" padding="10px 0 0">
							<QuestionCommentManagerToggle
								isVisible={
									!this.props.questionSettings ||
									this.props.questionSettings.allowEmployeeHideCommentFromManager
								}
								isVisibleForImmediateManager={
									this.props.temperature.visibleForImmediateManager
								}
								culture={this.props.culture}
								onVisibleForImmediateManagerChange={this.onVisibleForImmediateManagerChange}
							/>
						</ContentWrapper>
						<ContentWrapper display="flex" padding="10px 0 0">
							<AIAssistedRepliesToggle
								isVisible={
									this.props.questionSettings &&
									this.props.questionSettings.allowAiAssistedRepliesToggle
								}
								checked={this.props.temperature.allowAiAssistedReplies}
								culture={this.props.culture}
								onAllowAiAssistedRepliesChange={this.onAllowAiAssistedRepliesChange}
							/>
						</ContentWrapper>
					</ContentWrapper>
				</ContentWrapper>
				<QuestionTalkModalFooterWrapper
					nextButtonText={Resources[this.props.culture].send}
					backButtonText={Resources[this.props.culture].noThanks}
					nextButtonAttribute="escalation-strategy-question-send"
					backButtonAttribute="escalation-strategy-question-no"
					onBackClick={this.props.onCancel}
					onNextClick={this.props.onSubmit}
				/>
			</ContentWrapper>
		);
	}
}

QuestionEscalate.propTypes = {
	culture: PropTypes.string,
	backgroundColor: PropTypes.string,
	escalationDate: PropTypes.string,
	temperature: PropTypes.object,
	questionSettings: PropTypes.object,
	onChange: PropTypes.func,
	headerFontSize: PropTypes.string,
};

export default QuestionEscalate;
