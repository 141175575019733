// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Tooltip from "ComponentsLibrary/Tooltip/Tooltip";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import CheckBoxComponent from "Controls/CheckBoxComponent";

const LinkItem = styled.a`
	line-height: 17px;
	text-decoration: ${(props) => props.textDecoration || "none"};
	font-size: ${(props) => props.fontSize || "14px"};
	font-weight: ${(props) => props.fontWeight || "500"};
	margin: ${(props) => props.margin || "0"};
	color: ${Colors.darkestGrayText};
	&:hover {
		text-decoration: none;
		color: ${Colors.darkestGrayText};
	}
	&:focus {
		text-decoration: none;
		color: ${Colors.darkestGrayText};
	}
`;

class QuestionCommentManagerToggle extends PureComponent {
	onCheckboxClick = (e) => {
		e.stopPropagation();
		this.props.onVisibleForImmediateManagerChange();
	};

	onVisibleForImmediateManagerClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		this.props.onVisibleForImmediateManagerChange();
	};

	onDescriptionOpen = (e) => {
		e.stopPropagation();
		e.preventDefault();
		this.props.onDescriptionOpen && this.props.onDescriptionOpen();
	};

	render() {
		if (!this.props.isVisible) {
			return null;
		}

		return (
			<ContentWrapper
				data-test="question-comment-manager-toggle"
				display="flex"
				alignItems="flex-start"
				flexGrow="1"
				margin="0 10px 0 0">
				<CheckBoxComponent
					backgroundColor={Colors.white}
					margin="2px 0 0"
					checked={this.props.isVisibleForImmediateManager}
					onClick={this.onCheckboxClick}
				/>
				<ContentWrapper padding="0 0 0 10px" position="relative">
					<LinkItem href="#" margin="0 5px 0 0" onClick={this.onVisibleForImmediateManagerClick}>
						{Resources[this.props.culture].okThatYourImmediateManagerSeesTheComment}
					</LinkItem>
					<Tooltip
						followCursor={true}
						maxWidth={"500px"}
						text={Resources[this.props.culture].questionCommentWhatDoesItMeanDescription}>
						<LinkItem
							href="#"
							margin="5px 0 0"
							fontWeight="600"
							fontSize="14px"
							textDecoration="underline"
							onClick={this.onDescriptionOpen}>
							{Resources[this.props.culture].whatDoesThisMean}
						</LinkItem>
					</Tooltip>
				</ContentWrapper>
			</ContentWrapper>
		);
	}
}

QuestionCommentManagerToggle.propTypes = {
	isVisible: PropTypes.bool,
	isVisibleForImmediateManager: PropTypes.bool,
	culture: PropTypes.string,
	onVisibleForImmediateManagerChange: PropTypes.func,
	onDescriptionOpen: PropTypes.func,
};

export default QuestionCommentManagerToggle;
