// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import QuestionText from "./QuestionText";

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	background-color: ${(props) => props.backgroundColor || "transparent"};
	padding: ${(props) => props.padding || "0"};
	width: 100%;
	position: relative;
	${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`}
	@media(max-width: 768px) {
		flex-direction: column;
		padding: ${(props) => props.paddingXS || "0"};
	}
`;

const FeedbackBox = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

class FeedbackQuestionRow extends PureComponent {
	render() {
		return (
			<Wrapper
				backgroundColor={this.props.backgroundColor}
				padding={this.props.questionPadding}
				paddingXS={this.props.questionPaddingXS}
				paddingTop={this.props.isWithQuestionCounter ? "0" : null}>
				<QuestionText
					tabIndex="0"
					paddingTop={this.props.isWithQuestionCounter ? "0" : null}
					color={this.props.questionColor}
					fontSize={this.props.questionFontSize}
					fontSizeXS={this.props.questionFontSizeXS}
					fontWeight={this.props.questionFontWeight}
					padding={this.props.questionTextPadding}
					textAlign={this.props.questionTextAlign}>
					{this.props.question.description}
				</QuestionText>
				<FeedbackBox>{this.props.children}</FeedbackBox>
			</Wrapper>
		);
	}
}

FeedbackQuestionRow.propTypes = {
	isWithQuestionCounter: PropTypes.bool,
	isWithBackButton: PropTypes.bool,
	questionType: PropTypes.number,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionFontWeight: PropTypes.string,
	questionTextAlign: PropTypes.string,
	questionTextPadding: PropTypes.string,
	questionColor: PropTypes.string,
	culture: PropTypes.string,
	backgroundColor: PropTypes.string,
	question: PropTypes.object,
};

export default FeedbackQuestionRow;
