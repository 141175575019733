export const mapObject = (dataObject) => {
    const newObject = {};

    Object.keys(dataObject).forEach(
        key => {
            const newKey = key[0].toLowerCase() + key.slice(1);
            newObject[newKey] = dataObject[key];
        }
    );

    return newObject;
}

export const getStringHashCode = (value) => {
    let hash = 0,
    i, chr;

    if (value.length === 0) return hash;

    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return (hash >>> 0) + "";
}