const loginUrl = () => {
	return "/" + window.initialState.customer;
};

const widgetUrl = () => {
	return "/" + window.initialState.customer + "/temperature";
};

const batchesUrl = () => {
	return "/" + window.initialState.customer + "/batches";
};

const batchUrl = (id) => {
	return "/" + window.initialState.customer + "/batches/" + id;
};

const confirmationUrl = () => {
	return "/" + window.initialState.customer + "/confirmation";
};

const escalateUrl = () => {
	return "/" + window.initialState.customer + "/escalate";
};

const navigateToBatch = (id, history) => {
	history.push(batchUrl(id));
};

const navigateToLogin = (history) => {
	history ? history.push(loginUrl()) : (window.location.href = loginUrl());
};

const navigateToBatches = (history) => {
	history.push(batchesUrl());
};

const navigateToConfirmation = (history) => {
	history.push(confirmationUrl());
};

const navigateToEscalate = (history) => {
	history.push(escalateUrl());
};

const navigateToWidget = (history) => {
	history.push(widgetUrl());
};

export {
	loginUrl,
	widgetUrl,
	batchUrl,
	batchesUrl,
	escalateUrl,
	confirmationUrl,
	navigateToBatch,
	navigateToBatches,
	navigateToLogin,
	navigateToEscalate,
	navigateToConfirmation,
	navigateToWidget,
};
