// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class SmileyHmeStronglyAgree extends PureComponent {
    render() {
        return(
            <g fill="none" fillRule="nonzero" transform={this.props.isTemperature ? "translate(0 26)" : ""}>
                <path fill="#2C4021" d="M25.22 21.77c1.03 0 1.86.29 2.47.87.61.58.92 1.37.92 2.38 0 1.2-.37 2.12-1.1 2.77-.75.64-1.8.96-3.17.96a6.2 6.2 0 0 1-2.89-.58v-1.95c.39.2.84.37 1.35.5.51.13 1 .2 1.46.2 1.38 0 2.07-.57 2.07-1.7 0-1.08-.72-1.62-2.15-1.62a5.33 5.33 0 0 0-1.62.24l-.9-.49.4-5.45h5.81v1.92h-3.82l-.2 2.1.26-.05c.3-.07.67-.1 1.1-.1z" opacity=".8"/>
            </g>
        );
    }
}

SmileyHmeStronglyAgree.propTypes = {
    isTemperature: PropTypes.bool
}

export default SmileyHmeStronglyAgree;