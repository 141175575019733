import Colors from "Colors";

const LicenseType = {
	start: 1,
	complete: 2,
	perform: 3,
	action: 4
}

const Grade = {
	stronglyDisagree: 0,
	disagree: 1,
	agree: 2,
	stronglyAgree: 3
}

const GradeHme = {
	stronglyDisagree: 1,
	disagree: 2,
	neutral: 3,
	agree: 4,
	stronglyAgree: 5
}

const GradeEnps = {
	detractors: 0,
	passives: 1,
	promoters: 2,
}

const GradeConfig = {
	[Grade.stronglyDisagree]: {
		resourcesLabel: "stronglyDisagree",
		color: Colors.smileyStronglyDisagree,
		shadowColor: "rgba(250, 215, 127, 0.35)",
		iconName: "0.png",
		lineIconName: "smileyStronglyDisagree"
	},
	[Grade.disagree]: {
		resourcesLabel: "disagree",
		color: Colors.smileyDisagree,
		shadowColor: "rgba(250, 215, 127, 0.35)",
		iconName: "1.png",
		lineIconName: "smileyDisagree"
	},
	[Grade.agree]: {
		resourcesLabel: "agree2",
		color: Colors.smileyAgree,
		shadowColor: "rgba(157, 197, 100, 0.35)",
		iconName: "2.png",
		lineIconName: "smileyAgree"
	},
	[Grade.stronglyAgree]: {
		resourcesLabel: "stronglyAgree",
		color: Colors.smileyStronglyAgree,
		shadowColor: "rgba(157, 197, 100, 0.35)",
		iconName: "3.png",
		lineIconName: "smileyStronglyAgree"
	}
}

const GradeHMEConfig = {
	[GradeHme.stronglyDisagree]: {
		gradeValue: 1,
		resourcesLabel: "hmeResponseOption1",
		color: Colors.smileyStronglyDisagree
	},
	[GradeHme.disagree]: {
		gradeValue: 2,
		resourcesLabel: "hmeResponseOption2",
		color: Colors.smileyDisagree
	},
	[GradeHme.neutral]: {
		gradeValue: 3,
		resourcesLabel: "hmeResponseOption3",
		color: Colors.smileyNeutral
	},
	[GradeHme.agree]: {
		gradeValue: 4,
		resourcesLabel: "hmeResponseOption4",
		color: Colors.smileyAgree
	},
	[GradeHme.stronglyAgree]: {
		gradeValue: 5,
		resourcesLabel: "hmeResponseOption5",
		color: Colors.smileyStronglyAgree
	}
}

const CommentGradeConfig = {
	[Grade.stronglyDisagree]: {
		resourcesLabel: "stronglyDisagree",
		color: Colors.commentSmileyStronglyDisagree,
		colorDisabled: Colors.commentSmileyStronglyDisagreeDisabled,
		shadowColor: "rgba(250, 215, 127, 0.35)",
		iconName: "0.png",
		lineIconName: "smileyStronglyDisagree"
	},
	[Grade.disagree]: {
		resourcesLabel: "disagree",
		color: Colors.commentSmileyDisagree,
		colorDisabled: Colors.commentSmileyDisagreeDisabled,
		shadowColor: "rgba(250, 215, 127, 0.35)",
		iconName: "1.png",
		lineIconName: "smileyDisagree"
	},
	[Grade.agree]: {
		resourcesLabel: "agree2",
		color: Colors.commentSmileyAgree,
		colorDisabled: Colors.commentSmileyAgreeDisabled,
		shadowColor: "rgba(157, 197, 100, 0.35)",
		iconName: "2.png",
		lineIconName: "smileyAgree"
	},
	[Grade.stronglyAgree]: {
		resourcesLabel: "stronglyAgree",
		color: Colors.commentSmileyStronglyAgree,
		colorDisabled: Colors.commentSmileyStronglyAgreeDisabled,
		shadowColor: "rgba(157, 197, 100, 0.35)",
		iconName: "3.png",
		lineIconName: "smileyStronglyAgree"
	}
}

const CommentGradeHMEConfig = {
	[GradeHme.stronglyDisagree]: {
		gradeValue: 1,
		resourcesLabel: "hmeResponseOption1",
		color: Colors.commentSmileyStronglyDisagree,
		colorDisabled: Colors.commentSmileyStronglyDisagreeDisabled,
	},
	[GradeHme.disagree]: {
		gradeValue: 2,
		resourcesLabel: "hmeResponseOption2",
		color: Colors.commentSmileyDisagree,
		colorDisabled: Colors.commentSmileyDisagreeDisabled,
	},
	[GradeHme.neutral]: {
		gradeValue: 3,
		resourcesLabel: "hmeResponseOption3",
		color: Colors.commentSmileyNeutral,
		colorDisabled: Colors.commentSmileyNeutralDisabled,
	},
	[GradeHme.agree]: {
		gradeValue: 4,
		resourcesLabel: "hmeResponseOption4",
		color: Colors.commentSmileyAgree,
		colorDisabled: Colors.commentSmileyAgreeDisabled,
	},
	[GradeHme.stronglyAgree]: {
		gradeValue: 5,
		resourcesLabel: "hmeResponseOption5",
		color: Colors.commentSmileyStronglyAgree,
		colorDisabled: Colors.commentSmileyStronglyAgreeDisabled,
	}
}

const CommentGradeEnpsConfig = {
	[GradeEnps.detractors]: {
		resourcesLabel: "detractors"
	},
	[GradeEnps.passives]: {
		resourcesLabel: "passives"
	},
	[GradeEnps.promoters]: {
		resourcesLabel: "promoters"
	},
}

const GradeENPSConfig = {
	[GradeEnps.detractors]: {
		resourcesLabel: "detractors",
		color: Colors.smileyStronglyDisagree,
		shadowColor: "rgba(250, 215, 127, 0.35)",
	},
	[GradeEnps.passives]: {
		resourcesLabel: "passives",
		color: Colors.smileyNeutral,
		shadowColor: "rgba(157, 197, 100, 0.35)",
	},
	[GradeEnps.promoters]: {
		resourcesLabel: "promoters",
		color: Colors.smileyStronglyAgree,
		shadowColor: "rgba(157, 197, 100, 0.35)",
	},
}

const AnswerResponseSelectorFilters = {
	stronglyDisagree: "0",
	disagree: "1",
	agree: "2",
	stronglyAgree: "3"
}

const PeriodEnum = {
	week: 1,
	month: 2,
	quarter: 3,
	year: 4,
	custom: 5,
	standard: 6
};

const InsightsContainerTab = {
	improvements: 0,
	strengths: 1,
	future: 2
};

const InsightTypes = {
	question: 0,
	questionType: 1,
	bullying: 2,
	discrimination: 3,
	stress: 4,
	trend: 5
};

const AnswerPerQuestionLabels = {
	stronglyDisagree: "0",
	disagree: "1",
	agree: "2",
	stronglyAgree: "3"
};

const InsightIndexTypes = {
	improvement: 0,
	strength: 1,
	prediction: 2
};

const InsightTrendType =
{
	positive: 0,
	negative: 1,
	unchanged: 2
}

const UserImportStages = {
	fileUploadStage: 0,
	mappingStage: 1,
	templateStage: 2,
	summaryStage: 3,
	completeStage: 4
};

const Genders = {
	none: 0,
	female: 1,
	male: 2
};

const PasswordGenerators = {
	random: 0,
	static: 1
};

const OnboardingStages = {
	welcome: 0,
	contribute: 1,
	praise: 2,
	summary: 3
}

const BatchExecutionType = {
	standard: 0,
	custom: 1,
	pause: 2
}

const DayOfWeek = {
	sunday: 0,
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6
}

const RecurrenceDays = {
	start: 0,
	stop: -1,
	weekly: 7,
	everyTwoWeeks: 14,
	everyThreeWeeks: 21,
	monthly: 30,
	everySixWeeks: 42,
	everyTwoMonths: 60,
	quarter: 90
}

const WidgetChartType = {
	questionType: 0,
	questionCategory: 1,
	total: 2
};

const WidgetChartSize = {
	small: 0,
	medium: 1,
	large: 2
};

const ActivityType = {
	coachingSession: 1,
	goal: 2,
	task: 3,
	learning: 4,
	skill: 5,
	badge: 6,
	question: 7,
	feed: 8,
	dailyQuestion: 9,
	customQuestion: 11,
	informationMailing: 13,
	birthday: 14,
	comment: 15,
	kudo: 16,
	metric: 17,
	insights: 18,
	engagementReport: 19,
	temperatureConversation: 20,
	action: 21,
};

const QuestionContextType = {
	mainQuestion: 1,
	preQuestion: 2
}

const MetricType = {
	score: 0,
	percentage: 1
};

const InsightPeriod = {
	lastWeek: 0,
	lastMonth: 1,
	lastTwoMonths: 2,
	lastThreeMonths: 3,
	all: 4
};

const QuestionSurveyType = {
	standardSmiley: 0,
	customSmiley: 1,
	enps: 2,
	hme: 3
};

const AnalyzeGrouping = {
	group: 0,
	segment: 1,
	ageDistribution: 2,
	gender: 3,
	employmentInterval: 4
};

const TemperatureAverageIndexType = {
	winningtemp: 0,
	customer: 1,
	industry: 2
};

const TemperatureType = {
	regular: 0,
	custom: 1
};

const TemperatureTemplate = {
	default: 0,
	hme: 1,
	enps: 2,
};

const QuestionCommentViewMode = {
	none: 0,
	editor: 1,
	comment: 2
};

const OptionalAnswerActivationType = {
	notActivated: 0,
	declined: 1,
	approved: 2
};

const ResponseRateSorting = {
	responseRateDesc: 1,
	responseRateAsc: 2,
	groupNameAsc: 3,
	groupNameDesc: 4
};

const TemperatureCommentGroupFilter = {
	all: 0
};

const TabUserActivity = {
	loggedIn: 1,
	notLoggedIn: 2,
	downloadedApp: 3,
	slackIntegration: 4,
	responseRateMonthly: 5,
	responseRateByGroupChart: 6,
	responseRateByGroup: 7,
	invitations: 10
};

const MainTabUserActivity = {
	userActivity: 1,
	responseRate: 2
};

const GoalStatisticsTabUserActivity = {
	goalByGroups: 1,
	goalByUser: 2,
	goalCompletedByUser: 3
};

const SendInvitationSelectionType =
{
	newUsers: 0,
	remindersForUser: 1
};

const InvitationStatuses = {
	allStatuses: 0,
	confirmed: 1,
	pending: 2
};

const MembershipType = {
	task: 1,
	goal: 2,
	learning: 3,
	group: 4,
	questionGroup: 5,
	user: 6
}

const MembershipRole = {
	member: 1,
	administrator: 2
}

const ConversationMessageType = {
	message: 0,
	feedback: 1
}

const SegmentType = {
	role: 1,
	skill: 2,
	other: 3
}

const QuestionTemplate = {
	standard: 0,
	hme: 1
}

const LineChartColors = [
	"#595959",
	"#4db1af",
	"#ffbf40",
	"#fe6e6d",
	"#fd8d3a",
	"#59b0c7",
	"#85bc67",
	"#945bb3"
]

const GoalStatus = {
	all: 1,
	active: 2,
	completed: 3
}

const GoalStatusLabel = {
	[GoalStatus.all]: "all",
	[GoalStatus.active]: "active",
	[GoalStatus.completed]: "completed"
}

const Responsible = {
	all: 1,
	me: 2
}

const TemperatureGroupingType = {
	group: 1,
	segment: 2,
	goal: 3
}

const GoalProgressStatus = {
	offTrack: 0,
	average: 1,
	onTrack: 2
}

const ActionTemperatureType = {
	initial: 1,
	halfway: 2,
	finish: 3
}

const ActionStatus = {
	all: 0,
	current: 1,
	completed: 2,
	planned: 2
}

const GroupSelectorGroupingType = {
	groups: 1,
	segments: 2
}

const GoalSelectorGroupingType = {
	users: 1,
	groups: 2
}

const IconFontFamily = {
	actionIcon: "wttips",
	lineIcon: "line-icons",
	icomoon: "icomoon",
	fontasticIcon: "fontastic-icons",
}

const StatisticsMainTab = {
	responseRate: 1,
	answered: 2,
	usage: 3
}

const GroupType = {
	office: 1,
	department: 2,
	areaOfCompetence: 3,
	group: 4,
	region: 5,
	domain: 6,
	country: 7,
	all: 8
}

const InsightActionType = {
	all: 1,
	withAction: 2,
	withoutAction: 3
}

const StatisticsStatus = {
	all: 0,
	completed: 1,
	notCompleted: 3
}

const ChartType = {
	liquidDoughnutChart: 1,
	lineChart: 2,
	overview: 3,
	doughnutChart: 4,
	answersPerQuestion: 5,
	barChartCompare: 6
}

const QuestionEscalate = {
	temperature: 0,
	talk: 1
}

const DashboardTemperatureType = {
	temperature: 0,
	enps: 1,
	hme: 2
}

const TemperatureTypeScale = {
	temperature: { min: 0, max: 10 },
	enps: { min: -100, max: 100 },
	hme: { min: 0, max: 100 }
}

const MilestoneWizardSteps = {
	createMilestone: 0,
	editMilestone: 1
}

const QuestionDefaultStyles = {
	smileySize: "50px"
}

const ConfirmationWindowType = {
	hot: 0,
	warm: 1,
	cold: 2
}

const GoalFramework = {
	smart: 0,
	okr: 1
};
const GroupStatus = {
	deleted: 0,
	active: 1
}


const PraiseStatisticsTabUserActivity = {
	praisesByBadge: 1,
	praisesByGroup: 2,
	praisesByMonth: 3
};

const QuestionCounterMinValue = 6;

const TabTipsModal = {
	aboutBullying: 0,
	suggestions: 1,
};

const ActionType = {
	actionPlan: 1,
	quickAction: 2,
};

const ActionTaskCategoryType = {
	suggestions: 0,
	custom: 1,
	feedback: 2,
	inspiration: 3
};

const QuestionAdditionalStrategy = {
	noStrategy: 0,
	negativeDiscrimination: 1,
	negativeBullying: 2
};

const ExcludedReasonEnum = {
	inactiveForQuestionBatch: 1,
	excludedFromQuestionMailings: 2,
	hasActiveSnooze: 3,
	allGroupsExcludedFromQuestionMailings: 4,
	hasNotStarted: 5
};

const CompareAgainstItemType = {
	questionType: 0,
	questionCategory: 1,
	enps: 3,
	other: 4,
	smartIndex: 5
};

//TemperatureCategoryFactory
const TemperatureCategory = {
	temperature: "Total",
	enps: "Enps"
};

const UserStatus = {
	inactive: 0,
	active: 1,
	deleted: 2,
	pendingInvitation: 3,
	pendingRemoval: 4,
	impersonatedAsOperator: 5
};

const ListViewColumnTypes = {
	name: 0,
	role: 1,
	group: 2,
	status: 3,
	dotsMenu: 4,
	icons: 5,
};

const UserRole = {
	user: 1,
	support: 2,
	systemAdministrator: 3,
	groupAdministrator: 4,
	winningtempAdministrator: 5,
};

const ContactType = {
	phone: 1,
	email: 2,
	webMeeting: 3,
	physicalMeeting: 4,
	walkAndTalk: 5,
};

const CoachingSessionInviteStatus = {
	pending: 1,
	confirmed: 2,
	declined: 3,
	waitingForAdmin: 4,
};

const FollowupStatus = {
	invited: 1,
	planned: 2,
	notVerified: 3,
	verified: 4,
	declined: 5,
};

const FontFamily = {
	tTCommons: "TT Commons",
	tTCommonsMedium: "TT Commons Medium",
	tTCommonsMediumItalic: "TT Commons Medium Italic",
	tTCommonsDemiBold: "TT Commons DemiBold",
}

const ContactTypeIcon = {
	[ContactType.phone]: "phone",
	[ContactType.email]: "e-mail",
	[ContactType.webMeeting]: "web-meeting",
	[ContactType.physicalMeeting]: "physical-meeting",
	[ContactType.walkAndTalk]: "walk-and-talk",
}

const CoachingSessionType = {
	followUp: 0,
	milestone: 1,
	requestFollowUp: 2,
}

const CoachingSessionMemberType = {
	partOfTheMeeting: 1,
	personInFocus: 2,
}

const CoachingSessionTypeFilter = {
	followUp: 0,
	milestone: 1,
	bothSelected: 2,
}

const GradeType = {
	default: 0,
	hme: 1,
	enps: 2,
}

const BatchExecutionState = {
	completed: 1,
	running: 2,
	failed: 3,
	future: 4,
}

const ReadCommentStatus = {
	Read: 0,
	UnreadComment: 1,
	UnreadConversation: 2,
}

const GradeTypeScale = {
	enps: { min: -100, max: 100 },
	hme: { min: 0, max: 100 },
}

const GoalType = {
	objective: 0,
	keyResult: 1,
}

const RecipientsType = {
	responsible: 1,
	all: 2,
}

const OnboardingTaskType = {
	inviteThreeUsers: 1,
	setDateForQuestionMailing: 2,
	answerQuestioMailing: 3,
	answerDailyQuestionThreeTimes: 4,
	sendPraisesToTwoCollegues: 5,
	exploreInsights: 6,
	uploadProfilePictire: 7,
	createAction: 8,
	createGroup: 9,
	exportPdfReportFromDashboard: 10,
	watchIntroduction: 11,
}

const ActivityStatus = {
	workInProgress: 1,
	planned: 2,
	other: 3,
	finished: 4,
}

const FreeTrialResponsibleIntroductionLearningId = "232b454d-0e7f-4460-9a12-aad700b02813";

const GoalMeasurement = {
	manual: 0,
	underlyingTasks: 1,
	underlyingGoals: 2
}

export {
	LicenseType,
	AnswerResponseSelectorFilters,
	PeriodEnum,
	InsightsContainerTab,
	InsightTypes,
	AnswerPerQuestionLabels,
	InsightIndexTypes,
	InsightTrendType,
	UserImportStages,
	Genders,
	PasswordGenerators,
	OnboardingStages,
	BatchExecutionType,
	DayOfWeek,
	RecurrenceDays,
	WidgetChartType,
	WidgetChartSize,
	ActivityType,
	QuestionContextType,
	MetricType,
	InsightPeriod,
	QuestionSurveyType,
	AnalyzeGrouping,
	TemperatureAverageIndexType,
	TemperatureType,
	QuestionCommentViewMode,
	OptionalAnswerActivationType,
	ResponseRateSorting,
	Grade,
	GradeHme,
	GradeEnps,
	GradeConfig,
	GradeHMEConfig,
	CommentGradeConfig,
	CommentGradeHMEConfig,
	CommentGradeEnpsConfig,
	TemperatureCommentGroupFilter,
	TabUserActivity,
	MainTabUserActivity,
	GoalStatisticsTabUserActivity,
	SendInvitationSelectionType,
	InvitationStatuses,
	MembershipType,
	MembershipRole,
	ConversationMessageType,
	SegmentType,
	TemperatureTemplate,
	QuestionTemplate,
	LineChartColors,
	GoalStatus,
	GoalStatusLabel,
	Responsible,
	TemperatureGroupingType,
	GoalProgressStatus,
	ActionTemperatureType,
	ActionStatus,
	GroupSelectorGroupingType,
	IconFontFamily,
	StatisticsMainTab,
	GroupType,
	InsightActionType,
	StatisticsStatus,
	ChartType,
	QuestionEscalate,
	DashboardTemperatureType,
	TemperatureTypeScale,
	MilestoneWizardSteps,
	QuestionDefaultStyles,
	GoalFramework,
	GroupStatus,
	PraiseStatisticsTabUserActivity,
	TabTipsModal,
	ConfirmationWindowType,
	QuestionCounterMinValue,
	ActionType,
	GoalSelectorGroupingType,
	ActionTaskCategoryType,
	QuestionAdditionalStrategy,
	ExcludedReasonEnum,
	CompareAgainstItemType,
	TemperatureCategory,
	UserStatus,
	ListViewColumnTypes,
	UserRole,
	ContactType,
	CoachingSessionInviteStatus,
	FollowupStatus,
	FontFamily,
	ContactTypeIcon,
	CoachingSessionType,
	CoachingSessionMemberType,
	CoachingSessionTypeFilter,
	GradeType,
	GradeENPSConfig,
	BatchExecutionState,
	ReadCommentStatus,
	GradeTypeScale,
	GoalType,
	RecipientsType,
	OnboardingTaskType,
	FreeTrialResponsibleIntroductionLearningId,
	ActivityStatus,
	GoalMeasurement,
};