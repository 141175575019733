import React, { Component } from "react";
import PropTypes from "prop-types";

class Timer extends Component {
    onTick = () => {
        var shouldCancel = this.props.onTick();

        if (shouldCancel) {
            clearInterval(this.interval);
        }
    }

    componentDidMount() {
        this.interval = setInterval(this.onTick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

Timer.propTypes = {
    onTick: PropTypes.func
}

export default Timer;