import jwtDecode from 'jwt-decode';

const removeAuth = () => {
    localStorage.removeItem('access_token');
};

const setAuth = (token) => {
    localStorage.setItem('access_token', token);
};

const getAuth = () => {
    let token = localStorage.getItem('access_token') || null;

    if (!token) return null;

    var data = jwtDecode(token);

    return {
        token: token,
        name: data.unique_name,
        id: data.ID,
    };
}

export
{
    getAuth, setAuth, removeAuth
}