import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SvgIcon from "Controls/SvgIcon";
import { spacingIndex, spacing } from "../shared/spacing";
import { colors, transition, shadow } from "../shared/theme";
import font from "../shared/font";

const Wrapper = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: ${spacingIndex * 2}px ${spacingIndex * 1.5}px ${spacingIndex * 2}px ${spacingIndex * 2}px;
	width: ${(props) => props.width || `${spacingIndex * 32}px`};
	height: ${spacingIndex * 5}px;
	background-color: ${colors.white};
	box-shadow: ${(props) => (props.active ? shadow.greenOutline : "none")};
	border: 1px solid ${(props) => (props.active ? colors.jungle : props.error ? colors.red : colors.metal)};
	box-sizing: border-box;
	border-radius: 6px;
	transition: ${transition.main};
	cursor: pointer;
	${spacing}
	&:hover {
		box-shadow: ${(props) => (props.active ? shadow.greenOutline : shadow.grayOutline)};
	}
	&:focus {
		outline: none;
		border: 1px solid ${({ error }) => (error ? colors.red : colors.jungle)};
		box-shadow: ${({ error }) => (error ? shadow.redOutline : shadow.greenOutline)};
	}
	&:disabled {
		border: 1px solid ${colors.smoke};
		background-color: ${colors.smoke};
		cursor: default;
		&:hover {
			box-shadow: none;
		}
	}
`;

const Label = styled.span`
	display: block;
	user-select: none;
	color: ${(props) => (props.disabled ? colors.nickel : colors.onyx)};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	${font("normalRegular")}
`;

const DropdownSelectHeader = (props) => {
	return (
		<Wrapper type="button" data-test={props.dataTest} {...props}>
			<Label disabled={props.disabled}>{props.label}</Label>
			<SvgIcon icon={props.icon || "chevron-down-16"} size="16px" />
		</Wrapper>
	);
};

DropdownSelectHeader.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	dataTest: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
};

DropdownSelectHeader.defaultProps = {
	disabled: false,
	label: "Select",
	icon: null,
};

export default DropdownSelectHeader;
