import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

const Input = styled.input`
    border: none;
    padding: 15px;
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    font-weight: 300;
    line-height: 50px;
    text-align: center;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    font-size: ${props => props.fontSize} !important;
    padding-left: ${props => props.paddingLeft} !important;
    letter-spacing: ${props => props.letterSpacing} !important;
    &:focus {
        padding-left: 15px !important;
    }
`;

class Code extends Component {
	constructor(props) {
		super(props);

		this.input = React.createRef();

		this.state = {
			focused: false
		}
	}

	onBlur = () => {
		this.setState({ focused: false });
	}

	onFocus = () => {
		this.setState({ focused: true });
	}

	onKeyDown = (event) => {
		if (event.keyCode === 13) {
			this.input.current.blur();
			this.props.onEnter();
			event.preventDefault();
		}
	}

	getInputStyles = () => {
		const valueLength = this.props.value ? this.props.value.length : 0;

		switch (true) {
			case (valueLength > 0 && valueLength <= 4):
				return ({
					fontSize: "34px",
					paddingLeft: "15px",
					letterSpacing: "43px"
				});

			case (valueLength > 4 && valueLength <= 6):
				return ({
					fontSize: "26px",
					paddingLeft: "15px",
					letterSpacing: "28px"
				});

			case (valueLength > 6 && valueLength <= 8):
				return ({
					fontSize: "22px",
					paddingLeft: "15px",
					letterSpacing: "18px"
				});

			case (valueLength > 8 && valueLength <= 100):
				return ({
					fontSize: "20px",
					paddingLeft: "15px",
					letterSpacing: "12px"
				});

            default:
				return ({
					fontSize: "34px",
					paddingLeft: "50px",
					letterSpacing: "43px"
				});
		}
	}

	render() {
		const inputStyles = this.getInputStyles();

		return (
			<Input
				autoFocus
				placeholder={this.state.focused ? "" : this.props.t("Code")}
				ref={this.input}
				{...inputStyles}
				minLength="4"
				maxLength="100"
				type="text"
				value={this.props.value}
				onKeyDown={this.onKeyDown}
				onBlur={this.onBlur}
				onFocus={this.onFocus}
				onChange={this.props.onChange} />
		);
	}
}

Code.propTypes = {
	code: PropTypes.string,
	culture: PropTypes.string,
	onChange: PropTypes.func,
	onEnter: PropTypes.func
}

export default withTranslation()(Code);