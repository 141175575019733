/**
 *
 *  get() // Returns value of internal Guid. If no guid has been specified, returns a new one (value is then stored internally).
 *  newValue() // Returns a new Guid and sets it's value internally.
 *  empty() // Returns an empty Guid 00000000-0000-0000-0000-000000000000.
 *  isEmpty() // Returns boolean. True if empty/undefined/blank/null.
 *  isValid() // Returns boolean. True valid guid, false if not.
 *  set() // Retrns Guid. Sets Guid to user specified Guid, if invalid, returns an empty guid.
 *
 */

const guid = {
    value: null,

    set(val) {
        let value = null;
        if (val) {
            if (this.isValid(val)) {
                value = val;
            } else {
                value = this.empty();
            }
        }

        this.value = value;
    },

    get() {
        return this.value || this.newValue();
    },

    empty() {
        return "00000000-0000-0000-0000-000000000000";
    },

    isEmpty(gid) {
        return !gid || gid === this.empty();
    },

    isValid(value) {
        const regExp = new RegExp("\\b(?:[A-F0-9]{8})(?:-[A-F0-9]{4}){3}-(?:[A-F0-9]{12})\\b");
        return regExp.exec(value) != null;
    },

    newValue() {
        const res = [];
        const rgx = new RegExp("[2345]");
        let hv = null;

        for (let i = 0; i < 8; i++) {
            hv = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            if (rgx.exec(i.toString()) != null) {
                if (i === 3) { hv = "6" + hv.substr(1, 3); }
                res.push("-");
            }
            res.push(hv.toUpperCase());
        }

        const value = res.join("");
        this.value = value;

        return value;
    }
}

export default guid;