// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import { TemperatureTemplate, QuestionDefaultStyles, QuestionCounterMinValue } from "Enums";
import { heapTrack } from "Helpers";
import { SmileyHme } from "Controls/Smiley";
import QuestionWrapper from "./QuestionWrapper";
import QuestionComment from "./QuestionComment";
import FeedbackQuestionRow from "./FeedbackQuestionRow";
import FeedbackModule from "./FeedbackModule";
import QuestionMinified from "./QuestionMinified";
import QuestionCounter from "./QuestionCounter";

class QuestionHme extends PureComponent {
	constructor(props) {
		super(props);

		this.smilesConfig = this.getSmilesConfig();
		this.isWithQuestionCounter = this.props.questionsCount >= QuestionCounterMinValue;
	}

	getSmilesConfig = () => {
		return [
			{
				gradeValue: 1,
				text: Resources[this.props.culture].stronglyDisagree,
				color: Colors.smileyStronglyDisagree,
			},
			{
				gradeValue: 2,
				text: "",
				color: Colors.smileyDisagree,
			},
			{
				gradeValue: 3,
				text: "",
				color: Colors.smileyNeutral,
			},
			{
				gradeValue: 4,
				text: "",
				color: Colors.smileyAgree,
			},
			{
				gradeValue: 5,
				text: Resources[this.props.culture].stronglyAgree,
				color: Colors.smileyStronglyAgree,
			},
		];
	};

	onGradeChange = (grade) => {
		heapTrack(grade ? "[Survey] Select smiley" : "[Survey] Change survey answer");
		this.props.onChange({ ...this.props.question, grade: grade });
	};

	onCommentChange = (value) => {
		heapTrack("[Survey] Add comment");
		this.props.onChange({ ...this.props.question, comment: value });
	};

	onVisibleForImmediateManagerChange = () => {
		this.props.onChange({
			...this.props.question,
			visibleForImmediateManager: !this.props.question.visibleForImmediateManager,
		});
	};

	onAllowAiAssistedRepliesChange = () => {
		this.props.onChange({
			...this.props.question,
			allowAiAssistedReplies: !this.props.question.allowAiAssistedReplies,
		});
	};

	onExpandQuestionClick = () => {
		this.props.onActiveQuestionChange &&
			this.props.onActiveQuestionChange(this.props.question.questionId);
	};

	renderQuestion = (hasAnsweredQuestion, isMinifiedWithoutComment, isMinifiedWithComment) => {
		const configItem = this.smilesConfig.find((x) => x.gradeValue === this.props.question.grade);

		if (isMinifiedWithoutComment || isMinifiedWithComment) {
			return (
				<QuestionMinified
					isWithQuestionCounter={this.isWithQuestionCounter}
					isWithComment={!this.props.isCommentDisabled}
					isWithShake={isMinifiedWithoutComment}
					questionType={TemperatureTemplate.hme}
					grade={this.props.question.grade}
					gradeColor={configItem && configItem.color}
					questionPadding={this.props.questionPadding}
					questionPaddingXS={this.props.questionPaddingXS}
					questionText={this.props.question.description}
					questionColor={this.props.questionColor}
					questionFontSize={this.props.questionFontSize}
					questionFontSizeXS={this.props.questionFontSizeXS}
					questionFontWeight={this.props.questionFontWeight}
					questionTextPadding={this.props.questionTextPadding}
					questionTextAlign={this.props.questionTextAlign}
					culture={this.props.culture}
					onGradeClick={this.onGradeChange}
					onExpandQuestionClick={this.onExpandQuestionClick}
				/>
			);
		}

		return (
			<FeedbackQuestionRow
				isWithQuestionCounter={this.isWithQuestionCounter}
				questionPadding={this.props.questionPadding}
				questionPaddingXS={this.props.questionPaddingXS}
				questionFontSize={this.props.questionFontSize}
				questionFontSizeXS={this.props.questionFontSizeXS}
				questionFontWeight={this.props.questionFontWeight}
				questionTextAlign={this.props.questionTextAlign}
				questionTextPadding={this.props.questionTextPadding}
				questionColor={this.props.questionColor}
				question={this.props.question}
				culture={this.props.culture}
				backgroundColor={this.props.backgroundColor}>
				<FeedbackModule
					padding={this.props.smileysPadding}
					justifyContent={this.props.smileysJustifyContent}
					backgroundColor={this.props.smileysBackgroundColor}>
					{(!hasAnsweredQuestion || this.props.isCommentDisabled) &&
						this.smilesConfig.map((item, index) => (
							<SmileyHme
								key={index + "" + item.gradeValue}
								grade={item.gradeValue}
								text={item.text}
								iconName={item.iconName}
								color={item.color}
								culture={this.props.culture}
								margin={index === this.smilesConfig.length - 1 ? "0" : "0 10px 0 0"}
								smileySize={this.props.smileySize || QuestionDefaultStyles.smileySize}
								smileySizeXS={this.props.smileySizeXS}
								isReadOnly={this.props.isReadOnly}
								isActive={this.props.question.grade === item.gradeValue}
								onClick={this.onGradeChange}
							/>
						))}
					{!this.props.isCommentDisabled && hasAnsweredQuestion && (
						<QuestionComment
							isCommentDisabled={this.props.isCommentDisabled}
							isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
							isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
							allowAiAssistedReplies={this.props.question.allowAiAssistedReplies}
							questionType={TemperatureTemplate.hme}
							culture={this.props.culture}
							grade={this.props.question.grade}
							questionText={this.props.question.description}
							color={configItem.color}
							onChange={this.onCommentChange}
							questionPadding={this.props.questionPadding}
							questionPaddingXS={this.props.questionPaddingXS}
							onVisibleForImmediateManagerChange={this.onVisibleForImmediateManagerChange}
							onAllowAiAssistedRepliesChange={this.onAllowAiAssistedRepliesChange}
							isVisibleForImmediateManager={this.props.question.visibleForImmediateManager}
							comment={this.props.question.comment}
							onGradeClick={this.onGradeChange}
						/>
					)}
				</FeedbackModule>
			</FeedbackQuestionRow>
		);
	};

	render() {
		const hasAnsweredQuestion = !!this.props.question.grade || this.props.question.grade === 0;
		const isActiveQuestion = this.props.activeQuestionId === this.props.question.questionId;

		const isMinifiedWithoutComment = this.props.isCommentDisabled && hasAnsweredQuestion;
		const isMinifiedWithComment =
			!this.props.isCommentDisabled &&
			!isActiveQuestion &&
			hasAnsweredQuestion &&
			!this.props.question.comment;

		return (
			<QuestionWrapper
				data-test="question-hme"
				ref={this.props.feedbackQuestionRef}
				padding={this.props.wrapperPadding}
				margin={this.props.wrapperMargin}
				border={this.props.wrapperBorder}>
				{this.isWithQuestionCounter && (
					<QuestionCounter
						isMinified={isMinifiedWithoutComment || isMinifiedWithComment}
						culture={this.props.culture}
						questionTextPadding={this.props.questionTextPadding}
						questionIndex={this.props.questionIndex}
						questionsCount={this.props.questionsCount}
						color={this.props.questionColor}
					/>
				)}
				{this.renderQuestion(hasAnsweredQuestion, isMinifiedWithoutComment, isMinifiedWithComment)}
			</QuestionWrapper>
		);
	}
}

QuestionHme.propTypes = {
	isCommentDisabled: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	questionIndex: PropTypes.number,
	questionsCount: PropTypes.number,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	culture: PropTypes.string,
	questionColor: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionFontWeight: PropTypes.string,
	questionTextAlign: PropTypes.string,
	questionTextPadding: PropTypes.string,
	backgroundColor: PropTypes.string,
	smileySize: PropTypes.string,
	smileySizeXS: PropTypes.string,
	smileysPadding: PropTypes.string,
	smileysJustifyContent: PropTypes.string,
	smileysBackgroundColor: PropTypes.string,
	wrapperPadding: PropTypes.string,
	wrapperMargin: PropTypes.string,
	wrapperBorder: PropTypes.string,
	question: PropTypes.object,
	onChange: PropTypes.func,
	onActiveQuestionChange: PropTypes.func,
};

export default QuestionHme;
