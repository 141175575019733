import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class Batch extends Component {
    getQuote = () => {
        return this.props.batch.questions[0].description;
    }

    getDescription = () => {
        return this.props.batch.isTest
			? `${this.props.t("Test")}: ${this.props.batch.description}`
            : this.props.batch.description;
    }

    render() {
        return (
            <li className="item pointer" onClick={() => this.props.onClick(this.props.batch)}>
                <div className="description">
                    <div className="time">{this.props.batch.createdText}</div>
                    <div className="title">{this.getDescription()}</div>
					{
						this.props.batch.questions.length > 0 &&
						<div className="quote"><span className="icon icn-quote"></span>{this.getQuote()}</div>
					}
                </div>
                <div className="more">
                    <a href="#" onClick={() => this.props.onClick(this.props.batch)}>
						{this.props.t("Answer")} <span className="icon icn-chevron-right"></span>
                    </a>
                </div>
            </li>
        );
    }
}

Batch.propTypes = {
    culture: PropTypes.string,
    batch: PropTypes.object,
    onClick: PropTypes.func
}

export default withTranslation()(Batch);