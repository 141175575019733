// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import ContentWrapper from "Controls/ContentWrapper";
import Text from "Controls/Text";

const AlertComponent = (props) => {
    let styles = {
        borderRadius: "3px",
        padding: "15px",
        fontSize: "14px"
    };

    switch (true) {
        case props.warning:
            styles = {
                ...styles,
                color: "#8a6d3b",
                backgroundColor: "#fcf8e3",
                border: "1px solid #faebcc"
            };
            break;
        case props.danger:
            styles = {
                ...styles,
                color: "#a94442",
                backgroundColor: "#f2dede",
                border: "1px solid #ebccd1"
            };
            break;
        default:
            styles = {
                ...styles,
                color: "#3c763d",
                backgroundColor: "#dff0d8",
                border: "1px solid #d6e9c6"
            };
            break;
    }

    return (
        <ContentWrapper {...styles} {...props}>
            <Text color="inherit" fontSize="inherit">{props.text}</Text>
        </ContentWrapper>
    );
}

AlertComponent.propTypes = {
    text: PropTypes.string
}

export default AlertComponent;