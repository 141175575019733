const IDLE_TIMEOUT_IN_MS = 2 * 60 * 1000;
const IDLE_EVENTS = ["mousemove", "mousedown", "click", "scroll", "keypress", "touchstart"];

let idleStart = null;
let interval = null;

const clearIdleInterval = () => window.clearInterval(interval);
const setStartTime = () => idleStart = Date.now();

const checkIdleTime = (logout) => {
	if (Date.now() - idleStart >= IDLE_TIMEOUT_IN_MS) {
		clearIdleInterval();
		logout();
	}
}

const idleAutoLogout = (logout) => {
	IDLE_EVENTS.forEach((event) => {
		window.addEventListener(event, setStartTime);
	});

	setStartTime();
	interval = window.setInterval(() => checkIdleTime(logout), 1000);
}

export { idleAutoLogout, clearIdleInterval };