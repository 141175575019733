import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DropdownComponentHOC from "./DropdownComponentHOC";
import DropdownComponentContent from "./DropdownComponentContent";
import DropdownButtonComponent from "./DropdownButtonComponent";
import DropdownListComponent from "./DropdownListComponent";
import DropdownComponentWrapper from "./DropdownComponentWrapper";
import DropdownComponentMobileHeader from "./DropdownComponentMobileHeader";
import DropdownFooterComponent from "./DropdownFooterComponent";

const MobileButtonWrapper = styled.div`
	display: none;
	@media (max-width: 767px) {
		display: block;
		min-height: 40px;
	}
`;

class DropdownComponent extends PureComponent {
	renderContent = () => {
		return (
			<React.Fragment>
				<DropdownListComponent
					dataTest={this.props.dataTest}
					maxHeightXS={this.props.isWithSearch ? "calc(60vh - 187px)" : "calc(60vh - 130px)"}
					isFullHeightXS={this.props.isDropdownFullHeightXS}
					isFilterLoading={this.props.isFilterLoading}
					curvedCheckmark={this.props.curvedCheckmark}
					listMargin={this.props.dropdownListMargin}
					descriptionBeforeItems={this.props.descriptionBeforeItems}
					isPreventExpandedForFilter={this.props.isPreventExpandedForFilter}
					dropdownMaxHeight={this.props.dropdownMaxHeight}
					isMultiSelect={this.props.isMultiSelect}
					isWithSearch={this.props.isWithSearch}
					highlightSelectedItemColor={this.props.highlightSelectedItemColor}
					liFontWeight={this.props.liFontWeight}
					itemFontSize={this.props.liFontSize}
					isWithButton={this.props.isWithButton}
					filterText={this.props.searchText}
					selected={this.props.selectedArray}
					expandedIds={this.props.expandedIds}
					disabledIds={this.props.disabledIds}
					dataLoadingId={this.props.dataLoadingId}
					onGroupClick={this.props.onGroupClick}
					onSelectGroupClick={this.props.onSelectGroupClick}
					onSelectItemClick={this.props.onSelectItemClick}
					renderCustomItem={this.props.renderCustomItem}
					renderCustomItemParent={this.props.renderCustomItemParent}
					hoverBackgroundColor={this.props.hoverItemBackgroundColor}
					checkmarkColor={this.props.checkmarkColor}
					checkmarkHiddenForNotCheckedItems={this.props.checkmarkHiddenForNotCheckedItems}
					notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
					culture={this.props.culture}
					options={this.props.options}
				/>

				{this.props.isWithButton && (
					<DropdownButtonComponent
						label={this.props.buttonLabel}
						onButtonClick={this.props.onSearchButtonClick}
					/>
				)}

				<MobileButtonWrapper>
					<DropdownButtonComponent
						label="Close"
						onButtonClick={() => this.props.onCloseClick && this.props.onCloseClick()}
					/>
				</MobileButtonWrapper>
			</React.Fragment>
		);
	};

	render() {
		return (
			<DropdownComponentWrapper
				position={this.props.position}
				top={this.props.top}
				isFullHeightXS={this.props.isDropdownFullHeightXS}
				paddingTop={this.props.wrapperPaddingTop}
				dropdownHeight={this.props.dropdownHeight && this.props.dropdownHeight + "px"}
				maxHeight={this.props.wrapperMaxHeight}
				isWithTabs={this.props.tabs && !!this.props.selectedTab}
				right={this.props.wrapperRightValueImportant || this.props.wrapperRightValue}
				bottom={this.props.expandUnder ? null : this.props.wrapperBottomValue}
				ref={this.props.setWrapperElement}
				border={this.props.border}
				maxWidth={this.props.maxWidth}
				borderRadius={this.props.borderRadius}
				minWidth={this.props.dropdownMinWidth}>
				<DropdownComponentMobileHeader
					isWithSearch={this.props.isWithSearch}
					selectoorLabel={this.props.selectorLabel}
					tabs={this.props.tabs}
					onCloseClick={this.props.onCloseClick}
				/>

				<DropdownComponentContent
					dataTestItem={this.props.dataTestItem}
					isLoading={this.props.isLoading}
					isFilterLoading={this.props.isFilterLoading}
					curvedCheckmark={this.props.curvedCheckmark}
					isPreventExpandedForFilter={this.props.isPreventExpandedForFilter}
					isDropdownFullHeightXS={this.props.isDropdownFullHeightXS}
					isMultiSelect={this.props.isMultiSelect}
					isWithSearch={this.props.isWithSearch}
					isWithButton={this.props.isWithButton}
					isWithAdittionalFlags={!!this.props.adittionalFlags}
					dropdownMaxHeight={this.props.dropdownMaxHeight}
					dropdownListMargin={this.props.dropdownListMargin}
					highlightSelectedItemColor={this.props.highlightSelectedItemColor}
					hoverItemBackgroundColor={this.props.hoverItemBackgroundColor}
					checkmarkColor={this.props.checkmarkColor}
					checkmarkHiddenForNotCheckedItems={this.props.checkmarkHiddenForNotCheckedItems}
					notHighlightSelectedItemsText={this.props.notHighlightSelectedItemsText}
					liFontWeight={this.props.liFontWeight}
					liFontSize={this.props.liFontSize}
					searchText={this.props.searchText}
					dataLoadingId={this.props.dataLoadingId}
					culture={this.props.culture}
					buttonLabel={this.props.buttonLabel}
					selectedArray={this.props.selectedArray}
					expandedIds={this.props.expandedIds}
					disabledIds={this.props.disabledIds}
					options={this.props.options}
					onGroupClick={this.props.onGroupClick}
					onSelectGroupClick={this.props.onSelectGroupClick}
					onSelectItemClick={this.props.onSelectItemClick}
					renderCustomItem={this.props.renderCustomItem}
					renderCustomItemParent={this.props.renderCustomItemParent}
					onSearchButtonClick={this.props.onSearchButtonClick}
					emptySearchLabel={this.props.emptySearchLabel}
					onCloseClick={this.props.onCloseClick}
					isSelectedWithoutChildren={this.props.isSelectedWithoutChildren}
					isWithHighlightBar={this.props.isWithHighlightBar}
					paddingVertical={this.props.paddingVertical}
				/>
				{this.props.totalCount > 0 && (
					<DropdownFooterComponent
						totalCount={this.props.totalCount}
						currentCount={this.props.options.length}
						culture={this.props.culture}
					/>
				)}
			</DropdownComponentWrapper>
		);
	}
}

DropdownComponent.propTypes = {
	isLoading: PropTypes.bool,
	isDataLoaded: PropTypes.bool,
	isDropdownFullHeightXS: PropTypes.bool,
	isShouldBeClosed: PropTypes.bool,
	isMultiSelect: PropTypes.bool,
	isWithSearch: PropTypes.bool,
	isWithButton: PropTypes.bool,
	isWithAutoFocus: PropTypes.bool,
	isPreventExpandedForFilter: PropTypes.bool,
	checkmarkHiddenForNotCheckedItems: PropTypes.bool,
	notHighlightSelectedItemsText: PropTypes.bool,
	curvedCheckmark: PropTypes.bool,
	expandUnder: PropTypes.bool,
	dropdownHeight: PropTypes.number,
	mainViewHeight: PropTypes.number,
	searchTextMinLength: PropTypes.number,
	dropdownMaxHeight: PropTypes.string,
	searchText: PropTypes.string,
	buttonLabel: PropTypes.string,
	selectorLabel: PropTypes.string,
	culture: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	dataLoadingId: PropTypes.string,
	highlightSelectedItemColor: PropTypes.string,
	liFontWeight: PropTypes.string,
	liFontSize: PropTypes.string,
	dropdownMinWidth: PropTypes.string,
	descriptionBeforeItems: PropTypes.string,
	emptySearchLabel: PropTypes.string,
	wrapperRightValueImportant: PropTypes.string,
	wrapperPaddingTop: PropTypes.string,
	dropdownListMargin: PropTypes.string,
	hoverItemBackgroundColor: PropTypes.string,
	checkmarkColor: PropTypes.string,
	border: PropTypes.string,
	position: PropTypes.string,
	top: PropTypes.string,
	borderRadius: PropTypes.string,
	additionalItemFontSize: PropTypes.string,
	additionalItemPadding: PropTypes.string,
	dataTestItem: PropTypes.string,
	selectedTab: PropTypes.object,
	setWrapperElement: PropTypes.object,
	tabs: PropTypes.array,
	options: PropTypes.array,
	selectedArray: PropTypes.array,
	expandedIds: PropTypes.array,
	disabledIds: PropTypes.array,
	adittionalFlags: PropTypes.array,
	onFilterSearchTextChange: PropTypes.func.isRequired,
	onSearchButtonClick: PropTypes.func.isRequired,
	onGroupClick: PropTypes.func,
	onSelectGroupClick: PropTypes.func,
	onSelectItemClick: PropTypes.func,
	renderCustomItem: PropTypes.func,
	renderCustomItemParent: PropTypes.func,
	onCloseClick: PropTypes.func,
	onTabClick: PropTypes.func,
	onTabClickHandler: PropTypes.func,
	onAdittionalFlagChange: PropTypes.func,
	totalCount: PropTypes.number,
	isWithHighlightBar: PropTypes.bool,
	paddingVertical: PropTypes.number,
};

export default DropdownComponentHOC(DropdownComponent);
