// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import Button from "Controls/Button";
import Text from "Controls/Text";
import QuestionsFixedWrapper from "./QuestionsFixedWrapper";
import QuestionAnonymousLabel from "./QuestionAnonymousLabel";
import QuestionChristmasThemeToggle from "./QuestionChristmasThemeToggle";
import CustomSubject from "./CustomSubject";

class QuestionSubmit extends PureComponent {
	render() {
		return (
			<QuestionsFixedWrapper
				bottom="0"
				backgroundColor={this.props.backgroundColorXS || Colors.questionWindowBackgroundXS}
				backgroundColorXS={this.props.backgroundColorXS || Colors.questionWindowBackgroundXS}>
				<ContentWrapper padding="5px 10px 10px">
					{this.props.isGeneralCommentEnabled && (
						<CustomSubject
							{...{
								culture: this.props.culture,
								generalQuestion: this.props.generalQuestion,
								onGeneralQuestionChange: this.props.onGeneralQuestionChange,
								isAllowAiAssistedRepliesVisible: this.props.isAllowAiAssistedRepliesVisible,
								isHideCommentFromManagerVisible: this.props.isHideCommentFromManagerVisible,
							}}
						/>
					)}
					{this.props.isAllAnswered && (
						<Button
							aria-label={Resources[this.props.culture].send}
							data-track-id={this.props.submitTrackId}
							data-test="submit-btn"
							width="100%"
							color="#fff"
							textAlign="center"
							lineHeight="48px"
							fontSize="21px"
							fontWeight="600"
							margin="0 0 10px"
							disabled={!this.props.isEnabled}
							backgroundColor={Colors.buttonPurple}
							hoverBackgroundColor={Colors.buttonPurpleHighlighted}
							onClick={this.props.onSubmit}>
							{Resources[this.props.culture].send}
						</Button>
					)}
					{!this.props.isAllAnswered && (
						<Text
							display="block"
							margin="12px 0 22px"
							color={Colors.white}
							textAlign="center"
							fontSize="16px"
							fontWeight="600">
							{
								Resources[this.props.culture][
								this.props.isSingleQuestion
									? "youHaveToAnswerTheQuestion"
									: "youHaveToAnswerAllQuestions"
								]
							}
						</Text>
					)}
					<QuestionAnonymousLabel
						isCommentDisabled={this.props.isCommentDisabled}
						color={this.props.anonymousLabelColorXS}
						colorXS={this.props.anonymousLabelColorXS}
						culture={this.props.culture}
					/>
					{this.props.showChristmasModeToggle && (
						<QuestionChristmasThemeToggle
							onToggleChristmasMode={this.props.onToggleChristmasMode}
							isChristmasModeEnabled={this.props.isChristmasModeEnabled}
							culture={this.props.culture}
						/>
					)}
				</ContentWrapper>
			</QuestionsFixedWrapper>
		);
	}
}

QuestionSubmit.propTypes = {
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	isSingleQuestion: PropTypes.bool,
	isCommentDisabled: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	isGeneralCommentEnabled: PropTypes.bool,
	isAllAnswered: PropTypes.bool,
	isEnabled: PropTypes.bool,
	culture: PropTypes.string,
	backgroundColor: PropTypes.string,
	backgroundColorXS: PropTypes.string,
	anonymousLabelColor: PropTypes.string,
	anonymousLabelColorXS: PropTypes.string,
	submitTrackId: PropTypes.string,
	generalQuestion: PropTypes.object,
	onSubmit: PropTypes.func,
	onGeneralQuestionChange: PropTypes.func,
};

export default QuestionSubmit;
