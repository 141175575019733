export default ({
	actions: "'\\61'",
	ongoing: "'\\62'",
	time: "'\\63'",
	attach:"'\\64'",
	block:"'\\65'",
	calendar:"'\\66'",
	clock:"'\\67'",
	cloud:"'\\68'",
	dialog:"'\\69'",
	email:"'\\6a'",
	eye:"'\\6b'",
	meeting:"'\\6c'",
	peopleAdd:"'\\6d'",
	phone:"'\\6e'",
	checkCurve:"'\\6f'",
	cross:"'\\70'",
	swap:"'\\71'",
	comment:"'\\72'",
	gift:"'\\73'",
	add:"'\\74'",
	arrRight:"'\\75'",
	calendar1:"'\\76'",
	calendar2:"'\\77'",
	cardView:"'\\78'",
	eRemove:"'\\79'",
	gCheck:"'\\41'",
	listView:"'\\42'",
	menuDots:"'\\43'",
	multiple:"'\\44'",
	network:"'\\45'",
	pathMinus:"'\\46'",
	zoomBold:"'\\47'",
	single:"'\\48'",
	segmentation:"'\\49'",
	arrowDown:"'\\4a'",
	pauseCircle:"'\\4b'",
	filter:"'\\4c'",
	sort: "'\\7a'",
	exclude: "'\\4d'",
	archeryTarget: "'\\4e'",
	cWarning: "'\\4f'",
	switchArows: "'\\50'",
	undo: "'\\51'",
	searchPlus: "'\\52'",
	searchMinus: "'\\53'",
	excludeCross: "'\\54'",
	backArrow: "'\\55'",
	lineUser: "'\\31'",
	comment1: "'\\56'",
	note: "'\\57'",
	noteLock: "'\\58'",
	cycle: "'\\59'",
	walk: "'\\5a'",
	refresh: "'\\30'",
	searchList: "'\\32'",
	chat: "'\\33'",
	calendar3: "'\\35'",
	dataLocked: "'\\34'",
	close: "'\\38'",
	edit: "'\\36'",
	switchHorizontal: "'\\37'",
	playCircle: "'\\39'",
	mailingExclude: "'\\21'",
	mailingInclude: "'\\22'",
	sendMessage: "'\\23'",
});
