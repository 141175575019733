/* eslint-disable react/jsx-props-no-spreading */
import React, { cloneElement, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import mergeRefs from "react-merge-refs";
import {
	useFloating,
	shift,
	offset,
	flip,
	useHover,
	useInteractions,
	useFocus,
	useRole,
	useDismiss,
	arrow,
	safePolygon,
} from "@floating-ui/react-dom-interactions";
import styled, { css, keyframes } from "styled-components";
import { colors } from "../shared/theme";
import Text from "../Text";

const slideIn = keyframes`
    0% {
              transform: translateY(10px);
      opacity: 0;
    }
    100% {
              transform: translateY(0);
      opacity: 1;
    }
`;

const GetBoxTheme = (theme) => {
	switch (theme) {
		case "purple":
			return colors.purple;
		case "dark":
			return colors.onyx;
		case "white":
		default:
			return colors.white;
	}
};
const GetBoxTextColor = (theme) => {
	switch (theme) {
		case "purple":
		case "dark":
			return colors.white;
		case "light":
		default:
			return colors.onyx;
	}
};
const Wrapper = styled.div`
	z-index: 99999;
	display: flex;
	position: fixed;
	width: max-content;
	height: fit-content;
	${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : null)}
	padding: ${({ isWithCustomContent }) => (isWithCustomContent ? "" : "12px")};
	border-radius: 6px;
	box-shadow: 0px 6px 28px rgba(0, 0, 0, 0.15);
	animation: ${slideIn} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	background-color: ${({ theme }) => GetBoxTheme(theme)};
	pointer-events: none;
	cursor: initial;
`;
const ArrowDown = css`
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid ${({ theme }) => GetBoxTheme(theme)};
`;

const ArrowRight = css`
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid ${({ theme }) => GetBoxTheme(theme)};
`;

const ArrowLeft = css`
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid ${({ theme }) => GetBoxTheme(theme)};
`;

const ArrowUp = css`
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid ${({ theme }) => GetBoxTheme(theme)};
`;
const GetCarretCss = (placement) => {
	switch (placement) {
		case "bottom":
			return ArrowUp;
		case "left":
			return ArrowRight;
		case "right":
			return ArrowLeft;
		case "top":
		default:
			return ArrowDown;
	}
};
const Arrow = styled.div`
	position: absolute;
	width: 0;
	height: 0;
	${({ placement }) => GetCarretCss(placement)}
`;

const Tooltip = ({
	children,
	text,
	tooltipPreferredPlacement,
	interactive,
	theme,
	customContent,
	followCursor,
	isLoading,
	maxWidth,
	closeTooltipOnClick,
	useArrow,
	customOffset,
}) => {
	const [open, setOpen] = useState(false);
	const arrowRef = useRef(null);
	const {
		x,
		y,
		reference,
		floating,
		strategy,
		context,
		middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
		placement,
	} = useFloating({
		placement: tooltipPreferredPlacement,
		open,
		onOpenChange: setOpen,
		middleware: [
			offset(customOffset || 15),
			flip(),
			shift({ padding: 16 }),
			arrow({ element: arrowRef, padding: 5 }),
		],
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context, {
			handleClose: interactive ? safePolygon() : null,
		}),
		useFocus(context),
		useRole(context, { role: "tooltip" }),
		useDismiss(context, {
			referencePointerDown: closeTooltipOnClick,
			ancestorScroll: true,
		}),
	]);

	// Preserve the consumer's ref
	const ref = useMemo(() => mergeRefs([reference, children.ref]), [reference, children]);

	if ((!text && !customContent) || isLoading) return children;

	const staticSide = {
		top: "bottom",
		right: "left",
		bottom: "top",
		left: "right",
	}[placement.split("-")[0]];

	const onClick = (e) => e.stopPropagation();

	return (
		<>
			{cloneElement(
				children,
				getReferenceProps({
					ref,
					...children.props,
					onMouseMove({ clientX, clientY }) {
						if (followCursor) {
							reference({
								getBoundingClientRect() {
									return {
										width: 0,
										height: 0,
										x: clientX,
										y: clientY,
										left: clientX,
										right: clientX,
										top: clientY,
										bottom: clientY,
									};
								},
							});
						}
					},
				})
			)}
			{open && (
				<Wrapper
					{...getFloatingProps({
						theme,
						maxWidth,
						onClick,
						ref: floating,
						isWithCustomContent: !!customContent,
						style: {
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						},
					})}>
					{text && (
						<Text {...{ color: GetBoxTextColor(theme), whiteSpace: "pre-line" }}>{text}</Text>
					)}
					{customContent && customContent}
					{useArrow && (
						<Arrow
							{...{
								placement,
								theme,
								ref: arrowRef,
								style: {
									left: arrowX != null ? `${arrowX}px` : "",
									top: arrowY != null ? `${arrowY}px` : "",
									right: "",
									bottom: "",
									[staticSide]: "-10px",
								},
							}}
						/>
					)}
				</Wrapper>
			)}
		</>
	);
};

Tooltip.propTypes = {
	tooltipPreferredPlacement: PropTypes.oneOf(["top", "right", "left", "bottom"]),
	text: PropTypes.string,
	interactive: PropTypes.bool,
	theme: PropTypes.oneOf(["light", "purple", "dark"]),
	customContent: PropTypes.element,
	followCursor: PropTypes.bool,
	isLoading: PropTypes.bool,
	maxWidth: PropTypes.string,
	closeTooltipOnClick: PropTypes.bool,
	useArrow: PropTypes.bool,
	customOffset: PropTypes.number,
};
Tooltip.defaultProps = {
	tooltipPreferredPlacement: "top",
	text: null,
	interactive: false,
	followCursor: false,
	useArrow: true,
};
export default Tooltip;
