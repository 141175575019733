// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes, { bool } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.a`
	display: ${(props) => props.display || "block"};
	width: ${(props) => props.width || "135px"};
	height: auto;
	margin: ${(props) => props.margin || "0"};
`;

const Image = styled.img`
	border: 0;
	max-height: 100%;
	max-width: 100%;
	height: auto;
	width: 100%;
	position: relative;
	width: 100%;
	object-fit: contain;
`;

const Logo = (props) => {
	return (
		<>
			<Wrapper
				href="#"
				width={props.width}
				margin={props.margin}
				display={props.display}
				onClick={(e) => e.preventDefault()}>
				<Image
					src={
						props.usePurpleLogo
							? "/images/logo/logo-purple.png"
							: "/images/logo/logo-white-no-padding.png"
					}
					alt="Winningtemp logo"
				/>
			</Wrapper>
		</>
	);
};

Logo.propTypes = {
	usePurpleLogo: bool,
	display: PropTypes.string,
	width: PropTypes.string,
	margin: PropTypes.string,
};

export default Logo;
