// this file origin from Winningtemp.Shared

import React, { PureComponent, forwardRef } from "react";
import Colors from "Colors";
import Button from "Controls/Button";

class ButtonComponent extends PureComponent {
	render() {
		let styles = {
			borderRadius: "3px",
			fontSize: "14px",
			fontWeight: "600",
			touchAction: "manipulation"
		};

		switch (true) {
			case this.props.green:
				styles = {
					...styles,
					color: Colors.white,
					backgroundColor: Colors.buttonGreen,
					hoverBackgroundColor: Colors.buttonGreenHover,
					disabledBackgroundColor: Colors.buttonGreenDisabled,
					border: `1px solid ${Colors.buttonGreenBorder}`,
					disabledBorder: `1px solid ${Colors.buttonGreenDisabled}`,
					hoverBorder: `1px solid ${Colors.buttonGreenHoverBorder}`
				};
				break;
			case this.props.purple:
				styles = {
					...styles,
					color: Colors.white,
					backgroundColor: Colors.buttonPurple,
					hoverBackgroundColor: Colors.buttonPurpleHighlighted,
					disabledBackgroundColor: Colors.buttonPurpleDisabled,
					border: `1px solid ${Colors.buttonPurpleBorder}`,
					disabledBorder: `1px solid ${Colors.buttonPurpleDisabled}`,
					hoverBorder: `1px solid ${Colors.buttonPurpleHighlightedBorder}`
				};
				break;
			case this.props.blue:
				styles = {
					...styles,
					color: Colors.white,
					backgroundColor: Colors.buttonBlue,
					hoverBackgroundColor: Colors.buttonBlueHover,
					disabledBackgroundColor: Colors.buttonBlueDisabled,
					border: `1px solid ${Colors.buttonBlueBorder}`,
					disabledBorder: `1px solid ${Colors.buttonBlueDisabled}`,
					hoverBorder: `1px solid ${Colors.buttonBlueHoverBorder}`
				};
				break;
			case this.props.gray:
				styles = {
					...styles,
					color: Colors.white,
					backgroundColor: Colors.buttonLightGray,
					hoverBackgroundColor: Colors.buttonLightGrayHover,
					border: `1px solid ${Colors.buttonLightGrayBorder}`,
					hoverBorder: `1px solid ${Colors.buttonLightGrayHoverBorder}`
				};
				break;
			case this.props.primaryPurple:
				styles = {
					...styles,
					height: "40px",
					fontSize: "13px",
					padding: "0 20px",
					color: Colors.buttonPrimaryPurpleColor,
					backgroundColor: Colors.buttonPrimaryPurple,
					hoverBackgroundColor: Colors.buttonPrimaryPurpleHover,
					disabledBackgroundColor: Colors.buttonPrimaryPurpleDisabled,
					border: `1px solid ${Colors.buttonPrimaryPurpleBorder}`,
					hoverBorder: `1px solid ${Colors.buttonPrimaryPurpleHoverBorder}`,
					disabledBorder: `1px solid ${Colors.buttonPrimaryPurpleDisabledBorder}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.primaryBlue:
				styles = {
					...styles,
					height: "40px",
					fontSize: "13px",
					padding: "0 20px",
					color: Colors.white,
					backgroundColor: Colors.buttonBlue,
					hoverBackgroundColor: Colors.buttonBlueHover,
					disabledBackgroundColor: Colors.buttonBlueDisabled,
					border: `1px solid ${Colors.buttonBlueBorder}`,
					hoverBorder: `1px solid ${Colors.buttonBlueHoverBorder}`,
					disabledBorder: `1px solid ${Colors.buttonBlueDisabled}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.secondaryPurple:
				styles = {
					...styles,
					height: "40px",
					fontSize: "13px",
					padding: "0 20px",
					color: Colors.buttonSecondaryPurpleColor,
					backgroundColor: Colors.buttonSecondaryPurple,
					hoverBackgroundColor: Colors.buttonSecondaryPurpleHover,
					disabledBackgroundColor: Colors.buttonSecondaryPurpleDisabled,
					border: `1px solid ${Colors.buttonSecondaryPurpleBorder}`,
					hoverBorder: `1px solid ${Colors.buttonSecondaryPurpleHoverBorder}`,
					disabledBorder: `1px solid ${Colors.buttonSecondaryPurpleDisabledBorder}`,
					disabledCursor: "not-allowed",
					disabledColor: Colors.buttonSecondaryPurpleDisabledColor,
				};
				break;
			case this.props.primaryGreen:
				styles = {
					...styles,
					height: "40px",
					fontSize: "13px",
					padding: "0 20px",
					color: Colors.buttonPrimaryGreenColor,
					backgroundColor: Colors.buttonPrimaryGreen,
					hoverBackgroundColor: Colors.buttonPrimaryGreenHover,
					disabledBackgroundColor: Colors.buttonPrimaryGreenDisabled,
					border: `1px solid ${Colors.buttonPrimaryGreenBorder}`,
					hoverBorder: `1px solid ${Colors.buttonPrimaryGreenHoverBorder}`,
					disabledBorder: `1px solid ${Colors.buttonPrimaryGreenDisabledBorder}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.secondaryGreen:
				styles = {
					...styles,
					height: "40px",
					fontSize: "13px",
					padding: "0 20px",
					color: Colors.buttonSecondaryGreenColor,
					backgroundColor: Colors.buttonSecondaryGreen,
					hoverBackgroundColor: Colors.buttonSecondaryGreenHover,
					disabledBackgroundColor: Colors.buttonSecondaryGreenDisabled,
					border: `1px solid ${Colors.buttonSecondaryGreenBorder}`,
					hoverBorder: `1px solid ${Colors.buttonSecondaryGreenHoverBorder}`,
					disabledBorder: `1px solid ${Colors.buttonSecondaryGreenDisabledBorder}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.primaryRed:
				styles = {
					...styles,
					height: "40px",
					fontSize: "13px",
					padding: "0 20px",
					color: Colors.buttonPrimaryRedColor,
					backgroundColor: Colors.buttonPrimaryRed,
					hoverBackgroundColor: Colors.buttonPrimaryRedHover,
					disabledBackgroundColor: Colors.buttonPrimaryRedDisabled,
					border: `1px solid ${Colors.buttonPrimaryRedBorder}`,
					hoverBorder: `1px solid ${Colors.buttonPrimaryRedHoverBorder}`,
					disabledBorder: `1px solid ${Colors.buttonPrimaryRedDisabledBorder}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.purpleRounded:
				styles = {
					...styles,
					height: "40px",
					minWidth: "98px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 25px",
					borderRadius: "20px",
					color: Colors.buttonPurpleRoundedColor,
					backgroundColor: Colors.buttonPurpleRounded,
					hoverBackgroundColor: Colors.buttonPurpleRoundedHover,
					border: `1px solid ${Colors.buttonPurpleRoundedBorder}`,
					hoverBorder: `1px solid ${Colors.buttonPurpleRoundedHoverBorder}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.primaryPurpleRounded:
				styles = {
					...styles,
					height: "40px",
					minWidth: "98px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 16px",
					borderRadius: "20px",
					color: Colors.white,
					backgroundColor: Colors.purple,
					border: `1px solid ${Colors.purple}`,
					hoverOpacity: "0.6",
					disabledCursor: "not-allowed",
					disabledBackgroundColor: Colors.smoke,
					disabledBorder: `1px solid ${Colors.smoke}`,
					disabledColor: Colors.metal,
				};
				break;
			case this.props.secondaryPurpleRounded:
				styles = {
					...styles,
					height: "40px",
					minWidth: "98px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 16px",
					borderRadius: "20px",
					color: Colors.purple,
					backgroundColor: Colors.lightPurple,
					border: `1px solid ${Colors.lightPurple}`,
					hoverOpacity: "0.6",
					disabledColor: Colors.nickel,
					disabledBackgroundColor: Colors.smoke,
					disabledBorder: `1px solid ${Colors.smoke}`,
					disabledOpacity: "1",
				};
				break;
			case this.props.whiteRounded:
				styles = {
					...styles,
					height: "40px",
					minWidth: "98px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					borderRadius: "20px",
					color: Colors.buttonWhiteRoundedColor,
					backgroundColor: Colors.buttonWhiteRounded,
					hoverBackgroundColor: Colors.buttonWhiteRoundedHover,
					border: `1px solid ${Colors.buttonWhiteRoundedBorder}`,
					hoverBorder: `1px solid ${Colors.buttonWhiteRoundedHoverBorder}`,
					disabledCursor: "not-allowed",
				};
				break;
			case this.props.primaryBlueRounded:
				styles = {
					...styles,
					height: "40px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					borderRadius: "20px",
					color: Colors.white,
					backgroundColor: Colors.skyBlue,
					border: `1px solid ${Colors.skyBlue}`,
					hoverOpacity: "0.6",
					disabledColor: Colors.nickel,
					disabledBackgroundColor: Colors.smoke,
					disabledBorder: `1px solid ${Colors.smoke}`,
					disabledOpacity: "1",
				};
				break;
			case this.props.secondaryBlueRounded:
				styles = {
					...styles,
					height: "40px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					borderRadius: "20px",
					color: Colors.oceanBlue,
					backgroundColor: Colors.whiteBlue,
					border: `1px solid ${Colors.whiteBlue}`,
					hoverOpacity: "0.6",
				};
				break;
			case this.props.secondaryWhiteRounded:
				styles = {
					...styles,
					height: "40px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					borderRadius: "20px",
					color: Colors.nickel,
					backgroundColor: Colors.white,
					border: `1px solid ${Colors.fog}`,
					hoverOpacity: "0.6",
					disabledCursor: "not-allowed",
					disabledBorder: `1px solid ${Colors.smoke}`,
					disabledColor: Colors.metal,
				};
				break;
			case this.props.secondaryWhite:
				styles = {
					...styles,
					height: "40px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					color: Colors.nickel,
					backgroundColor: Colors.white,
					border: `1px solid ${Colors.fog}`,
					hoverOpacity: "0.6",
				};
				break;
			case this.props.primaryRedRounded:
				styles = {
					...styles,
					height: "40px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					borderRadius: "20px",
					color: Colors.white,
					disabledColor: Colors.nickel,
					backgroundColor: Colors.redOchre,
					disabledBackgroundColor: Colors.smoke,
					border: `1px solid ${Colors.redOchre}`,
					disabledBorder: `1px solid ${Colors.smoke}`,
					hoverOpacity: "0.6",
					disabledOpacity: "1",
				};
				break;
			case this.props.secondaryRedRounded:
				styles = {
					...styles,
					height: "40px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 24px",
					paddingXS: "0 16px",
					borderRadius: "20px",
					color: Colors.brick,
					disabledColor: Colors.nickel,
					backgroundColor: Colors.lightRed,
					disabledBackgroundColor: Colors.whiteRed,
					border: `1px solid ${Colors.lightRed}`,
					disabledBorder: `1px solid ${Colors.whiteRed}`,
					hoverOpacity: "0.6",
					disabledOpacity: "1",
				};
				break;
			case this.props.whiteRoundedSmall:
				styles = {
					...styles,
					minHeight: "24px",
					fontSize: "13px",
					fontWeight: "600",
					padding: "4px 16px",
					paddingXS: "0 8px",
					borderRadius: "20px",
					color: Colors.purple,
					backgroundColor: Colors.white,
					border: `1px solid ${Colors.fog}`,
					hoverOpacity: "0.6",
				};
				break;
			case this.props.secondaryPurpleRoundedSmall:
				styles = {
					...styles,
					minHeight: "24px",
					fontSize: "13px",
					fontWeight: "600",
					padding: "4px 16px",
					paddingXS: "0 8px",
					borderRadius: "20px",
					color: Colors.purple,
					backgroundColor: Colors.lightPurple,
					border: `1px solid ${Colors.lightPurple}`,
					hoverOpacity: "0.6",
				};
				break;
			case this.props.primaryGreenRounded:
				styles = {
					...styles,
					height: "40px",
					minWidth: "98px",
					fontSize: "16px",
					fontWeight: "500",
					padding: "0 16px",
					borderRadius: "20px",
					color: Colors.white,
					backgroundColor: Colors.jungle,
					border: `1px solid ${Colors.jungle}`,
					hoverOpacity: "0.6",
					disabledCursor: "not-allowed",
					disabledBackgroundColor: Colors.smoke,
					disabledBorder: `1px solid ${Colors.smoke}`,
					disabledColor: Colors.metal,
				};
				break;
			default:
				styles = {
					...styles,
					color: Colors.buttonWhiteColor,
					hoverColor: Colors.buttonWhiteHoverColor,
					backgroundColor: Colors.white,
					border: "1px solid #E0E0E0",
					hoverBorder: "1px solid #CCCCCC"
				};
		}

		return (
			<div ref={this.props.innerRef} style={{ display: "contents" }}>
				<Button
					{...styles}
					{...this.props}
					data-test={this.props.dataTest}
					data-track-id={this.props.dataTrackId}>
					{this.props.children}
				</Button>
			</div>
		);
	}
}
export default forwardRef((props, ref) => <ButtonComponent innerRef={ref} {...props} />);