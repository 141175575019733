// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import ContentWrapper from "Controls/ContentWrapper";
import QuestionTalkHOC from "./QuestionTalkHOC";
import QuestionTalkModalHeader from "./QuestionTalkModalHeader";
import QuestionDoYouWantToTalkScreen from "./QuestionDoYouWantToTalkScreen";
import QuestionSelectContactScreen from "./QuestionSelectContactScreen";

class QuestionTalk extends PureComponent {
	constructor(props) {
		super(props);

		this.config = this.getConfig();
	}

	getConfig = () => {
		return {
			[this.props.screens.doYouWantToTalk]: {
				render: this.renderDoYouWantToTalkScreen,
			},
			[this.props.screens.selectContact]: {
				render: this.renderSelectContactScreen,
			},
		};
	};

	getHasExternalPartnerEnabled = () => {
		return this.props.contacts?.some(x => x.Key.startsWith("External"));
	}

	renderDoYouWantToTalkScreen = () => {
		// switch and return a modified screen if customer has a external partner enabled
		// as the default text is very much focused on talking to someone and this is not always
		// compatible with the partner offerings
		if(this.getHasExternalPartnerEnabled()) {
			return (
				<QuestionDoYouWantToTalkScreen
					culture={this.props.culture}
					doYouWantToTalkDescription={
						Resources[this.props.culture].doYouNeedSupportAndWantToTalkToSomeoneExternalPartner
					}
					doYouWantToTalkQuestion={
						Resources[this.props.culture].doYouWantToContinueAndSeeWhatPossibilitiesWinningtempOffersAsSupport
					}
					nextButtonAttribute="escalation-strategy-health-step-1-yes"
					backButtonAttribute="escalation-strategy-health-step-1-no"
					onNoClick={this.props.onQuestionTalkNoSubmit}
					onYesClick={this.props.openSelectContactScreen}
				/>
			);
		}
		
		return (
			<QuestionDoYouWantToTalkScreen
				culture={this.props.culture}
				doYouWantToTalkDescription={
					Resources[this.props.culture].doYouNeedSupportAndWantToTalkToSomeone
				}
				doYouWantToTalkQuestion={
					Resources[this.props.culture].doYouWantToTalkToSomeoneAboutYourWorkSituation
				}
				nextButtonAttribute="escalation-strategy-health-step-1-yes"
				backButtonAttribute="escalation-strategy-health-step-1-no"
				onNoClick={this.props.onQuestionTalkNoSubmit}
				onYesClick={this.props.openSelectContactScreen}
			/>
		);
	};

	renderSelectContactScreen = () => {
		let text = this.getContactDescription();
		let isExternalContact = this.getIsExternalContact();

		return (
			<QuestionSelectContactScreen
				isExternalContact={isExternalContact}
				culture={this.props.culture}
				nextButtonAttribute="escalation-strategy-health-step-2-submit"
				backButtonAttribute="escalation-strategy-health-step-2-back"
				contacts={this.props.contacts}
				text={text}
				comment={this.props.questionTalkData.comment}
				onCommentChange={this.props.onCommentChange}
				selectedContact={this.props.selectedContact}
				onContactChange={this.props.onContactChange}
				onSendClick={this.props.onSubmit}
				onUndoClick={this.props.openDoYouWantToTalkScreen}
			/>
		);
	};

	getIsExternalContact = () => {
		return this.props.selectedContact?.Key.startsWith("External");
	};

	getContactDescription = () => {
		if (this.props.selectedContact?.Key === "External") {
			return `${Resources[this.props.culture].questionTalkExternalDescription}<br/><br/>${
				Resources[this.props.culture].orIfYouPreferYouCanTalkToSomeoneInYourOrganization
			}`;
		}

		if (this.props.selectedContact?.Key === "ExternalBraive") {
			let partnerUrl = "https://app.braive.com/users/sign_up?companyId=" + this.props.customerId;
			return `${Resources[this.props.culture].questionTalkExternalBraiveDescription.replace(
				"{{partner_url}}",
				partnerUrl
			)}<br/><br/>${Resources[this.props.culture].orIfYouPreferYouCanTalkToSomeoneInYourOrganization}`;
		}

		return `${Resources[this.props.culture].questionTalkDefaultDescription}<br/><br/>${
			Resources[this.props.culture].contactDesiredPerson
		}`;
	};

	render() {
		if (!this.config[this.props.activeScreen]) {
			return null;
		}

		return (
			<ContentWrapper>
				<QuestionTalkModalHeader
					titleText={Resources[this.props.culture].doYouWantToTalkToSomeone}
					progressWidth={this.props.progress}
				/>
				{this.config[this.props.activeScreen].render()}
			</ContentWrapper>
		);
	}
}

QuestionTalk.propTypes = {
	culture: PropTypes.string,
	token: PropTypes.string,
	urls: PropTypes.object,
	contacts: PropTypes.array,
	onQuestionTalkSubmit: PropTypes.func,
	onQuestionTalkNoSubmit: PropTypes.func,
};

export default QuestionTalkHOC(QuestionTalk);
