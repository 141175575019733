import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ContentWrapper from "Controls/ContentWrapper";
import SplashWrapper from "Controls/SplashWrapper";
import ConfirmationFooterButton from "./ConfirmationFooterButton";

class Confirmation extends PureComponent {
	render() {
		return (
			<div className="surveyQuestionsConfirmation surveyQuestionsContainer">
				<div className="surveyQuestionsBox">
					<SplashWrapper
						typeId={this.props.typeId}
						title={`${this.props.t("ThankYou")}!`}
						subTitle={this.props.t("YourAnswersWillHelpToContributeToYourOrganizationsWellBeing")}>
						<ContentWrapper display="flex" width="100%" padding="60px 0 0">
							{
								this.props.widgetEnabled &&
								<ConfirmationFooterButton
									name={this.props.t("ShowTemperature")}
									onClick={this.props.onWidgetClick} />
							}
							
							{
								this.props.shouldLogout &&
								<ConfirmationFooterButton
									timerEnabled={!this.props.widgetEnabled}
									timeout={3}
									name={this.props.t("LogOut")}
									onClick={this.props.onLogout} />
							}
							
							{
								!this.props.shouldLogout &&
								<ConfirmationFooterButton
									timerEnabled={!this.props.widgetEnabled}
									timeout={3}
									name={this.props.t("Back")}
									onClick={this.props.onBack} />
							}
						</ContentWrapper>
					</SplashWrapper>
				</div>
			</div>
		);
	}
}

Confirmation.propTypes = {
	shouldLogout: PropTypes.bool,
	widgetEnabled: PropTypes.bool,
	typeId: PropTypes.number,
	culture: PropTypes.string,
	onLogout: PropTypes.func,
	onBack: PropTypes.func,
	onWidgetClick: PropTypes.func
}

export default withTranslation()(Confirmation);