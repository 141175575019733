// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { Grade } from "Enums";
import Resources from "Resources";
import Button from "Controls/Button";
import CircleWrapper from "./CircleWrapper";
import TemperatureWrapper from "./TemperatureWrapper";
import SmileyStronglyDisagree from "./SmileyStronglyDisagree";
import SmileyDisagree from "./SmileyDisagree";
import SmileyAgree from "./SmileyAgree";
import SmileyStronglyAgree from "./SmileyStronglyAgree";

const shake = keyframes`
	0% { -webkit-transform: rotate(0deg); }
	25% { -webkit-transform: rotate(-3deg); }
	50% { -webkit-transform: rotate(3deg); }
	75% { -webkit-transform: rotate(-3deg); }
	100% { -webkit-transform: rotate(0deg); }
`;

const animation = (props) =>
	css`
		animation: ${shake} 0.5s linear;
	`;

const Wrapper = styled(Button)`
	transform-origin: 50% 100%;
	position: ${(props) => (props.position ? props.position : "relative")};
	${(props) => (props.isWithShake ? animation : null)}
	&:hover {
		.smiley-background {
			${(props) => !props.isReadOnly && `opacity: 0.2;`}
		}
	}
`;

const Image = styled.img`
	position: absolute;
	width: 52px;
	height: 54px;
	top: -20px;
	left: ${(props) => (props.left ? props.left : "0px")};
`;

class Smiley extends PureComponent {
	constructor(props) {
		super(props);

		const isIE = !!document.documentMode; // An IE only property, first available in IE8.
		const isEdge = /Edge/.test(navigator.userAgent);
		this.isWithAnimation = !isIE && !isEdge && this.props.isWithShake;

		this.state = {
			leftPosition: null,
		};
	}

	onClick = () => {
		if (!this.props.onClick) {
			return null;
		}

		this.props.onClick(this.props.grade);
	};

	renderSmile = (isWithAnimation) => {
		switch (this.props.grade) {
			case Grade.stronglyDisagree:
				return (
					<SmileyStronglyDisagree
						isTemperature={this.props.isTemperature}
						isWithAnimation={isWithAnimation}
					/>
				);
			case Grade.disagree:
				return (
					<SmileyDisagree
						isTemperature={this.props.isTemperature}
						isWithAnimation={isWithAnimation}
					/>
				);
			case Grade.agree:
				return (
					<SmileyAgree isTemperature={this.props.isTemperature} isWithAnimation={isWithAnimation} />
				);
			default:
				return (
					<SmileyStronglyAgree
						isTemperature={this.props.isTemperature}
						isWithAnimation={isWithAnimation}
					/>
				);
		}
	};

	renderCristmasHat = () => {
		switch (this.props.grade) {
			case Grade.stronglyDisagree:
				return <Image src={"/images/smiley/hat-1.png"} />;
			case Grade.disagree:
				return <Image src={"/images/smiley/hat-2.png"} />;
			case Grade.agree:
				return <Image src={"/images/smiley/hat-3.png"} left="3px" />;
			default:
				return <Image src={"/images/smiley/hat-4.png"} />;
		}
	};

	getAriaLabel = () => {
		if (!this.props.culture) {
			return null;
		}

		switch (this.props.grade) {
			case Grade.stronglyDisagree:
				return Resources[this.props.culture].stronglyDisagree;
			case Grade.disagree:
				return Resources[this.props.culture].disagree;
			case Grade.agree:
				return Resources[this.props.culture].agree2;
			default:
				return Resources[this.props.culture].stronglyAgree;
		}
	};

	render() {
		const ariaLabel = this.getAriaLabel();

		if (this.props.isTemperature) {
			return (
				<Wrapper
					data-test="smiley"
					tabIndex="-1"
					aria-label={ariaLabel}
					type="button"
					backgroundColor="transparent"
					isWithShake={this.props.isWithShake}
					isReadOnly={this.props.isReadOnly}
					margin={this.props.margin}
					disabled={this.props.isReadOnly}
					position={this.props.position}
					left={this.props.left}
					top={this.props.top}
					leftXS={this.props.leftXS}
					topXS={this.props.topXS}
					onClick={this.onClick}>
					<TemperatureWrapper
						color={this.props.color}
						isAnimated={this.isWithAnimation}
						gradeIndex={4 - this.props.grade - 1}
						gradeCount={4}
						isActive={this.props.isActive}>
						{this.renderSmile(this.isWithAnimation)}
					</TemperatureWrapper>
				</Wrapper>
			);
		}

		return (
			<Wrapper
				data-test="smiley"
				aria-hidden={this.props.culture ? "false" : "true"}
				aria-label={ariaLabel}
				type="button"
				backgroundColor="transparent"
				isWithShake={this.props.isWithShake}
				isReadOnly={this.props.isReadOnly}
				margin={this.props.margin}
				disabled={this.props.isReadOnly}
				position={this.props.position}
				left={this.props.left}
				top={this.props.top}
				leftXS={this.props.leftXS}
				topXS={this.props.topXS}
				minHeight={this.props.smileySize}
				minWidth={this.props.smileySize}
				height={this.props.smileySize}
				width={this.props.smileySize}
				onClick={this.onClick}>
				<CircleWrapper
					size={this.props.smileySize}
					color={this.props.color}
					isActive={this.props.isActive}>
					{this.renderSmile(this.isWithAnimation)}
				</CircleWrapper>
				{this.props.isChristmasModeEnabled && this.renderCristmasHat()}
			</Wrapper>
		);
	}
}

Smiley.propTypes = {
	isActive: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isTemperature: PropTypes.bool,
	isWithShake: PropTypes.bool,
	grade: PropTypes.number,
	margin: PropTypes.string,
	color: PropTypes.string,
	smileySize: PropTypes.string,
	smileySizeXS: PropTypes.string,
	position: PropTypes.string,
	top: PropTypes.string,
	left: PropTypes.string,
	topXS: PropTypes.string,
	leftXS: PropTypes.string,
	culture: PropTypes.string,
	onClick: PropTypes.func,
};

export default Smiley;
