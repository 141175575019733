import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "Controls/Button";
import Timer from "./Timer";

class ConfirmationFooterButton extends PureComponent {
	constructor(props) {
		super(props);

		this.buttonStyle = {
			width: "50%",
			borderRadius: "0",
			backgroundColor: "rgba(0,0,0,0.25)",
			hoverBackgroundColor: "rgba(0,0,0,0.35)",
			padding: "19px 0",
			textTransform: "uppercase",
			fontSize: "14px",
			fontWeight: "700",
			textAlign: "center",
			color: "#fff",
		};

		this.state = {
			timeout: this.props.timeout,
		};
	}

	onTick = () => {
		this.setState({
			timeout: this.state.timeout - 1,
		});

		if (this.state.timeout === 0) {
			this.props.onClick();
		}
	};

	render() {
		if (this.props.isTimerEnabled) {
			return (
				<Timer onTick={this.onTick}>
					<Button
						{...this.buttonStyle}
						data-track-id="survey-click-exit-after-taking-survey"
						onClick={this.props.onClick}>
						{`${this.props.name} (${this.state.timeout})`}
					</Button>
				</Timer>
			);
		}

		return (
			<Button
				{...this.buttonStyle}
				data-track-id="survey-click-exit-after-taking-survey"
				onClick={this.props.onClick}>
				{this.props.name}
			</Button>
		);
	}
}

ConfirmationFooterButton.propTypes = {
	isTimerEnabled: PropTypes.bool,
	timeout: PropTypes.number,
	name: PropTypes.string,
	onClick: PropTypes.func,
};

export default ConfirmationFooterButton;
