// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import { ConfirmationWindowType } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import Image from "Controls/Image";
import Text from "Controls/Text";

class SplashWrapper extends PureComponent {
	constructor(props) {
		super(props);
		this.config = this.getConfig();

		const configItem = this.config.find((i) => i.typeId === this.props.typeId);

		this.config = configItem || this.config[Math.floor(Math.random() * this.config.length)];
	}

	getConfig = () => {
		return [
			{
				typeId: ConfirmationWindowType.hot,
				backgroundColor: "#61cca6",
				backgroundImage: "bg-glimmer.png",
				icon: "weather-sun.png",
				iconHeight: "129px",
				iconMarginTop: "110px",
				titleColor: Colors.darkGrayText,
			},
			{
				typeId: ConfirmationWindowType.warm,
				backgroundColor: "#935bb1",
				backgroundImage: "bg-glimmer.png",
				icon: "weather-cloud.png",
				iconHeight: "111px",
				iconMarginTop: "125px",
				titleColor: Colors.white,
			},
			{
				typeId: ConfirmationWindowType.cold,
				backgroundColor: "#5db1c6",
				backgroundImage: "bg-snow.png",
				icon: "weather-snow.png",
				iconHeight: "129px",
				iconMarginTop: "110px",
				titleColor: Colors.darkGrayText,
			},
		];
	};

	render() {
		return (
			<ContentWrapper
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				justifyContentXS="flex-start"
				position="relative"
				background={`${this.config.backgroundColor} url(/images/modal/confirmation-bg.png) top center no-repeat`}
				boxShadow="0 3px 30px rgba(0, 0, 0, .4)"
				minHeightXS="100vh">
				<ContentWrapper
					display="flex"
					justifyContent="center"
					background={`url(/images/modal/${this.config.backgroundImage}) center center no-repeat`}
					backgroundSize="contain"
					minHeight="323px"
					minWidth="323px"
					paddingTop={this.config.iconMarginTop}>
					<Image src={`/images/modal/${this.config.icon}`} alt="" height={this.config.iconHeight} />
				</ContentWrapper>
				<Text
					display="block"
					padding="0 10px"
					textAlign="center"
					fontSize="30px"
					fontWeight="300"
					lineHeight="40px"
					margin="40px 0 5px"
					data-test="title"
					color={this.config.titleColor}>
					{this.props.title}
				</Text>
				<Text
					display="block"
					padding="0 10px 10px"
					textAlign="center"
					fontSize="16px"
					fontWeight="500"
					lineHeight="25px"
					maxWidth="500px"
					color={this.config.titleColor}>
					{this.props.subTitle}
				</Text>
				{this.props.children}
			</ContentWrapper>
		);
	}
}

SplashWrapper.propTypes = {
	typeId: PropTypes.number,
	title: PropTypes.string,
	subTitle: PropTypes.string,
};

export default SplashWrapper;
