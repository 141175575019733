// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import ContentWrapper from "Controls/ContentWrapper";
import Text from "Controls/Text";

class QuestionCommentInfo extends PureComponent {
    render() {
        return(
           <ContentWrapper display="none" displayXS="block" padding="5px 0 0">
               <img src="/images/icon-info.png" height="12px" />
                <Text
                    display="inline"
                    fontSize="11px"
                    color="#5A5F66"
                    margin="0 0 0 5px">
                    {this.props.isVisibleForImmediateManager ? Resources[this.props.culture].yourBossSeesCommentButNotTheSender : Resources[this.props.culture].yourBossDoesNotSeeYourComment}
                </Text>
           </ContentWrapper> 
        );
    }
}

QuestionCommentInfo.propTypes = {
    isVisibleForImmediateManager: PropTypes.bool,
    culture: PropTypes.string
}

export default QuestionCommentInfo;