export default ({
    goals: "'\\e000'",
    achievements: "'\\e001'",
    coaching: "'\\e003'",
	alert: "'\\e005'",
    arrowdown: "'\\e006'",
    arrowleft: "'\\e007'",
    arrowright: "'\\e008'",
    arrowup: "'\\e009'",
	flag: "'\\e01c'",
    dashboard: "'\\e00b'",
    earth: "'\\e00c'",
	elearning: "'\\e00e'",
	world: "'\\e00f'",
    stats: "'\\e011'",
    education: "'\\e00d'",
    work: "'\\e010'",
    search: "'\\e013'",
    learning: "'\\e016'",
    myrole: "'\\e014'",
    likeOutline: "'\\e015'",
	file: "'\\e017'",
    feedback: "'\\e018'",
    performance: "'\\e019'",
    attachment: "'\\e01a'",
    calendar: "'\\e01b'",
    gear1: "'\\e01d'",
    smileyStronglyDisagree: "'\\e020'",
    plus: "'\\e021'",
    x: "'\\e022'",
    user: "'\\e023'",
    arrowRight: "'\\e024'",
    arrowUp: "'\\e025'",
    arrowLeft: "'\\e027'",
    arrowDown: "'\\e028'",
    smileyDisagree: "'\\e01f'",
	listOutline: "'\\e02a'",
    settings: "'\\e02b'",
    smileyAgree: "'\\e02e'",
    smileyStronglyAgree: "'\\e02d'",
    switch: "'\\e02f'",
    checkboxChecked: "'\\e030'",
    close: "'\\e031'",
    arrowLeftBold: "'\\e032'",
    arrowRightBold: "'\\e033'",
    edit: "'\\e034'",
    lockThin: "'\\e035'",
    gear: "'\\e036'",
    plusCircle: "'\\e037'",
    organization: "'\\e038'",
    trash: "'\\e039'",
    zoomIn: "'\\e03a'",
    zoomOut: "'\\e03b'",
    newUser: "'\\e03c'",
    group: "'\\e03f'",
    teamPlayer: "'\\e040'",
    lock: "'\\e045'",
    warning: "'\\e046'",
    mail: "'\\e047'",
    list: "'\\e048'",
    bulb: "'\\e049'",
    quote: "'\\e04a'",
    segment: "'\\e04b'",
    book: "'\\e050'",
    list2: "'\\e051'",
	seminar: "'\\e052'",
	workshop: "'\\e053'",
	questionOutline: "'\\e055'",
    like: "'\\e058'",
    comment: "'\\e059'",
    badge: "'\\e05a'",
    update: "'\\e05b'",
    homeHouseStreamline: "'\\e05f'",
    agree: "'\\e066'",
	goalFocused: "'\\e069'",
    newMessage: "'\\e072'",
    plusCircle1: "'\\e074'",
    minusCircle: "'\\e075'",
    stress: "'\\e07a'",
    group1: "'\\e07b'",
    question: "'\\e07c'",
    sadUser: "'\\e07d'",
    chackmarkBold: "'\\e081'",
    autonomy: "'\\e084'",
    happinessQuestion: "'\\e08b'",
    participationQuestion: "'\\e08c'",
    personalDevelopmentQuestion: "'\\e08d'",
    meaningfullnessQuestion: "'\\e08e'",
    leadershipQuestion: "'\\e08f'",
    workQuestion: "'\\e090'",
    signOut: "'\\e094'",
    chevronLeft: "'\\e095'",
    chevronRight: "'\\e096'",
    skills: "'\\e012'",
    star: "'\\e057'",
});