import { setCulture } from "./culture";
import { post, get } from "./api";

const CHANGE_CULTURE = "CHANGE_CULTURE";
const ACCEPT_AGREEMENT_REQUEST = "ACCEPT_AGREEMENT_REQUEST";
const ACCEPT_AGREEMENT_SUCCESS = "ACCEPT_AGREEMENT_SUCCESS";
const ACCEPT_AGREEMENT_ERROR = "ACCEPT_AGREEMENT_ERROR";
const CHECK_AGREEMENT_REQUEST = "CHECK_AGREEMENT_REQUEST";
const CHECK_AGREEMENT_SUCCESS = "CHECK_AGREEMENT_SUCCESS";
const CHECK_AGREEMENT_ERROR = "CHECK_AGREEMENT_ERROR";

const changeCulture = (culture) => {
    setCulture(culture);

    return {
        type: CHANGE_CULTURE,
        culture: culture
    };
};

const acceptAgreementRequest = () => {
    return {
        type: ACCEPT_AGREEMENT_REQUEST
    };
};

const acceptAgreementSuccess = () => {
    return {
        type: ACCEPT_AGREEMENT_SUCCESS
    };
};

const acceptAgreementError = () => {
    return {
        type: ACCEPT_AGREEMENT_ERROR
    };
};

const checkAgreementRequest = () => {
    return {
        type: CHECK_AGREEMENT_REQUEST
    };
};

const checkAgreementSuccess = (showGdprAgreement) => {
    return {
        type: CHECK_AGREEMENT_SUCCESS,
        showGdprAgreement: showGdprAgreement
    };
};

const checkAgreementError = () => {
    return {
        type: CHECK_AGREEMENT_ERROR
    };
};

const acceptAgreement = () => {
    return (dispatch, getState) => {
        dispatch(acceptAgreementRequest());

        return post("user/checkgdpr", {}, getState())
            .then(response => {
                if (response.status >= 400) {
                    dispatch(acceptAgreementError());

                    return { success: false }
                }

                dispatch(acceptAgreementSuccess());

                return { success: true };
            });
    };
};

const checkAgreement = () => {
    return (dispatch, getState) => {
        dispatch(checkAgreementRequest());

        return get("user/showgdpr", getState())
            .then(response => {
                if (response.status >= 400) {
                    dispatch(checkAgreementError());

                    return { success: false }
                }

                dispatch(checkAgreementSuccess(response.data));

                return { success: true };
            });
    };
};

const getHeapData = () => {
    return (dispatch, getState) => {
        return get("externalTracking", getState())
            .then(response => {
                const data = response.data;

                if (response.status >= 400 || !data) {
                    dispatch(checkAgreementError());

                    return { success: false }
                }

                const visitor = {
                    id: response.data.userId,
                    role: response.data.role,
                    language_override: response.data.language || "en"
                }

                const account = {
                    id: data.customerId,
                    name: data.customerName,
                    planLevel: data.planLevel
                }

                if (data.extraProperties) {
                    const propperties = data.extraProperties.split(",");
                    propperties.forEach(
                        proppertie => {
                            const proppertieData = proppertie.split(":");
                            const value = proppertieData[1].trim();
                            account[proppertieData[0].trim()] = isNaN(Number(value)) ? value : Number(value);
                        }
                    );
                }

                return { success: true, visitor, account };
            });
    };
};

export {
    CHANGE_CULTURE,
    ACCEPT_AGREEMENT_REQUEST,
    ACCEPT_AGREEMENT_SUCCESS,
    ACCEPT_AGREEMENT_ERROR,
    CHECK_AGREEMENT_REQUEST,
    CHECK_AGREEMENT_SUCCESS,
    CHECK_AGREEMENT_ERROR,
    changeCulture,
    acceptAgreement,
    checkAgreement,
    getHeapData
}
