import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import TemperatureWidget from "../Assets/Templates";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import SurveyTitlebar from "./Question/SurveyTitlebar";
import HeaderWithProfile from "./HeaderWithProfile";

const Wrapper = styled.div`
    background: ${Colors.questionWindowBackground};
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px 10px 0;
    @media(max-width: 1024px) {
        min-height: 99vh;
        padding-top: 25px;
        padding-top: calc(25px + env(safe-area-inset-bottom));
        padding-bottom: ${props => props.paddingBottom || "55px"};
        padding-bottom: calc(${props => props.paddingBottom || "55px"} + env(safe-area-inset-bottom));
    }
    @media(max-width: 425px) {
        padding-top: 25px;
        padding-top: calc(25px + env(safe-area-inset-bottom));
    }
`;

class Widget extends PureComponent {
	componentDidMount() {
		eval(
			TemperatureWidget.build("winningtemp-widget", window.initialState.widget.url + "&culture=" + this.props.culture)
		);
	}

	render() {
		return (
			<SurveyContainerWrapper className="with-auto-height-adjust">
				<ContentWrapper display="block" position="fixed" top="0" width="100%" zIndex="1">
					<HeaderWithProfile
						padding="20px 30px"
						paddingMedia="20px"
						username={this.props.username}
						culture={this.props.culture}
						onLogout={this.props.onLogout}/>
				</ContentWrapper>
				<Wrapper>
					<SurveyTitlebar
						culture={this.props.culture}
						title={this.props.t("Temperature")}
						onBack={this.props.onBack}/>

					<div className="widget-container">
						<div id="winningtemp-widget"></div>
					</div>
				</Wrapper>
			</SurveyContainerWrapper>
		);
	}
}

Widget.propTypes = {
	culture: PropTypes.string,
	batch: PropTypes.object,
	onClick: PropTypes.func
}

export default withTranslation()(Widget);