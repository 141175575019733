// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";

const Wrapper = styled.div`
    display: flex;
    justify-content: ${props => props.justifyContent || "center"};
    width: 100%;
    padding: ${props => props.padding || "10px"};
    background-color: ${props => props.backgroundColor || Colors.backgroundLightGray};
    border-radius: 0 0 4px 4px;
    position: relative;
`;

class FeedbackModule extends PureComponent {
    render() {
        const isWithChildren = !!this.props.children.find(child => !!child);

        if (!isWithChildren) {
            return null;
        }

        return(
            <Wrapper
                padding={this.props.padding}
                justifyContent={this.props.justifyContent}
                backgroundColor={this.props.backgroundColor} >  
                {this.props.children}
            </Wrapper>  
        );
    }
}

FeedbackModule.propTypes = {
    padding: PropTypes.string,
    justifyContent: PropTypes.string,
    backgroundColor: PropTypes.string
}

export default FeedbackModule;