// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";

const QuestionText = styled.div`
	position: relative;
	max-width: 100%;
	text-align: ${(props) => props.textAlign || "left"};
	color: ${(props) => props.color || Colors.textGray};
	font-weight: ${(props) => props.fontWeight || "500"};
	font-size: ${(props) => props.fontSize || "16px"};
	padding: ${(props) => props.padding || "0px"};
	${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`}
	@media(max-width: 768px) {
		font-size: 16px;
		font-weight: 500;
		color: ${Colors.textGray};
	}
	&:focus {
		outline: none;
	}
`;

QuestionText.propTypes = {
	isWithQuestionCounter: PropTypes.bool,
	color: PropTypes.string,
	fontWeight: PropTypes.string,
	fontSize: PropTypes.string,
	padding: PropTypes.string,
	paddingTop: PropTypes.string,
	textAlign: PropTypes.string,
};

export default QuestionText;
