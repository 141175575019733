var WidgetTemplate = {    
    build: function(container, url) {
        return `
            (function() {
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                var url = "${url}";
                s.src = url + ( url.indexOf("?") >= 0 ? "&" : "?") + "ref=" + encodeURIComponent(window.location.href);
                var embedder = document.getElementById("${container}");
                embedder.parentNode.insertBefore(s, embedder);
            })();`;
    }
}

export default WidgetTemplate;