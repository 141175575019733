import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import Batch from "./Batch";
import HeaderWithProfile from "./HeaderWithProfile";
import { SurveyTitlebar } from "./Question";

class Batches extends Component {
	render() {
		const isWithTemperatureBtn = !this.props.fetching && this.props.widgetEnabled;

		return (
			<SurveyContainerWrapper className="with-auto-height-adjust" paddingTop="80px">
				<ContentWrapper display="block" position="fixed" top="0" width="100%" zIndex="1">
					<HeaderWithProfile
						padding="20px 30px"
						paddingMedia="20px"
						username={this.props.username}
						culture={this.props.culture}
						onLogout={this.props.onLogout}
					/>
				</ContentWrapper>
				<div className="surveyQuestionsBox">
					{this.props.fetching && (
						<div>
							<div className="no-questions">
								<img
									src="/images/logo/logo-animated.gif"
									alt="Loading"
									className="animated-logo"
								/>
							</div>
						</div>
					)}

					{!this.props.fetching && this.props.batches.length === 0 && (
						<React.Fragment>
							<SurveyTitlebar culture={this.props.culture} title={this.props.t("Questions")} />
							<ContentWrapper padding="10px">
								<ContentWrapper
									fontWeight="200"
									fontSize="18px"
									textAlign="center"
									padding="20px"
									backgroundColor={Colors.backgroundLightGray}
									borderRadius="3px">
									{this.props.t("ThereAreNoQuestionsToAnswer")}
								</ContentWrapper>
							</ContentWrapper>
						</React.Fragment>
					)}

					{!this.props.fetching && this.props.batches && this.props.batches.length > 0 && (
						<React.Fragment>
							<SurveyTitlebar culture={this.props.culture} title={this.props.t("Questions")} />
							<ul className="questionslist">
								{this.props.batches.map((batch) => (
									<Batch
										batch={batch}
										key={batch.id}
										culture={this.props.culture}
										onClick={this.props.onBatchClick}
									/>
								))}
							</ul>
						</React.Fragment>
					)}

					{isWithTemperatureBtn && (
						<ContentWrapper padding="0 10px">
							<div className="show-temperature">
								<a href="#" onClick={this.props.onWidgetClick}>
									{this.props.t("ShowTemperature")}{" "}
									<span className="icon icn-chevron-right"></span>
								</a>
							</div>
						</ContentWrapper>
					)}
				</div>
			</SurveyContainerWrapper>
		);
	}
}

Batches.propTypes = {
	username: PropTypes.string,
	culture: PropTypes.string,
	batches: PropTypes.array,
	onLogout: PropTypes.func,
	onBatchClick: PropTypes.func,
	onWidgetClick: PropTypes.func,
	fetching: PropTypes.bool,
	widgetsEnabled: PropTypes.bool,
	questionSettings: PropTypes.object,
};

export default withTranslation()(Batches);
