// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import { GradeType, GradeConfig, GradeHMEConfig } from "Enums";
import { Smiley, SmileyHme, SmileyEnps, SmileyGeneral } from "Controls/Smiley";

class SmileyFactory extends PureComponent {
	getColor = () => {
		if (this.props.gradeType === GradeType.enps) {
			let color;

			if (this.props.grade < 7) {
				color = Colors.smileyStronglyDisagree;
			} else if (this.props.grade < 9) {
				color = Colors.smileyNeutral;
			} else {
				color = Colors.smileyStronglyAgree;
			}

			return this.props.color || color;
		}

		const gradeConfig = this.props.gradeType === GradeType.hme ? GradeHMEConfig : GradeConfig;

		return this.props.color || gradeConfig[this.props.grade].color;
	};

	getSmileyProps = () => {
		return {
			isWithSmiley: this.props.isWithSmiley,
			isReadOnly: this.props.isReadOnly,
			isActive: this.props.isActive,
			isTemperature: this.props.isTemperature,
			isWithShake: this.props.isWithShake,
			grade: this.props.grade,
			temperatureHeight: this.props.temperatureHeight,
			temperatureWidth: this.props.temperatureWidth,
			position: this.props.position,
			left: this.props.left,
			top: this.props.top,
			leftXS: this.props.leftXS,
			topXS: this.props.topXS,
			margin: this.props.margin || "0",
			smileySize: this.props.iconSize || "32px",
			gradeFontSize: this.props.gradeFontSize,
			gradeOpacity: this.props.gradeOpacity,
			color: this.getColor(),
			onClick: this.props.onClick,
		};
	};

	render() {
		switch (this.props.gradeType) {
			case GradeType.hme:
				return <SmileyHme {...this.getSmileyProps()} />;
			case GradeType.enps:
				return <SmileyEnps {...this.getSmileyProps()} />;
			case GradeType.general:
				return <SmileyGeneral {...this.getSmileyProps()} />;
			case GradeType.default:
			default:
				return <Smiley {...this.getSmileyProps()} />;
		}
	}
}

SmileyFactory.propTypes = {
	isWithSmiley: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isActive: PropTypes.bool,
	isTemperature: PropTypes.bool,
	isWithShake: PropTypes.bool,
	gradeType: PropTypes.number,
	grade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	iconSize: PropTypes.string,
	temperatureHeight: PropTypes.string,
	temperatureWidth: PropTypes.string,
	margin: PropTypes.string,
	color: PropTypes.string,
	position: PropTypes.string,
	left: PropTypes.string,
	top: PropTypes.string,
	leftXS: PropTypes.string,
	topXS: PropTypes.string,
	gradeFontSize: PropTypes.string,
	gradeOpacity: PropTypes.string,
};

export default SmileyFactory;
