import Colors from "Colors";

const font = {
	bold: {
		fontWeight: 600,
	},
	medium: {
		fontWeight: 500,
	},
	regular: {
		fontWeight: 400,
	},
};

const colors = Colors;

const shadow = {
	ground: "0.0px 2.0px 4.0px 0px rgba(0, 0, 0, 0.1)",
	raise: "0.0px 4.0px 8.0px 0px rgba(0, 0, 0, 0.1)",
	pop: "0.0px 2.0px 12.0px 0px rgba(0, 0, 0, 0.1)",
	lift: "0.0px 4.0px 16.0px 0px rgba(0, 0, 0, 0.1)",
	fly: "0.0px 6.0px 28.0px 0px rgba(0, 0, 0, 0.15)",
	overlay: "0.0px 8.0px 32.0px 0px rgba(0, 0, 0, 0.2)",
	redOutline: "0px 0px 0px 4px rgba(255, 68, 76, 0.12)",
	greenOutline: "0px 0px 0px 4px rgba(0, 189, 171, 0.12)",
	grayOutline: "0px 0px 0px 4px rgba(204, 204, 204, 0.2)",
	selectionControlFocusOutline: `0px 0px 0px 4px rgba(0, 189, 171, 0.12)`,
	selectionControlHoverOutline: `0px 0px 0px 4px rgba(204, 204, 204, 0.2);`,
};

const border = {
	jungle: `1px solid ${colors.jungle}`,
	metal: `1px solid ${colors.metal}`,
	fog: `1px solid ${colors.fog}`,
	lightTurquoise: `1px solid ${colors.lightTurquoise}`,
	blue: `1px solid ${colors.blue}`,
	lightBlue: `1px solid ${colors.lightBlue}`,
	pink: `1px solid ${colors.pink}`,
	alternativelightPurple: `1px solid ${colors.alternativeLightPurple}`,
	coral: `1px solid ${colors.coral}`,
	lightRed: `1px solid ${colors.lightRed}`,
	yellow: `1px solid ${colors.yellow}`,
	lightYellow: `1px solid ${colors.lightYellow}`,
	vibrantOrange: `1px solid ${colors.vibrantOrange}`,
	lightOrange: `1px solid ${colors.lightOrange}`,
	smoke: `1px solid ${colors.smoke}`,
	red: `1px solid ${colors.red}`,
};

const transition = {
	main: "ease-out 200ms",
	modal: "ease-out 400ms",
};

const typography = {
	font,
	normalBold: {
		...font.bold,
		fontSize: "16px",
		lineHeight: "20px",
	},
	normalMedium: {
		...font.medium,
		fontSize: "16px",
		lineHeight: "20px",
	},
	normalRegular: {
		...font.regular,
		fontSize: "16px",
		lineHeight: "20px",
	},
	smallRegular: {
		...font.regular,
		fontSize: "13px",
		lineHeight: "15px",
	},
};

const opacity = {
	hover: "0.6",
};

const borderRadius = {
	main: "6px",
};

const theme = {
	shadow,
	colors,
	border,
	transition,
	typography,
	opacity,
	borderRadius,
};

export default theme;

export { shadow, colors, border, transition, typography, opacity, borderRadius };
