import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCulture } from '../actions/culture';
import { changeCulture } from '../actions/general';
import Start from '../components/Start';

const mapStateToProps = (state) => {
    return {
        culture: getCulture(state),
        languages: state.general.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {       
        onCultureChange: function(culture) {
            dispatch(changeCulture(culture));
        },
        onSubmit: function(customer) {
            // navigate without router to 
            // access controller from server-side.
            window.location.href = "/" + customer;
        }
    };
};

const StartContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Start));

export default StartContainer;