// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import { guid } from "Helpers";

class CircleWrapper extends PureComponent {
	render() {
		const filterId = `gradeCircleFilter-${guid.newValue()}`;
		const styles = {
			width: this.props.size || "46px",
			height: this.props.size || "46px",
			margin: this.props.margin,
		};

		return (
			<svg
				role="presentation"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				style={styles}
				viewBox="0 0 48 48">
				<g fill="none" fillRule="evenodd">
					<g transform="translate(4 2)">
						<g fillRule="nonzero">
							<filter
								id={filterId}
								width="135.1%"
								height="135.2%"
								x="-17.6%"
								y="-12.6%"
								filterUnits="objectBoundingBox">
								<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
								<feGaussianBlur
									in="shadowOffsetOuter1"
									result="shadowBlurOuter1"
									stdDeviation="2"
								/>
								<feColorMatrix
									in="shadowBlurOuter1"
									values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
								/>
							</filter>
							<g fill="#000" filter={`url(#${filterId})`}>
								<path d="M19.937 39.827C8.707 39.827.651 31.77.028 20.6-.585 9.595 8.815 0 19.938 0c11.122 0 20.52 9.595 19.907 20.601-.622 11.17-8.679 19.226-19.909 19.226z" />
							</g>
							<g fill={this.props.isActive ? Colors.mainBlue : Colors.white}>
								<path d="M19.937 39.827C8.707 39.827.651 31.77.028 20.6-.585 9.595 8.815 0 19.938 0c11.122 0 20.52 9.595 19.907 20.601-.622 11.17-8.679 19.226-19.909 19.226z" />
							</g>
						</g>
						<mask fill="#fff">
							<path d="M19.937 36.797c9.844 0 16.364-7.04 16.883-16.364.52-9.324-7.559-17.403-16.883-17.403-9.324 0-17.402 8.079-16.883 17.403.52 9.324 7.04 16.364 16.883 16.364z" />
						</mask>
						<g fill={this.props.color} fillRule="nonzero">
							<path d="M19.937 36.797c9.844 0 16.364-7.04 16.883-16.364.52-9.324-7.559-17.403-16.883-17.403-9.324 0-17.402 8.079-16.883 17.403.52 9.324 7.04 16.364 16.883 16.364z" />
						</g>
						<g className="smiley-background" fill={Colors.white} opacity="0">
							<path d="M19.937 36.797c9.844 0 16.364-7.04 16.883-16.364.52-9.324-7.559-17.403-16.883-17.403-9.324 0-17.402 8.079-16.883 17.403.52 9.324 7.04 16.364 16.883 16.364z" />
						</g>
						<path
							fill="#FFF"
							fillOpacity=".7"
							fillRule="nonzero"
							d="M12.342 4.736c.347.139-2.698 4.465-2.685 10.239.014 5.774 6.1 13.69 5.757 13.846-.343.156-7.428-.99-9.702-9.894-2.274-8.905 6.283-14.33 6.63-14.19z"
							mask="url(#d)"
							transform="rotate(20 10.378 16.784)"
						/>
					</g>
					{this.props.children}
				</g>
			</svg>
		);
	}
}

CircleWrapper.propTypes = {
	isActive: PropTypes.bool,
	size: PropTypes.string,
	color: PropTypes.string,
	margin: PropTypes.string,
};

export default CircleWrapper;
