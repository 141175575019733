import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loginUser } from '../actions/authentication';
import { changeCulture } from '../actions/general';
import { getCulture } from '../actions/culture';
import { batchesUrl } from '../actions/router';
import Login from '../components/Login';
import { withBackgroundHeightAdjust } from '../components/WithBackgroundHeightAdjust';

const mapStateToProps = (state) => {
    return {
        authenticated: state.authentication.authenticated,
        culture: getCulture(state),
        customerId: state.general.customerId,
        languages: state.general.languages,
        error: state.authentication.error,
        urls: {
            batches: batchesUrl()
        }
    };
};

const mapDispatchToProps = (dispatch) => {    
    return {
        onSubmit: function(username, customerId) {
            dispatch(loginUser(username, customerId));
        },
        onCultureChange: function(culture) {
            dispatch(changeCulture(culture));
        }
    };
};

const LoginContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withBackgroundHeightAdjust(Login)));

export default LoginContainer;