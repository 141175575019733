// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";

const SurveyContainerWrapper = styled.div`
	background-color: ${Colors.questionWindowBackground};
	padding-top: ${(props) => props.paddingTop || "77px"};
	min-height: 100vh;
	@media (max-width: 768px) {
		${(props) => (props.paddingTopXS ? `padding-top: ${props.paddingTopXS};` : null)};
		padding-bottom: ${(props) => (props.isSubmitted ? "0" : "150px")};
	}
	@media (max-width: 430px) {
		${(props) => (props.paddingTopXS ? `padding-top: ${props.paddingTopXS};` : null)};
		padding-bottom: ${(props) => (props.isSubmitted ? "0" : "200px")};
	}
`;

SurveyContainerWrapper.propTypes = {
	isSubmitted: PropTypes.bool,
	paddingTop: PropTypes.string,
	paddingTopXS: PropTypes.string,
};

export default SurveyContainerWrapper;
