import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";

class QuestionCounter extends PureComponent {
	render() {
		return(
			<ContentWrapper
				padding={this.props.questionTextPadding || "10px 10px 5px"}
				paddingBottom={this.props.isMinified ? "0" : "5px"}
				backgroundColor={this.props.isMinified && !this.props.isEnps ? Colors.backgroundLightGray : null}
				fontSize="13px"
				color={this.props.color || Colors.textGray}>
				{`${Resources[this.props.culture].question} ${this.props.questionIndex + 1} / ${this.props.questionsCount}`}
			</ContentWrapper>
		);
	}
}

QuestionCounter.propTypes = {
	isMinified: PropTypes.bool,
	isEnps: PropTypes.bool,
	questionIndex: PropTypes.number,
	questionsCount: PropTypes.number,
	questionTextPadding: PropTypes.string,
	culture: PropTypes.string,
	color: PropTypes.string,
}

export default QuestionCounter;