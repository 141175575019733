import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SvgIcon from "Controls/SvgIcon";
import { spacingIndex } from "ComponentsLibrary/shared/spacing";
import { typography, colors } from "ComponentsLibrary/shared/theme";

const ListItemWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.isWithHighlightBar ? "flex-start" : "space-between")};
	cursor: pointer;
	min-height: ${spacingIndex * 4}px;
	padding: ${(props) => (props.paddingVertical ? `${props.paddingVertical}px 0` : "0")};
	padding-right: ${spacingIndex}px;
	padding-left: ${(props) =>
		props.paddingLeft ? (props.isWithBorder ? props.paddingLeft - 4 : props.paddingLeft) + "px" : "10px"};
	background-color: ${(props) => (props.isItemDisabled ? "#fff" : props.backgroundColor || "inherit")};
	&:hover {
		background-color: ${(props) =>
			props.isItemDisabled ? colors.white : props.hoverBackgroundColor || colors.lightTurquoise};
		cursor: ${(props) => (props.isItemDisabled ? "default" : "pointer")};

		& > div {
			background-color: ${(props) => (props.isWithHighlightBar ? colors.jungle : "inherit")};
		}
	}
	@media (max-width: 767px) {
		display: none;
	}
`;

const ListItemWrapperMobile = styled.div`
	display: none;
	align-items: center;
	cursor: pointer;
	min-height: ${spacingIndex * 4}px;
	padding-right: ${spacingIndex}px;
	padding: ${(props) => (props.paddingVertical ? `${props.paddingVertical}px 0` : "0")};
	padding-left: ${(props) =>
		props.paddingLeft ? (props.isWithBorder ? props.paddingLeft - 4 : props.paddingLeft) + "px" : "10px"};
	background-color: ${(props) => (props.isItemDisabled ? "#fff" : props.backgroundColor || "inherit")};
	&:hover {
		background-color: ${(props) => (props.isItemDisabled ? "#fff" : colors.lightTurquoise)};
		cursor: ${(props) => (props.isItemDisabled ? "default" : "pointer")};
		& > div {
			background-color: ${(props) => (props.isWithHighlightBar ? colors.jungle : "inherit")};
		}
	}
	@media (max-width: 767px) {
		display: flex;
	}
`;

const ListItemLabel = styled.span`
	user-select: none;
	${typography.normalRegular};
	color: ${(props) => (props.isItemDisabled ? colors.metal : colors.onyx)};
`;

const HiglightBar = styled.div`
	background-color: ${(props) => props.backgroundColor || colors.fog};
	min-height: 40px;
	margin-right: 12px;
	width: 4px;
	border-radius: 12px;
	flex-shrink: 0;
`;

const DropdownListItem = (props) => {
	const paddingLeft = props.recursionLevel * props.paddingIndex + props.itemPaddingIndex;
	const paddingLeftMobile = props.recursionLevel * props.paddingIndex + props.itemPaddingIndexMobile;
	const backgroundColorHiglightBar = props.isSelected && colors.jungle;
	const backgroundColor =
		!props.isMultiSelect &&
		!props.highlightSelectedItemColor &&
		props.isSelected &&
		colors.lightTurquoise;

	if (props.renderCustomItem) {
		return props.renderCustomItem(
			props.item,
			props.isSelected,
			props.isParentSelected,
			paddingLeft,
			paddingLeftMobile,
			props.itemIndex,
			props.itemsLength
		);
	}

	return (
		<div>
			<ListItemWrapper
				data-test={"list-item-" + props.dataTestItem}
				isItemDisabled={props.isItemDisabled}
				isWithBorder={props.isSelected && props.highlightSelectedItemColor}
				hoverBackgroundColor={props.hoverBackgroundColor}
				backgroundColor={backgroundColor}
				paddingLeft={props.highlightSelectedItemColor ? 45 : paddingLeft}
				isWithHighlightBar={props.isWithHighlightBar}
				paddingVertical={props.paddingVertical}>
				{props.isWithHighlightBar && (
					<HiglightBar {...{ backgroundColor: backgroundColorHiglightBar }} />
				)}
				<ListItemLabel
					isItemDisabled={props.isItemDisabled}
					notHighlightSelectedItemsText={props.notHighlightSelectedItemsText}
					liFontWeight={props.liFontWeight}
					itemFontSize={props.itemFontSize}
					highlightSelectedItemColor={props.highlightSelectedItemColor}
					selected={props.isSelected}>
					{props.item.Name}
				</ListItemLabel>
				{props.isMultiSelect && (
					<SvgIcon
						fill={props.isSelected ? colors.jungle : "transparent"}
						size="16px"
						icon="check-16"
					/>
				)}
			</ListItemWrapper>
			<ListItemWrapperMobile
				isItemDisabled={props.isItemDisabled}
				isWithBorder={props.isSelected && props.highlightSelectedItemColor}
				backgroundColor={backgroundColor}
				paddingLeft={props.highlightSelectedItemColor ? 45 : paddingLeftMobile}>
				{props.isMultiSelect && (
					<SvgIcon
						fill={props.isSelected ? colors.jungle : "transparent"}
						size="16px"
						icon="check-16"
						margin={`0 ${spacingIndex}px 0 0`}
					/>
				)}
				<ListItemLabel
					isItemDisabled={props.isItemDisabled}
					notHighlightSelectedItemsText={props.notHighlightSelectedItemsText}
					liFontWeight={props.liFontWeight}
					highlightSelectedItemColor={props.highlightSelectedItemColor}
					selected={props.isSelected}>
					{props.item.Name}
				</ListItemLabel>
			</ListItemWrapperMobile>
		</div>
	);
};

DropdownListItem.propTypes = {
	isMultiSelect: PropTypes.bool,
	curvedCheckmark: PropTypes.bool,
	checkmarkHiddenForNotCheckedItems: PropTypes.bool,
	isItemDisabled: PropTypes.bool,
	isSelected: PropTypes.bool,
	isParentSelected: PropTypes.bool,
	notHighlightSelectedItemsText: PropTypes.bool,
	recursionLevel: PropTypes.number,
	itemPaddingIndex: PropTypes.number,
	itemPaddingIndexMobile: PropTypes.number,
	itemIndex: PropTypes.number,
	itemsLength: PropTypes.number,
	paddingIndex: PropTypes.number,
	dataTestItem: PropTypes.string,
	highlightSelectedItemColor: PropTypes.string,
	hoverBackgroundColor: PropTypes.string,
	liFontWeight: PropTypes.string,
	itemFontSize: PropTypes.string,
	checkmarkColor: PropTypes.string,
	item: PropTypes.object,
	renderCustomItem: PropTypes.func,
	isWithHighlightBar: PropTypes.bool,
	paddingVertical: PropTypes.number,
};

export default React.memo(DropdownListItem);
