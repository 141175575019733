// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import { QuestionAdditionalStrategy } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import Text from "Controls/Text";
import SimpleDropdownSelect from "Controls/SimpleDropdownSelect";
import QuestionTalkModalFooterWrapper from "./QuestionTalkModalFooterWrapper";
import QuestionTalkInputLabel from "./QuestionTalkInputLabel";
import Question from "../Question";

class QuestionSelectContextScreen extends PureComponent {
	getContextDropdownLabel = () => {
		switch (this.props.question.additionalInformationStrategy) {
			case QuestionAdditionalStrategy.negativeDiscrimination:
				return Resources[this.props.culture].inWhatContextHaveYourExperiencedDiscrimination;
			case QuestionAdditionalStrategy.negativeBullying:
				return Resources[this.props.culture].inWhatContextHaveYourExperiencedBullying;
			default:
				return "";
		}
	};

	render() {
		return (
			<ContentWrapper
				backgroundColor={Colors.questionWindowBackground}
				backgroundColorXS={Colors.questionWindowBackgroundXS}
				paddingBottom="0"
				minHeightXS="99vh"
				paddingTopXS="60px">
				<ContentWrapper position="relative" padding="10px 10px 0">
					<ContentWrapper
						backgroundColor={Colors.backgroundLightGray}
						borderRadius="4px"
						padding="20px 15px 30px">
						<Question
							questionTextPadding="20px"
							questionPadding="0"
							question={this.props.question}
							isCommentDisabled={true}
							isReadOnlyAfterSubmit={true}
							culture={this.props.culture}
							questionColor={Colors.darkGrayText}
							questionFontSize="18px"
							questionFontSizeXS="16px"
						/>
						<Text
							color={Colors.textGray}
							display="inline-block"
							fontWeight="500"
							fontSize="14px"
							lineHeight="18px"
							dangerouslySetInnerHTML={{
								__html: Resources[this.props.culture].shareAdditionalInfoText,
							}}></Text>
						<QuestionTalkInputLabel text={this.getContextDropdownLabel()} />
						<SimpleDropdownSelect
							selected={this.props.selectedContextOption}
							labelPropName="Name"
							onChange={this.props.onContextOptionChange}
							options={this.props.contextOptions}
							border={`1px solid ${Colors.borderLightGray}`}
						/>
						{this.props.detailsOptions && !!this.props.detailsOptions.length && (
							<React.Fragment>
								<QuestionTalkInputLabel
									text={Resources[this.props.culture].whatIsTheExperienceAssociatedWith}
								/>
								<SimpleDropdownSelect
									selected={this.props.selectedDetailOption}
									labelPropName="Name"
									onChange={this.props.onDetailOptionChange}
									options={this.props.detailsOptions}
									border={`1px solid ${Colors.borderLightGray}`}
								/>
							</React.Fragment>
						)}
					</ContentWrapper>
				</ContentWrapper>
				<QuestionTalkModalFooterWrapper
					nextButtonText={this.props.questionSettings?.isCommentDisabled ? Resources[this.props.culture].send : Resources[this.props.culture].next}
					nextButtonAttribute={this.props.questionSettings?.isCommentDisabled ? "follow-up-strategy-questions-step-4-send" : "follow-up-strategy-questions-step-3-next"}
					onNextClick={this.props.questionSettings?.isCommentDisabled ? this.props.onSendClick : this.props.onNextClick}
				/>
			</ContentWrapper>
		);
	}
}

QuestionSelectContextScreen.propTypes = {
	culture: PropTypes.string,
	question: PropTypes.object,
	selectedDetailOption: PropTypes.object,
	selectedContextOption: PropTypes.object,
	questionSettings: PropTypes.object,
	detailsOptions: PropTypes.array,
	contextOptions: PropTypes.array,
	onNextClick: PropTypes.func,
	onSendClick: PropTypes.func,
	onDetailOptionChange: PropTypes.func,
	onContextOptionChange: PropTypes.func,
};

export default QuestionSelectContextScreen;
