import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { spacing } from "./shared/spacing";
import font from "./shared/font";

const style = css`
	margin: 0;
	padding: 0;
	${spacing}
	${(props) => font(props.fontType)}
	${(props) => props.display && `display: ${props.display};`}
	${(props) => props.flex && `flex: ${props.flex};`}
	${(props) => props.textAlign && `text-align: ${props.textAlign};`}
	color: ${(props) => props.color || "#353A40"};
	${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
	${(props) =>
		props.isEllipsis &&
		`
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	`}
`;

const Header = styled.h1`
	${style};
`;

const Span = styled.span`
	${style};
`;

const Text = (props) => {
	if (
		props.fontType === "headerHuge" ||
		props.fontType === "headerBold" ||
		props.fontType === "headerMedium" ||
		props.fontType === "headerRegular"
	) {
		return (
			<Header data-test={props.dataTest} {...props}>
				{props.children}
			</Header>
		);
	}

	return (
		<Span data-test={props.dataTest} {...props}>
			{props.children}
		</Span>
	);
};

Text.propTypes = {
	isEllipsis: PropTypes.bool,
	color: PropTypes.string,
	display: PropTypes.string,
	textAlign: PropTypes.string,
	lineHeight: PropTypes.string,
	fontType: PropTypes.oneOf([
		"headerCosmic",
		"headerMassive",
		"headerHuge",
		"headerBold",
		"headerMedium",
		"headerRegular",
		"hugeBold",
		"hugeMedium",
		"hugeRegular",
		"largeBold",
		"largeMedium",
		"largeRegular",
		"normalBold",
		"normalMedium",
		"normalRegular",
		"smallBold",
		"smallRegular",
		"microBold",
		"microRegular",
	]),
	children: PropTypes.node,
};

Text.defaultProps = {
	isEllipsis: null,
	color: "#353A40",
	fontType: "normalMedium",
	children: "Text",
	display: null,
	textAlign: null,
};

export default Text;
