// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.ul`
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 0 3px 3px;
    border: solid 1px #cccccc;
    position: absolute;
    z-index: 1000;
    max-height: ${props => props.maxHeight || "300px"};
    overflow: auto;
    list-style-type: none;
    padding-left: 0px;
    user-select: none;
`;

const ListItem = styled.li`
    color: ${props => props.color || "#333333"};
    cursor: pointer;
    display: block;
    text-align: left;
    font-weight: 400;
    line-height: 1.42857;
    padding: 3px 20px;
    white-space: ${props => props.isWithLongItems ? "unset" : "nowrap"};
    background-color: ${props => props.isActive ? "#f5f5f5" : "transparent"};
    &:hover {
        color: #262626;
        background-color: #f5f5f5;
    }
`;

class DropdownComponent extends PureComponent {
	render() {
		return (
			<Wrapper maxHeight={this.props.maxHeight}>
				{
					this.props.options.map((item, index) =>
						<ListItem
							key={index}
							color={this.props.itemColor}
							isActive={this.props.selected === item}
							isWithLongItems={this.props.isWithLongItems}
							onClick={() => this.props.onItemClick(item)}>
							{item[this.props.labelPropName]}
						</ListItem>
					)
				}
			</Wrapper>
		);
	}
}

DropdownComponent.propTypes = {
	isWithLongItems: PropTypes.bool,
	maxHeight: PropTypes.string,
	labelPropName: PropTypes.string,
	itemColor: PropTypes.string,
	selected: PropTypes.object,
	options: PropTypes.array,
	onItemClick: PropTypes.func
}

export default DropdownComponent;