import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";

const TextAreaComponent = styled.textarea`
	display: ${props => props.display || "block" };
	width: ${props => props.width || "auto" };
	height: ${props => props.height || "auto" };
	border: ${props => props.border || `1px solid ${Colors.borderLightGray}` };
	margin: ${props => props.margin || "0" };
	padding: ${props => props.padding || "0" };
	background-color: ${props => props.backgroundColor || "#ffffff" };
	font-size: ${props => props.fontSize || "14px" };
	box-shadow: ${props => props.boxShadow || "none" };
	${props => props.transition ? `transition: ${props.transition}` : null };
	${props => props.color ? `color: ${props.color}` : null };
	${props => props.lineHeight ? `line-height: ${props.lineHeight}` : null };
	${props => props.borderRadius ? `border-radius: ${props.borderRadius}` : null };
	${props => props.fontWeight ? `font-weight: ${props.fontWeight}` : null };
	${props => props.boxSizing ? `box-sizing: ${props.boxSizing}` : null };
	${props => props.maxHeight ? `max-height: ${props.maxHeight}` : null };
	${props => props.minHeight ? `min-height: ${props.minHeight}` : null };
	${props => props.maxWidth ? `max-width: ${props.maxWidth}` : null };
	${props => props.minWidth ? `min-width: ${props.minWidth}` : null };
	${props => props.resize ? `resize: ${props.resize}` : null };
	${props => props.borderWidth ? `border-width: ${props.borderWidth}` : null };
	@media(max-width: 768px) {
		${props => props.paddingXS ? `padding: ${props.paddingXS};` : null}
	}
	&::placeholder {
		color: ${props => props.placeholderColor || Colors.textLighterGray};
	}
	&:focus {
		box-shadow: ${props => props.focusBoxShadow || "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"};
		border-color: ${props => props.focusBorderColor || Colors.inputFocusBorder};
		${props => props.focusBackgroundColor ? `background-color: ${props.focusBackgroundColor}` : null };
	}
	&:disabled {
		${props => props.disabledBackgroundColor ? `background-color: ${props.disabledBackgroundColor};` : null}
	}
`;

TextAreaComponent.propTypes = {
	display: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	border: PropTypes.string,
	margin: PropTypes.string,
	padding: PropTypes.string,
	transition: PropTypes.string,
	backgroundColor: PropTypes.string,
	focusBackgroundColor: PropTypes.string,
	color: PropTypes.string,
	lineHeight: PropTypes.string,
	boxShadow: PropTypes.string,
	borderRadius: PropTypes.string,
	fontWeight: PropTypes.string,
	focusBoxShadow: PropTypes.string,
	focusBorderColor: PropTypes.string,
	boxSizing: PropTypes.string,
	maxWidth: PropTypes.string,
	minWidth: PropTypes.string,
	maxHeight: PropTypes.string,
	minHeight: PropTypes.string,
	resize: PropTypes.string,
	paddingXS: PropTypes.string,
	disabledBackgroundColor: PropTypes.string,
	borderWidth: PropTypes.string,
}

export default TextAreaComponent;