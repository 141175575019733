// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class SmileyHmeDisagree extends PureComponent {
    render() {
        return(
            <g fill="none" fillRule="nonzero" transform={this.props.isTemperature ? "translate(0 26)" : ""}>
                <path fill="#402815" d="M28.8 28.6h-7.48v-1.57l2.69-2.72c.8-.81 1.31-1.38 1.56-1.7.24-.3.42-.6.52-.87.11-.27.17-.54.17-.83 0-.43-.12-.75-.36-.96a1.38 1.38 0 0 0-.95-.32c-.41 0-.82.1-1.2.29-.4.19-.8.46-1.23.81l-1.23-1.45c.53-.45.96-.77 1.31-.96.35-.18.73-.33 1.14-.43.4-.1.87-.15 1.37-.15.67 0 1.26.13 1.78.37a2.72 2.72 0 0 1 1.62 2.53c0 .5-.1.96-.27 1.4-.17.42-.44.87-.8 1.32-.36.46-1 1.1-1.92 1.94l-1.38 1.3v.1h4.66v1.9z" opacity=".8"/>
            </g>
        );
    }
}

SmileyHmeDisagree.propTypes = {
    isTemperature: PropTypes.bool
}

export default SmileyHmeDisagree;