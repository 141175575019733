// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import Colors from "Colors";
import ContentWrapper from "Controls/ContentWrapper";
import TextAreaComponent from "Controls/TextAreaComponent";
import Text from "Controls/Text";
import QuestionCommentManagerToggle from "../QuestionCommentManagerToggle";
import AIAssistedRepliesToggle from "../AIAssistedRepliesToggle";
import QuestionTalkModalFooterWrapper from "./QuestionTalkModalFooterWrapper";
import Question from "../Question";

class QuestionDescribeDetailsScreen extends PureComponent {
	onCommentChange = (event) => {
		this.props.onCommentChange(event.target.value);
	};

	render() {
		return (
			<ContentWrapper
				backgroundColor={Colors.questionWindowBackground}
				backgroundColorXS={Colors.questionWindowBackgroundXS}
				paddingBottom="0"
				minHeightXS="99vh"
				paddingTopXS="60px">
				<ContentWrapper position="relative" padding="10px 10px 0">
					<ContentWrapper
						backgroundColor={Colors.backgroundLightGray}
						borderRadius="4px"
						padding="20px 15px 30px">
						<Question
							questionTextPadding="20px"
							questionPadding="0"
							question={this.props.question}
							isCommentDisabled={true}
							isReadOnlyAfterSubmit={true}
							culture={this.props.culture}
							questionColor="#4c4c4c"
							addCommentColor="#7b828c"
							questionFontSize="18px"
							questionFontSizeXS="16px"
						/>
						<Text
							display="inline-block"
							width="100%"
							margin="35px 0 2px"
							color={Colors.textGray}
							fontSize="14px"
							fontWeight="600"
							lineHeight="20px"
							dangerouslySetInnerHTML={{
								__html: Resources[this.props.culture].describeExperienceTheSituation,
							}}
						/>
						<TextAreaComponent
							value={this.props.comment}
							borderRadius="3px"
							height="140px"
							padding="10px 15px"
							fontSize="14px"
							fontWeight="500"
							color={Colors.darkGrayText}
							maxWidth="100%"
							width="100%"
							boxShadow={`0 2px 2px ${Colors.borderLightGray}`}
							onChange={this.onCommentChange}
						/>
						<ContentWrapper display="flex" displayXS="none" padding="10px 0 0">
							<QuestionCommentManagerToggle
								isVisible={
									!this.props.questionSettings ||
									this.props.questionSettings.allowEmployeeHideCommentFromManager
								}
								isVisibleForImmediateManager={this.props.isVisibleForImmediateManager}
								culture={this.props.culture}
								onVisibleForImmediateManagerChange={
									this.props.onVisibleForImmediateManagerChange
								}
							/>
						</ContentWrapper>
						<ContentWrapper display="none" displayXS="flex" padding="10px 0 0">
							<QuestionCommentManagerToggle
								isVisible={
									!this.props.questionSettings ||
									this.props.questionSettings.allowEmployeeHideCommentFromManager
								}
								isVisibleForImmediateManager={this.props.isVisibleForImmediateManager}
								culture={this.props.culture}
								onDescriptionOpen={this.props.commentDescriptionToggle}
								onVisibleForImmediateManagerChange={
									this.props.onVisibleForImmediateManagerChange
								}
							/>
						</ContentWrapper>
						<ContentWrapper display="flex" displayXS="none" padding="10px 0 0">
							<AIAssistedRepliesToggle
								isVisible={
									!this.props.questionSettings ||
									this.props.questionSettings.allowAiAssistedRepliesToggle
								}
								checked={this.props.allowAiAssistedReplies}
								culture={this.props.culture}
								onAllowAiAssistedRepliesChange={this.props.onAllowAiAssistedRepliesChange}
							/>
						</ContentWrapper>
						<ContentWrapper display="none" displayXS="flex" padding="10px 0 0">
							<AIAssistedRepliesToggle
								isVisible={
									!this.props.questionSettings ||
									this.props.questionSettings.allowAiAssistedRepliesToggle
								}
								checked={this.props.allowAiAssistedReplies}
								culture={this.props.culture}
								onAllowAiAssistedRepliesChange={this.props.onAllowAiAssistedRepliesChange}
							/>
						</ContentWrapper>
					</ContentWrapper>
				</ContentWrapper>
				<QuestionTalkModalFooterWrapper
					nextButtonText={Resources[this.props.culture].send}
					backButtonText={Resources[this.props.culture].back}
					nextButtonAttribute="follow-up-strategy-questions-step-4-send"
					backButtonAttribute="follow-up-strategy-questions-step-4-back"
					onBackClick={this.props.onBackClick}
					onNextClick={this.props.onSendClick}
				/>
			</ContentWrapper>
		);
	}
}

QuestionDescribeDetailsScreen.propTypes = {
	allowAiAssistedReplies: PropTypes.bool,
	isVisibleForImmediateManager: PropTypes.bool,
	culture: PropTypes.string,
	comment: PropTypes.string,
	question: PropTypes.object,
	questionSettings: PropTypes.object,
	onSendClick: PropTypes.func,
	onBackClick: PropTypes.func,
	onCommentChange: PropTypes.func,
	onVisibleForImmediateManagerChange: PropTypes.func,
	commentDescriptionToggle: PropTypes.func,
	onAllowAiAssistedRepliesChange: PropTypes.func,
};

export default QuestionDescribeDetailsScreen;
