// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Resources from "Resources";
import Colors from "Colors";
import { TemperatureTemplate } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import { SmileyFactory } from "Controls/Smiley";
import ButtonComponent from "Controls/ButtonComponent";
import Text from "Controls/Text";
import QuestionCommentHeaderMobile from "./QuestionCommentHeaderMobile";
import QuestionCommentManagerToggle from "./QuestionCommentManagerToggle";
import QuestionAnonymousLabel from "./QuestionAnonymousLabel";
import AIAssistedRepliesToggle from "./AIAssistedRepliesToggle";

const Wrapper = styled(ContentWrapper)`
	padding-top: 0;
	padding-top: env(safe-area-inset-top);
`;

const TextArea = styled.textarea`
	display: flex;
	flex-grow: 1;
	resize: none;
	box-sizing: border-box;
	overflow-x: hidden;
	width: 100%;
	min-height: 48px;
	max-height: 160px;
	border: 1px solid #ededed;
	box-shadow: 0 2px 0 0 #ced3d9;
	border-radius: 2px;
	background-color: #fff;
	padding: 15px;
	font-size: 14px;
	font-weight: 300;
	line-height: 18px;
`;

class QuestionCommentMobile extends PureComponent {
	constructor(props) {
		super(props);

		this.textArea = React.createRef();
	}

	componentDidMount = () => {
		// fix for ios mobile
		if (window.innerWidth <= 768) {
			setTimeout(() => {
				this.textArea.current.focus();
				this.textArea.current.value = "";
				this.textArea.current.value = this.props.comment;
			});
		}
	};

	render() {
		return (
			<Wrapper
				display="none"
				displayXS="flex"
				flexDirection="column"
				position="fixed"
				zIndex="2"
				width="100%"
				height="100%"
				backgroundColor={Colors.questionWindowBackgroundXS}
				top="0"
				left="0">
				<QuestionCommentHeaderMobile
					title={Resources[this.props.culture].comment}
					buttonText={Resources[this.props.culture].cancel}
					onButtonClick={this.props.onCommentCancel}
				/>

				<SmileyFactory
					isTemperature={this.props.questionType === TemperatureTemplate.default}
					iconSize={this.props.questionType !== TemperatureTemplate.default ? "50px" : null}
					isReadOnly={true}
					position="absolute"
					left="10px"
					top="70px"
					gradeType={this.props.questionType}
					grade={this.props.grade}
					color={this.props.color}
				/>

				<ContentWrapper
					display="block"
					padding="15px 15px 15px 65px"
					minHeight={this.props.questionType !== TemperatureTemplate.default ? "64px" : "94px"}
					backgroundColor={Colors.backgroundLightGray}>
					<Text fontSize="17px" color={Colors.textDarkestGray} lineHeight="23px">
						{this.props.questionText}
					</Text>
				</ContentWrapper>

				<TextArea
					rows={3}
					ref={this.textArea}
					value={this.props.comment}
					placeholder={Resources[this.props.culture].leaveAVoluntaryComment}
					onChange={this.props.onCommentChange}
				/>

				<ContentWrapper display="flex" padding="10px" backgroundColor={Colors.backgroundLightGray}>
					<QuestionCommentManagerToggle
						isVisible={
							this.props.isHideCommentFromManagerVisible &&
							!!this.props.comment &&
							!!this.props.comment.length
						}
						isVisibleForImmediateManager={this.props.isVisibleForImmediateManager}
						culture={this.props.culture}
						onVisibleForImmediateManagerChange={this.props.onVisibleForImmediateManagerChange}
						onDescriptionOpen={this.props.onDescriptionOpen}
					/>
					<AIAssistedRepliesToggle
						isVisible={
							this.props.isAllowAiAssistedRepliesVisible &&
							!!this.props.comment &&
							!!this.props.comment.length
						}
						checked={this.props.allowAiAssistedReplies}
						culture={this.props.culture}
						onAllowAiAssistedRepliesChange={this.props.onAllowAiAssistedRepliesChange}
						onDescriptionOpen={this.props.onAiAssistedRepliesDescriptionToggle}
					/>
					<ButtonComponent
						purple
						display="inline-block"
						disabled={!this.props.comment || !this.props.comment.length}
						margin="0 10px 0 0"
						width="80px"
						minWidth="80px"
						height="35px"
						borderRadius="4px"
						onClick={this.props.onNextClick}>
						<Text fontSize="16px" fontWeight="600" color={Colors.white}>
							{Resources[this.props.culture].ok}
						</Text>
					</ButtonComponent>
				</ContentWrapper>
				<QuestionAnonymousLabel
					isCommentDisabled={this.props.isCommentDisabled}
					culture={this.props.culture}
					padding="10px"
				/>
			</Wrapper>
		);
	}
}

QuestionCommentMobile.propTypes = {
	isCommentDisabled: PropTypes.bool,
	allowAiAssistedReplies: PropTypes.bool,
	isVisibleForImmediateManager: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	questionType: PropTypes.number,
	grade: PropTypes.number,
	color: PropTypes.string,
	culture: PropTypes.string,
	questionText: PropTypes.string,
	comment: PropTypes.string,
	onCommentCancel: PropTypes.func,
	onNextClick: PropTypes.func,
	onCommentChange: PropTypes.func,
	onDescriptionOpen: PropTypes.func,
	onVisibleForImmediateManagerChange: PropTypes.func,
	onAiAssistedRepliesDescriptionToggle: PropTypes.func,
};

export default QuestionCommentMobile;
