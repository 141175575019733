import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DropdownIcon from "./DropdownIcon";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${props => props.padding || "8px 12px"};
    font-size: 14px;
    font-weight: ${props => props.selectorFontWeight || "600"};
    background-color: ${props => props.disabled ? "#f7f7f7" : (props.backgroundColor || "#fff")};
    color: #4c4c4c;
    border: ${props => props.border || "1px solid #e0e0e0"};
    border-bottom: ${props => props.bottomBorder || "solid 2px #59b0c7"};
    border-radius:  ${props => props.borderRadius || "2px"};
    padding-bottom: 7px;
    cursor: pointer;
    ${props => props.height ? `height: ${props.height};` : null}
    ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : null}
`;

const MainViewLabelsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 3px;
    width: calc(100% - 8px);
`;

const SelectorLabel = styled.span`
    font-size: ${props => props.labelFontSize || "10px"};
    user-select: none;
    text-transform: uppercase;
    font-weight: ${props => props.selectorFontWeight || "600"};
    color: ${props => props.selectorLabelColor || "#999999"};
    line-height: 12px;
    display: block;
    margin-bottom: 4px;
`;

const SelectedLabel = styled.span`
    display: block;
    user-select: none;
    color: ${props => props.color || "#53a6bd"};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const DropdownMainView = (props) => {
    return (
        <Wrapper
            onClick={props.onClick}
            disabled={props.disabled}
            height={props.height}
            backgroundColor={props.backgroundColor}
            padding={props.padding}
            borderRadius={props.borderRadius}
            border={props.border}
            bottomBorder={props.bottomBorder}
            selectorFontWeight={props.selectorFontWeight}
            maxWidth={props.maxWidth}>
            <MainViewLabelsWrapper>
                {
                    props.selectorLabel && 
                        <SelectorLabel
                            labelFontSize={props.labelFontSize}
                            selectorLabelColor={props.selectorLabelColor}
                            selectorFontWeight={props.selectorFontWeight}>
                                {props.selectorLabel}
                        </SelectorLabel>
                }
                <SelectedLabel color={props.selectedLabelColor}>{props.selectedLabel}</SelectedLabel>
            </MainViewLabelsWrapper>
            <DropdownIcon/>
        </Wrapper>    
    );
}

DropdownMainView.propTypes = {
    disabled: PropTypes.bool,
    labelFontSize: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    border: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    selectorLabel: PropTypes.string,
    selectorLabelColor: PropTypes.string,
    selectorFontWeight: PropTypes.string,
    selectedLabel: PropTypes.string,
    selectedLabelColor: PropTypes.string,
    bottomBorder: PropTypes.string,
    maxWidth: PropTypes.string,
    onClick: PropTypes.func
}

export default DropdownMainView;