import {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST} from "../actions/authentication";
import { getAuth } from "../actions/authentication.localStorage";

const authentication = (state = { fetching: false, authenticated: getAuth() ? true : false }, action) => {
    switch(action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                fetching: true,
                authenticated: false,
                error: false
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                name: null,
                authenticated: false 
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                fetching: false,
                authenticated: true,
                error: false
            };
        case LOGIN_FAILURE:
            return {
                ...state, 
                fetching: false,
                authenticated: false,
                error: true
            };
        default:
            return state;
    }
}

export default authentication;