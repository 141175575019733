// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import ContentWrapper from "Controls/ContentWrapper";
import Image from "Controls/Image";
import Text from "Controls/Text";

const SurveyGdprContent = (props) => {
    return (
        <ContentWrapper
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center">
            <Image
                src="/images/onboarding/icon-safe-big.png"
                margin="40px auto 20px"/>

            <Text
                display="inline-block"
                fontSize="30px"
                fontWeight="600"
                color="#4c4c4c"
				margin="0 0 30px"
				padding="0 20px"
                textAlign="center">
                {Resources[props.culture].beforeWeMoveOn || Resources['en-US'].beforeWeMoveOn}...
            </Text>
            <Text
                display="inline-block"
                fontSize="14px"
                fontWeight="500"
                color="#4c4c4c"
				margin="0 0 40px"
				padding="0 20px"
                lineHeight="18px"
                textAlign="center">
                {Resources[props.culture].gdprIntroduction || Resources['en-US'].gdprIntroduction}
            </Text>
        </ContentWrapper>
    );
}

SurveyGdprContent.propTypes = {
    culture: PropTypes.string
}

export default SurveyGdprContent;