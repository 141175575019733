// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LineIcons from "LineIcons";
import LineIconComponent from "Controls/LineIconComponent";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${props => props.padding || "20px"};
    background-color: ${props => props.backgroundColor || "#fef7e4"};
    border-bottom: 1px solid #e4decd;
    border-top: ${props => props.isWithTopBorder ? "1px solid #e4decd" : "none"};
    @media(max-width: 768px) {
        padding: ${props => props.paddingMedia || "15px 20px"};
    }
    @media(max-width: 425px) {
        padding: 10px 20px;
    }
`;

const Text = styled.span`
    font-weight: ${props => props.fontWeight || "600"};
    font-size: ${props => props.fontSize || "13px"};
    color: ${props => props.color || "#666666"};
    text-transform: ${props => props.textTransform || "none"};
    @media(max-width: 425px) {
        font-size: 13px;
        font-weight: 600;
    }
`;

const InfoMessage = (props) => {
    return (
        <Wrapper
            padding={props.padding}
            paddingMedia={props.paddingMedia}
            backgroundColor={props.backgroundColor}
            isWithTopBorder={props.isWithTopBorder}>
            {
                props.isWithIcon &&
                <LineIconComponent
                    color={props.color || "#666666"}
                    fontSize={props.fontSize || "13px"}
                    fontWeight="600"
                    content={LineIcons.lock}
                    margin="0 5px 0 0"/>
            }
            <Text
            color={props.color}
            fontWeight={props.fontWeight}
            fontSize={props.fontSize}
            textTransform={props.textTransform}>
                {props.text}
            </Text> 
        </Wrapper>
    );
}

InfoMessage.propTypes = {
    isWithIcon: PropTypes.bool,
    isWithTopBorder: PropTypes.bool,
    padding: PropTypes.string,
    paddingMedia: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    text: PropTypes.string,
    backgroundColor: PropTypes.string,
    textTransform: PropTypes.string
}

export default InfoMessage;