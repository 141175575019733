import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ContentWrapper from "Controls/ContentWrapper";
import { format } from "Helpers";
import Resources from "Resources";
import Text from "ComponentsLibrary/Text";
import { colors } from "ComponentsLibrary/shared/theme";

class DropdownFooterComponent extends PureComponent {
	render() {
		return (
			<ContentWrapper
				display="flex"
				justifyContent="flex-end"
				width="100%"
				padding="8px 16px"
				marginBottom="-8px"
				borderTop={`1px solid ${colors.fog}`}
				backgroundColor={colors.smoke}>
				<Text fontType="smallRegular" color={colors.nickel}>
					{`${format(
						Resources[this.props.culture].viewingXofY,
						this.props.currentCount,
						this.props.totalCount
					)} `}
				</Text>
			</ContentWrapper>
		);
	}
}

DropdownFooterComponent.propTypes = {
	culture: PropTypes.string,
	currentCount: PropTypes.number,
	totalCount: PropTypes.number,
};

export default DropdownFooterComponent;
