/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import SvgIcon from "Controls/SvgIcon";
import styled from "styled-components";
import { spacing, spacingIndex } from "../shared/spacing";
import { transition, colors } from "../shared/theme";
import font from "../shared/font";

const Wrapper = styled.button`
	display: ${(props) => props.display || "flex"};
	flex-direction: ${(props) => (props.rightIcon ? "row-reverse" : "row")};
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-transform: ${(props) => props.textTransform || "capitalize"};
	transition: ${transition.main};
	color: ${(props) => props.color || colors.purple};
	gap: ${spacingIndex / 2}px;
	border: none;
	background-color: transparent;
	padding: 0;
	${(props) =>
		props.isEllipsis &&
		`
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	`}
	${spacing}
	${(props) => (props.fontType ? font(props.fontType) : font("normalMedium"))}
	&:focus,
	&:hover {
		outline: none;
		${(props) => props.useUnderline && `text-decoration: underline`};
		${(props) => props.hoverColor && `color: ${props.hoverColor}`};
	}
	&:disabled {
		text-decoration: none;
		cursor: default;
		opacity: 0.5;
	}
`;

const TextButton = forwardRef((props, ref) => {
	return (
		<div ref={ref}>
			<Wrapper {...props} data-test={props.dataTest}>
				{props.icon && (
					<SvgIcon fill={colors.purple} size="16px" rotate={props.iconRotation} icon={props.icon} />
				)}
				{props.label}
			</Wrapper>
		</div>
	);
});

const propTypes = {
	disabled: PropTypes.bool,
	rightIcon: PropTypes.bool,
	isEllipsis: PropTypes.bool,
	display: PropTypes.string,
	dataTest: PropTypes.string,
	label: PropTypes.string,
	icon: PropTypes.string,
	fontType: PropTypes.string,
	iconRotation: PropTypes.string,
	color: PropTypes.string,
	hoverColor: PropTypes.string,
	onClick: PropTypes.func,
	useUnderline: PropTypes.bool,
};

TextButton.propTypes = propTypes;

TextButton.defaultProps = {
	label: "TextButton",
	useUnderline: true,
};

export default TextButton;

export { propTypes };
