import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCulture } from '../actions/culture';
import { getAuth } from '../actions/authentication.localStorage';
import Escalate from '../components/Escalate';

const mapStateToProps = (state) => {
    var authentication = getAuth();

    var escalated =
        Object.assign(state.questions.escalated, {
            description: getTemperatureFromEscalated(state.questions.escalated, state.questions.batches)
        });

    return {
        culture: getCulture(state),
        username: authentication ? authentication.name : null,
        escalated: escalated
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


const getTemperatureFromEscalated = (escalated, batches) => {
    var escalatedBatch = batches.filter(x => x.id === escalated.batch.id);
    return escalatedBatch[0].questions.filter(x => x.questionId === escalated.questionId)[0];
};

const EscalateContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Escalate));

export default EscalateContainer;