import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DropdownWrapper = styled.div`
    display: ${props => props.display ? props.display : "inline-block"};
    vertical-align: middle;
    position: relative;
    width: ${props => props.width ? props.width : "fit-content"};
    margin: ${props => props.margin ? props.margin : "0"};
    padding: ${props => props.padding ? props.padding : "0"};
    ${props => props.flexGrow ? `flex-grow: ${props.flexGrow}` : null};
    ${props => props.maxWidth ? `max-width: ${props.maxWidth}` : null};
    @media (max-width: 768px) {
        display: ${props => props.mediaDisplay ? props.mediaDisplay : "inline-block"};
        width: ${props => props.mediaWidth ? props.mediaWidth : "100%"};
        ${props => props.mediaMargin ? `margin: ${props.mediaMargin}` : null};
        ${props => props.flexGrowXS ? `flex-grow: ${props.flexGrowXS}` : null};
    }
`;

DropdownWrapper.propTypes = {
    width: PropTypes.string,
	maxWidth: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    mediaMargin: PropTypes.string,
    mediaDisplay: PropTypes.string,
    mediaWidth: PropTypes.string,
	display: PropTypes.string,
	flexGrow: PropTypes.string,
	flexGrowXS: PropTypes.string,
}

export default DropdownWrapper;