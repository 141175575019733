// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes                from "prop-types";
import Colors                   from "Colors";
import Text                     from "Controls/Text";

class QuestionTalkInputLabel extends PureComponent {
	render() {
		return (
			<Text
				display="inline-block"
				width="100%"
				margin={this.props.margin ? this.props.margin : "35px 0 2px"}
				color={this.props.color ? this.props.color : Colors.textGray}
				fontSize={this.props.fontSize ? this.props.fontSize : "14px"}
				fontWeight={this.props.fontWeight ? this.props.fontWeight : "600"}
				lineHeight={this.props.lineHeight ? this.props.lineHeight : "20px"}>
					{this.props.text}
			</Text>
		);
	}
}

QuestionTalkInputLabel.propTypes = {
	color: PropTypes.string,
	margin: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.string,
	lineHeight: PropTypes.string,
	text: PropTypes.string,
};

export default QuestionTalkInputLabel;