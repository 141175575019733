// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const DURATION = "0.5s";

class SmileyStronglyAgree extends PureComponent {
    render() {
        if (!this.props.isWithAnimation) {
            return (
                <g fill="#41464D" fillRule="nonzero" transform={this.props.isTemperature ? "translate(12.52 43.28)" : "translate(12.52 17.28)"}>
                    <ellipse cx="3" cy="1.3" rx="2.5" ry="2" transform="rotate(-20, 5, 1.3)"></ellipse>
                    <path stroke="#41464D" strokeWidth="1.5" fill="#41464D" d="M 5.5 8 c 0 10 12.5 10 13 0"></path>
                    <ellipse cx="20" cy="1.7" rx="2.5" ry="2" transform="rotate(20, 20, 1.5)"></ellipse>
                </g>
            );
        }

        return (
            <g fill="#41464D" fillRule="nonzero" transform={this.props.isTemperature ? "translate(12.52 43.28)" : "translate(12.52 17.28)"}>
                <ellipse cx="2.77" cy="2.74" rx="2.3" ry="2.3" transform="rotate(-20, 5, 1.3)">
                    <animate attributeName="cx" dur={DURATION} from="2.77" to="3" fill="freeze" />
                    <animate attributeName="cy" dur={DURATION} from="2.74" to="1.3" fill="freeze" />
                    <animate attributeName="rx" dur={DURATION} from="2.3" to="2.5" fill="freeze" />
                    <animate attributeName="ry" dur={DURATION} from="2.3" to="2" fill="freeze" />
                </ellipse>
                <path stroke="#41464D" strokeWidth="1.5" fill="#41464D">
                    <animate attributeName="d" dur={DURATION} from="M 6.25 10.72 c 0 0 10 0 10 0" to="M 5.5 8 c 0 10 12.5 10 13 0" fill="freeze" />
                </path>
                <ellipse cx="19.4" cy="2.74" rx="2.3" ry="2.3" transform="rotate(20, 20, 1.5)">
                    <animate attributeName="cx" dur={DURATION} from="19.4" to="20" fill="freeze" />
                    <animate attributeName="cy" dur={DURATION} from="2.74" to="1.7" fill="freeze" />
                    <animate attributeName="rx" dur={DURATION} from="2.3" to="2.5" fill="freeze" />
                    <animate attributeName="ry" dur={DURATION} from="2.3" to="2" fill="freeze" />
                </ellipse>
            </g>
        );
    }
}

SmileyStronglyAgree.propTypes = {
    isWithAnimation: PropTypes.bool,
    isTemperature: PropTypes.bool
}

export default SmileyStronglyAgree;