// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";
import Resources from "Resources";
import { heapTrack } from "Helpers";
import { QuestionCounterMinValue, TemperatureTemplate } from "Enums";
import ContentWrapper from "Controls/ContentWrapper";
import Button from "Controls/Button";
import QuestionWrapper from "./QuestionWrapper";
import FeedbackQuestionRow from "./FeedbackQuestionRow";
import QuestionCounter from "./QuestionCounter";
import QuestionComment from "./QuestionComment";
import QuestionMinified from "./QuestionMinified";

class QuestionEnps extends PureComponent {
	constructor(props) {
		super(props);

		this.isWithQuestionCounter = this.props.questionsCount >= QuestionCounterMinValue;
	}

	onGradeChange = (grade) => {
		heapTrack(grade ? "ENPS value (on web)" : "[Survey] Change survey answer");
		this.props.onChange({ ...this.props.question, grade });
	};

	onCommentChange = (value) => {
		heapTrack("[Survey] Add comment");
		this.props.onChange({ ...this.props.question, comment: value });
	};

	onVisibleForImmediateManagerChange = () => {
		this.props.onChange({
			...this.props.question,
			visibleForImmediateManager: !this.props.question.visibleForImmediateManager,
		});
	};

	onAllowAiAssistedRepliesChange = () => {
		this.props.onChange({
			...this.props.question,
			allowAiAssistedReplies: !this.props.question.allowAiAssistedReplies,
		});
	};

	renderButtons = () => {
		const grade = parseInt(this.props.question.grade);
		const isWithAnswer = grade >= 0 && grade <= 10;
		const buttons = [];

		for (let i = 0; i <= 10; i++) {
			buttons.push(
				<Button
					key={i}
					aria-label={`${i} ${Resources[this.props.culture].of} 10`}
					flexGrow="1"
					fontSize="16px"
					height="40px"
					backgroundColor={
						!isWithAnswer || grade === i ? "transparent" : "rgba(255, 255, 255, 0.5)"
					}
					hoverBackgroundColor="rgba(255, 255, 255, 0.5)"
					border="1px solid #fff"
					borderRadius="0"
					borderWidth={i === 10 ? "0 1px" : "0 0 0 1px"}
					color={grade === i ? "#fff" : "#000"}
					onClick={() => this.onGradeChange(i)}>
					{i}
				</Button>
			);
		}

		return buttons;
	};

	getCommentItemColor = () => {
		const grade = this.props.question.grade;

		if (grade <= 2) {
			return Colors.smileyStronglyDisagree;
		}
		if (grade <= 4) {
			return Colors.smileyDisagree;
		} else if (grade <= 6) {
			return Colors.smileyNeutral;
		} else if (grade <= 8) {
			return Colors.smileyAgree;
		} else {
			return Colors.smileyStronglyAgree;
		}
	};

	renderQuestion = (hasAnsweredQuestion, isMinifiedWithoutComment, isMinifiedWithComment) => {
		const commentColor = this.getCommentItemColor();

		if (isMinifiedWithoutComment || isMinifiedWithComment) {
			return (
				<QuestionMinified
					isWithQuestionCounter={this.isWithQuestionCounter}
					isWithComment={!this.props.isCommentDisabled}
					isWithShake={isMinifiedWithoutComment}
					questionType={TemperatureTemplate.enps}
					grade={this.props.question.grade}
					gradeColor={commentColor}
					questionPadding={this.props.questionPadding}
					questionPaddingXS={this.props.questionPaddingXS}
					questionText={this.props.question.description}
					questionColor={this.props.questionColor}
					questionFontSize={this.props.questionFontSize}
					questionFontSizeXS={this.props.questionFontSizeXS}
					questionFontWeight={this.props.questionFontWeight}
					questionTextPadding={this.props.questionTextPadding}
					questionTextAlign={this.props.questionTextAlign}
					culture={this.props.culture}
					onGradeClick={this.onGradeChange}
					onExpandQuestionClick={this.onExpandQuestionClick}
				/>
			);
		}

		return (
			<FeedbackQuestionRow
				isWithQuestionCounter={this.isWithQuestionCounter}
				questionPadding={this.props.questionPadding}
				questionPaddingXS={this.props.questionPaddingXS}
				questionFontSize={this.props.questionFontSize}
				questionFontSizeXS={this.props.questionFontSizeXS}
				questionFontWeight={this.props.questionFontWeight}
				questionTextAlign={this.props.questionTextAlign}
				questionTextPadding={this.props.questionTextPadding}
				questionColor={this.props.questionColor}
				questionLabel={this.props.questionLabel}
				question={this.props.question}
				culture={this.props.culture}
				backgroundColor={this.props.backgroundColor}>
				{(!hasAnsweredQuestion || this.props.isCommentDisabled) && (
					<ContentWrapper
						display="flex"
						width="100%"
						border="1px solid #fff"
						borderWidth="0 0 1px"
						borderRadius="0 0 4px 4px"
						overflow="hidden"
						boxSizing="border-box"
						backgroundImage={`linear-gradient(to right, ${Colors.smileyStronglyDisagree} 0%, ${Colors.smileyDisagree} 25%, ${Colors.smileyNeutral} 50%, ${Colors.smileyAgree} 75%, ${Colors.smileyStronglyAgree} 100%)`}>
						{this.renderButtons()}
					</ContentWrapper>
				)}
				{!this.props.isCommentDisabled && hasAnsweredQuestion && (
					<ContentWrapper
						boxSizing="border-box"
						padding="10px"
						width="100%"
						backgroundColor={Colors.backgroundLightGray}>
						<QuestionComment
							isCommentDisabled={this.props.isCommentDisabled}
							isReadOnlyAfterSubmit={this.props.isReadOnlyAfterSubmit}
							isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
							isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
							allowAiAssistedReplies={this.props.question.allowAiAssistedReplies}
							questionType={TemperatureTemplate.enps}
							culture={this.props.culture}
							grade={this.props.question.grade}
							questionText={this.props.question.description}
							color={commentColor}
							onChange={this.onCommentChange}
							questionPadding={this.props.questionPadding}
							questionPaddingXS={this.props.questionPaddingXS}
							onVisibleForImmediateManagerChange={this.onVisibleForImmediateManagerChange}
							onAllowAiAssistedRepliesChange={this.onAllowAiAssistedRepliesChange}
							isVisibleForImmediateManager={this.props.question.visibleForImmediateManager}
							comment={this.props.question.comment}
							onGradeClick={this.onGradeChange}
						/>
					</ContentWrapper>
				)}
			</FeedbackQuestionRow>
		);
	};

	render() {
		const hasAnsweredQuestion = !!this.props.question.grade || this.props.question.grade === 0;
		const isActiveQuestion = this.props.activeQuestionId === this.props.question.questionId;
		const isMinifiedWithoutComment = this.props.isCommentDisabled && hasAnsweredQuestion;
		const isMinifiedWithComment =
			!this.props.isCommentDisabled &&
			!isActiveQuestion &&
			hasAnsweredQuestion &&
			!this.props.question.comment;

		return (
			<QuestionWrapper
				ref={this.props.feedbackQuestionRef}
				padding={this.props.wrapperPadding}
				margin={this.props.wrapperMargin}
				border={this.props.wrapperBorder}>
				{this.isWithQuestionCounter && (
					<QuestionCounter
						isMinified={true}
						isEnps={true}
						culture={this.props.culture}
						questionIndex={this.props.questionIndex}
						questionsCount={this.props.questionsCount}
						questionTextPadding={this.props.questionTextPadding}
						color={this.props.questionColor}
					/>
				)}
				{this.renderQuestion(hasAnsweredQuestion, isMinifiedWithoutComment, isMinifiedWithComment)}
			</QuestionWrapper>
		);
	}
}

QuestionEnps.propTypes = {
	isCommentDisabled: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	isAllowAiAssistedRepliesVisible: PropTypes.bool,
	questionIndex: PropTypes.number,
	questionsCount: PropTypes.number,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	questionColor: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionFontWeight: PropTypes.string,
	culture: PropTypes.string,
	wrapperMargin: PropTypes.string,
	question: PropTypes.object,
	onChange: PropTypes.func,
	highlight: PropTypes.bool,
};

export default QuestionEnps;
