// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "Colors";
import LineIcons from "LineIcons";
import FontasticIcons from "FontasticIcons";

const Wrapper = styled.label`
    display: flex;
    padding: 0;
    margin: ${props => props.margin || "0"};
    border-radius: 2px;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    min-width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    min-height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    max-width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    max-height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    background-color: ${props => props.backgroundColor || "transparent"};
`;

const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    min-width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    min-height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    max-width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    max-height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    &:checked ~ span {
        background-color: ${props => props.purple ? Colors.purple : (props.jungle ? Colors.jungle : Colors.mainBlue)};
        border: ${props => props.checkedBorder || "none"};
        color: ${Colors.white};
        &:before {
            content: ${props => props.curvedCheckedIcon ? FontasticIcons.gCheck : LineIcons.checkboxChecked};
        }
    }
    &:disabled ~ span {
        background-color: ${props => props.disabledBackgroundColor || Colors.backgroundLightGray};
        border: 1px solid #e0e0e0;
        cursor: not-allowed;
		&:before {
			${props => props.disabledCheckMarkColor ? `color: ${props.disabledCheckMarkColor};` : null}
		}
    }
`;

const Checkmark = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    min-width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    min-height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    max-width: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    max-height: ${props => props.wrapperSize ? props.wrapperSize : "16px"};
    border-radius: 2px;
    border: solid 1px ${props => props.borderColor || "#dbdbdb"};
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${props => props.curvedCheckedIcon ? "fontastic-icons" : "line-icons"} !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    &:before {
        left: ${props => props.curvedCheckedIcon ? "0" : "1px"};
        position: relative;
    }
`;

const CheckBoxComponent = (props) => {
    return (
        <Wrapper
            disabled={!!props.disabled}
            wrapperSize={props.wrapperSize}
            backgroundColor={props.backgroundColor}
			disabledBackgroundColor={props.disabledBackgroundColor}
            margin={props.margin}>
            <Input 
				id={props.id}
                purple={props.purple}
                jungle={props.jungle}
                wrapperSize={props.wrapperSize}
                curvedCheckedIcon={props.curvedCheckedIcon}
				disabledBackgroundColor={props.disabledBackgroundColor}
				disabledCheckMarkColor={props.disabledCheckMarkColor || (props.purple ? Colors.purple : null)}
                type="checkbox"
                checked={!!props.checked}
                disabled={!!props.disabled}
                readOnly
                onClick={props.onClick && props.onClick} />
            <Checkmark wrapperSize={props.wrapperSize} curvedCheckedIcon={props.curvedCheckedIcon} borderColor={props.borderColor}/>
        </Wrapper>
    );
};

CheckBoxComponent.propTypes = {
    purple: PropTypes.bool,
    jungle: PropTypes.bool,
    curvedCheckedIcon: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    wrapperSize: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
	disabledBackgroundColor: PropTypes.string,
	disabledCheckMarkColor: PropTypes.string,
	checkedBorder: PropTypes.string,
	margin: PropTypes.string,
	id: PropTypes.string,
    onClick: PropTypes.func
};

export default CheckBoxComponent;