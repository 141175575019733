// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Resources from "Resources";
import LineIcons from "LineIcons";
import LineIconComponent from "Controls/LineIconComponent";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 20px 5px;
	width: 100%;
	box-sizing: border-box;
	@media (max-width: 1024px) {
		padding: 10px 20px 0;
	}
`;

const BackWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-right: none;
	padding: 0 0 0 10px;
`;

const BackLink = styled.a`
	display: flex;
	align-items: center;
	width: 100%;
	color: #fff;
	font-size: 14px;
	line-height: 24px;
	text-transform: uppercase;
	font-weight: 500;
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		color: #eee;
	}
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 1000px;
	padding-left: 0;
	overflow: hidden;
`;

const TitleText = styled.div`
	display: inline-block;
	color: #fff;
	font-size: 20px;
	line-height: 24px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (max-width: 425px) {
		font-size: 16px;
	}
`;

const SurveyTitlebar = (props) => {
	return (
		<Wrapper isWithBackButton={!!props.onBack}>
			<TitleWrapper paddingMedia={props.onBack ? "20px" : "0"}>
				<TitleText>{props.title}</TitleText>
			</TitleWrapper>
			{props.onBack && (
				<BackWrapper>
					<BackLink
						href="#"
						onClick={(e) => {
							e.preventDefault();
							props.onBack(e);
						}}>
						<LineIconComponent content={LineIcons.arrowleft} margin="0 5px 0 0" fontSize="12px" />{" "}
						{Resources[props.culture].back}
					</BackLink>
				</BackWrapper>
			)}
		</Wrapper>
	);
};

SurveyTitlebar.ropTypes = {
	culture: PropTypes.string,
	title: PropTypes.string,
	onBack: PropTypes.func,
};

export default SurveyTitlebar;
