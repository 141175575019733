import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: ${props => props.borderBottom || "solid 1px #d9d9d9"};
    padding: ${props => props.padding || "16px 20px"};
    background-color: ${props => props.backgroundColor || "#AF60BF"};
    min-height: 16.43px;
    @media(max-width: 425px) {
        padding: ${props => props.paddingXS || "14px 20px"};
    }
`;

const HeaderButton = styled.button`
    font-size: 30px;
    text-shadow: none;
    margin-top: -2px;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-weight: 700;
    line-height: 1;
    color: #000000;
    outline: none;
    opacity: .2;
    padding: 0;
    margin-left: 10px;
    &:hover {
        opacity: .5;
    }
`;

const HeaderText = styled.h4`
    color: #ffffff;
    line-height: 1.42857143;
    font-size: ${props => props.fontSize || "20px"};
    font-weight: 700;
    margin: 0;
    @media(max-width: 425px) {
        font-size: ${props => props.fontSizeXS || "16px"};
    }
`;

const ModalHeader = (props) => {
    return (
        <Wrapper backgroundColor={props.backgroundColor} padding={props.padding} paddingXS={props.paddingXS} borderBottom={props.borderBottom}>
            <HeaderText fontSize={props.fontSize} fontSizeXS={props.fontSizeXS}>
                {props.title}
            </HeaderText>
            {
                props.onCloseClick &&
                <HeaderButton aria-label="Close" onClick={props.onCloseClick}>
                    <span aria-hidden="true">×</span>
                </HeaderButton>
            }
        </Wrapper>
    );
}

ModalHeader.propTypes = {
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontSize: PropTypes.string,
    fontSizeXS: PropTypes.string,
    padding: PropTypes.string,
    paddingXS: PropTypes.string,
    borderBottom: PropTypes.string,
    onCloseClick: PropTypes.func
}

export default ModalHeader;