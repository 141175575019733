const palette = {
	purple: "#7f2696",
	darkPurple: "#4e0854",
	lightPurple: "#f4efff",
	whitePurple: "#f9f7ff",
	alternativePurple: "#e2d6ff",
	pink: "#c86ee5",
	turquoise: "#00d2bf",
	jungle: "#00bdab",
	skobeloff: "#007374",
	lightTurquoise: "#c6f4ec",
	whiteTurquoise: "#f1fcfa",
	red: "#ff444c",
	coral: "#ff6f79",
	orange: "#ffa000",
	honey: "#ba8b00",
	yellow: "#ffc107",
	lightYellow: "#feeec2",
	whiteYellow: "#fff8e6",
	black: "#000000",
	white: "#ffffff",
	redOchre: "#ee6c4d",
	brick: "#b63c23",
	lightRed: "#ffe8ea",
	whiteRed: "#fff9f7",
	alternativeRed: "#fff5f6",
	palmLeaf: "#6da34d",
	green: "#71bc49",
	sapGreen: "#3e7421",
	pistachio: "#9ed57b",
	royalBlue: "#4764c5",
	cobalt: "#213d98",
	oceanBlue: "#3e7b8b",
	lightBlue: "#CBF4FF",
	whiteBlue: "#ECFBFF",
	skyBlue: "#59b0c7",
	lightCornflower: "#f6f7fc",
	bronze: "#cf8426",
	gambodge: "#995700",
	pastelOrange: "#ffb456",
	onyx: "#353a40",
	nickel: "#6b6f75",
	metal: "#cccccc",
	fog: "#eeeeee",
	smoke: "#f5f5f5",
	whiteSmoke: "#fafafa",
	vibrantRed: "#ff5c61",
	vibrantOrange: "#ffa000",
	vibrantYellow: "#ffd900",
	vibrantLime: "#bdec34",
	vibrantGreen: "#5ad036",
};

export default {
	...palette,
    error: "#f16867",
    warningBackground: "#fef7e4",
    warningBorder: "#e4decd",
    textDarkestGray: "#333333",
    labelLighterGray: "#959da6",
    labelLightGray: "#999999",
    buttonPurple: "#af60bf",
    buttonPurpleBorder: "#af60bf",
    buttonPurpleDisabled: "#8b7b8e",
    buttonPurpleHighlighted: "#bb66cc",
    buttonPurpleHighlightedBorder: "#bb66cc",
	buttonPurpleHighlighted2: "#c562d9",
    buttonLightGray: "#b3b3b3",
    buttonLightGrayHover: "#999999",
    buttonLightGrayBorder: "#b3b3b3",
    buttonLightGrayHoverBorder: "#999999",
    buttonBlue: "#59b0c7",
    buttonBlueHover: "#65c6e0",
    buttonBlueBorder: "#509eb3",
    buttonBlueHoverBorder: "#5bb2c9",
    buttonBlueDisabled: "#999999",
    buttonWhiteColor: "#737373",
    buttonWhiteHoverColor: "#595959",
    buttonWhiteBorder: "#e0e0e0",
    buttonWhiteHoverBorder: "#cccccc",
    buttonWhiteDisabled: "#d9d9d9",
    buttonWhiteDisabledBorder: "#d9d9d9",
    buttonGreen: "#82BE62",
    buttonGreenHover: "#7ECA56",
    buttonGreenBorder: "#82BE62",
    buttonGreenHoverBorder: "#7ECA56",
	buttonGreenDisabled: "#70AB7D",
	buttonPrimaryPurple: "#a465ba",
	buttonPrimaryPurpleBorder: "#a465ba",
	buttonPrimaryPurpleColor: "#ffffff",
	buttonPrimaryPurpleHover: "#c18bd4",
	buttonPrimaryPurpleHoverBorder: "#c18bd4",
	buttonPrimaryPurpleHoverColor: "#ffffff",
	buttonPrimaryPurpleDisabled: "#9a7fa0",
	buttonPrimaryPurpleDisabledBorder: "#9a7fa0",
	buttonPrimaryPurpleDisabledColor: "#ffffff",
	buttonPrimaryGreen: "#50b7a8",
	buttonPrimaryGreenBorder: "#50b7a8",
	buttonPrimaryGreenColor: "#ffffff",
	buttonPrimaryGreenHover: "#6ac9bb",
	buttonPrimaryGreenHoverBorder: "#6ac9bb",
	buttonPrimaryGreenHoverColor: "#ffffff",
	buttonPrimaryGreenDisabled: "#61beb0",
	buttonPrimaryGreenDisabledBorder: "#61beb0",
	buttonPrimaryGreenDisabledColor: "#ffffff",
	buttonPrimaryRed: "#d14545",
	buttonPrimaryRedBorder: "#d14545",
	buttonPrimaryRedColor: "#ffffff",
	buttonPrimaryRedHover: "#da6a6a",
	buttonPrimaryRedHoverBorder: "#da6a6a",
	buttonPrimaryRedHoverColor: "#ffffff",
	buttonPrimaryRedDisabled: "#d55757",
	buttonPrimaryRedDisabledBorder: "#d55757",
	buttonPrimaryRedDisabledColor: "#ffffff",
	buttonSecondaryPurple: "#ffffff",
	buttonSecondaryPurpleBorder: "#a465ba",
	buttonSecondaryPurpleColor: "#a465ba",
	buttonSecondaryPurpleHover: "#faf4fc",
	buttonSecondaryPurpleHoverBorder: "#a465ba",
	buttonSecondaryPurpleHoverColor: "#a465ba",
	buttonSecondaryPurpleDisabled: "#ffffff",
	buttonSecondaryPurpleDisabledBorder: "#e9d0ed",
	buttonSecondaryPurpleDisabledColor: "#e9d0ed",
	buttonSecondaryGreen: "#ffffff",
	buttonSecondaryGreenBorder: "#50b7a8",
	buttonSecondaryGreenColor: "#50b7a8",
	buttonSecondaryGreenHover: "#f5fbfa",
	buttonSecondaryGreenHoverBorder: "#50b7a8",
	buttonSecondaryGreenHoverColor: "#50b7a8",
	buttonSecondaryGreenDisabled: "#f3f3f3",
	buttonSecondaryGreenDisabledBorder: "#50b7a8",
	buttonSecondaryGreenDisabledColor: "#50b7a8",
	buttonPurpleRounded: "#7f2696",
	buttonPurpleRoundedBorder: "#7f2696",
	buttonPurpleRoundedColor: "#ffffff",
	buttonPurpleRoundedHover: "#af3bcd",
	buttonPurpleRoundedHoverBorder: "#af3bcd",
	buttonWhiteRounded: "#ffffff",
	buttonWhiteRoundedBorder: "#a465ba",
	buttonWhiteRoundedColor: "#73388a",
	buttonWhiteRoundedHover: "#f1ebf3",
	buttonWhiteRoundedHoverBorder: "#73388a",
    mainBlue: "#59b0c7",
    mainPurple: "#af60bf",
    mainPurpleDark: "#8552a1",
    mainLightGray: "#999999",
	mainGray: "#a4abb3",
	mainGreen: "#00d2bf",
    smileyStronglyDisagree: "#EE7273",
    smileyDisagree: "#EF926B",
    smileyNeutral: "#f3d43a",
    smileyAgree: "#B6D05C",
    smileyStronglyAgree: "#82BE62",
	commentSmileyStronglyDisagree: palette.vibrantRed,
	commentSmileyDisagree: palette.vibrantOrange,
	commentSmileyNeutral: palette.vibrantYellow,
	commentSmileyAgree: palette.vibrantLime,
	commentSmileyStronglyAgree: palette.vibrantGreen,
	commentSmileyStronglyDisagreeDisabled: "#848484",
	commentSmileyDisagreeDisabled: "#A9A9A9",
	commentSmileyNeutralDisabled: "#D9D9D9",
	commentSmileyAgreeDisabled: "#D9D9D9",
	commentSmileyStronglyAgreeDisabled: "#ABABAB",
    borderLightGray: "#e5e5e5",
    borderGray: "#d9d9d9",
    shadowLightGray: "rgba(0, 0, 0, 0.03)",
    backgroundLightBlue: "#f2f9fa",
    backgroundLightGray: "#f7f7f7",
    backgroundGray: "#e6e6e6",
    darkestGrayText: "#333333",
    darkGrayText: "#4c4c4c",
    grayText: "#737373",
    backgroundDark: "#343a40",
    questionWindowBackground: "#41464c",
    questionWindowBackgroundXS: "#41464c",
    questionBorder: "#dddddd",
    questionAnonymousLabel: "#a1a6ac",
    questionAnonymousLabelXS: "#a1a6ac",
    textGray: "#666666",
    textLighterGray: "#b2b2b2",
    textLightGray: "#959da6",
    selectpickerRed: "#fe6e6d",
    selectpickerBlue: "#6bb2ed",
    selectpickerGreen: "#78e092",
    selectpickerYellow: "#f5d762",
    selectpickerOrange: "#fa9d7e",
    selectpickerPurple: "#a491ff",
    inputFocusBorder: "#66afe9",
    questionWindowProgressBackground: "#9853a6",
    questionWindowProgress: "#683973",
    textRed: "#ff1f40",
    textGreen: "#97e126",
    backgroundLightGrayBlue: "#f8fbfd",
    formItemsBorderderLightGray: "#e9eef1",
    formItemsBackgroundLightGray: "#f9fbfd",
    notificationBackgroundBeige: "#fcf8e3",
    notificationTextBrown: "#8a6d3b",
    notificationBorderBeige: "#faebcc",
    formItemsLabelDarkGray: "#353a40",
    formItemsHoverLightPurple: "#E7CFEB",
    textDarkBlue: "#194156",
    formSelectedItemsBlue: "#6faec4",
	darkBlue: "#337ab7",
	milestoneTimelineBorder: "#EEEEEE",
    labelPrimaryYellow: "#cf8426",
    labelPrimaryGreen: "#6da34d",
    labelPrimaryBlue: "#62709f",
    sea: "#31a2a3",
    buttonRedHover: "#fe6e6d",
    titleDarkGray: "#353a40",
    filterItemHoverLightGray: "#fafafa",
    labelPrimaryRed: "#ee6c4d",
	hoverDarkBlue: "#23527c",
	headerBackground: "#ffffff",
	headerText: "#73388a",
	formBackground: "#ffffff",
	followUpPersonInFocusBackground: "#fafafa",
	followUpPersonInFocusHoverBackground: "#ededed",
	followUpGiverOfFeedbackBackground: palette.whiteBlue,
	milestoneGiverOfFeedbackBackground: palette.lightRed,
	followUpGiverOfFeedbackHoverBackground: "#d1e9ee",
	milestoneGiverOfFeedbackHoverBackground: palette.lightRed,
	followUpGiverOfFeedbackDisabledBackground: palette.whiteBlue,
	milestoneGiverOfFeedbackDisabledBackground: palette.lightRed,
	formInputColor:"#353a40",
	formInputHoverBorder:"#cdd6dc",
	formBorder: "#EEEEEE",
	formInputPlaceholder: "#cccccc",
	formInputInfo: "#6b6f75",
	formInputDisabled: "#f5f9fb",
	formPurple: "#7f2696",
	formComponentHoverBoxShadow: palette.turquoise,
    toggleComponent: "#31a2a3",
    toggleTurnedOffColor: "#9B9B9B",
	toggleTurnedOff: "#EEEEEE",
	dropdownItemDisabledBackgroundColor: "#F9F9F9",
	temperatureRed: "#ff5c61",
	temperatureYellow: "#ffd900",
	temperatureLightGreen: "#bdec34",
	temperatureGreen: "#5ad036",
	sidebarItemDarkGray: "#aeb0b3",
};