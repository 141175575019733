// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "Controls/Button";
import CircleWrapper from "./CircleWrapper";

const Wrapper = styled(Button)`
	transform-origin: 50% 100%;
`;

class SmileyGeneral extends PureComponent {
	onClick = () => {
		if (!this.props.onClick) {
			return null;
		}

		this.props.onClick(this.props.grade);
	};

	render() {
		return (
			<Wrapper
				data-test="smiley-general"
				aria-hidden={this.props.culture ? "false" : "true"}
				type="button"
				backgroundColor="transparent"
				margin={this.props.margin}
				disabled={this.props.isReadOnly}
				isWithShake={this.props.isWithShake}
				position={this.props.position}
				left={this.props.left}
				top={this.props.top}
				leftXS={this.props.leftXS}
				topXS={this.props.topXS}
				minHeight={this.props.smileySize}
				minWidth={this.props.smileySize}
				height={this.props.smileySize}
				width={this.props.smileySize}
				onClick={this.onClick}>
				<CircleWrapper
					size={this.props.smileySize}
					sizeXS={this.props.smileySizeXS}
					color="#cccccc"
					isActive={this.props.isActive}>
					<g fill="#353a40" fillRule="nonzero" transform="translate(16, 14)">
						<path d="M2 2c-0.552 0-1 0.448-1 1s0.448 1 1 1h12c0.552 0 1-0.448 1-1s-0.448-1-1-1h-12z"></path>
						<path d="M2 7c-0.552 0-1 0.448-1 1s0.448 1 1 1h12c0.552 0 1-0.448 1-1s-0.448-1-1-1h-12z"></path>
						<path d="M2 12c-0.552 0-1 0.448-1 1s0.448 1 1 1h8c0.552 0 1-0.448 1-1s-0.448-1-1-1h-8z"></path>
					</g>
				</CircleWrapper>
			</Wrapper>
		);
	}
}

SmileyGeneral.propTypes = {
	isActive: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isTemperature: PropTypes.bool,
	isWithShake: PropTypes.bool,
	grade: PropTypes.number,
	margin: PropTypes.string,
	color: PropTypes.string,
	smileySize: PropTypes.string,
	smileySizeXS: PropTypes.string,
	position: PropTypes.string,
	left: PropTypes.string,
	top: PropTypes.string,
	topXS: PropTypes.string,
	leftXS: PropTypes.string,
	gradeFontSize: PropTypes.string,
	gradeOpacity: PropTypes.string,
	culture: PropTypes.string,
	onClick: PropTypes.func,
};

export default SmileyGeneral;
