// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import Resources from "Resources";

const AgreementBlock = (props) => {
	const culture = Resources[props.culture]?.gdprAgreementInfoAboutProcessing ? props.culture : "en-US";

	return (
		<div>
			<h2>{Resources[culture].gdprAgreementInfoAboutProcessing}</h2>
			<p>{Resources[culture].gdprAgreementCongratsAndInfo}</p>
			<h3>{Resources[culture].gdprAgreementResponsibilityForYourPersonalData}</h3>
			<p>{Resources[culture].gdprAgreementResponsibilityInfo}</p>
			<h3>{Resources[culture].gdprAgreementPersonalDataEmployerShared}</h3>
			<ul style={{ marginLeft: "20px" }}>
				<li>{Resources[culture].gdprAgreementFirstLastNames}</li>
				<li>{Resources[culture].gdprAgreementEmail}</li>
				<li>{Resources[culture].gdprAgreementOrganisation}</li>
				<li>{Resources[culture].gdprAgreementBirthdate}</li>
				<li>{Resources[culture].gdprAgreementStartDate}</li>
			</ul>
			<h3>{Resources[culture].gdprAgreementWhoHasAccessToPersonalData}</h3>
			<p>{Resources[culture].gdprAgreementWhoHasAccessToPersonalDataInfo}</p>
			<h3>{Resources[culture].gdprAgreementPeriodForProcessing}</h3>
			<p>{Resources[culture].gdprAgreementPeriodForProcessingInfo}</p>
			<h3>{Resources[culture].gdprAgreementAnonymousAndSensitive}</h3>
			<p>{Resources[culture].gdprAgreementAnonymousAndSensitiveInfo}</p>
			<p>{Resources[culture].gdprAgreementAnonymousAndSensitiveInfo2}</p>
		</div>
	);
};

AgreementBlock.propTypes = {
	culture: PropTypes.string,
};

export default AgreementBlock;
