// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Resources from "Resources";
import {
	OptionalAnswerActivationType,
	GradeConfig,
	TemperatureTemplate,
	QuestionDefaultStyles,
	QuestionCounterMinValue,
} from "Enums";
import { heapTrack } from "Helpers";
import { Smiley } from "Controls/Smiley";
import DropdownSelect from "ComponentsLibrary/DropdownSelect/DropdownSelect";
import TextButton from "ComponentsLibrary/Button/TextButton";
import Text from "ComponentsLibrary/Text";
import { colors } from "ComponentsLibrary/shared/theme";
import QuestionWrapper from "./QuestionWrapper";
import QuestionMinified from "./QuestionMinified";
import QuestionComment from "./QuestionComment";
import QuestionActivator from "./QuestionActivator";
import FeedbackQuestionRow from "./FeedbackQuestionRow";
import FeedbackModule from "./FeedbackModule";
import QuestionCounter from "./QuestionCounter";

class Question extends PureComponent {
	constructor(props) {
		super(props);

		this.smilesConfig = this.getSmilesConfig();
		this.isWithQuestionCounter = this.props.questionsCount >= QuestionCounterMinValue;
	}

	getSmilesConfig = () => {
		return [
			{
				gradeValue: 0,
				text: Resources[this.props.culture].stronglyDisagree,
				color: GradeConfig[0].color,
				iconName: GradeConfig[0].lineIconName,
			},
			{
				gradeValue: 1,
				text: Resources[this.props.culture].disagree,
				color: GradeConfig[1].color,
				iconName: GradeConfig[1].lineIconName,
			},
			{
				gradeValue: 2,
				text: Resources[this.props.culture].agree2,
				color: GradeConfig[2].color,
				iconName: GradeConfig[2].lineIconName,
			},
			{
				gradeValue: 3,
				text: Resources[this.props.culture].stronglyAgree,
				color: GradeConfig[3].color,
				iconName: GradeConfig[3].lineIconName,
			},
		];
	};

	activated = () => {
		if (!this.props.question.needActivation) return true;

		return this.props.question.activationType === OptionalAnswerActivationType.approved;
	};

	onActivation = (activated) => {
		this.props.onChange({
			...this.props.question,
			activationType: activated
				? OptionalAnswerActivationType.approved
				: OptionalAnswerActivationType.declined,
		});
	};

	onGradeChange = (grade) => {
		heapTrack(grade ? "[Survey] Select smiley" : "[Survey] Change survey answer");
		this.props.onChange({ ...this.props.question, grade: grade, skipReason: null });
	};

	onCommentChange = (value) => {
		heapTrack("[Survey] Add comment");
		this.props.onChange({ ...this.props.question, comment: value });
	};

	onVisibleForImmediateManagerChange = () => {
		this.props.onChange({
			...this.props.question,
			visibleForImmediateManager: !this.props.question.visibleForImmediateManager,
		});
	};

	onAllowAiAssistedRepliesChange = () => {
		this.props.onChange({
			...this.props.question,
			allowAiAssistedReplies: !this.props.question.allowAiAssistedReplies,
		});
	};

	onExpandQuestionClick = () => {
		this.props.onActiveQuestionChange &&
			this.props.onActiveQuestionChange(this.props.question.questionId);
	};

	onSkipReasonSelect = (reason) => {
		this.props.onChange({ ...this.props.question, skipReason: reason.Id });
	};

	undoSkip = () => {
		this.props.onChange({ ...this.props.question, skipReason: null });
	};

	renderQuestion = (hasAnsweredQuestion, isMinifiedWithoutComment, isMinifiedWithComment) => {
		const configItem = this.smilesConfig.find((x) => x.gradeValue === this.props.question.grade);

		if (!this.activated()) {
			return (
				<QuestionActivator
					isWithQuestionCounter={this.isWithQuestionCounter}
					activationAlign={this.props.activationAlign}
					questionFontSize={this.props.questionFontSize}
					questionFontSizeXS={this.props.questionFontSizeXS}
					questionColor={this.props.questionColor}
					questionFontWeight={this.props.questionFontWeight}
					questionPadding={this.props.questionPadding}
					questionPaddingXS={this.props.questionPaddingXS}
					questionTextPadding={this.props.questionTextPadding}
					culture={this.props.culture}
					question={this.props.question}
					onActivation={this.onActivation}
				/>
			);
		}

		if (isMinifiedWithoutComment || isMinifiedWithComment) {
			return (
				<QuestionMinified
					isWithQuestionCounter={this.isWithQuestionCounter}
					isWithComment={!this.props.isCommentDisabled}
					isWithShake={isMinifiedWithoutComment}
					isReadOnlyAfterSubmit={this.props.isReadOnlyAfterSubmit}
					questionType={TemperatureTemplate.default}
					grade={this.props.question.grade}
					gradeColor={configItem && configItem.color}
					questionPadding={this.props.questionPadding}
					questionPaddingXS={this.props.questionPaddingXS}
					questionText={this.props.question.description}
					questionColor={this.props.questionColor}
					questionFontSize={this.props.questionFontSize}
					questionFontSizeXS={this.props.questionFontSizeXS}
					questionFontWeight={this.props.questionFontWeight}
					questionTextPadding={this.props.questionTextPadding}
					questionTextAlign={this.props.questionTextAlign}
					culture={this.props.culture}
					onGradeClick={this.onGradeChange}
					onExpandQuestionClick={this.onExpandQuestionClick}
				/>
			);
		}

		return (
			<FeedbackQuestionRow
				isWithQuestionCounter={this.isWithQuestionCounter}
				questionPadding={this.props.questionPadding}
				questionPaddingXS={this.props.questionPaddingXS}
				questionFontSize={this.props.questionFontSize}
				questionFontSizeXS={this.props.questionFontSizeXS}
				questionFontWeight={this.props.questionFontWeight}
				questionTextAlign={this.props.questionTextAlign}
				questionTextPadding={this.props.questionTextPadding}
				questionColor={this.props.questionColor}
				question={this.props.question}
				culture={this.props.culture}
				backgroundColor={this.props.backgroundColor}>
				<FeedbackModule
					padding={this.props.smileysPadding}
					justifyContent={this.props.smileysJustifyContent}
					backgroundColor={this.props.smileysBackgroundColor}>
					{(!hasAnsweredQuestion || this.props.isCommentDisabled) && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}>
							{(!this.props.canSkipQuestion ||
								(this.props.canSkipQuestion && !this.props.question.skipReason)) && (
								<div style={{ display: "flex" }}>
									{this.smilesConfig.map((item, index) => (
										<Smiley
											key={index + "" + item.gradeValue}
											grade={item.gradeValue}
											iconName={item.iconName}
											color={item.color}
											culture={this.props.culture}
											margin={
												index === this.smilesConfig.length - 1 ? "0" : "0 10px 0 0"
											}
											smileySize={
												this.props.smileySize || QuestionDefaultStyles.smileySize
											}
											smileySizeXS={this.props.smileySizeXS}
											isReadOnly={this.props.isReadOnly}
											isActive={this.props.question.grade === item.gradeValue}
											onClick={this.onGradeChange}
											isChristmasModeEnabled={this.props.isChristmasModeEnabled}
										/>
									))}
								</div>
							)}

							{this.props.question.skipReason && (
								<Text fontType="normalMedium" color={colors.onyx}>
									{Resources[this.props.culture].youveSkippedThisQuestion}
								</Text>
							)}

							{this.props.canSkipQuestion && !this.props.question.skipReason && (
								<DropdownSelect
									margin="12px 0 0 0"
									mediaWidth="auto"
									isDiscriptive={true}
									noSelectedLabel={Resources[this.props.culture].skipQuestion}
									options={[
										{
											Id: 1,
											Name: Resources[this.props.culture].iDontUnderstandTheQuestion,
										},
										{
											Id: 2,
											Name: Resources[this.props.culture].theQuestionIsNotRelevantToMe,
										},
										{
											Id: 3,
											Name: Resources[this.props.culture]
												.iDontHaveEnoughInformationToAnswer,
										},
										{
											Id: 4,
											Name: Resources[this.props.culture].otherReason,
										},
									]}
									culture={this.props.culture}
									onChange={this.onSkipReasonSelect}
								/>
							)}
							{this.props.canSkipQuestion && this.props.question.skipReason && (
								<TextButton
									mt={1.5}
									label={Resources[this.props.culture].undoSkip}
									icon="chevron-left-16"
									onClick={this.undoSkip}
								/>
							)}
						</div>
					)}
					{!this.props.isCommentDisabled && hasAnsweredQuestion && (
						<QuestionComment
							isCommentDisabled={this.props.isCommentDisabled}
							isReadOnlyAfterSubmit={this.props.isReadOnlyAfterSubmit}
							isHideCommentFromManagerVisible={this.props.isHideCommentFromManagerVisible}
							isAllowAiAssistedRepliesVisible={this.props.isAllowAiAssistedRepliesVisible}
							questionType={TemperatureTemplate.default}
							culture={this.props.culture}
							grade={this.props.question.grade}
							questionText={this.props.question.description}
							color={configItem.color}
							onChange={this.onCommentChange}
							questionPadding={this.props.questionPadding}
							questionPaddingXS={this.props.questionPaddingXS}
							onVisibleForImmediateManagerChange={this.onVisibleForImmediateManagerChange}
							onAllowAiAssistedRepliesChange={this.onAllowAiAssistedRepliesChange}
							isVisibleForImmediateManager={this.props.question.visibleForImmediateManager}
							allowAiAssistedReplies={this.props.question.allowAiAssistedReplies}
							comment={this.props.question.comment}
							onGradeClick={this.onGradeChange}
						/>
					)}
				</FeedbackModule>
			</FeedbackQuestionRow>
		);
	};

	render() {
		const hasAnsweredQuestion = !!this.props.question.grade || this.props.question.grade === 0;
		const isActiveQuestion = this.props.activeQuestionId === this.props.question.questionId;

		const isMinifiedWithoutComment = this.props.isCommentDisabled && hasAnsweredQuestion;
		const isMinifiedWithComment =
			!this.props.isCommentDisabled &&
			!isActiveQuestion &&
			hasAnsweredQuestion &&
			!this.props.question.comment;

		return (
			<QuestionWrapper
				data-test="question"
				ref={this.props.feedbackQuestionRef}
				maxWidth={this.props.isMobileView && "90vw"}
				padding={this.props.wrapperPadding}
				margin={this.props.wrapperMargin}
				border={this.props.wrapperBorder}>
				{this.isWithQuestionCounter && (
					<QuestionCounter
						isMinified={isMinifiedWithoutComment || isMinifiedWithComment}
						culture={this.props.culture}
						questionIndex={this.props.questionIndex}
						questionsCount={this.props.questionsCount}
						questionTextPadding={this.props.questionTextPadding}
						color={this.props.questionColor}
					/>
				)}
				{this.renderQuestion(hasAnsweredQuestion, isMinifiedWithoutComment, isMinifiedWithComment)}
			</QuestionWrapper>
		);
	}
}

Question.propTypes = {
	canSkipQuestion: PropTypes.bool,
	isMobileView: PropTypes.bool,
	isCommentDisabled: PropTypes.bool,
	isHideCommentFromManagerVisible: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isReadOnlyAfterSubmit: PropTypes.bool,
	questionIndex: PropTypes.number,
	questionsCount: PropTypes.number,
	questionPadding: PropTypes.string,
	questionPaddingXS: PropTypes.string,
	culture: PropTypes.string,
	questionColor: PropTypes.string,
	questionFontSize: PropTypes.string,
	questionFontSizeXS: PropTypes.string,
	questionFontWeight: PropTypes.string,
	questionTextAlign: PropTypes.string,
	questionTextPadding: PropTypes.string,
	backgroundColor: PropTypes.string,
	smileySize: PropTypes.string,
	smileySizeXS: PropTypes.string,
	smileysPadding: PropTypes.string,
	smileysJustifyContent: PropTypes.string,
	smileysBackgroundColor: PropTypes.string,
	activationAlign: PropTypes.string,
	wrapperPadding: PropTypes.string,
	wrapperMargin: PropTypes.string,
	wrapperBorder: PropTypes.string,
	activeQuestionId: PropTypes.string,
	question: PropTypes.object,
	onChange: PropTypes.func,
	onActiveQuestionChange: PropTypes.func,
};

export default Question;
