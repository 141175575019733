import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import config from "./config";

const Wrapper = styled.svg`
	display: ${(props) => props.display || "inline-block"};
	fill: ${(props) => props.fill || "currentColor"};
	height: ${(props) => props.size || "24px"};
	width: ${(props) => props.size || "24px"};
	min-width: ${(props) => props.size || "24px"};
	${(props) => (props.alignSelf ? `align-self: ${props.alignSelf};` : null)}
	${(props) => (props.margin ? `margin: ${props.margin};` : null)}
	${(props) => (props.rotate ? `transform: rotate(${props.rotate});` : null)}
	${(props) => (props.position ? `position: ${props.position};` : null)}
	${(props) => (props.top ? `top: ${props.top};` : null)}
	${(props) => (props.right ? `right: ${props.right};` : null)}
	${(props) => (props.bottom ? `bottom: ${props.bottom};` : null)}
	${(props) => (props.left ? `left: ${props.left};` : null)}
	${(props) => (props.opacity ? `opacity: ${props.opacity};` : null)}
	@media(max-width: 768px) {
		${(props) => (props.displayXS ? `display: ${props.displayXS};` : null)}
		${(props) => (props.marginXS ? `margin: ${props.marginXS};` : null)}
		${(props) =>
			props.sizeXS
				? `height: ${props.sizeXS}; width: ${props.sizeXS}; min-width: ${props.sizeXS};`
				: null}
	}
`;

class SvgIcon extends PureComponent {
	render() {
		const Icon = config[this.props.icon];
		return (
			<Wrapper {...this.props} viewBox="0 0 512 512" data-test={this.props.dataTest}>
				{Icon ? <Icon /> : null}
			</Wrapper>
		);
	}
}

SvgIcon.propTypes = {
	display: PropTypes.string,
	fill: PropTypes.string,
	icon: PropTypes.string,
	margin: PropTypes.string,
	rotate: PropTypes.string,
	size: PropTypes.string,
	sizeXS: PropTypes.string,
	displayXS: PropTypes.string,
	position: PropTypes.string,
	top: PropTypes.string,
	right: PropTypes.string,
	bottom: PropTypes.string,
	left: PropTypes.string,
	marginXS: PropTypes.string,
	alignSelf: PropTypes.string,
	dataTest: PropTypes.string,
};

export default SvgIcon;
