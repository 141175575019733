import React, { PureComponent } from "react";
import Resources from "Resources";

const SCREENS = {
	doYouWantToTalk: 0,
	selectContact: 1,
}

export default ComposedComponent => class extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeScreen: SCREENS.doYouWantToTalk,
			progress: 0,
			selectedContact: this.props.contacts[0],
			selected: this.props.contacts.length > 0 ? this.props.contacts[0].Key : null,
			questionTalkData: {
				SupportUserId: this.props.contacts[0].Key,
				comment: "",
			}
		}
	}

	onCommentChange = (comment) => {
		this.setState({
			questionTalkData: {
				...this.state.questionTalkData,
				comment,
			}
		});
	}

	onContactChange = (contact) => {
		this.setState({
			selectedContact: contact,
			questionTalkData: {
				...this.state.questionTalkData,
				SupportUserId: contact.Key,
			}
		});
	}

	onSubmit = () => {
		const isExternal = this.state.selectedContact?.Key.startsWith("External");

		if (!this.state.selected) {
			return null;
		}

		const dataForSave = {
			Comment: this.state.questionTalkData.comment,
			SupportUserId: this.state.questionTalkData.SupportUserId
		}

		this.props.token && (dataForSave.Token = this.props.token);

		this.props.onQuestionTalkSubmit(
			dataForSave,
			isExternal ? this.props.urls.submitExternal : this.props.urls.submitDefault,
			Resources[this.props.culture].aMessageIsSentToTheContactPerson
		);
	}

	onQuestionTalkNoSubmit = () => {
		this.props.onQuestionTalkNoSubmit(this.props.urls.submitDefault, this.props.token);
	}

	openDoYouWantToTalkScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(0);
		this.setState({
			activeScreen: SCREENS.doYouWantToTalk,
			progress: 0,
		});
	}

	openSelectContactScreen = () => {
		this.props.getOutterProgressBarWidth && this.props.getOutterProgressBarWidth(50);
		this.setState({
			activeScreen: SCREENS.selectContact,
			progress: 50,
		});
	}

	render() {
		return (
			<ComposedComponent
				{...this.props}
				{...this.state}
				screens={SCREENS}
				openDoYouWantToTalkScreen={this.openDoYouWantToTalkScreen}
				onQuestionTalkNoSubmit={this.onQuestionTalkNoSubmit}
				openSelectContactScreen={this.openSelectContactScreen}
				onCommentChange={this.onCommentChange}
				onContactChange={this.onContactChange}
				onSubmit={this.onSubmit} />
		);
	}
}