// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import ContentWrapper from "Controls/ContentWrapper";
import { AgreementWrapper, AgreementBlock } from "Controls/Gdpr";

const SurveyGdprAgreement = (props) => {
	return (
		<ContentWrapper padding="20px" flex="1">
			<AgreementWrapper color="#4c4c4c" overflowY="none" backgroundColor="#fff" width="auto" paddingXS="10px">
				<AgreementBlock culture={props.culture} />
			</AgreementWrapper>
		</ContentWrapper>
	);
};

SurveyGdprAgreement.propTypes = {
	culture: PropTypes.string,
};

export default SurveyGdprAgreement;
