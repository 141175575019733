import React from "react";
import { css } from "styled-components";
import theme from "./theme";

const font = (fontType) => {
	return (
		fontType &&
		theme.typography[fontType] &&
		css`
			font-family: ${theme.typography[fontType].fontFamily} !important;
			font-weight: ${theme.typography[fontType].fontWeight};
			font-size: ${theme.typography[fontType].fontSize};
			line-height: ${theme.typography[fontType].lineHeight};
			${theme.typography[fontType].letterSpacing && `letter-spacing: ${theme.typography[fontType].letterSpacing};`};
			${theme.typography[fontType].textTransform && `text-transform: ${theme.typography[fontType].textTransform};`};
		`
	);
};

export default font;
