/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import SvgIcon from "Controls/SvgIcon";
import { colors, transition, shadow } from "../shared/theme";
import { spacingIndex, spacing } from "../shared/spacing";

const Default = css`
	background-color: transparent;
	box-shadow: none;
	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
	&:active {
		background-color: rgba(0, 0, 0, 0.05);
	}
	&:focus-visible {
		box-shadow: 0px 0px 0px 3px rgba(0, 204, 185, 0.5);
	}
	&:disabled {
		background-color: transparent;
	}
`;

const Inverse = css`
	background-color: transparent;
	box-shadow: none;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	&:active {
		background-color: rgba(0, 0, 0, 0.1);
	}
	&:focus-visible {
		box-shadow: 0px 0px 0px 3px rgba(0, 204, 185, 0.5);
	}
	&:disabled {
		background-color: transparent;
	}
`;

const White = css`
	background-color: ${colors.white};
	box-shadow: none;
	&:hover {
		background-color: ${colors.whiteSmoke};
	}
	&:active {
		background-color: ${colors.whiteSmoke};
	}
	&:focus-visible {
		box-shadow: ${shadow.focusGreenOutline};
	}
	&:disabled {
		background-color: ${colors.whiteSmoke};
	}
`;

const XLarge = css`
	height: ${spacingIndex * 4}px;
	width: ${spacingIndex * 4}px;
`;

const Large = css`
	height: ${spacingIndex * 3}px;
	width: ${spacingIndex * 3}px;
`;

const Medium = css`
	height: ${spacingIndex * 2.5}px;
	width: ${spacingIndex * 2.5}px;
`;

const Small = css`
	height: ${spacingIndex * 2}px;
	width: ${spacingIndex * 2}px;
`;

const getStylesBySize = (size) => {
	switch (size) {
		case "xlarge":
			return XLarge;
		case "large":
			return Large;
		case "medium":
			return Medium;
		case "small":
		default:
			return Small;
	}
};

const getStylesByAppearance = (appearance) => {
	switch (appearance) {
		case "white":
			return White;
		case "inverse":
			return Inverse;
		case "default":
		default:
			return Default;
	}
};

const getFillColorByAppearance = (appearance) => {
	switch (appearance) {
		case "inverse":
			return colors.white;
		case "white":
		case "default":
		default:
			return colors.onyx;
	}
};

const getIconSize = (size) => {
	switch (size) {
		case "xlarge":
			return `${spacingIndex * 3}px`;
		case "large":
			return `${spacingIndex * 2}px`;
		case "medium":
		case "small":
		default:
			return `${spacingIndex * 1.5}px`;
	}
};

const getDefaultIcon = (size) => {
	switch (size) {
		case "xlarge":
		case "large":
			return "cross-16";
		case "medium":
		case "small":
		default:
			return "cross-12";
	}
};

const Wrapper = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	border-radius: 50%;
	transition: ${transition.main};
	${(props) => props.position && `position: ${props.position};`}
	${(props) => props.top && `top: ${props.top};`}
	${(props) => props.right && `right: ${props.right};`}
	${(props) => getStylesBySize(props.size)}
	${(props) => getStylesByAppearance(props.appearance)}
	${spacing}
	&:focus {
		outline: none;
	}
	&:disabled {
		opacity: 0.5;
		cursor: ${(props) => props.disabledCursor || "default"};
	}
`;

const IconButton = (props) => {
	const fillColor = getFillColorByAppearance(props.appearance);
	const iconSize = getIconSize(props.size);
	const defaultIcon = getDefaultIcon(props.size);

	return (
		<Wrapper {...props} data-test={props.dataTest}>
			<SvgIcon
				isLoading={props.isLoading}
				rotate={props.iconRotate}
				fill={fillColor}
				size={iconSize}
				icon={props.icon || defaultIcon}
				transition={props.iconTransition}
				dontShowIconWhenLoading={props.dontShowIconWhenLoading}
			/>
		</Wrapper>
	);
};

const propTypes = {
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	iconRotate: PropTypes.string,
	iconTransition: PropTypes.string,
	dataTest: PropTypes.string,
	position: PropTypes.string,
	top: PropTypes.string,
	right: PropTypes.string,
	disabledCursor: PropTypes.string,
	size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
	appearance: PropTypes.oneOf(["default", "inverse", "white"]),
	onClick: PropTypes.func,
};

IconButton.propTypes = propTypes;

IconButton.defaultProps = {
	size: "large",
	appearance: "default",
};

export default IconButton;

export { propTypes, Wrapper as IconButtonWrapper };
