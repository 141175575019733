// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
	display: ${(props) => props.display || "flex"};
	align-items: ${(props) => props.alignItems || "center"};
	justify-content: ${(props) => props.justifyContent || "center"};
	cursor: ${(props) => props.cursor || "pointer"};
	background-color: ${(props) => props.backgroundColor || "#ffffff"};
	border: ${(props) => props.border || "none"};
	color: ${(props) => props.color || "#999999"};
	font-size: ${(props) => props.fontSize || "13px"};
	font-weight: ${(props) => props.fontWeight || "500"};
	height: ${(props) => props.height || "auto"};
	width: ${(props) => props.width || "auto"};
	border-radius: ${(props) => props.borderRadius || "3px"};
	margin: ${(props) => props.margin || "0"};
	padding: ${(props) => props.padding || "0"};
	text-transform: ${(props) => props.textTransform || "none"};
	transition: ${(props) => props.transition || "all .5s cubic-bezier(.125,.75,.415,1)"};
	${(props) => (props.paddingRight ? `padding-right: ${props.paddingRight};` : null)}
	${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : null)}
	${(props) => (props.marginRight ? `margin-right: ${props.marginRight};` : null)}
	${(props) => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : null)}
	${(props) => (props.flexWrap ? `flex-wrap: ${props.flexWrap};` : null)}
	${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : null)}
	${(props) => (props.textDecoration ? `text-decoration: ${props.textDecoration};` : null)}
	${(props) => (props.borderWidth ? `border-width: ${props.borderWidth};` : null)}
	${(props) => (props.borderBottom ? `border-bottom: ${props.borderBottom};` : null)}
	${(props) => (props.borderLeft ? `border-left: ${props.borderLeft};` : null)}
	${(props) => (props.position ? `position: ${props.position};` : null)}
	${(props) => (props.top ? `top: ${props.top};` : null)}
	${(props) => (props.right ? `right: ${props.right};` : null)}
	${(props) => (props.bottom ? `bottom: ${props.bottom};` : null)}
	${(props) => (props.left ? `left: ${props.left};` : null)}
	${(props) => (props.overflow ? `overflow: ${props.overflow};` : null)}
	${(props) => (props.opacity ? `opacity: ${props.opacity};` : null)}
	${(props) => (props.float ? `float: ${props.float};` : null)}
	${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow};` : null)}
	${(props) => (props.lineHeight ? `line-height: ${props.lineHeight};` : null)}
	${(props) => (props.flex ? `flex: ${props.flex};` : null)}
	${(props) => (props.flexDirection ? `flex-direction: ${props.flexDirection};` : null)}   
	${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : null)}   
	${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : null)}   
	${(props) => (props.flexGrow ? `flex-grow: ${props.flexGrow};` : null)}   
	${(props) => (props.whiteSpace ? `white-space: ${props.whiteSpace};` : null)}   
	${(props) => (props.outline ? `outline: ${props.outline};` : null)}
	${(props) => (props.outlineOffset ? `outline-offset: ${props.outlineOffset};` : null)}
	${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : null)}
	${(props) => (props.touchAction ? `touch-action: ${props.touchAction};` : null)}
	${(props) => (props.background ? `background: ${props.background};` : null)}
	${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : null)}
	${(props) => (props.fontStyle ? `font-style: ${props.fontStyle};` : null)}
	${(props) => (props.textOverflow ? `text-overflow: ${props.textOverflow};` : null)}
	${(props) => (props.borderRight ? `border-right: ${props.borderRight};` : null)}
	${(props) => (props.borderTop ? `border-top: ${props.borderTop};` : null)}
	${(props) => (props.fontFamily ? `font-family: ${props.fontFamily};` : null)};
	& * {
		${(props) => (props.fontFamily ? `font-family: ${props.fontFamily};` : null)};
	}
	&:hover {
		display: ${(props) => props.display || "flex"};
		${(props) => (props.hoverBackgroundColor ? `background-color: ${props.hoverBackgroundColor};` : null)}
		${(props) => (props.hoverColor ? `color: ${props.hoverColor};` : null)}
		${(props) => (props.hoverBorder ? `border: ${props.hoverBorder};` : null)}
		${(props) => (props.hoverBorderBottom ? `border-bottom: ${props.hoverBorderBottom};` : null)}
		${(props) => (props.hoverTextDecoration ? `text-decoration: ${props.hoverTextDecoration};` : null)}
		${(props) => (props.hoverBoxShadow ? `box-shadow: ${props.hoverBoxShadow};` : null)}
		${(props) => (props.hoverOpacity ? `opacity: ${props.hoverOpacity};` : null)}
		${(props) =>
			props.hoverWithIconTextDecoration
				? `
			> span:last-child {
				text-decoration: ${props.hoverWithIconTextDecoration};
			}
		`
				: null}
	}
	@media (max-width: 768px) {
		${(props) => (props.displayXS ? `display: ${props.displayXS};` : null)}
		${(props) => (props.widthXS ? `width: ${props.widthXS};` : null)}
		${(props) => (props.heightXS ? `height: ${props.heightXS};` : null)}
		${(props) => (props.borderWidthXS ? `border-width: ${props.borderWidthXS};` : null)}
		${(props) => (props.paddingXS ? `padding: ${props.paddingXS};` : null)}
		${(props) => (props.topXS ? `top: ${props.topXS};` : null)}
		${(props) => (props.rightXS ? `right: ${props.rightXS};` : null)}
		${(props) => (props.leftXS ? `right: ${props.leftXS};` : null)}
		${(props) => (props.marginXS ? `margin: ${props.marginXS};` : null)}
		${(props) => (props.minWidthXS ? `min-width: ${props.minWidthXS};` : null)}   
		${(props) => (props.positionXS ? `position: ${props.positionXS};` : null)}   
		${(props) => (props.colorXS ? `color: ${props.colorXS};` : null)}   
		${(props) => (props.maxWidthXS ? `max-width: ${props.maxWidthXS};` : null)}
		${(props) => (props.fontSizeXS ? `font-size: ${props.fontSizeXS};` : null)}
		${(props) => (props.borderRadiusXS ? `border-radius: ${props.borderRadiusXS};` : null)}
		${(props) => (props.flexGrowXS ? `flex-grow: ${props.flexGrowXS};` : null)}   
		${(props) => (props.alignItemsXS ? `align-items: ${props.alignItemsXS};` : null)}   
		${(props) => (props.backgroundColorXS ? `background-color: ${props.backgroundColorXS};` : null)}   
		&:hover {
			${(props) =>
				props.hoverBackgroundColorXS ? `background-color: ${props.hoverBackgroundColorXS};` : null}
			${(props) => (props.displayXS ? `display: ${props.displayXS};` : null)}
		}
	}
	@media (max-width: 1280px) {
		${(props) => (props.displayS ? `display: ${props.displayS};` : null)}
		&:hover {
			${(props) => (props.displayS ? `display: ${props.displayS};` : null)}
		}
	}
	&:disabled {
		background-color: ${(props) => props.disabledBackgroundColor || props.backgroundColor || "#ffffff"};
		border: ${(props) => props.disabledBorder || props.border || "none"};
		border-width: ${(props) => props.disabledBorderWidth || props.borderWidth || "none"};
		cursor: ${(props) => props.disabledCursor || "not-allowed"};
		${(props) => (props.disabledColor ? `color: ${props.disabledColor};` : null)}
		${(props) => (props.disabledOpacity ? `opacity: ${props.disabledOpacity};` : null)}
	}
	&:focus {
		outline: ${(props) => props.focusOutline || props.outline || "none"};
		${(props) => (props.focusBorderColor ? `border-color: ${props.focusBorderColor};` : null)}
		${(props) => (props.focusBoxShadow ? `box-shadow: ${props.focusBoxShadow};` : null)}
	}
`;

Button.propTypes = {
	backgroundColor: PropTypes.string,
	border: PropTypes.string,
	displayS: PropTypes.string,
	borderWidth: PropTypes.string,
	borderWidthXS: PropTypes.string,
	hoverBorder: PropTypes.string,
	color: PropTypes.string,
	colorXS: PropTypes.string,
	fontSize: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	widthXS: PropTypes.string,
	padding: PropTypes.string,
	borderRadius: PropTypes.string,
	margin: PropTypes.string,
	hoverBackgroundColor: PropTypes.string,
	disabledBackgroundColor: PropTypes.string,
	hoverColor: PropTypes.string,
	textTransform: PropTypes.string,
	textAlign: PropTypes.string,
	textDecoration: PropTypes.string,
	hoverTextDecoration: PropTypes.string,
	opacity: PropTypes.string,
	hoverOpacity: PropTypes.string,
	overflow: PropTypes.string,
	float: PropTypes.string,
	lineHeight: PropTypes.string,
	cursor: PropTypes.string,
	boxShadow: PropTypes.string,
	paddingXS: PropTypes.string,
	alignItems: PropTypes.string,
	justifyContent: PropTypes.string,
	borderBottom: PropTypes.string,
	borderLeft: PropTypes.string,
	marginXS: PropTypes.string,
	flexDirection: PropTypes.string,
	topXS: PropTypes.string,
	rightXS: PropTypes.string,
	leftXS: PropTypes.string,
	top: PropTypes.string,
	right: PropTypes.string,
	bottom: PropTypes.string,
	left: PropTypes.string,
	flexGrow: PropTypes.string,
	flexGrowXS: PropTypes.string,
	whiteSpace: PropTypes.string,
	focusOutline: PropTypes.string,
	minWidth: PropTypes.string,
	maxWidth: PropTypes.string,
	disabledColor: PropTypes.string,
	positionXS: PropTypes.string,
	minHeight: PropTypes.string,
	touchAction: PropTypes.string,
	paddingRight: PropTypes.string,
	marginTop: PropTypes.string,
	marginRight: PropTypes.string,
	hoverBoxShadow: PropTypes.string,
	disabledBorder: PropTypes.string,
	marginBottom: PropTypes.string,
	flexWrap: PropTypes.string,
	zIndex: PropTypes.string,
	hoverWithIconTextDecoration: PropTypes.string,
	fontStyle: PropTypes.string,
	disabledCursor: PropTypes.string,
	textOverflow: PropTypes.string,
	hoverBorderBottom: PropTypes.string,
	alignItemsXS: PropTypes.string,
	heightXS: PropTypes.string,
	fontSizeXS: PropTypes.string,
	transition: PropTypes.string,
	borderRadiusXS: PropTypes.string,
	disabledOpacity: PropTypes.string,
	borderRight: PropTypes.string,
	borderTop: PropTypes.string,
	fontFamily: PropTypes.string,
	fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Button;
