export default (groupId, options) => {
	let group = null;
	const findGroup = (groupsArr) => {
		if (group) {
			return null;
		}
		return groupsArr.forEach((g) => {
			if (g.Id === groupId) {
				group = g;
				return null;
			}
			if (g.Children && g.Children.length) {
				return findGroup(g.Children);
			}
			return null;
		});
	};
	findGroup(options);
	return group;
};
