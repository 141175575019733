import React, { Component } from "react";
import PropTypes from "prop-types";

class Customer extends Component {
    onKeyDown = (event) => {        
        if (event.keyCode === 13) {
            this.props.onEnter();
            event.preventDefault();
        }
    }

    render() {
        return (
            <input
                ref="customer"
                className="required username"
                placeholder="checkin-name"
                type="text"
                value={this.props.value}
                onKeyDown={this.onKeyDown}
                onChange={(e) => this.props.onChange(e.target.value)} />
        );
    }
}

Customer.propTypes = {
    value: PropTypes.string,
    culture: PropTypes.string,        
    onChange: PropTypes.func,
    onEnter: PropTypes.func
}

export default Customer;