import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import Code from "./Code";
import LanguageSelector from "./LanguageSelector";
import Logo from "./Logo";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = { code: "" };
	}

	onCodeChange = (e) => {
		this.setState({ code: e.target.value });
	}

	onSubmit = () => {
		if (!this.state.code || this.state.code.length < 4)
			return;

		this.props.onSubmit(
			this.state.code,
			this.props.customerId,
			this.props.culture
		);

		this.setState({ code: "" });
	}

	render() {
		if (this.props.authenticated) {
			return (
				<Redirect to={this.props.urls.batches}/>
			);
		}

		return (
			<SurveyContainerWrapper className="with-auto-height-adjust" paddingTopXS="0">
				<div className="login-form">
					<div className="login-header">
						<Logo
							width="185px"
							margin="0 auto"/>
					</div>
					<div className="login-form-container">
						<div className="code-form-input">
							<div className="form-group">
								<div className="help-text">{this.props.t("EnterYourCode")}</div>
								<Code
									culture={this.props.culture}
									value={this.state.code}
									onEnter={this.onSubmit}
									onChange={this.onCodeChange}/>
							</div>
						</div>
						<div className="login-form-button">
							<button
								type="submit"
								disabled={this.state.code.length < 4}
								className="btn btn-blue submit-btn"
								onClick={this.onSubmit}>
								{this.props.t("SignIn")}
							</button>
						</div>
					</div>
					<div className="bottom-action">
						{this.props.error &&
							<div className="error-code">
							{this.props.t("InvalidCode")}
							</div>
						}
						<div className="no-code-received">
							<b>{this.props.t("HaventGotAnyCode")}</b>{this.props.t("PleaseContactYourImmediateManager") +"."}
						</div>
					</div>
					<div className="login-language-container">
						<LanguageSelector
							culture={this.props.culture}
							languages={this.props.languages}
							onCultureChange={this.props.onCultureChange}/>
					</div>
				</div>
			</SurveyContainerWrapper>
		);
	}
}

Login.propTypes = {
	authenticated: PropTypes.bool,
	culture: PropTypes.string,
	customerId: PropTypes.string,
	error: PropTypes.bool,
	urls: PropTypes.object,
	onSubmit: PropTypes.func,
	onCultureChange: PropTypes.func
}

export default withTranslation()(Login);