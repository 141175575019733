// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Colors from "Colors";

const getGradeStyles = (props) => ({
	fontSize: props.fontSize || "15px",
	fontWeight: "700",
	fill: Colors.darkGrayText,
})

class SmileyGrade extends PureComponent {
	render() {
		return (
			<text
				x="24"
				y="28"
				textAnchor="middle"
				style={getGradeStyles(this.props)}
				opacity={this.props.opacity || ".8"} >
				{this.props.grade}
			</text>
		);
	}
}

SmileyGrade.propTypes = {
	grade: PropTypes.number,
	fontSize: PropTypes.string,
	opacity: PropTypes.string,
}

export default SmileyGrade;