import React, { Component } from "react";
import PropTypes from "prop-types";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import Customer from "./Customer";
import LanguageSelector from "./LanguageSelector";
import Logo from "./Logo";

class Start extends Component {
	constructor(props) {
		super(props);
		this.state = { customer: "" };
	}

	onSubmit = () => {
		if (!this.state.customer)
			return;

		this.props.onSubmit(this.state.customer);
	}

	render() {
		return (
			<SurveyContainerWrapper className="with-auto-height-adjust" paddingTopXS="0">
				<div className="login-form">
					<div className="login-header">
						<Logo
							width="185px"
							margin="0 auto"/>
					</div>
					<div className="login-form-container">
						<div className="customer-form-input">
							<div className="form-group">
								<Customer
									value={this.state.customer}
									onEnter={this.onSubmit}
									onChange={(customer) => this.setState({ customer: customer })}/>
							</div>
						</div>
						<div className="login-form-button">
							<button type="submit" className="btn btn-blue submit-btn" onClick={this.onSubmit}>
								CHECKIN
							</button>
						</div>
					</div>
					<div className="bottom-action"></div>
					<div className="login-language-container">
						<LanguageSelector
							culture={this.props.culture}
							languages={this.props.languages}
							onCultureChange={this.props.onCultureChange}/>
					</div>
				</div>
			</SurveyContainerWrapper>
		);
	}
}

Start.propTypes = {
	culture: PropTypes.string,
	languages: PropTypes.array,
	onCultureChange: PropTypes.func,
	onSubmit: PropTypes.func
}

export default Start;