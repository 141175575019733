import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { findAnswerWithExtendedTalkEscalation } from "Helpers";
import { logoutUser } from "../actions/authentication";
import {
	submitBatch,
	updateQuestion,
	saveTemperatureComments,
	saveQuestionTalk,
	getOptionsForInformationStrategy,
	getOptionsForInformationStrategies,
	saveQuestionTalkExtendedComment,
	saveQuestionTalkExtendedExperience,
	toggleChristmasMode,
	onQuestionTalkExtendedSend,
} from "../actions/batches";
import { navigateToBatches, navigateToLogin, navigateToWidget } from "../actions/router";
import { ESCALATE_TEMPERATURE_ID, ESCALATE_TALK_ID, ESCALATE_TALK_EXTENDED_ID } from "../constants";
import { getAuth } from "../actions/authentication.localStorage";
import { getCulture } from "../actions/culture";
import { withBackgroundHeightAdjust } from "../components/WithBackgroundHeightAdjust";
import Survey from "../components/Survey";
import Escalate from "../components/Escalate";
import EscalateTalk from "../components/EscalateTalk";
import EscalateTalkExtended from "../components/EscalateTalkExtended";
import Confirmation from "../components/Confirmation";

class QuestionContainer extends Component {
	onQuestionTalkSubmit = (data, url) => {
		data.UserEscalateId = this.props.escalated.result.userEscalateId;
		this.props.onQuestionTalkSubmit(data, url, this.props.batch);
	};

	onQuestionTalkNoSubmit = (url, token) => {
		const data = {
			Declined: true,
			UserEscalateId: this.props.escalated.result.userEscalateId,
		};
		this.props.onQuestionTalkSubmit(data, url, this.props.batch);
	};

	onSubmitQuestions = (batch) => {
		const questionTalkExtendedQuestions = findAnswerWithExtendedTalkEscalation(batch.questions);

		if (questionTalkExtendedQuestions && questionTalkExtendedQuestions.length > 0) {
			return this.props.getOptionsForInformationStrategies(
				batch,
				questionTalkExtendedQuestions,
				questionTalkExtendedQuestions
			);
		}

		return this.props.onQuestionSubmit(batch);
	};

	onQuestionTalkExtendedCommentSubmit = (commentData) => {
		this.props.onQuestionTalkExtendedCommentSubmit(this.props.batch, commentData);
	};

	onQuestionTalkExtendedExperienceSubmit = (answer) => {
		this.props.onQuestionTalkExtendedExperienceSubmit(this.props.batch, answer);
	};

	onQuestionTalkExtendedSend = (answers) => {
		this.props.onQuestionTalkExtendedSend(this.props.batch, answers);
	};

	onToggleChristmasMode = () => {
		this.props.onToggleChristmasMode(this.props.batch);
	};

	render() {
		const { escalated } = this.props;

		if (this.props.batch && this.props.batch.completed) {
			return (
				<Confirmation
					culture={this.props.culture}
					widgetEnabled={this.props.widgetEnabled}
					shouldLogout={this.props.shouldLogout}
					onBack={this.props.onBack}
					onLogout={this.props.onLogout}
					onWidgetClick={this.props.onWidgetClick}
				/>
			);
		}

		if (escalated && escalated.result.typeId === ESCALATE_TEMPERATURE_ID) {
			return (
				<Escalate
					culture={this.props.culture}
					username={this.props.username}
					batch={this.props.batch}
					temperature={escalated.result.temperature}
					urls={escalated.result.urls}
					userEscalateId={escalated.result.userEscalateId}
					userEscalateDate={escalated.result.userEscalateDate}
					onLogout={this.props.onLogout}
					onSubmit={this.props.onEscalateSubmit}
					onCancel={this.props.onEscalateSubmit}
				/>
			);
		}

		if (escalated && escalated.result.typeId === ESCALATE_TALK_ID) {
			return (
				<EscalateTalk
					username={this.props.username}
					onLogout={this.props.onLogout}
					onQuestionTalkSubmit={this.onQuestionTalkSubmit}
					onQuestionTalkNoSubmit={this.onQuestionTalkNoSubmit}
					batch={this.props.batch}
					userEscalateId={escalated.result.userEscalateId}
					urls={escalated.result.urls}
					contacts={escalated.result.contacts}
					culture={this.props.culture}
				/>
			);
		}

		if (escalated && escalated.result.typeId === ESCALATE_TALK_EXTENDED_ID) {
			return (
				<EscalateTalkExtended
					username={this.props.username}
					onLogout={this.props.onLogout}
					culture={this.props.culture}
					questionSettings={escalated.batch.questionSettings}
					escalateTalkExtendedData={escalated.result.escalateTalkExtendedData}
					onQuestionTalkExtendedSend={this.onQuestionTalkExtendedSend}
				/>
			);
		}

		return (
			<Survey
				batch={this.props.batch}
				isGeneralCommentEnabled={this.props.batch?.questionSettings?.isGeneralCommentEnabled}
				isAllowAiAssistedRepliesVisible={
					this.props.batch?.questionSettings?.allowAiAssistedRepliesToggle
				}
				submitting={this.props.submitting}
				username={this.props.username}
				culture={this.props.culture}
				onLogout={this.props.onLogout}
				onBack={this.props.onBack}
				onChange={this.props.onQuestionChange}
				onSubmit={this.onSubmitQuestions}
				onToggleChristmasMode={this.onToggleChristmasMode}
			/>
		);
	}
}

const mapStateToProps = (state, { match }) => {
	const escalated = state.questions.escalated && {
		...state.questions.escalated,
		description: getTemperatureFromEscalated(state.questions.escalated, state.questions.batches),
	};
	const authentication = getAuth();

	return {
		culture: getCulture(state),
		username: authentication ? authentication.name : null,
		submitting: state.questions.submitting,
		batch: getBatchFromId(match.params.id, state.questions.batches),
		escalated: escalated,
		widgetEnabled: state.general.widget.enabled,
		shouldLogout: state.questions.batches.filter((x) => !x.submitted).length === 0,
		generalQuestion: state.questions.generalQuestion,
	};
};

const mapDispatchToProps = (dispatch, { history }) => {
	return {
		onLogout: () => dispatch(logoutUser()).then(navigateToLogin(history)),
		onBack: () => navigateToBatches(history),
		onQuestionChange: (batch, question) => dispatch(updateQuestion(batch, question)),
		onEscalateSubmit: (data, batch, url) =>
			dispatch(saveTemperatureComments(data, batch, url)).then((result) => logout(result, dispatch)),
		onQuestionTalkSubmit: (data, url, batch) =>
			dispatch(saveQuestionTalk(data, batch, url)).then((result) => logout(result, dispatch)),
		onWidgetClick: () => navigateToWidget(history),
		onQuestionSubmit: (batch) => {
			dispatch(submitBatch(batch)).then((result) => logout(result, dispatch));
		},
		getOptionsForInformationStrategy: (batch, questionTalkExtendedQuestion) => {
			dispatch(getOptionsForInformationStrategy(batch, questionTalkExtendedQuestion)).then((result) =>
				logout(result, dispatch)
			);
		},
		getOptionsForInformationStrategies: (batch, questionTalkExtendedQuestions, escalations) => {
			dispatch(
				getOptionsForInformationStrategies(batch, questionTalkExtendedQuestions, escalations)
			).then((result) => logout(result, dispatch));
		},
		onQuestionTalkExtendedCommentSubmit: (batch, commentData) => {
			dispatch(saveQuestionTalkExtendedComment(batch, commentData)).then((result) =>
				logout(result, dispatch)
			);
		},
		onQuestionTalkExtendedExperienceSubmit: (batch, answer) => {
			dispatch(saveQuestionTalkExtendedExperience(batch, answer)).then((result) =>
				logout(result, dispatch)
			);
		},
		onQuestionTalkExtendedSend: (batch, answers) => {
			dispatch(onQuestionTalkExtendedSend(batch, answers)).then((result) => logout(result, dispatch));
		},
		onToggleChristmasMode: (batch) => {
			dispatch(toggleChristmasMode(batch)).then((result) => logout(result, dispatch));
		},
	};
};

const logout = (result, dispatch) => {
	if (result.status === 401) {
		dispatch(logoutUser()).then(navigateToLogin(history));
	}
};

const getBatchFromId = (id, batches) => {
	var current = batches.filter((x) => x.id === id);
	return current.length > 0 ? Object.assign({}, current[0]) : null;
};

const getTemperatureFromEscalated = (escalated, batches) => {
	var escalatedBatch = batches.filter((x) => x.id === escalated.batch.id);

	if (!escalatedBatch.length) {
		return null;
	}

	return escalatedBatch[0].questions.filter((x) => x.questionId === escalated.questionId)[0];
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withBackgroundHeightAdjust(QuestionContainer))
);
