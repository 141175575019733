import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SvgIcon from "Controls/SvgIcon";
import { spacingIndex, spacing } from "../shared/spacing";
import { colors, transition } from "../shared/theme";
import font from "../shared/font";

const Wrapper = styled.button`
	display: flex;
	align-items: center;
	padding: 0;
	background-color: transparent;
	box-shadow: none;
	border: none;
	box-sizing: border-box;
	transition: ${transition.main};
	color: ${(props) => props.color || colors.onyx};
	cursor: pointer;
	width: 100%;
	${spacing}
	&:hover {
		color: ${colors.purple};
		svg {
			fill: ${colors.purple};
		}
	}
	&:focus {
		outline: none;
		color: ${colors.purple};
		svg {
			fill: ${colors.purple};
		}
	}
	&:disabled {
		color: ${colors.nickel};
		cursor: default;
	}
`;

const Label = styled.span`
	display: block;
	user-select: none;
	color: inherit;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: ${spacingIndex}px;
	${(props) => font(props.fontType || "normalBold")}
`;

const DescriptiveDropdownSelectHeader = (props) => {
	return (
		<Wrapper type="button" {...props}>
			<Label fontType={props.fontType}>{props.label}</Label>
			<SvgIcon icon="chevron-down-16" size="16px" fill={props.color || "inherit"} />
		</Wrapper>
	);
};

DescriptiveDropdownSelectHeader.propTypes = {
	disabled: PropTypes.bool,
	label: PropTypes.string,
	color: PropTypes.string,
	fontType: PropTypes.oneOf([
		"headerCosmic",
		"headerMassive",
		"headerHuge",
		"headerBold",
		"headerMedium",
		"headerRegular",
		"hugeBold",
		"hugeMedium",
		"hugeRegular",
		"largeBold",
		"largeMedium",
		"largeRegular",
		"normalBold",
		"normalMedium",
		"normalRegular",
		"smallBold",
		"smallRegular",
		"microBold",
		"microRegular",
	]),
	onClick: PropTypes.func,
};

DescriptiveDropdownSelectHeader.defaultProps = {
	disabled: false,
	label: "Select",
};

export default DescriptiveDropdownSelectHeader;
