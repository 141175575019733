// this file origin from Winningtemp.Shared

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LineIconComponent = styled.span`
    font-family: "line-icons" !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
    font-variant: normal;
    font-style: normal;
    line-height: ${props => props.lineHeight || "1"};
    color: ${props => props.color || "inherit"};
    font-size: ${props => props.fontSize || "inherit"};
    font-weight: ${props => props.fontWeight || "normal"};
    margin: ${props => props.margin || "0"};
    ${props => props.rotation ? `transform: rotate(${props.rotation});` : null}
    ${props => props.verticalAlign ? `vertical-align: ${props.verticalAlign}` : null };
    &:before {
        content: ${props => props.content};
    }
    @media(max-width: 768px) {
        ${props => props.fontSizeXS ? `font-size: ${props.fontSizeXS};` : ""}
        ${props => props.colorXS ? `color: ${props.colorXS};` : ""}
        ${props => props.lineHeightXS ? `line-height: ${props.lineHeightXS};` : ""}
        ${props => props.marginXS ? `margin: ${props.marginXS};` : ""}
    }
`;

LineIconComponent.propTypes = {
    color: PropTypes.string,
    lineHeight: PropTypes.string,
    fontSize: PropTypes.string,
    fontSizeXS: PropTypes.string,
    fontWeight: PropTypes.string,
    content: PropTypes.string,
    margin: PropTypes.string,
    verticalAlign: PropTypes.string,
	colorXS: PropTypes.string,
	lineHeightXS: PropTypes.string,
	marginXS: PropTypes.string,
    rotation: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
}

export default LineIconComponent;