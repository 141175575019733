// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ContentWrapper from "Controls/ContentWrapper";
import Button from "Controls/Button";
import Text from "Controls/Text";

class QuestionCommentHeaderMobile extends PureComponent {
    render() {
        return (
            <ContentWrapper
                display="none"
                displayXS="flex"
                alignItems="flex-end"
                justifyContent="center"
                backgroundColor="#343a40"
                padding="10px"
                height="60px">
                <Button
                    position="absolute"
                    backgroundColor="transparent"
                    left="15px"
                    fontSize="15px"
                    lineHeight="15px"
                    padding="0"
                    color="#FFFFFF"
                    onClick={this.props.onButtonClick}>
                    {this.props.buttonText}
                </Button>
                <Text
                    fontSize="15px"
                    fontWeight="600"
                    color="#FFFFFF">
                    {this.props.title}
                </Text>
            </ContentWrapper>
        );
    }
}

QuestionCommentHeaderMobile.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func
}

export default QuestionCommentHeaderMobile;