// this file origin from Winningtemp.Shared

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const DURATION = "0.5s";

class SmileyAgree extends PureComponent {
    render() {
        if (!this.props.isWithAnimation) {
            return (
                <g fill="#41464D" fillRule="nonzero" transform={this.props.isTemperature ? "translate(12.52 43.28)" : "translate(12.52 17.28)"}>
                    <ellipse cx="2.77" cy="3.3" rx="2.3" ry="2.3"></ellipse>
                    <path stroke="#41464D" strokeWidth="1.5" fill="none" d="M 5.5 9 q 6 6 12.5 0"></path>
                    <ellipse cx="19.4" cy="3.3" rx="2.3" ry="2.3"></ellipse>
                </g>
            );
        }

        return (
            <g fill="#41464D" fillRule="nonzero" transform={this.props.isTemperature ? "translate(12.52 43.28)" : "translate(12.52 17.28)"}>
                <ellipse cx="2.77" cy="2.74" rx="2.3" ry="2.3">
                    <animate attributeName="cy" dur={DURATION} from="2.74" to="3.3" fill="freeze" />
                </ellipse>
                <path stroke="#41464D" strokeWidth="1.5" fill="none">
                    <animate attributeName="d" dur={DURATION} from="M 6 10 q 5 0 10 0" to="M 5.5 9 q 6 6 12.5 0" fill="freeze" />
                </path>
                <ellipse cx="19.4" cy="2.74" rx="2.3" ry="2.3">
                    <animate attributeName="cy" dur={DURATION} from="2.74" to="3.3" fill="freeze" />
                </ellipse>
            </g>
        );
    }
}

SmileyAgree.propTypes = {
    isWithAnimation: PropTypes.bool,
    isTemperature: PropTypes.bool
}

export default SmileyAgree;