import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "Controls/Button";
import SurveyContainerWrapper from "./Question/SurveyContainerWrapper";
import HeaderWithProfile from "./HeaderWithProfile";
import { QuestionDispatch } from "./Question";
import SurveyGdprContent from "./SurveyGdprContent";
import SurveyGdprAgreement from './SurveyGdprAgreement';

const ContentWrapper = styled.div`
    background: #f7f6f5;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
`;

const GdprWrapper = styled.div`
    max-width: inherit;
`;

const SubmitButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #59b0c7;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    padding: 20px 10px;
    border-radius: 0;
    width: 100%;
    max-width: inherit;
    &:hover {
        background-color:#4a93a5;
    }
`;

const SurveyGdpr = (props) => {
	const { t } = useTranslation();

    return (
		<SurveyContainerWrapper className="with-auto-height-adjust" paddingTopXS="0">
            <ContentWrapper>
                <HeaderWithProfile
                    padding="20px 30px"
                    paddingMedia="20px"
                    username={props.username}
                    culture={props.culture}
					onLogout={props.onLogout} />

                {
                    props.showOnboarding &&
					<div>
                        <SurveyGdprContent culture={props.culture}/>
                        <QuestionDispatch
                            enabled={true}
                            culture={props.culture}
                            onClick={props.onNext}                            
							buttonText={t("Next")} />
                    </div>                    
                }

                {
                    props.showAgreement &&
					<GdprWrapper>
                        <SurveyGdprAgreement culture={props.culture} />
                        <SubmitButton
                            type="button"
                            onClick={props.onAcceptAgreement}>
							{t("IAgree")}
                        </SubmitButton>
                    </GdprWrapper>                    
                }
                
            </ContentWrapper>
        </SurveyContainerWrapper>
    );
}

SurveyGdpr.propTypes = {
    culture: PropTypes.string,
    username: PropTypes.string,    
    showAgreement: PropTypes.bool,
    showOnboarding: PropTypes.bool,
    onAcceptAgreement: PropTypes.func,
    onNext: PropTypes.func,
    onLogout: PropTypes.func
}

export default SurveyGdpr;